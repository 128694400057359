import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, CompanyModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, logoutUser} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { useAppSelector } from '../../../redux/hooks';
import {getAllColumnDefinitions} from '../../../modules/sgl-utils/agGridHelpers';
import useChangeCompany from '../../hooks/use-company-change'
import { customSessionExpired } from '../../sgl-utils/customSessionExpiredDialog'
import { useSearchParams } from "react-router-dom"

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  currentCompany: CompanyModel | undefined
  setCurrentCompany: Dispatch<SetStateAction<CompanyModel | undefined>>
  updatePassword: boolean | undefined
  setUpdatePassword: Dispatch<SetStateAction<boolean | undefined>>
  setCompanies: any,
  companies: any[] 
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  currentCompany: undefined,
  setCurrentCompany: () => {},
  updatePassword: undefined,
  setUpdatePassword: () => {},
  setCompanies: () => {},
  companies: []
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentCompany, setCurrentCompany] = useState<CompanyModel | undefined>()
  const [updatePassword, setUpdatePassword] = useState<boolean | undefined>(false)
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const [companies, setCompanies] = useState([]) // List of companies that user has access to
  const {clearLastSetCompany} = useChangeCompany()

  // When user login set a timeout to display sessionExpired dialog when the session expires
  useEffect(() => {
    let timeoutId: any
    if(currentUser && auth && auth?.refreshToken){
      let user = JSON.parse(atob(auth.refreshToken.split('.')[1]));
      let timeout = (user.exp - user.iat) * 1000
      timeoutId = setTimeout(() => handleTimeout() , timeout )
    }

    return () => {
      if(currentUser && auth){
        clearTimeout(timeoutId)
      }
    }
  }, [currentUser]);

  const handleTimeout = () => {
    const moment = require('moment')
    let authInfo: any = localStorage.getItem("sgl-auth-react-cloud") // get auth info from local storage
    let refreshToken = JSON.parse(authInfo).refreshToken
    if (refreshToken) {
      let user = JSON.parse(atob(refreshToken.split('.')[1]));
      let currentTime = moment().unix() // current time stamp for comparison
      // if refresh token has expired, show session expired dialog.
      if (user.exp <= currentTime) {
        logout(true) // logout user called with true value for inactivityLogout param
        customSessionExpired()
      } else {
        // if refresh token is valid then set new timeout 
        let timeout = (user.exp - currentTime) * 1000
        setTimeout(() => handleTimeout(), timeout)
      }
    } else {
      customSessionExpired()
    }
  }

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async (inactivityLogout = false) => {
    document.title = "ShowGroundsLive"
    if(auth && auth.api_token){
      logoutUser(inactivityLogout)
    }
    await getAllColumnDefinitions(currentUser?.id, customer_id, "remove")
    clearLastSetCompany()
    saveAuth(undefined)

    if (!inactivityLogout) { // adding this check because setting current user and current company to undefined will navigate to login page and we don't want that for inactivity logout
      setCurrentUser(undefined)
      setCurrentCompany(undefined)  
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, currentCompany, setCurrentCompany, updatePassword, setUpdatePassword, logout, companies, setCompanies}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => { 
  const {auth, logout, setCurrentUser, setCompanies } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { changeCompany, getLastSetCompany } = useChangeCompany()
  const [searchParams, setSearchParams] = useSearchParams()

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)

            if (data.smc_access) {
                // const defaultCompany = data.smc_access.find(company => company.company_id === data.default_sgl_cloud_company)
                const defaultCompany = getLastSetCompany();
                const urlCustomerId = Number(searchParams.get("customer_id"))
                if(urlCustomerId){ 
                      let company = data.smc_access.find(c => c.company_id === urlCustomerId)
                      if (company) {
                          // searchParams.delete('customer_id')
                          setSearchParams(searchParams)
                          await changeCompany(company, data as any)  
                      }
                } else {
                  if (defaultCompany) {
                      let latest_default_company = data.smc_access.find( ( smc ) => { return smc.company_id == defaultCompany.company_id })
                      if ( latest_default_company ) {
                        await changeCompany(latest_default_company, data as any)  
                      } else {
                        await changeCompany(defaultCompany, data as any)
                      }
                  }
                }

                setCompanies(data.smc_access)
            } else {
                setCompanies([])
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {  
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
