import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../redux/hooks';

// Active report js CSS files
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import '../../../_metronic/assets/sass/ar-styles.scss'

import { GeneralTab } from './Detail/GeneralTab';
import { DesignerTab } from './Detail/DesignerTab';
import { ReportsHistoryTab } from './Detail/HistoryTab';
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter';
import { useReportsContext, ReportsContextProvider } from './ReportsContext';
import { PageTitle } from '../../../_metronic/layout/core';

import Select from "react-select";
import axios from 'axios';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import useAccessChecker from '../../modules/hooks/use-access-checker';
import history from '../../modules/sgl-utils/unstableHistory';
import { HistoryTab } from '../../modules/components/HistoryTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

const ReportWrapper = () => {
    const intl = useIntl()    
    return (
        <ReportsContextProvider>
            <PageTitle>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
            <ReportsDetail />
        </ReportsContextProvider>
    )
}

const ReportsDetail = (props) => {
    const [reportStorage, setReportStorage] = useState(new Map());
    const intl = useIntl()
    const [templateDefinition, setTemplateDefinition] = useState('');
    const activeReportRef = useRef()
    const { report_id } = useParams();
    const alertDialog = useAlert()
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const { reportName, setReportName, displayName, setDisplayName, setArea, area, setResponse, setMetaData, metaData, setComments, setCustomerId, setPublished, setIsSystemReport, setSinglePrintPerRecord, setSubReportId, customerId, published, isSystemReport, comments, subReportId, singlePrintPerRecord, reportGroup, setReportGroup, systemReport, setSystemReport, subreportPrintAction, setSubreportPrintAction, reportLabel, setReportLabel, allowCsvExport, setAllowCsvExport } = useReportsContext()
    const navigate = useNavigate()
    const loadingOverlay = useLoadingOverlay()
    const { hasSpecialPermissionToAccess, handleDetailPageCatch } = useAccessChecker()
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    const [historyLogs, setHistoryLogs] = useState([])
    const [loading, setLoading] = useState(true)

    // Convert json string to json object
    const getObjectFromJsonString = (stringValue) => {
        let jsonValue = stringValue.slice(0, stringValue.length);
        let convertedObject = jsonValue;
        convertedObject = JSON.parse(convertedObject);
        return convertedObject;        
    }
   
    useEffect(() => {
        // Get report details
        const getReportDetails = async () => {
            loadingOverlay({ show: true})
            await axios.all([
                axios.get( process.env.REACT_APP_NEST_API_URL+ '/reports/details', {
                    params: {
                        report_id: report_id,
                        customer_id: customerID
                    }
                }),
                axios.get( process.env.REACT_APP_NEST_API_URL + '/reports/metaData', {
                    params: {
                        report_id: report_id,
                        customer_id: customerID
                    }
                }),
            ]).then (
                axios.spread( ( {data : reportData}, {data: meta}) => {
                loadingOverlay({ show: false })
                const systemReportEditAccess = hasSpecialPermissionToAccess('cloud_reports:edit-system-reports');

                //Check if record id is invalid then redirect to list page
                if ((reportData || reportData === '') && isInvalidRecord(reportData.report, report_id, 'sgl_id')){
                    navigate('/reports')
                }

                // If the user has no access to edit system reports and the report is a system report, then redirect to list page
                if (reportData?.report?.is_system_report && !systemReportEditAccess) {
                    alertDialog({ message: "You do not have permission to edit system reports."})
                    navigate('/reports')
                }

                setMetaData(meta)
                if (reportData.success) {
                    let report = reportData.report;

                    // If report_id is greater than 0, the record is coming from the database in string from
                    // Then we have to convert it into object
                    // But if report_id = 0 then record is already an object
                    if (report_id > 0) {
                        report.report_definition = getObjectFromJsonString(report.report_definition)
                    }

                    // Report id > 0 has other records such as area, customerid, published details etc. saved in the db
                    if (report_id > 0) {
                        setArea(reportData.report.area)
                        setReportName(reportData.report.name)
                        setDisplayName(reportData.report.name) // Has to have a seperate display name because if we change the report name, then designer is reloaded with changed name, making report to lose all data
                        setCustomerId(reportData.report.customer_id)
                        setPublished(reportData.report.is_published)
                        setIsSystemReport(reportData.report.is_system_report)
                        setComments(reportData.report.notes)
                        setSubReportId(reportData.report.subreport_id)
                        setSinglePrintPerRecord(reportData.report.single_print_per_record)
                        setReportGroup(reportData?.report?.report_group)
                        setSystemReport(reportData?.report?.system_report)
                        setSubreportPrintAction(reportData?.report?.subreport_print_action)
                        setHistoryLogs(reportData?.changeHistoryLogs)
                        setReportLabel(reportData?.report?.report_label)
                        setAllowCsvExport(reportData?.report?.allow_csv_export)
                    } else {
                        setDisplayName(report?.report_definition?.Name)
                    }
                    
                    setTemplateDefinition(report)
                    setResponse(reportData)
                    setLoading(false)
                } else {
                    alertDialog({
                        title: "Error",
                        message: reportData.message
                    });
                    setLoading(false)
                }
            }))
            .catch(reason => { 
                setLoading(false)
                handleDetailPageCatch(reason.response, loadingOverlay, props)
            });
        }

        getReportDetails();
        
    }, [report_id])

    useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    // On report save
    const onSave = function (info) {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        next_button.disabled = false;
        prev_button.disabled = false;
        last_button.disabled = false;
        first_button.disabled = false;

        const reportId = info.id || report_id;
        setReportStorage(new Map(reportStorage.set(reportId, info.definition)));

        // Save report name in the report definition
        info.definition.Name = reportName || info.displayName;

        const report_definition = JSON.stringify(info.definition);

        if(reportName == ''){
            alertDialog({message: intl.formatMessage({ id: "FORM.INPUT.REPORTS.ALERT.REPORTNAME" })})
        } 
        else if(area == ''){
            alertDialog({message: intl.formatMessage({ id: "FORM.INPUT.REPORTS.ALERT.AREA" })})
        } 
        else if(customerId < 0 || customerId == undefined){
            alertDialog({message: intl.formatMessage({ id: "FORM.INPUT.REPORTS.ALERT.COMPANY" })})
        }
        else{
            // Get report parameters from report
            let reportParameters = info.definition.ReportParameters;
            reportParameters = JSON.stringify(reportParameters);

            let data ={ 
                report: {
                    report_definition: report_definition,
                    is_published: published,
                    is_system_report: isSystemReport,
                    area: area,
                    name: reportName,
                    notes: comments,
                    customer_id: customerId,
                    system_report: systemReport,
                    // subreport_id: subReportId,
                    subreport_id: subReportId,
                    single_print_per_record: singlePrintPerRecord,
                    report_parameters: reportParameters,
                    report_group: reportGroup,
                    subreport_print_action: subreportPrintAction,
                    report_label: reportLabel,
                    allow_csv_export: allowCsvExport
                }
            }
            loadingOverlay({show: true})
            // Update report details
            axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/detail', {
                report_id: reportId,
                customer_id: customerID,
                data
            }).then((response) => {
                loadingOverlay({show: false})
                if (response.data.success) {
                    // Use Case (Param Id changed): Next/Prev buttons
                    if(props?.id == undefined && Number(reportId) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                        if(row_selected && row_selected != "" && row_ids[row_selected]){
                            setLoading(true)
                            history.push(`${PUBLIC_URL}/reports/detail/${row_ids[row_selected]}?customer_id=${customerId}${window.location.hash}`, { allowNavigation: true })
                        }
                    }
                    else if(props?.id == undefined && reportId > 0){
                        history.push(`${PUBLIC_URL}/reports`, { allowNavigation: true })
                    }
                    else if(props?.id == undefined && reportId == 0){
                        history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
                    }
                    if( row_selected == row_ids?.length -1){
                        if (next_button && last_button) {
                            next_button.disabled = true;
                            last_button.disabled = true;
                            prev_button.disabled = false;
                            first_button.disabled = false;
                        }
                      }
                    if(row_selected == 0){
                        if (prev_button && first_button) {
                            prev_button.disabled = true;
                            first_button.disabled = true;
                            next_button.disabled = false;
                            last_button.disabled = false;
                        }
                    }
                } else {
                    alertDialog({
                        title: "Error",
                        message: response.data.message
                    });
                }
            })
            .catch(error => { console.error(error); throw error; });

            return Promise.resolve({ displayName: reportId });
        }
    };

    // On save as for report, this will download .rdlx file on system
    const onSaveAs = async (info) => {
        const reportId = info.id || report_id;
        const reportDefinition = JSON.stringify(info?.definition);
        setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
        
        // Download file -- start here
        const blob = new Blob([reportDefinition], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reportId}.rdlx-json`; // Will be downloaded as this name and extension
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);        
        // Download file -- end here

        return Promise.resolve({id: reportId, displayName: reportId });
    }

    // Upload reports in json format (Supported file extensions .rdlx-json and .txt)
    const onUploadReport = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.rdlx-json,.txt';   // Accept only .rdlx-json and .txt files
        // Add an event listener to handle file selection
        fileInput.addEventListener('change', handleFileSelect);   
        fileInput.click();    // Trigger the file input dialog
    }

    // Handle uploaded design file. Read the file and update template definition object
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContents = e.target.result;
            // Check if the file is valid JSON
            try {
                const jsonData = JSON.parse(fileContents);  // Throws error if the file is not a valid JSON

                // Validate report structure, all reports have a name but other fields are optional
                if (!jsonData.Name) {
                    throw new Error('Invalid report structure');
                }

                // Update templateDefinition state
                setTemplateDefinition((prevTemplateDefinition) => ({
                ...prevTemplateDefinition,
                report_definition: jsonData,
                }));
                
            } catch (error) {
                // Handle the case when the file is not valid JSON
                alertDialog({
                    title: "Error",
                    message: "Invalid Report Structure"
                });
            }
        };
        reader.readAsText(file);
      };

    const tabs = ["GENERAL", "DESIGNER", "REVISIONS", "HISTORY"]
    const componentTags = [
      <GeneralTab report_id={report_id} />,
      <DesignerTab 
        save={onSave}
        onSaveAs={onSaveAs}
        report={{  id: report_id, definition: templateDefinition.report_definition,  displayName: displayName}} 
        Width="100%" 
        Height="100%"
        activeReportRef={activeReportRef}
        onUploadReport={onUploadReport}
      />,
      <ReportsHistoryTab />,
      <HistoryTab area="Reports" rowData={historyLogs} />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    const handleSubmit = async (e) => {
        e.preventDefault()
        let report = await activeReportRef.current.getReport()
        onSave(report)
    }
   
    useAutoFocus('reportName', loading)

    return (
        <>
        {!loading && (
        <>
        <div id={"reports_detail_page"} className="modal-body py-3 px-4 show-input-form input-form"> {/* Column Group */}
            <form className='form max-width' onSubmit={handleSubmit} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } }}>
            <HelpLink tooltip="REPORT.DETAIL.LINK.HELP" classname="top" />
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-150px' htmlFor='reportName'  data-tooltip-id="REPORT.DETAIL.LABEL.REPORTNAME">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.LABEL.NAME'})}</label>

                    <div className='col-lg-4'>
                        <input
                            value={reportName}
                            type='text'
                            id='reportName'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            onChange={(e) => setReportName(e.target.value)}
                        />
                    </div>
                    {/* CHECKBOX */}
                    <div className='col-lg-2 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            checked={isSystemReport}
                            className='form-check-input'
                            type='checkbox'
                            id='isSystemReports'
                            onChange={(e) => setIsSystemReport(e.target.checked)}
                            disabled={!hasSpecialPermissionToAccess('cloud_reports:edit-system-reports')}
                        />
                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='isSystemReports' data-tooltip-id="REPORT.DETAIL.LABEL.SYSTEMREPORT">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.SYSTEMREPORT'})}</label>
                    </div>
                </div>

                {/* Report label which will be printed on the pdf */}
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-150px' htmlFor='report_label' data-tooltip-id="REPORT.DETAIL.LABEL.REPORTLABEL">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.LABEL.REPORTLABEL'})}</label>

                    <div className='col-lg-4'>
                        <input
                            value={reportLabel}
                            type='text'
                            id='report_label'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            onChange={(e) => setReportLabel(e.target.value)}
                        />
                    </div>

                    {/* CHECKBOX FOR ALLOWING CSV EXPORT*/}
                    <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            checked={allowCsvExport}
                            className='form-check-input'
                            type='checkbox'
                            id='allowCsvExport'
                            onChange={(e) => setAllowCsvExport(e.target.checked)}
                        />
                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='allowCsvExport' data-tooltip-id="REPORT.DETAIL.LABEL.ALLOWCSVEXPORT">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.ALLOWCSVEXPORT'})}</label>
                    </div>
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-150px' htmlFor='area' data-tooltip-id="REPORT.DETAIL.LABEL.AREA">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.LABEL.AREA'})}</label>

                    {
                        metaData &&
                        <div className='col-lg-4'>
                        <Select
                            placeholder="Select"
                            id="area"
                            isSearchable={true}
                            options={metaData.reportAreas}
                            value={metaData.reportAreas?.find((ss) => ss.value == area)}
                            onChange={(e) => {
                                setArea(e.value)
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                        />    
                    </div>
                    }
                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            checked={published}
                            className='form-check-input'
                            type='checkbox'
                            id='published'
                            onChange={(e) => setPublished(e.target.checked)}
                        />
                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='published' data-tooltip-id="REPORT.DETAIL.LABEL.ACTIVE">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.PUBLISHED'})}</label>
                    </div>
                    {report_id > 0 ? 
                        <div className='col form-check-sm form-check-custom ps-lg-2'>
                            <label className='col-form-label fs-5 fw-bold py-1 mx-1' htmlFor='systemReport' data-tooltip-id="REPORT.DETAIL.LABEL.SYSTEMREPORTNO">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.SYSTEMREPORTNUMBER'})}</label>
                            <label className='col-form-label mx-2 fs-5 fw-bold py-1'>{systemReport}</label>
                        </div>
                    : null}
                </div>
                <div className='d-flex flex-column my-2 border p-2 bg-white'>
                    <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                        <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                            tabIndex="-1"
                                            id={tab + "-tab"}
                                            data-bs-toggle='tab'
                                            href={"#" + tab + "-Reports-" +report_id}
                                            data-tooltip-id={`REPORT.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.REPORTS.TAB.' + tab })}
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>

                    <div className="tab-content mt-5 pb-2" id="myTabContent">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Reports-" +report_id} role="tabpanel" >
                                        <HelpLink tooltip={"REPORT.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                        {componentTags[index]}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            <InputFormFooter goBackPath={'/reports'} data={'report'} />
            </form>
        </div>
        </>
    	)}
        </>
    );
}

export { ReportWrapper as ReportsDetail }