
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import Select from "react-select";
import { NumericFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls';

// Metronic Components
import { KTSVG } from '../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';
import { searchOptions } from '../../modules/sgl-utils/reactSelectOptions';


type Props = {
    show: boolean
    handleClose: () => void
    onSubmitForm:({})=>void
    onClearFilter:()=>void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PaymentBatchesSearchForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const { register, watch, reset, getValues, control, setValue, formState } = useForm({});
    const intl = useIntl()
    const [formMetaData,setFormMetaData]=useState<any>()
    const context=useOutputContext()

    const handleReset = () => {
        reset();
        //reseting dropdowns in the form
        setValue("batchno","")
        setValue("batch_status","")
        setValue("show_id","")
        setValue("start_date","")
        setValue("end_date","")
        setValue("amount_start","")
        setValue("amount_end","")
    }

    useEffect(() => {
        //to pre-fill search fields
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
      },[]);

    const handleSave = async () => {
        let data = getValues();
        // remove prefix $ from amounts >>>
        if(data.amount_start && data.amount_start !== '' && isNaN(data.amount_start)){
            data.amount_start = Number(data.amount_start.replace('$', ''))
        }

        if(data.amount_end && data.amount_end !== '' && isNaN(data.amount_end)){
            data.amount_end = Number(data.amount_end.replace('$', ''))
        }
        // remove prefix $ from amounts <<<
        
        onSubmitForm(data);
    }

    useEffect(() => {
        //calling metadata api to populate form dropdowns
        if(show){
            axios.get(process.env.REACT_APP_NEST_API_URL + '/payment-batches/advanceSearchMetaData', {
                params: {
                customer_id: customer_id
                },
            }).then((res) => {
                if(res.status===200){
                setFormMetaData(res.data)
                }            
            }).catch((e) => {
            })
        }
    },[show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form mw-650px'
            show={show}
            onHide={handleClose}
            onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                  handleSave();
                }
                if (event.key === "Escape") {
                    handleClose();
                }
            }}
        >
            <div className='modal-header py-0 px-4' >
                <h2 className="fs-4" >{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.TITLE.SEARCHPAYMENTBATCHES' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
                
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'> {/* Card Body */}

                        <div className="form-group"> {/* Column Group */}

                            {/* Batch No */}
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='BATCHNO'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.BATCHNO' })}</label>
                                <div className='col'>
                                    <input
                                        id="BATCHNO"
                                        type='text'
                                        {...register('batchno')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.BATCHNO' })}
                                        autoFocus
                                        tabIndex={1}
                                    />
                                </div>
                            </div>

                            {/* Batch Status */}
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='BATCHSTATUS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.BATCHSTATUS' })}</label>
                                <div className='col'>
                                <Controller
                                    name='batch_status'
                                    control = {control}
                                    render={({field: {onChange, value, name}}) => (
                                    <Select
                                        styles={reactSelectStyles}
                                        options={formMetaData?.batch_statuses}
                                        id='BATCHSTATUS'
                                        value={ value ? formMetaData?.batch_statuses.find((ele:any) => { return ele.value === value}) : "" }
                                        name={name}
                                        isSearchable={true}
                                        onChange={(batch_status:any) => {
                                            onChange(batch_status.value)  
                                        }}
                                        placeholder='Select'
                                        tabIndex={2}
                                    />
                                    )}
                                />
                                </div>
                            </div>
                            {/* Show */}
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='SHOW'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.SHOW' })}</label>
                                <div className='col'>
                                <Controller
                                    name='show_id'
                                    control = {control}
                                    render={({field: {onChange, value, name}}) => (
                                    <Select
                                        styles={reactSelectStyles}
                                        options={formMetaData?.shows}
                                        id='SHOW'
                                        value={ value ? formMetaData?.shows.find((ele:any) => { return ele.value === value}) : "" }
                                        name={name}
                                        isSearchable={true}
                                        onChange={(show:any) => {
                                            onChange(show.value)  
                                        }}
                                        placeholder='Select'
                                        tabIndex={3}
                                    />
                                    )}
                                />
                                </div>
                            </div>

                            {/* Posted Between */}
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='POSTEDBETWEEN'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.POSTEDBETWEEN' })}</label>
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="start_date"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY')
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                                id="DATESFROM"
                                                                autoComplete="off"
                                                                name={name}
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onClose={(value: any, dateStr: string, instance: { input: { value: string; }; }) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    } 

                                                                    setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                }}
                                                                onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                tabIndex={4}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <label htmlFor='DATESFROM' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-20px'>&</label>
                                        <div className='col ps-0'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="end_date"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY') // Display format with four-digit year
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                                id="DATESTO"
                                                                name={name}
                                                                autoComplete="off"
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onChange={(value: any) => {
                                                                    console.log("Selected Value:", value[0]);
                                                                    onChange((value && value.length > 0) ? moment(value[0]).format('YYYY-MM-DD') : null); // Format date to YYYY-MM-DD
                                                                }}
                                                                onClose={(value: any, dateStr: string, instance: { input: { value: string; }; }) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    }
                                                                    setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                }}
                                                                onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                tabIndex={5}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <label htmlFor='DATESTO' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Amount Between */}
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='AMOUNTBETWEEN'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENT.BATCHES.LABEL.AMOUNTBETWEEN'})}</label>
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <Controller
                                                name="amount_start"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <NumericFormat
                                                    {...field}
                                                    prefix={intl.formatMessage({ id: 'CURRENCY' })}
                                                    id="AMOUNTBETWEEN"
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO' })}
                                                    tabIndex={6}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-20px'>&</label>
                                        <div className='col ps-0'>
                                            <Controller
                                                name="amount_end"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <NumericFormat
                                                    {...field}
                                                    prefix={intl.formatMessage({ id: 'CURRENCY' })}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    id="amount_end"
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO' })}
                                                    tabIndex={7}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='row mb-2'>
                                <div className='col-lg-1 w-lg-145px pe-0'></div>
                                <div className='col form-check-sm form-check-custom px-3'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='EXACTMATCHESONLY'
                                        {...register("exact_matches")}
                                        tabIndex={8}
                                    />
                                    <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='EXACTMATCHESONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.EXACTMATCHESONLY' })}</label>
                                </div>
                            </div>

                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='SEARCH'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.SEARCH' })}</label>
                                    <div className='col'>
                                    <ReactSelectComponent 
                                        name='search'
                                        id='SEARCH'
                                        options={searchOptions} 
                                        control={control} 
                                        methods={null} 
                                        tabIndex={9}
                                    />
                                    </div>
                                </div>
                        </div> {/* End Column Group */}


                    </div>{/* End Card Body */}

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={() => handleReset()} tabIndex={10}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={11}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={() => handleSave()} tabIndex={12}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { PaymentBatchesSearchForm }