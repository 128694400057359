import { useEffect, useMemo, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { useFormContext } from 'react-hook-form';
import  { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { AddFeeForm } from './Accounts/AddFeeForm';
import { AddPaymentForm } from './Accounts/AddPaymentForm';
import { TransferCreditForm } from './Accounts/TransferCreditForm';
import { RollForwardForm } from './Accounts/RollForwardForm';
import { PaymentDetailForm } from './Accounts/PaymentDetailForm';
import { FeeDetailForm } from './Accounts/FeeDetailForm';
import { SearchInput } from './SearchInput';
import { PersonLookupForm } from './General/PersonLookupForm';
import { DeleteFeeForm } from './Accounts/DeleteFeeForm';
import { useAlert, useConfirm, useFlashAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { ChangeCreditCardForm } from './Accounts/ChangeCreditCardForm';
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { BooleanFilterOptions } from '../../../modules/output-listing/filters/BooleanFilter';

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options } from '../../../modules/sgl-utils/fieldControls';
import DecimalPointRenderer from '../../../modules/output-listing/renderers/DecimalPointRenderer';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { PmrRpChangeDialog } from './Classes/PmrRpChangeDialog';
import { RolledbackPeoplePaymentsDetail } from './Classes/RolledbackPeoplePaymentsDetail';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AccountTab = (props) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const methods = useFormContext()
    const selectRef = useRef();
    const flashAlert = useFlashAlert()
    const loadingOverlay = useLoadingOverlay()
    const [entryFeeGridRef, setEntryFeeRef] = useState()
    const [paymentxEntriesGridRef, setPaymentxEntriesGridRef] = useState()
    const { hasSpecialPermissionToAccess } = useAccessChecker()

    const [onCreditHold, setOnCreditHold] = useState()
    const responsiblePartyId = methods.watch("entry.responsibleparty_id")
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const show_financials_locked = methods.watch('show.islocked')
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [showChangeCCForm, setShowChangeCCForm] = useState(false);
    const secured_payment_method_id = methods.watch('entry.secured_payment_method_id')

    const [oldRpId, setOldRpId] = useState(0);
    const [oldRpSource, setOldRpSource] = useState('');
    const [displayBalanceRefundRollbackDialog, setDisplayBalanceRefundRollbackDialog] = useState(false); // display dialog that show which entries balance will be changed with balance refund rollback
    const [rolledbackBalanceRefundDetail, setRolledbackBalanceRefundDetails] = useState([])
    const [displayRpChangeConfirmDialog, setDisplayRpChangeConfirmDialog] = useState(false); // display confirm dialog before rp change

    const [person_search_status, setPersonSearchStatus] = useState('');
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [people_search_list, setPeopleSearchList] = useState([]);
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })

    useEffect(() => {
        if (entryFeeGridRef && methods.getValues("entryFeesData.entryFees")) {
            entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
        }
    }, [methods.getValues("entryFeesData.entryFees")])

    useEffect(() => {
        if (paymentxEntriesGridRef && methods.getValues("paymentxEntries")) {
            paymentxEntriesGridRef.setRowData(methods.getValues("paymentxEntries"))
        }
    }, [methods.getValues("paymentxEntries")])

    useEffect(() => {
        // Get entry missing stall status
        props.checkMissingEntryStall(methods.getValues('entry'), methods.getValues("entryFeesData"))
        .then(res => methods.setValue("checkMissingEntryStall", res))
    }, [methods.watch("entry.trainer_id"), methods.watch("entryFeesData.entryFees") ])

    useEffect(() => {
        // set responsible party id and name on load
        if(methods.getValues('entry.responsiblepartysource')){
            if(methods.getValues('entry.responsiblepartysource') == '2'){
                selectRef.current.value = methods.getValues("entry.responsiblepartysource")+ '_' + methods.getValues("entry.responsibleparty_id")
            }else if(parseInt(methods.getValues('entry.responsiblepartysource')) > 4){
                selectRef.current.value = '2_'+ methods.getValues('entry.responsibleparty_id')
            }else{
                selectRef.current.value = methods.getValues("entry.responsiblepartysource")
            }
        }
        console.log('responsible_party_search before: ', methods.getValues('responsible_party_search'))

        let key = `linked_people[${methods.getValues("entry.responsibleparty_id")}].people_data.fl_name`
        console.log('responsible_party_search after: ', methods.getValues(key))

        methods.setValue('entry.responsibleparty', methods.getValues(key))
        methods.setValue('responsible_party_search', methods.getValues(key), {shouldDirty: true})

        // set on credit hold label to responsible party's on credit hold
        key = `linked_people[${methods.getValues('entry.responsibleparty_id')}].people_data.on_credit_hold`
        setOnCreditHold(methods.getValues(key))
        setSearchDisabled(selectRef.current.value != "4")

    }, [methods.watch("entry.responsibleparty_id"), methods.watch("entry.responsiblepartysource")])

    const onResponsiblePartyChange = (e) => {
        // set responsible party to the value of selected option (id of that people)
        methods.setValue("entry.responsiblepartysource", e.target.value, {shouldDirty: true})

        switch(e.target.value){
            case '1':
                methods.setValue("entry.responsibleparty_id", methods.getValues('entry.entryowner_id'), {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${methods.getValues('entry.entryowner_id')}].people_data.fl_name`), {shouldDirty: true})
                break

            case '3':
                methods.setValue("entry.responsibleparty_id", methods.getValues('entry.trainer_id'), {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}].people_data.fl_name`), {shouldDirty: true})
                break

                
            case '0':
            case '4':
                methods.setValue("entry.responsibleparty_id", 0, {shouldDirty:true})
                methods.setValue("entry.responsibleparty", '', {shouldDirty: true})
                break
            
            default:
                let rider_id = e.target.value.split('_')[1]
                methods.setValue("entry.responsibleparty_id", rider_id, {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${rider_id}].people_data.fl_name`), {shouldDirty: true})
                methods.setValue("entry.responsiblepartysource",  e.target.value.split('_')[0], {shouldDirty: true})
                break
        }
    }

    const responsiblePartySelect = () => {
        if (methods.getValues("entry")) {
            const entryRiders = methods.watch("entry.EntryRiders")

            let entryRidersOptions;
            // let entryRiderOptions2;
            if (entryRiders) {
                let riderNumber = 0
                entryRidersOptions = entryRiders.map(
                    (er, idx) => {
                        // If rider is deleted don't populate in rp dropdown
                        if(!er.isDeleted){
                            riderNumber++
                            return <option key={riderNumber} value={'2_'+ er.rider_id}>Rider {riderNumber} ({ er.rider_name})</option>
                        }
                    }
                )
            }


            return (
                <select
                    ref={selectRef}
                    className='form-select form-select-sm fs-6 min-h-20px py-1' 
                    onChange={(e) => onResponsiblePartyChange(e)}
                    disabled={show_financials_locked}
                    >
                    <option value='0'>Select...</option>
                    <option value='1'>Owner</option>
                    <option value='3'>Trainer</option>
                    { entryRidersOptions }
                    <option value='4'>Other</option>
                </select>
            )
        }
    }

    // sets entry secured status label based on payment method
    const entrySecuredStatus = methods.getValues("paymentMethod") ?
            `Entry secured using ${methods.getValues("paymentMethod")['redacted'] ? "REDACTED" : ""} credit card ${methods.getValues("paymentMethod")['name_on_card']} - ENDING ${methods.getValues("paymentMethod")['card_lastfour']} - Exp: ${methods.getValues("paymentMethod")['card_exp'].substring(0, 2)}/${methods.getValues("paymentMethod")['card_exp'].substring(2, 4)}` : ""

    // Link text of secure credit card dialog link
    const entrySecuredCCChange = secured_payment_method_id > 0 ? "Change Card" : "Select Card to Secure Entry"

    const setSecurePaymentMethod = async (selectedPaymentMethod) => {
        if(selectedPaymentMethod && Object.keys(selectedPaymentMethod).length > 0 && selectedPaymentMethod['payment_method_id'] != methods.getValues('entry.secured_payment_method_id')){
            const response = await axios.post(`${NEST_API_URL}/entries/getShowSeriesEntries`, {
                customer_id: customer_id,
                entry_id: methods.getValues('entry.entry_id') ?? -1,
                show_id: methods.getValues('entry.show_id'),
                card_owner_id: selectedPaymentMethod.people_id,
                entry: methods.getValues('entry'),
                payment_method_id: selectedPaymentMethod['payment_method_id']
            })

            if(!response.data?.success && response.data?.message){
                alertDialog({ message: response.data?.message })
                return
            }

            if(response.data?.success && response.data?.show_series_entries_count > 0){
                let choice = await confirmDialog({
                    message: `Apply entry secured credit card changes for all shows in ${response.data?.show_series?.series_name}? ${response.data.show_series_entries_count} ${response.data.show_series_entries_count > 1 ? 'entries' : 'entry'} will be updated.`,
                    title: "warning",
                    icon: "warning",
                    okButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.OK'}),
                    cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'})
                })
                
                methods.setValue('update_cc_in_future_entries', choice, {shouldDirty: true})
            }

            methods.setValue('paymentMethod', selectedPaymentMethod)
            methods.setValue('entry.secured_payment_method_id', selectedPaymentMethod['payment_method_id'], {shouldDirty: true})
        }
    }

    // styling for priority order rows in entry fees
    const priorityOrderClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.priority_order',
        };
    }, []);

    const entryFeeColumnDefs = [
        { field: 'ordersupplyfromweb', headerName: "",  sortable: false, minWidth: 70, maxWidth: 70, filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressColumnsToolPanel: true, },
        { field: 'date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DATE' }), cellClass: 'text-center', cellRenderer: DateRenderer, filter: 'agDateColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'quantity', width: 60, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.QUANTITY' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: params => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'description', width: 300, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.FEEDESCRIPTION' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'category', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.CATEGORY' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'unitprice', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.UNITPRICE' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: (params) => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'totalprice', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TOTALPRICE' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: (params) => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'delivered', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DEL' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressMenu: false, suppressMovable: false },
        { field: 'invoice_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.INVOICENUM' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'entry_stall', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.I' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressMenu: false, suppressMovable: false },
        { field: 'ShowFee.ShowTaxRate.tax_abbreviation', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TX' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
    ]

    const entryFeeRowData = methods.getValues("entryFeesData.entryFees")?.filter(entryFee => !entryFee.isDeleted)

    const paymentxEntriesColumnDefs = [
        { field: 'amount_applied', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.AMOUNTAPPLIED' }), filter: 'agNumberColumnFilter', cellRenderer: params => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'Payment.payment_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.PAYMENTDATE' }), cellClass: 'text-center', filter: 'agDateColumnFilter', cellRenderer: DateRenderer, suppressMenu: false, suppressMovable: false },
        { field: 'Payment.type', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TYPE' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false},
        { field: 'Payment.description', flex: 3, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DESCRIPTION' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
    ]

    const paymentxEntriesRowData = methods.watch("paymentxEntries")

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), [])

    const [showAddFeeForm, setShowAddFeeForm] = useState(false)
    const [showDeleteFeeForm, setShowDeleteFeeForm] = useState(false)
    const [showAddPaymentForm, setShowAddPaymentForm] = useState(false)
    const [showTransferCreditForm, setShowTransferCreditForm] = useState(false)
    const [showRollForwardForm, setShowRollForwardForm] = useState(false)
    const [showPaymentDetailForm, setShowPaymentDetailForm] = useState(false)
    const [showFeeDetailForm, setShowFeeDetailForm] = useState(false)
    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const [searchDisabled, setSearchDisabled] = useState(true)
    const [selectedFee, setSelectedFee] = useState({})
    const [selectedPaymentxEntry, setSelectedPaymentxEntry] = useState({})
    const [transferCreditDetail, setTransferCreditDetail] = useState(null)

    const onBlur = (e) => {
        if(e.target.value && e.target.value !=''){
            callSearchPersonApi(e.target.value)
        }
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    const onCellDoubleClick = (params) => {
        setSelectedPaymentxEntry(params.data)
        setShowPaymentDetailForm(true)
    }

    const onCellClicked = (params) => {
        const entryFeeIndex = methods.getValues('entryFeesData.entryFees').findIndex((fee) => fee.entryfees_id === params.data.entryfees_id);
        setSelectedFee({...params.data, rowIndex: entryFeeIndex })
    }

    const onFeeCellDoubleClick = () => {
        setShowFeeDetailForm(true)
    }

    const onTransferCreditToAccount = async () => {
        
        let transferableCredit = parseFloat(methods.getValues('entry')?.balance)

        if(!methods.getValues('entry.responsibleparty_id')){ // show error if responsible party not set for the entry
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.MISSING.PMR.RP' }) });
            return;
        }

        if(!methods.getValues(`linked_people[${methods.getValues('entry')?.responsibleparty_id}].people_data`)){
            alertDialog({message: 'Responsible Party not found.'})
            return;
        }

        if(transferableCredit <= -0.01){ // entry have some credit balance
            let message = ''
            let trips = methods.watch('trips')
            let tripswithUnAppliedPrizeMoney = trips.filter((trip) => trip.total_prize_money > 0 && !trip.prize_money_spent)

            if(tripswithUnAppliedPrizeMoney.length > 0){
                message = `Prize money is not applied to this entry. `
            }

            message = message + `Are you sure you want to transfer ${currencyFormatter(Math.abs(transferableCredit))} to ${methods.getValues('entry')?.responsibleparty} (RP)?`

            let choice = await confirmDialog({
                message,
                title: "warning",
                icon: "warning",
                okButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.OK'}),
                cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'})
            })

            if(choice){
                loadingOverlay({show: true})
                await props.callFormSubmit('move-credit-to-account')// save entry data
                await axios.post(`${NEST_API_URL}/accounting/transferCreditFromEntryToAR`, {
                    customer_id: customer_id,
                    entry_id: methods.getValues('entry.entry_id'),
                    transferCredit: false,
                    ignorePrizeFromBalTransfer: true
                }).then(async (res) => {
                    if(res?.data?.success){
                        // update the paymentxentries records
                        methods.setValue('paymentxEntries', res?.data?.paymentxentries) 
                        
                        //reload entry data
                        await props.reloadPageData(methods.getValues('entry.entry_id'))

                        let message = []
                        if(res?.data?.amountMovedToRP){
                            message.push(`${currencyFormatter(Math.abs(res?.data?.amountMovedToRP))} has been moved to Account ${methods.getValues('entry.responsibleparty')} from Entry #${methods.getValues('entry.number')}.`)
                        }
                        
                        if(res?.data?.amountMovedToPMR){
                            message.push(`${currencyFormatter(Math.abs(res?.data?.amountMovedToPMR))} has been moved to Account ${methods.getValues('entry.prizemoneyrecipient')} from Entry #${methods.getValues('entry.number')}.`)
                        }

                        if(message.length > 0){
                            alertDialog({message: message.join('<br/>')})
                        }
                    }else{
                        alertDialog({ message: res?.data?.error })
                    }
                })

                loadingOverlay({show: false})
            }

        }else{
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.ERRORMESSAGE.NO.CREDIT.FOUND' }) });
        }
    }

    //NOT USED: TEST THIS THOROUGHLY IN CASE NEED TO REUSE THIS
    const onMoveCreditFromAccount = async () => {
        if(methods.getValues('entry.balance') <= 0.01){
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.ACCOUNT.ERROR' }) });
            return;
        }

        if(!methods.getValues('entry.prizemoneyrecipient_id') && !methods.getValues('entry.responsibleparty_id')){
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.MISSING.PMR.RP' }) });
            return;
        }

        await props.callFormSubmit('move-credit-from-account')// save entry data
        
        // get the amount present in Account for PMR and RP
        await axios.post(`${NEST_API_URL}/accounting/getTransferableAmountForEntry`, {
            customer_id: customer_id,
            entry_id: methods.getValues('entry.entry_id'),
            entry: methods.getValues('entry')
        }).then((response) => {

            if(response?.data?.totalCredit && response?.data?.totalCredit > 0.01){
                setTransferCreditDetail(response?.data)
                setShowTransferCreditForm(true)
            }else{
                alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.ACCOUNT.BALANCE.ERROR' }) });
            }
        })
    }

    const missingEntryStallMessage = () => {
        let message = ""
        let className = ""
        if(methods.getValues('checkMissingEntryStall.displayEntryStallMessage')){
            if (methods.getValues("checkMissingEntryStall.stableFeeCount") > 0) { //Entry has stable fee
                let stallsRemaining = methods.getValues("checkMissingEntryStall.stallsRemaining") >= 0? methods.getValues("checkMissingEntryStall.stallsRemaining"): '0'
                message = "Entry using 0 Trainer Stall. Remaining: " + stallsRemaining
            } else if (methods.getValues("checkMissingEntryStall.stallsRemaining") >= 0) { //Trainer inventory for stall can be used?
                message = "Entry using 1 Trainer Stall. Remaining: " + methods.getValues("checkMissingEntryStall.stallsRemaining")
                className = "text-success"
            } else {
                message = "Entry using 0 Trainer Stall. Remaining: 0"
                className = "text-danger"
            }
        }

        return (
            <label className={`col-form-label fs-5 py-1 fw-bold ${className}`} data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRYSTALL">
                { message }
            </label>
        )
    }

    const handleDeleteMultipleFees = () => {
        const selectedRows = entryFeeGridRef.getSelectedRows();
        if (selectedRows.length > 0) {
          let hasRefundFee = false;
          let hasRefundedFee = false;
          let hasSplitFee = false;
      
          for (const row of selectedRows) {
            if (row.refund) {
              hasRefundFee = true;
            } else if (row.refund_id && row.refund_id !== 0) {
              hasRefundedFee = true;
            } else if (row.fee_splitted) {
              hasSplitFee = true;
            }
          }
      
          if (hasRefundFee) {
            alertDialog({ message: "Selected fees are refund fees and cannot be deleted.", title: "error", icon: "ban", });
          } else if (hasRefundedFee) {
            alertDialog({ message: "Some of the selected fees have already been refunded.", title: "error", icon: "ban", });
          } else if (hasSplitFee) {
            alertDialog({ message: "Fees transferred as trainer split cannot be deleted.", title: "error", icon: "ban", });
          } else {
            setSelectedRowData(selectedRows)
            setShowDeleteFeeForm(true)
          }
        }
      };

    const markAsViewed = () => {
        if (entryFeeGridRef.getSelectedRows().length > 0) {
            
            const selectedRow = entryFeeGridRef.getSelectedRows()[0]
            if (selectedRow.ordersupplyfromweb) {
                const entryFees = methods.getValues("entryFeesData.entryFees")
                const index = entryFees.findIndex(ef => ef.entryfees_id == selectedRow.entryfees_id)

                methods.setValue(`entryFeesData.entryFees[${index}].ordersupplyfromweb`, 0)
                methods.setValue(`entryFeesData.entryFees[${index}].isUpdated`, true)

                entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
            }
        }
    }

    //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
    
        let firstName = props.getFirstNameFromSearchString(search_term)
        let lastName = props.getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    async function callSearchPersonApi(search_term){
        let search_response = await props.callSearchPersonApi(search_term, 'PERSON')
        if (search_response.showPersonLookup){
            setDialogData(search_response.dialog_data)
            setPeopleSearchList(search_response.search_list)
            setSearchCountStatus(search_response.people_count_status)
            setDefaultOrg(search_response.default_org)
            setShowPersonLookup(search_response.showPersonLookup)
        } else {
            setPersonSearchStatus(search_response.search_status)
        }      
    }

    async function getSelectedPeople(people, organization, person_role){
        let search_status = await props.getSelectedPeople(people, organization, person_role)
        if(search_status){
            setPersonSearchStatus(search_status)
        }
    }

    async function onAddPayment() { 
        props.getMembershipsUpdatedRecords()
        let form_data = methods.getValues()
        let validate_response = await props.onSubmitData(form_data, 'payments')
        if(validate_response.verified){
            setShowAddPaymentForm(true)
        }
    }

    let changeEntryRP = async() => {

        let balanceRefundRolledback = false
        if(oldRpId && methods.getValues('entry.entry_id') > 0){
            await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/rollbackBalanceRefundForEntry', {
                customer_id: customer_id,
                entry_id: methods.getValues('entry.entry_id'),
                save_record: false,
                responsibleparty_id: oldRpId
            }).then( async response => {
                if(response?.data?.success){
    
                    // update payment records
                    if(response.data.payments && response.data.payments.length > 0){
                        let new_payments = methods.getValues('new_payments')?methods.getValues('new_payments'):[]
                        new_payments.push(...response.data.payments)
                        methods.setValue('new_payments', new_payments, {shouldDirty: true})
                    }
    
                    // update paymentxentries record
                    if(response.data.paymentxEntries && response.data.paymentxEntries.length > 0){
                        let paymentxEntries = methods.getValues('paymentxEntries') ? methods.getValues('paymentxEntries') : []
                        let newPaymentxEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id === methods.getValues('entry')?.entry_id)
                        paymentxEntries.push(...newPaymentxEntries)
                        let newAmount = newPaymentxEntries.reduce((val, pxe) => val + pxe.amount_applied, 0)
                        methods.setValue('entry.balance', methods.getValues('entry.balance') ? methods.getValues('entry.balance') + newAmount : newAmount)
                        methods.setValue('paymentxEntries', paymentxEntries, {shouldDirty: true})
                        let paymentxEntriesForOtherEntries = methods.getValues('paymentxEntriesForOtherEntries') ? methods.getValues('paymentxEntriesForOtherEntries') : []
                        let newPaymentxEntriesForOtherEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id !== methods.getValues('entry')?.entry_id)
                        paymentxEntriesForOtherEntries.push(...newPaymentxEntriesForOtherEntries)
                        methods.setValue('paymentxEntriesForOtherEntries', newPaymentxEntriesForOtherEntries, {shouldDirty: true})
                    }
    
                    // update account register records
                    if(response.data.accountRegisters && response.data.accountRegisters.length > 0){
                        let updated_account_registers = methods.getValues('updated_account_registers')?methods.getValues('updated_account_registers'):[]
                        updated_account_registers = updated_account_registers.concat(response.data.accountRegisters)
                        methods.setValue('updated_account_registers', updated_account_registers, {shouldDirty: true})
                    }
    
                    // update the account register distributions
                    if(response.data.accountRegisterDistributions && response.data.accountRegisterDistributions.length > 0){
                        let updated_account_register_distributions = methods.getValues('updated_account_register_distributions')?methods.getValues('updated_account_register_distributions'):[]
                        updated_account_register_distributions = updated_account_register_distributions.concat(response.data.accountRegisterDistributions)
                        methods.setValue('updated_account_register_distributions', updated_account_register_distributions, {shouldDirty: true})
                    }   

                    balanceRefundRolledback = true
                } else {
                    alertDialog({message: response.data.error, title: 'Error'})
                }
            })
        }
        return balanceRefundRolledback
    }

    useEffect(() => {
        if(!methods.getValues('RTO Action') && !methods.getValues('balanceRefunded')){
            validateRpChange()
        }
    }, [methods.watch("entry.responsibleparty_id"), methods.getValues('RTO Action')])

    const validateRpChange = async() => {
        if(
            oldRpId && 
            methods.getValues('entry')?.entry_id > 0 && //not new entry
            methods?.formState?.dirtyFields?.entry?.hasOwnProperty('responsibleparty_id') && // pmr is changed by user
            oldRpId != methods.getValues('entry.responsibleparty_id') // there is some actual change in pmr
        ){
            // Check if some balance refund transaction was done from the entry
            const response = await axios.get(`${NEST_API_URL}/accounting/entryHasTransferredBalanceRefund?customer_id=${customer_id}&entry_id=${methods.getValues('entry.entry_id')}&people_id=${oldRpId}`)
            if(response.data){
                methods.setValue('RTO Action', 'RP is updating')
                setDisplayRpChangeConfirmDialog(true)
                return
            }
        }

        setOldRpId(methods.getValues('entry.responsibleparty_id'))
        setOldRpSource(methods.getValues('entry.responsiblepartysource'))
    }

    onkeydown = (e) => {
        const activeTab = document.querySelector('.nav-link.active');
        if (activeTab?.id?.includes('ACCOUNT')) {
            if ((e.ctrlKey || e.metaKey) && (e.code === 'KeyM')) { // Ctrl + M
              e.preventDefault();
              setShowAddFeeForm(true);
            }
        }
    }

    const [os, setOS] = useState("");

    useEffect(() => {
        setOS(getOS());
    }, []);

    const getOS = () => {
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Win") !== -1) return "Windows";
        if (userAgent.indexOf("Mac") !== -1) return "MacOS";
        if (userAgent.indexOf("X11") !== -1) return "UNIX";
        if (userAgent.indexOf("Linux") !== -1) return "Linux";
        return "Unknown";
    }

    return (
        <>
            {showAddFeeForm && <AddFeeForm 
                show={showAddFeeForm} 
                handleClose={() => setShowAddFeeForm(false)} 
                formMeta={props.formMeta}
                entryFeeGridRef={entryFeeGridRef}
                callFormSubmit={() => props.callFormSubmit('add-fee')}
            />}
            {showAddPaymentForm && <AddPaymentForm show={showAddPaymentForm} handleClose={() => setShowAddPaymentForm(false)} getSubmitData={props.getSubmitData} getMembershipsUpdatedRecords={props.getMembershipsUpdatedRecords} setLockPrizeMoneyAction={props.setLockPrizeMoneyAction}/>}
            {showTransferCreditForm && <TransferCreditForm show={showTransferCreditForm} handleClose={() => setShowTransferCreditForm(false)} transferCreditDetail={transferCreditDetail} setTransferCreditDetail={setTransferCreditDetail} updateCallbackFromParent={() => props.updateCallbackFromParent()} callFormSubmit={() => props.callFormSubmit('move-credit-from-account')}/>}
            {showRollForwardForm && <RollForwardForm show={showRollForwardForm} handleClose={() => setShowRollForwardForm(false)} />}
            {showPaymentDetailForm && <PaymentDetailForm 
                show={showPaymentDetailForm} 
                handleClose={() => setShowPaymentDetailForm(false)} 
                selectedPaymentxEntry={selectedPaymentxEntry}
            />}
            {showFeeDetailForm && <FeeDetailForm 
                show={showFeeDetailForm} 
                handleClose={() => setShowFeeDetailForm(false)} 
                rowData={selectedFee} 
                entryFeeGridRef={entryFeeGridRef}
                />
            }
            {showDeleteFeeForm && <DeleteFeeForm 
                show={showDeleteFeeForm} 
                handleClose={() => setShowDeleteFeeForm(false)}
                // rowData={selectedFee}
                rowData={selectedRowData}
                entryFeeGridRef={entryFeeGridRef}
                />
            }
            {/* <PersonLookupForm show={showPersonLookup} handleClose={() => setShowPersonLookup(false)} fieldName={'responsible_party_search'} /> */}
            {showPersonLookup && <PersonLookupForm 
                    show={showPersonLookup} 
                    handleClose={() => {methods.setValue('responsible_party_search', methods.getValues('entry.responsibleparty'));setShowPersonLookup(false);}} 
                    customer_id={customer_id} 
                    fieldName={'responsible_party_search'} 
                    dialogData={dialogData}    
                    setDialogData={setDialogData}                 
                    person_search_list={people_search_list}
                    default_org={default_org}
                    person_search_status={search_count_status}
                    type = {'PERSON'}
                    show_id = {currentShowID}
                    handleSearchTerm = {handleSearchTerm}
                    getSelectedPeople = {getSelectedPeople}
                    generateNewRTOId = {props.generateNewRTOId}
                    updatePeople = {props.updatePeople}
            />}
            {showChangeCCForm && <ChangeCreditCardForm 
                show={showChangeCCForm} 
                handleClose={() => setShowChangeCCForm(false)}
                handleSave={setSecurePaymentMethod}
            />}

            { (displayRpChangeConfirmDialog) ? <PmrRpChangeDialog show={displayRpChangeConfirmDialog} handleClose={() => {methods.setValue('RTO Action', ''); setDisplayRpChangeConfirmDialog(false)}} prevPeopleId={oldRpId} setPrevPeopleId={setOldRpId} prevPeopleSource={oldRpSource} setPrevPeopleSource={setOldRpSource} setDisplayEntriesChangesDetail={setDisplayBalanceRefundRollbackDialog} setEntriesAndARChangesDetail={setRolledbackBalanceRefundDetails} entriesAndARChangesDetail={rolledbackBalanceRefundDetail} calledFor={'RP'} rollbackPeoplePayments={changeEntryRP}/> : null }

            { displayBalanceRefundRollbackDialog && <RolledbackPeoplePaymentsDetail show={displayBalanceRefundRollbackDialog} handleClose={() => setDisplayBalanceRefundRollbackDialog(false)} data={rolledbackBalanceRefundDetail} calledFor={'RP'}/> }
            <div className='form-group mb-2'>
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold d-flex mb-5 entries-search-legend">
                        <div className='d-flex align-self-center min-w-lg-150px'><label data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.RESPONSIBLEPARTY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.GROUPLABEL.RESPONSIBLEPARTY' })}</label></div>
                        <div className='d-flex align-items-center my-1 w-150px mx-3'>
                           { responsiblePartySelect() }
                        </div>
                        <SearchInput 
                            containerClass='me-2 w-225px'
                            onSearch={onSearch} 
                            onBlur={onBlur} 
                            disabled={searchDisabled || show_financials_locked} 
                            fieldName={'responsible_party_search'}
                            id={'responsible_party_search'}
                            autoComplete='off'
                        />
                        <div className='col-lg-1 d-flex align-items-center w-lg-25px'>
                            {methods.getValues('entry.responsibleparty_id') > 0 ?<i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: 'white' }}  onClick ={() =>{props.callbackFromParent(methods.getValues('entry.responsibleparty_id'), methods.getValues(`linked_people[${methods.getValues('entry.responsibleparty_id')}]`), "People", "PeopleDetail")}}></i>: null}    
                        </div>
                        <b></b>
                    </legend>
                    <div className='row mb-2'>
                        <div className='col-auto'>
                            <button type="button" disabled={show_financials_locked} onClick={() => setShowAddFeeForm(true)}  className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.ADDFEE">
                                { props?.id === undefined
                                    ? os === 'MacOS'
                                        ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.LABEL.ADDFEEMAC' })
                                        : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.LABEL.ADDFEEWIN' })
                                    : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDFEE' })
                                }
                            </button>
                        </div>
                        <div className='col-auto'>
                            <button 
                                className="btn btn-sm btn-secondary fw-bold text-uppercase"
                                type="button"
                                onClick={markAsViewed}
                                data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.MARKASVIEWED"
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MARKASVIEWED' })}
                            </button>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>
                                {
                                    onCreditHold ?
                                        <div className='col'>
                                            <div className='row'>
                                                <div className='col-lg-1 w-lg-30px d-flex align-items-center'>
                                                    <i className="fas fa-2xl fa-regular fa-triangle-exclamation me-1 text-warning"></i>
                                                </div>
                                                <label className='col fs-6 py-1 pt-2 text-danger'>Responsible Party is on credit hold.</label>
                                            </div>
                                        </div>
                                    :
                                        ""
                                }
                                { hasSpecialPermissionToAccess('cloud_entries:delete-fee-from-entries') &&
                                <div className='col-auto'>
                                    <button type="button" disabled={show_financials_locked} onClick={handleDeleteMultipleFees} className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.DELETEFEE">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.DELETEFEE' })}
                                    </button>
                                </div> }
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <OutputListingGridStatic 
                            area ={"Entries - Account Fees"} 
                            columnDefs={entryFeeColumnDefs} 
                            rowData={methods.getValues("entryFeesData.entryFees")?.filter(entryFee => !entryFee.isDeleted) ?? []} 
                            containerStyle={containerStyle} 
                            onCellDoubleClick={onFeeCellDoubleClick} 
                            onCellClicked={onCellClicked}
                            rowClassRules={priorityOrderClassRules}
                            setGridRef={setEntryFeeRef}
                            selection={'single'}
                            />
                    </div>
                    <div className='d-flex flex-row justify-content-between mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRYFEESCOUNT">
                        { entryFeeRowData?.length > 0? entryFeeRowData.length : 0} {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.FEES' })}
                        </label>
                        <div className='d-flex'>
                            <label className='col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.RP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.RP' })}
                                <span className='fw-normal'>
                                    &nbsp;
                                    {  
                                        currencyFormatter(methods.getValues(`linked_people[${responsiblePartyId}].rtoBalance`))  
                                    }
                                </span>
                            </label>
                            <label className='col-form-label fs-5 py-1 fw-bold ms-5' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.PMR">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.PMR'})}
                                <span className='fw-normal'>
                                    &nbsp;
                                    {  
                                        currencyFormatter(
                                            methods.getValues(`linked_people[${(methods.getValues("entry.prizemoneyrecipient_id"))}].rtoBalance`)
                                        )
                                    }
                                </span>
                            </label>
                        </div>
                        { missingEntryStallMessage() }
                    </div>
                    <div className='row mb-2'>
                        <div className='col-lg-1 w-lg-150px'>
                            <button type="button" disabled={show_financials_locked} onClick={() => onAddPayment()} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ADDPAYMENT">
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDPAYMENT' })}
                            </button>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-1 w-lg-150px'>
                                    {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                                    {/* <button type="button" disabled onClick={onRollForwardClick} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ROLLFORWARD' })}
                                    </button> */}
                                </div>
                                <div className='col-lg-1 w-lg-225px mb-2'>
                                    {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                                    {/* <button type='button' onClick={onMoveCreditFromAccount} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.MOVECREDITFROMACCOUNT">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MOVECREDITFROMACCOUNT' })}
                                    </button> */}
                                </div>
                                <div className='col-lg-1 w-lg-225px'>
                                    {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                                    <button type='button' onClick={onTransferCreditToAccount} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.MOVECREDITTOACCOUNT">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MOVECREDITTOACCOUNT' })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <OutputListingGridStatic 
                            area ={"Entries - Account 2"} 
                            columnDefs={paymentxEntriesColumnDefs} 
                            rowData={paymentxEntriesRowData} 
                            containerStyle={containerStyle} 
                            setGridRef={setPaymentxEntriesGridRef}
                            onCellDoubleClick={onCellDoubleClick}
                        >                                
                        </OutputListingGridStatic>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.PAYMENTSCOUNT">
                            { paymentxEntriesRowData.length + " " + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.PAYMENTS' })}
                        </label>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.WHTAX">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.WHTAX' })}
                            <span className='fw-normal'>
                                &nbsp;
                                { currencyFormatter(methods.getValues("withHoldingTax")) }
                            </span>
                        </label>
                        <label onClick={() => setShowChangeCCForm(true)} className={`col col-form-label fs-5 py-1 text-${methods.getValues("paymentMethod.redacted") ? "danger" : "success"} text-end`} data-tooltip-id="">
                            { entrySecuredStatus }
                        </label>
                        <div className='col-auto'>
                            <button type="button" onClick={() => setShowChangeCCForm(true)} className="btn btn-sm btn-secondary fw-bold px-4 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.SELECTCARDTOSECUREENTRY">
                            {entrySecuredCCChange}
                            </button>
                        </div>
                    </div>
                </fieldset>
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.GROUPLABEL.RESERVATIONPAYMENT' })} <b></b></legend>
                    <div className='row mb-2'>
                        <div className='col-lg-1 form-check-sm form-check-custom w-lg-125px'>
                            <label className='col-form-label mx-3 fs-5 py-1' htmlFor='RECIEVED' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.RECEIVED"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.RECIEVED' })}</label>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='RECIEVED'
                            />
                        </div>
                        <label className='col-form-label mx-2 fs-5 py-1 col-lg-1 w-lg-40px' htmlFor='DATE' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.DATE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.DATE' })}</label>
                        <div className='col-lg-2'>
                            <div className="input-group date">
                                <Flatpickr
                                    className="form-control form-control-sm mb-lg-0  fs-6 min-h-20px py-1"
                                    id="DATE"
                                    placeholder="Select Date"
                                    options={date_options}
                                    autoComplete={"off"}
                                />
                                <div className="input-group-append">
                                    <label htmlFor='DATE' className='date-label'>
                                        <span className="input-group-text">
                                            <i className="la la-calendar"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label className='col-form-label mx-2 fs-5 py-1 col-lg-1 w-lg-40px' htmlFor='NOTES' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.NOTES"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.NOTES' })}</label>
                        <div className='col'>
                            <input
                                id='NOTES'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.NOTES' })}
                            />
                        </div>
                    </div>
                    {/* <div className='row mb-2'>
                        <label className='col col-form-label fs-5 py-1 fw-bold text-end' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.TRANSFERABLECREDIT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.TRANSFERABLECREDIT' })} <span className='fw-normal'>$100.00</span></label>
                    </div> */}
                </fieldset>
            </div>
        </>
    );
}

export { AccountTab }