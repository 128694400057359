import { useMemo, useState, useEffect} from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import { MembershipTab } from './AdditonalTab/MembershipTab'
import { AdditionalManualOverrideForm } from './AdditonalTab/AdditionalManualOverrideForm'
import { USEFMembershipTab } from './AdditonalTab/USEFMembershipTab'
import { FeiEcMembershipTab } from './AdditonalTab/FeiEcMembershipTab'
import { useFormContext} from 'react-hook-form'
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

// Assets
import "flatpickr/dist/themes/material_blue.css"
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';

// Constants
import { renderIcon, getExtraNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import { useAppSelector } from '../../../redux/hooks'
import moment from 'moment'
import TaxFormInformationModal from './AdditonalTab/TaxFormInformaionModal';
import { allowManualOverrideForRTO, deleteMembershipOverride, isEcCoachMembershipValidByShowLevel, isEcMembershipValid } from './AdditonalTab/MembershipFunctions';
import { DateStringEditor } from '../../../modules/sgl-utils/DateStringEditor';

const AdditionalTab = (props) => {
    
    const [membershipGridState, setMembershipGridState] = useState(null)
    const [showManualOverrideForm, setManualOverrideForm] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [organization_members_added, setOrganizationMembersAdded] = useState([])
    const [rowData, setRowData] = useState([])
    const [tab_names, setTabNames] = useState([])

    const intl = useIntl();
    const methods = useFormContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const currentShowEndDate = useAppSelector(state => state.currentShow.end_date);
    const organizationMembersData = methods.watch('organizationMembers');
    const [showUsefMismatchForm, setShowUsefMismatchForm] = useState(false)
    const [FEINameMismatch, setFEINameMismatch] = useState(false)
    const [ECNameMismatch, setECNameMismatch] = useState(false)
    const [personFirstName, setPersonFirstName] = useState("")
    const [personLastName, setPersonLastName] = useState("")
    const alertDialog = useAlert()
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const sglLite = useAppSelector(state => state.showCompany.sgl_lite)
    const loadingOverlay = useLoadingOverlay();

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }
    
    const organizationTabs = () => {
        const organizationTabNames = ["USEF", "EC", "FEI"]
        if(methods.getValues('ushja_tab') == true){
            organizationTabNames.push("USHJA")
        }
        if(methods.getValues('norcal_tab') == true){
            organizationTabNames.push("Norcal")
        }
        if(methods.getValues('ohja_tab') == true){
          organizationTabNames.push("OHJA")
      }
        return organizationTabNames
    }

    useEffect(() => {
        priorityColor(methods.watch('organizationMembers'))
        setTabNames(tabName())
    }, [organizationMembersData])

    useEffect(() => {
        if(membershipGridState && methods.getValues('organizationMembers')){
            //   membershipGridState.setRowData(methods.getValues('organizationMembers'))
            updateAGGridWithoutFlickering(({ current: { api : membershipGridState}}), methods.watch('organizationMembers')) // update grid without rerendering 
      
        }
    }, [methods.watch('organizationMembers')])

    function tabName() {
        const tabs = []
        if(methods.getValues('organizationMembers')){
            let organizationMembers = methods.getValues('organizationMembers')
            let membershipFilter = organizationMembers?.map(data => {return data.organization_code.toUpperCase()})
            let existingTabs = organizationTabs()

            existingTabs.map((data, i) => {
                if(membershipFilter.includes(data.toUpperCase())){
                    let index = organizationMembers.findIndex((datas) => {  return data.toUpperCase() == datas.organization_code.toUpperCase()})
                    if(index >= 0){
                        tabs.push({"name": organizationMembers[index]?.organization_code, "membership_number": organizationMembers[index]?.membership_number, "organization": organizationMembers[index]?.organization_code})
                    }
                }  
            })
            
        }
        return tabs
    }

    const columnDefs = [
        { field: 'organization_code',  headerName:"",  cellRenderer: renderIcon, maxWidth: 30, lockVisible: false, lockPosition: 'left', cellClass: 'text-center pl-r-5 image-cell', headerClass: 'ag-header-cell-wrap-text pl-r-5' },
        { field: 'organization_code',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.CODE' }),  maxWidth: 150, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
        { field: 'membership_number', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.MEMBERSHIPID' }),  minWidth: 150, lockVisible: false,  editable: (params) => checkMembershipNumberEditable(params).editable, cellClass: (params) => checkMembershipNumberEditable(params).cellClass, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
        { field: 'valid_start', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.STARTDATE' }),  lockVisible: false, lockPosition: 'left', cellEditor: DateStringEditor, editable: (params) => checkDateEditable(params).editable, minWidth: 130, maxWidth: 180, cellClass: (params) => checkDateEditable(params).cellClass, cellRenderer: DateRenderer, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellStyle: {textAlign: 'right'} },
        { field: 'valid_end', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ENDDATE' }),  lockVisible: false, lockPosition: 'left', cellEditor: DateStringEditor, editable: (params) => checkDateEditable(params).editable, minWidth: 130, maxWidth: 180, cellClass: (params) => checkDateEditable(params).cellClass, cellRenderer: DateRenderer, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellStyle: {textAlign: 'right'} },
        { field: 'membership_level', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.LEVEL' }),  minWidth: 200, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellClass: 'non-editable-cell', cellStyle: {textAlign: 'left'}, valueGetter: function getMembershipLevel(params) { 
            if(params?.data?.organization_code)
            {
                if(params?.data?.organization_code === 'JA' || params?.data?.organization_code === 'Tax Status'){
                    //Display membership level
                    return params?.data?.membership_level
                }else if(params?.data?.organization_code === 'EC'){
                    if(params?.data?.membership_override?.override_membershiplevel){// override exists than 
                        //Display override membership level
                        return params?.data?.membership_override?.override_membershiplevel
                    }else if(params?.data?.membership_number){
                        //Display membership level
                        return params?.data?.membership_level
                    }
                }
            }else{
              return ''
            }
        }}
    ]

    // function to check if membership id is editable, in case of Tax Status, it's not editable
    function checkMembershipNumberEditable(params) {
        const { data } = params.node;
        let isEditable = true;
        const disableEditingForOrgs = ['Tax Status', 'EC-SAFESPORT']
        if (disableEditingForOrgs.includes(data.organization_code)) {
            isEditable = false;
        }

        const cellClass = `${isEditable ? 'editable-cell' : 'non-editable-cell'} ag-left-aligned-cell`;
        return {
          editable: isEditable,
          cellClass: cellClass,
        };
    }

    // function to check if membership date is editable
    function checkDateEditable(params) {
        const { data } = params.node;
        let isEditable = !(data.managed && data.system_org);

        const disableEditingForOrgs = ['Tax Status', 'EC-SAFESPORT']
        // If Tax Status row, then date editing should not be allowed
        if (disableEditingForOrgs.includes(data.organization_code)) {
            isEditable = false;
        }

        const cellClass = isEditable ? 'editable-cell' : 'non-editable-cell';
        return {
          editable: isEditable,
          cellClass: cellClass,
        };
    }
  
    // const rowData = methods.getValues('organizationMembers')

    const rowClassRules = useMemo(() => {
        return {
            'bg-override': 'data.override',
            'bg-expired': 'data.expired',
            'bg-current': 'data.current',
            'bg-valid': 'data.valid',
            'bg-special': 'data.special',
            'bg-none': 'data.none',
        };
    }, []);

    const onCellDoubleClick = (params) => {

        if(params.colDef.field == "icon" || params.colDef.field == "organization_code"){
            setSelectedRow(params.data)
            if (params.data.organization_code == 'Tax Status') {
                props.setShowTaxInformation(true);
            }else if(params.colDef.field == "organization_code" && allowManualOverrideForRTO(params.data.organization_code)){
                // if org_code column is clicked and organization can perform override than open override form
                setManualOverrideForm(true)// open mamual override form
            }
        }
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '100vh' }), []);

    const renderMembershipTab = (tab) => {
        switch (tab.name) {
            case "USEF": 
                return <USEFMembershipTab membership_number={tab.membership_number} membershipGridState = {membershipGridState} removeMembership={removeMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup} getLookupData={getLookupData} showUsefMismatchForm={showUsefMismatchForm} setShowUsefMismatchForm={setShowUsefMismatchForm} />
            case "EC": 
                return <FeiEcMembershipTab tabname={tab.name} membership_number={tab.membership_number} membershipGridState = {membershipGridState} removeMembership={removeMembership} clearMembership={clearMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup}  getLookupData={getLookupData} personFirstName={personFirstName} personLastName={personLastName} FEINameMismatch={FEINameMismatch} setFEINameMismatch={setFEINameMismatch} ECNameMismatch={ECNameMismatch} setECNameMismatch={setECNameMismatch}/>
            case "FEI": 
                return <FeiEcMembershipTab tabname={tab.name} membership_number={tab.membership_number} membershipGridState = {membershipGridState} removeMembership={removeMembership} clearMembership={clearMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup}  getLookupData={getLookupData} personFirstName={personFirstName} personLastName={personLastName} FEINameMismatch={FEINameMismatch} setFEINameMismatch={setFEINameMismatch} ECNameMismatch={ECNameMismatch} setECNameMismatch={setECNameMismatch}/>
            default: 
                return <MembershipTab tabname={tab.name} membership_number={tab.membership_number} membershipGridState = {membershipGridState} removeMembership={removeMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup} getLookupData={getLookupData}/>
        }
    }

    const priorityColor = (organizationMembers) => {
        let rowDataleftGrid = organizationMembers?.map((data) => {
            // initializing color schemes
            data.none = false;
            data.expired = false;
            data.special = false;
            data.override = false;
            data.valid = false;

            if (data.membership_number && data?.membership_number != "")
                {
                    let ab_horseMembershipValid = false;
                    let membership_color = 'white';
                    
                    let today = moment().format('YYYY-MM-DD'); // default  //ADD Time zone info
                    // if(props.form_meta.timezone_info){
                    //     today = moment(props.form_meta.timezone_info.date)
                    // }
                    let orgToCheckEndDateOnly = ['EC', 'EC-Coach'] // we validate membership against end date only and ignore start date
                    data.is_membershipvalid = false
                    ab_horseMembershipValid = false
                    if(orgToCheckEndDateOnly.includes(data.organization_code)){
                        // Handle color coding for EC and EC-Coach
                        if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !=''){
                            if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD'))){ 
                                // If membership end date is valid by current date
                                data.is_membershipvalid = true
                                ab_horseMembershipValid = true
                                
                            }else{
                                // If membership end date is valid by show end date for EC-Coach only
                                if(data.organization_code == 'EC-Coach' && moment(currentShowEndDate).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD'))){
                                    data.is_membershipvalid = true
                                    ab_horseMembershipValid = true
                                }
                            }
                            // Check membership validation for special cases if membership is already valid
                            if(data.organization_code == 'EC-Coach' && data.is_membershipvalid){
                                let isCoachMembershipValid = isEcCoachMembershipValidByShowLevel(data.membership_number, methods.getValues('show_data.ec_rating'))
                                if(!isCoachMembershipValid){
                                    data.is_membershipvalid = false
                                    ab_horseMembershipValid = false
                                }
                            }else if(data.organization_code == 'EC' && data.is_membershipvalid){
                                let membersipLevel = ''
                                let show_data = methods.getValues('show_data')
                                if(data?.membership_override?.override_membershiplevel){// override exists than 
                                    membersipLevel = data?.membership_override?.override_membershiplevel
                                }else{
                                    membersipLevel = methods.getValues('people.ec_sport_license_type')
                                }
                                if(show_data?.require_ecmembership && show_data?.require_membership_type != ""){
                                    let ecMembershipValid = isEcMembershipValid(membersipLevel, show_data?.require_membership_type)
                                    if(!ecMembershipValid){
                                        data.is_membershipvalid = false
                                        ab_horseMembershipValid = false
                                    }
                                }
                            }
                        }
                    }else{
                        if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !=''){
                            if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(data.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                                data.is_membershipvalid = true
                                ab_horseMembershipValid = true
                            }
                        }
                    }
                    
                    // }

                    let membershipOverride = data.membership_override
                       
                    if(membershipOverride && membershipOverride.inactive_override_date >= currentShowEndDate){
                        // al_membershipcolor = '#FFFF00' //Yellow Color
                        membership_color = 'yellow'
                        if(membershipOverride.owner_nonmemberfeepaid || (membershipOverride.ec_override_reason == 20 && membershipOverride.organization_code == 'EC')){
                            // al_membershipcolor = '#FFA500' //Orange Color
                            membership_color = 'orange'
                        }
                    } else if (ab_horseMembershipValid){
                        // al_membershipcolor = '#00FF00' //Green Color
                        membership_color = 'green';

                        // Check USEF suspension and change row color to red if suspended, regardless of their current membership validity
                        if (methods.watch('people') && methods.watch('people.usef_suspension') == true && data?.organization_code === 'USEF') {
                            membership_color = 'red';
                        }

                        // Check EC suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (methods.watch('people') && methods.watch('people.ec_standingsstatus') == "Not In Good Standing" && data?.organization_code === 'EC') {
                            membership_color = 'red';
                        }

                        // Check FEI suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (methods.watch('people') && methods.watch('people.fei_isactive') == false && data?.organization_code === 'FEI') {
                            membership_color = 'red';
                        }
                    }else {
                        if(!data.valid_end || data?.valid_end == '0000-00-00' || data?.valid_end == ''){ //change format
                            // ec statuses in array
                            let ec_inactive_statuses = ['DECEASED', 'RETIRED', 'SOLD'];
                            if(membershipOverride && membershipOverride.membership_number != "" && membershipOverride.organization_code == "EC" && 
                            ec_inactive_statuses.includes(methods.getValues("people.ec_status") )){
                            // al_membershipcolor = "#FF0000" //Red
                            membership_color = 'red'
                             } else {
                                    // al_membershipcolor = "#FFFFFF" //White
                                    membership_color = 'white'
                            }
                        } else{
                                // al_membershipcolor = "#FF0000" //Red
                                membership_color = 'red'
                        }
                    }

                    // Set membership color for tax status override (in case of status = review or if status = manual and tin is not verified)
                    if (data.organization_code == 'Tax Status') {
                        if (data.membership_override) {
                            membership_color = 'yellow'
                        }
                    }
            
                    switch(membership_color){
                        case 'white':
                            data.none = true;
                            break;
                        case 'red':
                            data.expired = true;
                            break;
                        case 'orange':
                            data.special = true;
                            break;
                        case 'yellow':
                            data.override = true;
                            break;
                        case 'green':
                            data.valid = true;
                            break;
                    }
            } else{
                data.none = true
            }
            return data
        })

        // membershipGridState?.setRowData(rowDataleftGrid)
        if(membershipGridState){
            updateAGGridWithoutFlickering(({ current: { api : membershipGridState}}), rowDataleftGrid) // update grid without rerendering 
        }
    }

    useEffect(() => {
        methods.setValue('organization_members_added',organization_members_added,{shouldDirty:true})
    }, [organization_members_added])

    const getMembershipVariable = (organization_code) => {
        let lookup_variable = ''

        switch(organization_code.toUpperCase()){
            case 'USEF':
                lookup_variable = 'usef_response_eti_lookup'
                break;

            case 'EC':
                lookup_variable = 'ec_lookup_response'
                break;

            case 'FEI':
                lookup_variable = 'fei_lookup_response'
                break;

            case 'USHJA':
                lookup_variable = 'ushja_lookup_response'
                break;

            case 'OHJA':
                lookup_variable = 'ohja_lookup_response'
                break;

            case 'NORCAL':
                lookup_variable = 'norcal_lookup_response'
                break;
        }

        return lookup_variable
    }

    function onCellValueChanged(params) {
        const moment = require('moment');
        const inValidValues = [undefined, '00/00/0000', '0000-00-00']

        const updatedData = params.data;
        // Update organizationMembers with the modified row data
        const organizationMembers = methods.getValues('organizationMembers').map(member => 
            member.sgl_id === updatedData.sgl_id ? updatedData : member
        );
        methods.setValue('organizationMembers', organizationMembers);
    
        if (params.node.data.valid_start && !inValidValues.includes(params.node.data.valid_start)) { //format valid_start date if not formattted already on input
            if(!params.node.data.valid_start.includes('/') && !params.node.data.valid_start.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_start = `${params.node.data.valid_start.slice(0, 2)}/${params.node.data.valid_start.slice(2, 4)}/${params.node.data.valid_start.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }

        if (params.node.data.valid_end && !inValidValues.includes(params.node.data.valid_end)) { //format valid_end date if not formattted already on input
            if(!params.node.data.valid_end.includes('/') && !params.node.data.valid_end.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_end = `${params.node.data.valid_end.slice(0, 2)}/${params.node.data.valid_end.slice(2, 4)}/${params.node.data.valid_end.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }
        
        let updated_memberships_list = onMembershipUpdate(params.node.data, organization_members_added)
        setOrganizationMembersAdded([...updated_memberships_list])
        let lookup_variable = getMembershipVariable(params.node.data?.organization_code)
        
        if(lookup_variable){
            methods.setValue(`people.${lookup_variable}`, '', {shouldDirty: true})
        }
        
        if(lookup_variable){
            if(params.node.data?.membership_number && params.node.data?.membership_number != ''){
                methods.setValue('lookup_organization', params.node.data.organization_code)
            }
        }
    }
    function onMembershipUpdate(membership_data, added_memberships){
        if(membership_data.membership_number == ""){
            membership_data.valid_start = '0000-00-00'
            membership_data.valid_end = '0000-00-00'
            membership_data.membership_level = ""

            if(membership_data.organization_code == 'EC'){
                methods.setValue('people.ec_sport_license_type', '', { shouldDirty: true})
            }
            //Remove membership override when membership_number is empty
            let membership_override_added = methods.getValues('membership_override_added') ?? []
            let membership_override_removed = methods.getValues('membership_override_removed') ?? []
            let updatedData = deleteMembershipOverride(membership_data, membership_override_added, membership_override_removed)
            methods.setValue('membership_override_added', updatedData.membership_override_added, { shouldDirty: true })
            methods.setValue('membership_override_removed', updatedData.membership_override_removed, { shouldDirty: true })
        }

        if(membership_data.valid_end && moment(membership_data.valid_end).isValid()){
            let valid_end = moment(membership_data.valid_end).isValid()?moment(membership_data.valid_end):'';
            if(membership_data.membership_number !="" && valid_end.isSameOrAfter(moment())){
                membership_data.is_membershipvalid = true;
            }
        }

        let found_code = ''
        if(added_memberships && added_memberships.length > 0){
            found_code = added_memberships?.some(el => el.organization_code === membership_data.organization_code);
        }

        if(found_code){
            let objIndex = added_memberships.findIndex((obj => obj.organization_code == membership_data.organization_code));
            added_memberships[objIndex].organization_code = membership_data.organization_code;
            added_memberships[objIndex].membership_number = membership_data.membership_number;
            added_memberships[objIndex].membership_level = membership_data.membership_level;
            added_memberships[objIndex].name_mismatch_accepted = membership_data.name_mismatch_accepted;
            added_memberships[objIndex].is_membershipvalid = membership_data.is_membershipvalid;
            added_memberships[objIndex].valid_start = (membership_data.valid_start && moment(membership_data.valid_start).isValid())? membership_data.valid_start:'';
            added_memberships[objIndex].valid_end = (membership_data.valid_end && moment(membership_data.valid_end).isValid())? membership_data.valid_end:'';
            added_memberships[objIndex].membership_level = membership_data.membership_level ?? '';
        }
        else{
            membership_data.valid_start = (membership_data.valid_start && moment(membership_data.valid_start).isValid())? membership_data.valid_start:'';
            membership_data.valid_end = (membership_data.valid_end && moment(membership_data.valid_end).isValid())? membership_data.valid_end:'';
            added_memberships.push(membership_data)
        }
        priorityColor(methods.watch('organizationMembers'))
        return added_memberships;
    }

    useEffect(() => {

        let org = methods.getValues('lookup_organization')
        
        let end_point = '';
        if(org && org != ''){
            // call lookup data apis
            let memObj = {};
            membershipGridState.forEachNode(node => {
                if(org == node.data.organization_code)
                memObj = node.data
              });
            if(org == 'USEF'){
                end_point = 'usefLookup'
            } else if(org == 'FEI' /*&& !sglLite*/){
                end_point = 'feiLookup'
            } else if(org == 'EC'){
                end_point = 'ecLookup'
            } else if(org == 'USHJA'){
                end_point = 'ushjaLookup'
            } else if(org == 'OHJA'){
                end_point = 'emLookup'
            } else if(org.toUpperCase() == 'NORCAL'){
                end_point = 'emLookup'
            }
            if(end_point != ''){
                getLookupData(org, memObj, end_point)
            }
        }
    }, [methods.watch('lookup_organization')])

    async function getLookupData(org, memObj, end_point, accept_mismatch_checked= false, associated_membership = ''){
        if(methods.getValues('people.isfarmrto') && end_point == 'ushjaLookup'){
            return;
        }

        loadingOverlay({show: true})
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/people/' + end_point, {
            params: {
                people: methods.getValues("people"),
                customer_id: customer_id,
                membership_obj: memObj,
                accept_mismatch_checked: accept_mismatch_checked,
                associated_membership: associated_membership
            }
        }).then((response) => {
            if(response.data.success) {
                methods.setValue('people', response.data.people, {shouldDirty: true})
                // setpersonUpdateData(result.data.person) // adding for now to update 
                if(response.data.updated_memberships_list) {
                    updateMembershipsDataFromLookup(response.data.updated_memberships_list, response.data.low_priority_orgs)
                }
                if(response.data.mismatch_fields && response.data.mismatch_fields.length > 0) {
                    if(org == 'USEF'){
                        methods.setValue('usef_lookup_field', response.data.mismatch_fields)
                        if(response.data.usef_show_pass_override){
                                // let usef_show_pass_overrides = methods.getValues('usef_show_pass_overrides')
                                // if (usef_show_pass_overrides){
                                //     if(!(usef_show_pass_overrides.find(s => s.show_id == response.data.usef_show_pass_override.show_id))){
                                //         usef_show_pass_overrides.push(response.data.usef_show_pass_override)
                                //         methods.setValue('usef_show_pass_overrides', usef_show_pass_overrides, {shouldDirty: true})
                                //     }
                                // } else {
                                methods.setValue('usef_show_pass_overrides', [response.data.usef_show_pass_override], {shouldDirty: true})

                            // }
                        }
                        setShowUsefMismatchForm(true)
                    } else if (org == 'FEI'){
                        let mismatch_field = response.data.mismatch_fields[0]
                        setPersonFirstName(mismatch_field.first)
                        setPersonLastName(mismatch_field.last_name)
                        setFEINameMismatch(true)
                    }else if (org == 'EC'){
                        let mismatch_field = response.data.mismatch_fields[0]
                        setPersonFirstName(mismatch_field.first)
                        setPersonLastName(mismatch_field.last_name)
                        setECNameMismatch(true)
                    }

                }
            } else {
                let error_id = 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.'+response.data.error_message_id
                alertDialog({message: intl.formatMessage({ id: error_id })})
                clearMembership(org)
                // alertDialog({message: response.data.error_message, title: 'Error'})
            }
        
        })
        methods.setValue('lookup_organization', '');
        loadingOverlay({show: false})
    }

    function updateMembershipsDataFromLookup(updated_memberships_list, low_priority_orgs=[]){
        if(Object.keys(updated_memberships_list).length > 0){
            for (const queryKey of Object.keys(updated_memberships_list)) {
                if(updated_memberships_list[queryKey] && Object.keys(updated_memberships_list[queryKey]).length > 0){ 
                    if(low_priority_orgs.includes(queryKey)){
                        // dont update/create if user already created the membership
                        let organization_members = methods.getValues('organization_members_added') || []
                        let membership_exists = organization_members?.find(data => data.organization_code == queryKey && data.membership_number != '')
                        if(!membership_exists){
                            updateMembership(updated_memberships_list[queryKey])
                        }
                    } else {
                        updateMembership(updated_memberships_list[queryKey])
                    }
                } else {
                    if(!low_priority_orgs.includes(queryKey)){
                        removeMembership(queryKey)
                    }
                }
            }
        }
    }

    function updateMembership(updated_membership){
        let memberships = methods.getValues('organizationMembers')
        let membershipIndex = -1
        if(memberships && memberships.length > 0){ membershipIndex = memberships.findIndex((obj => obj.organization_code.toUpperCase() == updated_membership.organization_code.toUpperCase())); }
        if(membershipIndex >= 0){
            for (const queryKey of Object.keys(updated_membership)) {
                memberships[membershipIndex][queryKey] = updated_membership[queryKey]
            }
        }
        methods.setValue('organizationMembers',memberships,{shouldDirty:true})
        let updated_memberships_list = onMembershipUpdate(updated_membership, organization_members_added)
        setOrganizationMembersAdded([...updated_memberships_list])
    }
    
    function removeMembership(organization_code, remove_override = false){
        // Check for membership variable
        let lookup_variable = getMembershipVariable(organization_code)

        // removing from the list of recently added organizations
        let organization_members = methods.getValues('organization_members_added')
        let index = -1 
        if(organization_members && organization_members.length > 0) { index = organization_members.findIndex(data => data.organization_code == organization_code) }
        if(index >= 0){
            organization_members = organization_members.splice(index, 1)
            setOrganizationMembersAdded([...organization_members])
        }

        // clearing values from organization members list from grid
        let organizationMembers = methods.getValues('organizationMembers')
        let membershipIndex = -1
        if(organizationMembers && organizationMembers.length > 0){ membershipIndex = organizationMembers.findIndex((obj => obj.organization_code == organization_code)); }
        if(membershipIndex >= 0){
            if(lookup_variable){
                methods.setValue(`people.${lookup_variable}`, '', { shouldDirty: true })
            }
            
            organizationMembers[membershipIndex].membership_number = ""
            organizationMembers[membershipIndex].valid_start = "0000-00-00"
            organizationMembers[membershipIndex].valid_end = "0000-00-00"
            organizationMembers[membershipIndex].membership_level = ""
            
            if(organizationMembers[membershipIndex].organization_code == "EC"){
                methods.setValue('people.ec_sport_license_type', '', { shouldDirty: true })
            }

            // adding in the list of memberships to remove
            let membershipsRemove = methods.getValues('memberships_removed')
            if(membershipsRemove){
                membershipsRemove.push(organizationMembers[membershipIndex])
            } else {
                membershipsRemove = [organizationMembers[membershipIndex]]
            }
            methods.setValue('memberships_removed', membershipsRemove, {shouldDirty: true})
            // end adding in the list of memberships to remove

            // remove membership override if exists
            if(remove_override){
                let memebershipOverrideToRemove = organizationMembers[membershipIndex]['membership_override'];
                if(memebershipOverrideToRemove){
                    let membershipsOverridesRemoved = methods.getValues('membership_override_removed')
                    if(membershipsOverridesRemoved){
                        membershipsOverridesRemoved.push(memebershipOverrideToRemove)
                    } else {
                        membershipsOverridesRemoved = [memebershipOverrideToRemove]
                    }
                    methods.setValue('membership_override_removed', membershipsOverridesRemoved, {shouldDirty: true})
                    organizationMembers[membershipIndex]['membership_override'] = null;
                }
            }
            // end remove membership override if exists
        }
        
        methods.setValue('organizationMembers',organizationMembers,{shouldDirty:true})
    }

    //Clear membership dates if membership not found from look up
    function clearMembership(org, clearMembershipNumber=false){
        let organization_members = methods.getValues().organizationMembers?.map((member) => {
            if(member.organization_code === org){
                if(member.membership_number){
                    member.valid_start = "0000-00-00"
                    member.valid_end = "0000-00-00"
                    member.valid = false
                    member.is_membershipvalid = false
                    member.membership_level = ''
                    if(org == 'EC'){
                        methods.setValue('people.ec_sport_license_type', '', { shouldDirty: true})
                    }
                    if(clearMembershipNumber){
                        // Use when tax status lookup return no data
                        member.membership_number = ''
                        member.membership_exist = false
                        member.membership_level = ''
                    }
                    member.name_mismatch_accepted = false

                }

                let updated_memberships_list = onMembershipUpdate(member, methods.getValues('organization_members_added') ?methods.getValues('organization_members_added'): [])
                setOrganizationMembersAdded([...updated_memberships_list])
            }
            return member
        })
        methods.setValue('organizationMembers',organization_members, { shouldDirty: true })
    }

    function updatePersonRecordAfterTaxStatusLookup(person_id, personData, taxStatusMembership){

        // update people record (e.g address etc) with lookup data
        if(personData){
            methods.setValue('people',personData, { shouldDirty: true })
        }
        
        // update person Tax Status membership
        if(taxStatusMembership){
            let organization_members = methods.getValues().organizationMembers?.map((membership) => {

                if(membership.organization_code === 'Tax Status'){  
                    membership = taxStatusMembership
                    let updated_memberships_list = onMembershipUpdate(membership, methods.getValues('organization_members_added') ? methods.getValues('organization_members_added'): [])
                    setOrganizationMembersAdded([...updated_memberships_list])
                }
                return membership
            })
            methods.setValue('organizationMembers',organization_members, { shouldDirty: true })
        }
    }

    // Handle TIN status check button to send api call to backend
    const handleTinStatusCheck = async () => {
        loadingOverlay({ show: true })
        let response = await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/tinlookupandupdateperson`, {
            people_id: methods.getValues('people.people_id'),
            customer_id: customer_id,
            people: methods.getValues('people')
        })

        loadingOverlay({ show: false })
        if (response.data.success) {
            let updatedPersonData = response?.data?.updated_people_data
            if(updatedPersonData){
                await updatePersonRecordAfterTaxStatusLookup(methods.getValues('people.people_id'), updatedPersonData, null)
            }
            // updateCallbackFromParent();
        } else {
            alertDialog({ message: response.data.message})
        }
    }

    useEffect(() => {
        // when updated from Tax Document Actions run tin check
        if(props.runTinCheck){
            handleTinStatusCheck()
        }
    }, [props.runTinCheck])
        
    return (
        <>
            {props.showTaxInformation && (
                <TaxFormInformationModal 
                    show={props.showTaxInformation} handleClose={() => props.setShowTaxInformation(false)} 
                    updateCallbackFromParent={props.updateCallbackFromParent}
                    personData={methods.getValues('people')}
                    organizationMembership={ methods.getValues('organizationMembers')}
                    updatePersonRecordAfterTaxStatusLookup={updatePersonRecordAfterTaxStatusLookup}
                    clearMembership={clearMembership}
                    calledFrom='RTO'
                    handleTinStatusCheck={handleTinStatusCheck}
                    selectedTaxFormRequestType={props.selectedTaxFormRequestType}
                />)}
            <div className='form-group row mb-2'>
                {showManualOverrideForm && <AdditionalManualOverrideForm show={showManualOverrideForm} handleClose={() => setManualOverrideForm(false)} rowData={selectedRow} personId={methods.getValues("people.people_id")} calledFrom={"people"}/>}
                <div className='row mb-2'>
                    <div className='col-lg-6'> {/* Column 1 */}
                        <div className='row align-items-center mb-2 d-grid'>
                            <OutputListingGridStatic
                                onGridReady={params => setMembershipGridState(params.api)}
                                columnDefs={columnDefs}
                                // rowData={rowData}
                                containerStyle={containerStyle}
                                rowClassRules={rowClassRules}
                                onCellDoubleClick={onCellDoubleClick}
                                rowHeight={getExtraNarrowRowHeight}
                                setGridRef={setMembershipGridState}
                                onCellValueChanged={onCellValueChanged}
                                enableRangeSelection={true}
                                stopEditingWhenCellsLoseFocus={true}
                            ></OutputListingGridStatic>
                        </div>
                    </div> {/* End Column 1 */}
                    <div className='col-lg-6 d-flex flex-column mb-2 border-start bg-white'> {/* Column 2 */}
                    {/* <h3 className='text-center'>Memberships</h3> */}
                        <ul className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap mb-0' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            {tab_names.length > 0 &&
                                tab_names.map((tab, index) => {
                                    return (
                                        <li key={index} className='nav-item'>
                                            <a
                                                className={"nav-link text-active-dark py-1 text-gray-700" + (governingOrganization == tab.name ? ' active' : '')}
                                                id={tab + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + tab.name}
                                                data-tooltip-id={`PEOPLE.DETAIL.TAB.ADDITIONAL.SECTION.${tab.name}`}
                                            >
                                                {tab.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className="tab-content mt-2 pb-2" id="myTabContent">
                            {tab_names.length > 0 &&
                                tab_names.map((tab, index) => {
                                    return (
                                        <div key={index} className={"tab-pane fade show" + (governingOrganization == tab.name ? " active" : "")} id={tab.name} role="tabpanel" >
                                            {renderMembershipTab(tab)}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div> {/* End Column 2 */}
                </div>
            </div>
        </>
    );
}

export { AdditionalTab }