import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'
import { NumericFormat } from 'react-number-format'
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider'

type Props = {
    show: boolean
    handleClose: () => void,
    name: string,
    digitalSignatureAction: any,
    mobileNumber: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SignatureAuthRequestForm = ({ show, handleClose, name, digitalSignatureAction, mobileNumber }: Props) => {
    const intl = useIntl()
    const [phoneNumber, setPhoneNumber] = useState(mobileNumber);
    const alertDialog = useAlert()

    const digitalSignature = () => {
        if(phoneNumber.length < 11){
            alertDialog({message: `Please enter complete mobile number including country code.`, icon: 'stop'})
        }
        else{
            digitalSignatureAction('Send Signature Auth Request', phoneNumber)
            handleClose()
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event :any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    digitalSignature()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.SENDSIGNAUTH.TITLE.SENDSIGNAUTH' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                            <div className='col-lg-1 d-flex align-self-center'>
                                <i className="fa-sharp fa-solid fa-circle-info fa-2xl" style={{ color: '#578EBE' }}></i>
                            </div>
                            <div className='col ms-lg-2'>
                                <div className='row mb-2'>
                                    <label className='col-lg-12 col-form-label fs-5 py-1' htmlFor={'KEEPENTRYNUM'} data-tooltip-id="ENTRIES.DETAIL.MODAL.SENDSIGNATUREAUTHREQUEST.LABEL.MOBILENUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.SENDSIGNAUTH.LABEL.KEEPENTRYNUM' })} {name}</label>
                                    <div className='col-lg-12'>
                                        {/* <input
                                            id={'KEEPENTRYNUM'}
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            autoFocus
                                        /> */}
                                        <NumericFormat
                                            id='show_tax_id'
                                            decimalScale={0}
                                            allowNegative={false}
                                            value={phoneNumber}
                                            onValueChange={(e: any) => {
                                                setPhoneNumber(e.value)
                                            }}
                                            autoFocus
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={digitalSignature} tabIndex={3}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SignatureAuthRequestForm }