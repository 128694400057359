import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options, maskDateInput, setDate, setTime, time_options_12hours } from '../../../../modules/sgl-utils/fieldControls';
import { Controller, useFormContext } from 'react-hook-form';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { NumericFormat, PatternFormat } from 'react-number-format';
import axios from 'axios';
import moment from 'moment';
import { useAppSelector } from '../../../../redux/hooks';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../../modules/sgl-utils/SglFunctions';
import Select from "react-select";
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';

type Props = {
    show: boolean
    handleClose: () => void
    formMeta: any
    entryFeeGridRef: any
    callFormSubmit: () => void
}

type dictionary = {
    [key: string]: any
}

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AddFeeForm = ({ show, handleClose, formMeta, entryFeeGridRef, callFormSubmit }: Props) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const quantityRef = useRef<HTMLInputElement>(null)
    const feeRef = useRef<any>(null)
    const executeRef = useRef<any>(null)
    const methods = useFormContext()  
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);

    const [selectedFee, setSelectedFee] = useState<dictionary>({})
    const [isDisabledForDel, setIsDisabledForDel] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [errorColor, setErrorColor] = useState<string>();
    const [quantity, setQuantity] = useState<any>();
    const [showBarns, setShowBarns] = useState([]) // barns available for the current
    const [selectedBarn, setSelectedBarn] = useState<any>({}) // barn associated with the fee
    const alertDialog = useAlert();

    useEffect(() => {
        if(show){
            feeRef?.current?.focus()
        }
    }, [show])

    const feesData: dictionary[] = formMeta && formMeta.showFeesDescriptions ? formMeta.showFeesDescriptions : []
    const feesOptions = feesData.map((fee: any) => {
        return {value: fee.sgl_id, label: fee.description}
    })
    feesOptions.unshift({ label: 'Select...', value: '' })

    const onFeeSelect = (e: any) => {
        let obj = feesData.find((fee: dictionary) => {
            return fee.sgl_id == e.value
        })
        setSelectedFee(obj ? obj : {})
        setIsDisabledForDel(obj && obj?.MasterFee?.physical_delivery ? false : true)
        if(e.value){
            quantityRef?.current?.focus()
        }
    }

    const addEntryFee = async (event: any) => {
        loadingSpinnerBtnWaitByEnter(event)
        let newEntryFee = methods.getValues("newEntryFee")
    
        newEntryFee = {
            ...newEntryFee,
            entryfees_id: -Math.abs(Math.floor(new Date().valueOf() * Math.random())),
            description: selectedFee.description,
            unitprice: selectedFee.unit_price,
            category: selectedFee.category,
            showfees_id: selectedFee.showfees_id,
            ShowFee: {
                MasterFee: selectedFee.MasterFee,
                show_id: currentShowID
            },
            date: moment().format("YYYY-MM-DD"),
            refund: 0,
            refund_id: 0,
            fee_splitted: 0,
            ordersupplyfromweb: 0,
            quantity
        } 
        newEntryFee.totalprice = (newEntryFee.unitprice * Number(quantity)).toFixed(2)

        try {
            await axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/validateEntryFeeRecord', {
                params: {
                    quantity: quantity,
                    show_id: currentShowID,
                    customer_id: customerId,
                    showfees_id: selectedFee.showfees_id
                }
            })

            // Clear errors in case of success
            setError("")

            // Add new entry fee to entry fees array
            methods.setValue('entryFeesData.entryFees', [...methods.getValues("entryFeesData.entryFees"), newEntryFee], { shouldDirty: true})

            // Reload Entry Fee Ag-grid without Deleted Rows
            const entryFees = methods.getValues("entryFeesData.entryFees")
            const filteredEntryFees = entryFees.filter((entryFee : any) => !entryFee.isDeleted)
            entryFeeGridRef.setRowData(filteredEntryFees)
            loadingSpinnerBtnReleaseByEnter(event)
            setError(`${quantity} ${selectedFee.description} Fee's added to Entry ${methods.getValues("entry.number")} for a total of ${newEntryFee.totalprice}`)
            setErrorColor('text-success')
            clearState()

            // Save form data
            setTimeout(callFormSubmit, 500)
        } catch (reason : any) {
            setError(reason.response.data.error)
            setErrorColor('error_message')
            loadingSpinnerBtnReleaseByEnter(event)
            if(reason.response.data.error.includes("quantity")){
                quantityRef.current?.focus()
            }
        }
    }

    useEffect(() => {
        if (methods.getValues("entry")) {
            let trainer = methods.getValues("entry.trainer")
            let delivery_notification_phone =  methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}].people_data.sgl_mobile`)
            let delivery_barn_location = methods.getValues("entry.location")
                           
            methods.setValue('newEntryFee.trainer_name', trainer)
            methods.setValue('newEntryFee.delivery_notification_phone', delivery_notification_phone)
            methods.setValue('newEntryFee.delivery_barn_location', delivery_barn_location)
            methods.setValue('newEntryFee.delivery_stall_location', "")
            methods.setValue('newEntryFee.priority_order', false)
            methods.setValue('newEntryFee.entry_stall', false)
        }
    }, [methods.watch("entry")])

    const onClose = () => {
        clearState()
        setError("")
        handleClose();  
    }

    const clearState = () => {
        setSelectedFee({}); 
        setIsDisabledForDel(true)
        let existingEntryFee = methods.getValues("newEntryFee")

        let newEntryFee = {
            ...existingEntryFee,
            description: '',
            unitprice: 0,
            category: 0,
            showfees_id: 0,
            date: moment().format("YYYY-MM-DD"),
            refund: 0,
            refund_id: 0,
            fee_splitted: 0,
            ordersupplyfromweb: 0,
            invoice_number: ''
        } 
        setQuantity('')
        methods.setValue("newEntryFee", newEntryFee)
    }

    const getShowBarns = async() => {
        // load the barn for the current show
        await axios.get(`${NEST_API_URL}/shows/getShowBarns?show_id=${currentShowID}&customer_id=${customerId}`)
        .then(response => {
            if(response.data){
                setShowBarns(response.data)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    useEffect(() => {
        getShowBarns()
        // no barn entered for the fee
        setSelectedBarn({label: 'Select', value: ''})
    }, [])

    useEffect(() => {
        // if the barn entered in fee is present in show barns then auto select it other wise select others options
        let delivery_barn_location = methods.getValues('newEntryFee.delivery_barn_location')
        if(delivery_barn_location){
            let _barnSelected = showBarns?.find((barn) => String(barn['value'])?.toLowerCase()?.trim() == delivery_barn_location?.toLowerCase()?.trim())
            if(_barnSelected){
                setSelectedBarn(_barnSelected)
            }else{
                setSelectedBarn({label: 'Others', value: 'Others'})
            }
        }else{
            // no barn entered for the fee
            setSelectedBarn({label: 'Select', value: ''})
        }
    }, [showBarns, methods.getValues('newEntryFee.delivery_barn_location')])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form add-fee-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event: any) => { //by using onKeyDown instead of onKeyPress, we ensure function is called even if a field is in focus
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }

                if (event.key === "Enter" && !isButtonFocused) { //call function only when no button is in focus
                    let executeButton = document.getElementById('AddFeeExecuteButton')
                    addEntryFee(executeButton)
                }
            }}
            onEscapeKeyDown={onClose}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDFEE"})}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='FEEDESC' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.FEEDESC">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.FEEDESC' })}</label>
                            <div className='col-lg-7'>
                                <Select 
                                    ref={feeRef}
                                    options={feesOptions}
                                    id="FEEDESC"
                                    value={feesOptions.filter((ss: any) => ss.value === selectedFee.sgl_id)}
                                    onChange={e => onFeeSelect(e)}
                                    styles={reactSelectStyles}
                                    tabIndex={1}
                                    filterOption={(option, inputValue) =>
                                        option?.label?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
                                    }
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.UNITPRICE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.UNITPRICE' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(selectedFee['unit_price']) }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.ENTRYNUM"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ENTRYNUM' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { methods.getValues("entry.number") }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='QUANTITY' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.QUANTITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.QUANTITY' })}</label>
                            <div className='col-lg-7'>
                                {/* <input
                                    value={quantity}
                                    id='QUANTITY'
                                    type='text'
                                    ref={quantityRef}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.QUANTITY' })}
                                    onChange={e => setQuantity(e.target.value)}
                                /> */}
                                <NumericFormat
                                    value={quantity}
                                    id='QUANTITY'
                                    type='text'
                                    getInputRef={quantityRef}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.QUANTITY' })}
                                    thousandSeparator={true} 
                                    allowNegative={true} // allow fee with negative quantity 
                                    onValueChange={({ value }) => setQuantity(value)}
                                    decimalScale={2}
                                    isAllowed={({ floatValue }) => floatValue === undefined || floatValue < 10000}
                                    tabIndex={2}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='INVOICENUM' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.INVOICENUM">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.INVOICENUM' })}</label>
                            <div className='col-lg-7'>
                                <input
                                    {...methods.register("newEntryFee.invoice_number")}
                                    id='INVOICENUM'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.INVOICENUM' })}
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        { !isDisabledForDel ?
                            <>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='NOTIFICATIONPHONE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.NOTIFICATIONPHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.NOTIFICATIONPHONE' })}</label>
                                    <div className='col-lg-7'>
                                        <Controller
                                            control={methods.control}
                                            name="newEntryFee.delivery_notification_phone"
                                            render={({ field: { onChange, value } }) => (
                                                <PatternFormat
                                                    id='NOTIFICATIONPHONE'
                                                    format="(###) ###-####"
                                                    value={
                                                        value === "" ? "" : Number(value)
                                                    }
                                                    onValueChange={(e)=>{
                                                        onChange(e.value)
                                                    }}
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.NOTIFICATIONPHONE' })}
                                                    tabIndex={4}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='BARN' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.BARN">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.BARN' })}</label>
                                    <div className='col-lg-7'>
                                        <div className='row'>
                                            {/* Barn Drop Down */}
                                            <div className={selectedBarn.value == 'Others' ? 'col-lg-6' : 'col-lg-12'}>
                                                <Select
                                                    placeholder="Any"
                                                    value={selectedBarn}
                                                    options = {showBarns}
                                                    isSearchable={true}
                                                    onChange={(option) => {
                                                        let _selectedBarn = ''
                                                        setSelectedBarn(option)
                                                        if(option.value == 'Others'){
                                                            // Clear out the delivery_barn_location field as user will enter it manually
                                                            _selectedBarn = ''
                                                        }else {
                                                            _selectedBarn = option.value
                                                        }

                                                        methods.setValue(`newEntryFee.delivery_barn_location`, _selectedBarn)
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                    })}
                                                    styles={reactSelectStyles}
                                                /> 
                                            </div>

                                            {
                                                /* Input Field for Others */
                                                selectedBarn.value == 'Others' ?
                                                <div className='col-lg-6'>
                                                    <input
                                                        {...methods.register("newEntryFee.delivery_barn_location")}
                                                        id='BARN'
                                                        type='text'
                                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.BARN' })}
                                                        tabIndex={5}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='STALL' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.STALL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.STALL' })}</label>
                                    <div className='col-lg-7'>
                                        <input
                                            {...methods.register("newEntryFee.delivery_stall_location")}
                                            id='STALL'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.STALL' })}
                                            tabIndex={6}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='TRAINER' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.TRAINER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINER' })}</label>
                                    <div className='col-lg-7'>
                                        <input
                                            {...methods.register("newEntryFee.trainer_name")}
                                            id='TRAINER'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINER' })}
                                            tabIndex={7}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='ARRIVALDATE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.ARRIVALDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ARRIVALDATE' })}</label>
                                    <div className='col-lg-7'>
                                        <div className="input-group date">
                                            <Controller
                                                name="newEntryFee.arrival_date"
                                                control={methods.control}
                                                render={({
                                                    field: { onChange, value, name },
                                                }) => {
                                                    const formattedValue = (value && moment(value).isValid())
                                                        ? moment(value).format('MM/DD/YYYY')
                                                        : '';

                                                    return (
                                                        <Flatpickr
                                                            className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                                            id="ARRIVALDATE"
                                                            placeholder="MM/DD/YYYY"
                                                            options={date_options}
                                                            name={name}
                                                            value={formattedValue}
                                                            onClose={(value: moment.MomentInput[], dateStr: moment.MomentInput, instance: { input: { value: string; }; }) => {
                                                                const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                if (!isValidDate) {
                                                                    instance.input.value = ''; // Clear the input if the date is invalid
                                                                }
                                                                setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                            }}
                                                            onInput={(e: any) => { maskDateInput(e) }}
                                                            autoComplete="off"
                                                            tabIndex={8}
                                                        />
                                                    );
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <label htmlFor='ARRIVALDATE' className='date-label'>
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='ARRIVALTIME' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.ARRIVALTIME"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ARRIVALTIME' })}</label>
                                    <div className='col-lg-7'>
                                        <div className="input-group date">
                                        <Controller
                                            name="newEntryFee.arrival_time"
                                            render={({
                                                field: { onChange, value, name },
                                            }) => (
                                                <Flatpickr 
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                    id="ARRIVALTIME"
                                                    placeholder="00 : 00"
                                                    name = {name}
                                                    value = {value}
                                                    options={time_options_12hours}
                                                    onClose={(value: any) => setTime(value, onChange)}
                                                    onChange={(value: any) => setTime(value, onChange)}
                                                    autoComplete={"off"}
                                                    tabIndex={9}
                                                />
                                                )}
                                            />
                                            <div className="input-group-append">
                                                <label htmlFor='ARRIVALTIME' className='date-label'
                                                       onClick={(e) => {
                                                            e.stopPropagation();
                                                            const atModal = document.getElementById('ARRIVALTIME') as HTMLElement & { _flatpickr?: { open: () => void } };
                                                            if (atModal?._flatpickr) {
                                                                atModal._flatpickr.open();
                                                            }
                                                        }}
                                                >
                                                    <span className="input-group-text">
                                                        <i className="la la-clock-o"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='PRIORITYORDER'></label>
                                    <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            {...methods.register("newEntryFee.priority_order")}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='PRIORITYORDER'
                                            tabIndex={10}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='PRIORITYORDER' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.PRIORITYORDER"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.PRIORITYORDER' })}</label>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }      
                       
                        <div className={'row mb-2' + (methods.getValues('entry.trainer_account') && (selectedFee?.stablefee || selectedFee?.MasterFee?.stablefee)  ? '' : ' d-none')}>
                            <label className='col-lg-1 col-form-label fs-5 py-1 fw-bold w-lg-145px' htmlFor='TRAINERSTALLINVENTORY'></label>
                            <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("newEntryFee.entry_stall")}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='TRAINERSTALLINVENTORY'
                                    tabIndex={11}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='TRAINERSTALLINVENTORY' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.LABEL.TRAINERSTALLINVENTORY"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINERSTALLINVENTORY' })}</label>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <label className={`${errorColor}`}>
                                { error }
                            </label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <label className={'col-form-label py-1' + (methods.getValues('entry.trainer_account') && (selectedFee?.stablefee || selectedFee?.MasterFee?.stablefee) ? '' : ' d-none')}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINERSTALLINVENTORYMSG' })}</label>

                        <button type="button" className='btn btn-sm btn-dark fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={12} data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.BUTTON.DONE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>

                        <button id="AddFeeExecuteButton" ref={executeRef} type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={addEntryFee} autoFocus tabIndex={13} data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDFEE.BUTTON.EXECUTE">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.EXECUTE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { AddFeeForm }