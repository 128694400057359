import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { date_options, maskDateInput } from '../sgl-utils/fieldControls';

type Props = {
  show: boolean
  onConfirm: (date: string) => void,
  onClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DatePickerDialog = ({ show, onConfirm, onClose }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const intl = useIntl()

  const [date, setDate] = useState('');

  useEffect(() => {
    if(!isLoading){
      setShowModal(true)
    }else{
      setIsLoading(false)
    }
  }, [show])

  const hideModal = () => {
    if(onClose){
      onClose()
    }
    setDate('')
    setShowModal(false)
  }

  const submitConfirm = () => {
    if(onConfirm){
      onConfirm(date)
    }
    setDate('')
    setShowModal(false)
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={!isLoading && showModal}
      onHide={hideModal}
      onKeyDown={(event:any) => {
        if (event.key == "Enter" && !event.shiftKey) {
          submitConfirm()
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        {/* <h2 className="fs-4">{renderedTitle}</h2> */}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={hideModal}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4 shadow-lg'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='date'>Date: </label>
                <div className='col-lg-7'>
                    <div className="input-group date">     
                        <Flatpickr 
                            className="form-control form-control-sm  fs-6 min-h-20px py-1"
                            id = "date"
                            placeholder="MM/DD/YYYY"
                            value={  date ? moment(date).format('MM/DD/YYYY') : null  }
                            options={date_options}
                            autoComplete={"off"}
                            onClose={(value: moment.MomentInput[], dateStr: any, instance: any) => {
                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                              if (!isValidDate) {
                                  instance.input.value = ''; // Clear the input if the date is invalid
                              }
                              setDate(moment(value[0]).format('YYYY-MM-DD'));
                            }}
                            autoFocus
                            onInput={(e: any) => { maskDateInput(e) }}
                        />
                        <div className="input-group-append">
                            <label htmlFor='date' className='date-label'>
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>

            <button className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={hideModal}>
              { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
            </button>

            <button className='btn btn-sm btn-dark fw-bold' onClick={submitConfirm}>
              { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' }) }
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { DatePickerDialog }