import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";

// Metronic COmponents
import { KTSVG } from '../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css"

import { date_options, maskDateInput, setDate } from '../../../modules/sgl-utils/fieldControls';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
const moment = require('moment');

type Props = {
    show: boolean
    handleClose: () => void
    selectedTaxRow: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TaxRateInfoForm = ({ show, handleClose, selectedTaxRow }: Props) => {
    const [effectiveDate, setEffectiveDate] = useState(selectedTaxRow.effective_date) // effective date state
    const [rate, setRate] = useState(selectedTaxRow.rate) // rate state
    const [errorMessage, setErrorMessage] = useState('') // error message state	
    const intl = useIntl();
    const methods = useFormContext();

    // Set error message to empty after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorMessage('')
        }, 5000)
        return () => clearTimeout(timer)
    }, [errorMessage])


    // Update tax rates in the grid, will save at final save call of show tax rates
    const applyTaxRate = async () => {
        let taxRates = methods.getValues('tax_rates') ?? [];
        const showTaxRate = methods.getValues('show_tax_rate');

        let error = '';
        if (effectiveDate == '0000-00-00') {
            error = 'Tax Effective Date is Required.\n'
        }
        if (!rate || rate <= 0 || rate == '') {
            error = error + 'Valid Tax Rate is Required.'
        }

        setErrorMessage(error);

        // Generate random negative value 
        const randomValue = Math.floor(Math.random() * -1000000);
        
        // If no error, then process addition
        if (error == '') {
            // process addition
            // First find if the tax rate exists in the grid
            const existingTaxRate = taxRates.find((taxRate: any) => taxRate.taxrates_id == selectedTaxRow.taxrates_id);

            // If not exists, then add the tax rate
            if (!existingTaxRate) {
                const newTaxRate = {
                    show_tax_rates_id: showTaxRate.show_tax_rates_id,
                    customer_id: showTaxRate.customerId,
                    effective_date: effectiveDate,
                    rate: rate,
                    taxrates_id: randomValue
                }
    
                taxRates.push(newTaxRate);
            } else {
                // Update the existing tax rate
                existingTaxRate.effective_date = effectiveDate;
                existingTaxRate.rate = rate;
                existingTaxRate.isDirty = true; // TODO: Need to check if this is required
            }

            methods.setValue('tax_rates', taxRates, { shouldDirty: true });
            handleClose();
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-350px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event:any) => { 
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    applyTaxRate()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.MODAL.TITLE.TAXRATEINFO' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='EFFECTIVEDATE' data-tooltip-id="TAXRATE.DETAIL.MODAL.TAXRATEINFORMATION.LABEL.EFFECTIVEDATE">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.EFFECTIVEDATE' })}</label>
                            <div className='col-lg-8'>
                                <div className="input-group date">
                                    <Flatpickr
                                        className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                        id="EFFECTIVEDATE"
                                        placeholder="MM/DD/YYYY"
                                        autoComplete="off"
                                        options={date_options}
                                        value={effectiveDate && effectiveDate !== '0000-00-00' 
                                            ? moment(effectiveDate).format('MM/DD/YYYY') 
                                            : null}
                                        onClose={(value: any, dateStr: string, instance: any) => {

                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                setEffectiveDate(''); // Clear the effective date if invalid
                                            } else {
                                                const formattedDate = moment(value[0]).format('YYYY-MM-DD');
                                                setEffectiveDate(formattedDate);
                                            }

                                            // Set the date with formatting logic
                                            setDate(dateStr, (formattedDate: string) => {
                                                setEffectiveDate(formattedDate);
                                            });
                                        }}
                                        onInput={(e: any) => {
                                            maskDateInput(e);
                                        }}
                                        autoFocus
                                        tabIndex={1}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor='EFFECTIVEDATE' className='date-label'>
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='RATE' data-tooltip-id="TAXRATE.DETAIL.MODAL.TAXRATEINFORMATION.LABEL.RATE">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.RATE' })}</label>
                            <div className='col-lg-8'>
                                <NumericFormat
                                    suffix={'%'}
                                    type="text"
                                    id='RATE'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                                    placeholder="Rate (%)"
                                    allowNegative={false}
                                    decimalScale={2}
                                    tabIndex={2}
                                    value={rate > 0 ? rate * 100 : ''} // display rate in %
                                    onValueChange={(e) => {
                                        // save rate in decimal
                                        setRate(parseFloat(e.value)/100)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Show error message in red color */}
                    {errorMessage && errorMessage != '' && (
                        <div className='d-flex flex-stack'>
                            <div className='me-5'>
                                <span className='text-danger fw-bold fs-6'>{errorMessage}</span>
                            </div>
                        </div>
                    )}

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={3}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => applyTaxRate()} tabIndex={4} data-tooltip-id="TAXRATE.DETAIL.MODAL.TAXRATEINFORMATION.BUTTON.APPLY">
                            {intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.MODAL.APPLY' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { TaxRateInfoForm }