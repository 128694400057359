import { useEffect } from 'react';
import { handlePrompt  } from '../sgl-utils/unstableHistory';

const useNavigationPrompt = (formState) => {
    useEffect(() => {
        let unblock
        const { dirtyFields } = formState;
        const fieldsToExclude = ['balance', 'class_fees_subtotal', 'entry_fee_subtotal', 'tax', 'total_fees', 'total_paid'];
        const isExcludedFieldDirty = Object.keys(dirtyFields).some(fieldName => fieldsToExclude.includes(fieldName));
        
        // Block navigation and register a callback that fires when a navigation attempt is blocked.
        if (formState.isDirty && Object.values(dirtyFields).length > 0 && !isExcludedFieldDirty) {
            console.warn("Dirty: ", dirtyFields)
            unblock = handlePrompt()
        }
        return () => { if (typeof unblock === "function") { unblock() } }
    }) // To force useEffect to run again when a prop changes.
}

export default useNavigationPrompt