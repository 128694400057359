import React, {useState, useCallback, useEffect, useRef} from 'react'
import {Stage, Layer, Rect, Text, Line, Group, Transformer, Circle} from 'react-konva'
import {Stage as StageType} from 'konva/lib/Stage'
import Split from 'react-split'
import {
  Square,
  Circle as CircleIcon,
  Triangle,
  Type,
  Image as ImageIcon,
  Plus,
  Trash2,
  ChevronUp,
  ChevronDown,
  Copy,
  FlipHorizontal,
  FlipVertical,
  Minus,
  Save,
  Upload,
  Redo,
} from 'lucide-react'
import './barn-designer.css'

const feetToPixelRatio = 10

const BarnDesigner = () => {
  // Original barn state
  const [formData, setFormData] = useState({
    structureLength: 500,
    structureWidth: 100,
    horizontalAisleCount: 1,
    verticalAisleCount: 1,
    horizontalAisleWidth: 15,
    verticalAisleWidth: 15,
    stallLength: 15,
    stallWidth: 15,
    exteriorStallRow: false,
  })

  const [customizations, setCustomizations] = useState({
    stalls: {},
    aisles: {},
  })

  const [selectedId, setSelectedId] = useState(null)
  const [isEditingLabel, setIsEditingLabel] = useState(false)
  const [tempLabel, setTempLabel] = useState('')

  // Canvas state
  const [canvasElements, setCanvasElements] = useState([])
  const [selectedElement, setSelectedElement] = useState(null)
  const [isDragging, setIsDragging] = useState(false)

  // UI state
  const [errors, setErrors] = useState({})
  const [barnLayout, setBarnLayout] = useState({})
  const [layoutErrors, setLayoutErrors] = useState([])
  const [stageScale, setStageScale] = useState(1)
  const [stagePosition, setStagePosition] = useState({x: 0, y: 0})
  const [isEditMode, setIsEditMode] = useState(false)

  const [isTransforming, setIsTransforming] = useState(false)
  const [draggedElements, setDraggedElements] = useState({})

  const [lastStallNumber, setLastStallNumber] = useState(0)

  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showLoadDialog, setShowLoadDialog] = useState(false)
  const [designName, setDesignName] = useState('')

  // Refs
  const stageRef = useRef(null)
  const transformerRef = useRef(null)
  const selectedShapeRef = useRef(null)

  const shapeTemplates = {
    rectangle: {
      type: 'rectangle',
      width: 100,
      height: 100,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Square,
    },
    circle: {
      type: 'circle',
      radius: 50,
      width: 100, // Added for consistent sizing
      height: 100, // Added for consistent sizing
      fill: '#e9edef',
      stroke: '#333869',
      icon: CircleIcon,
    },
    triangle: {
      type: 'triangle',
      points: [50, 0, 0, 100, 100, 100], // Updated points for better triangle
      width: 130,
      height: 100,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Triangle,
    },
    'stall-top': {
      type: 'stall',
      orientation: 'top',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Square,
    },
    'stall-right': {
      type: 'stall',
      orientation: 'right',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Square,
    },
    'stall-bottom': {
      type: 'stall',
      orientation: 'bottom',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Square,
    },
    'stall-left': {
      type: 'stall',
      orientation: 'left',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333869',
      icon: Square,
    },
    'line-solid-h': {
      type: 'line',
      width: 400,
      height: 20, // Increased height for better hit area
      points: [0, 10, 400, 10], // Centered within the height
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      hitStrokeWidth: 20, // Wider hit area for selection
      icon: Minus,
    },
    'line-dashed-h': {
      type: 'line',
      width: 400,
      height: 20,
      points: [0, 10, 400, 10],
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      dash: [10, 10],
      hitStrokeWidth: 20,
      icon: Minus,
    },
    'line-dotted-h': {
      type: 'line',
      width: 400,
      height: 20,
      points: [0, 10, 400, 10],
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      dash: [2, 5],
      hitStrokeWidth: 20,
      icon: Minus,
    },
    'line-solid-v': {
      type: 'line',
      width: 20,
      height: 400,
      points: [10, 0, 10, 400],
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      hitStrokeWidth: 20,
      icon: Minus,
    },
    'line-dashed-v': {
      type: 'line',
      width: 20,
      height: 400,
      points: [10, 0, 10, 400],
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      dash: [10, 10],
      hitStrokeWidth: 20,
      icon: Minus,
    },
    'line-dotted-v': {
      type: 'line',
      width: 20,
      height: 400,
      points: [10, 0, 10, 400],
      stroke: '#333869',
      strokeWidth: 2,
      lineCap: 'round',
      dash: [2, 5],
      hitStrokeWidth: 20,
      icon: Minus,
    },
  }

  // Helper function to get stall points based on position and orientation
  const getStallPoints = (width, height, orientation) => {
    const openingSize = height * 0.2

    switch (orientation) {
      case 'top':
        return [
          0,
          height, // bottom left
          width,
          height, // bottom right
          width,
          0, // top right
          width - openingSize,
          0, // top right inner
          width - openingSize,
          openingSize, // right inner
          openingSize,
          openingSize, // left inner
          openingSize,
          0, // top left inner
          0,
          0, // top left
        ]

      case 'right':
        return [
          0,
          0, // top left
          0,
          height, // bottom left
          width,
          height, // bottom right
          width,
          height - openingSize, // bottom right inner
          width - openingSize,
          height - openingSize, // bottom inner
          width - openingSize,
          openingSize, // top inner
          width,
          openingSize, // top right inner
          width,
          0, // top right
        ]

      case 'bottom':
        return [
          0,
          0, // top left
          width,
          0, // top right
          width,
          height, // bottom right
          width - openingSize,
          height, // bottom right inner
          width - openingSize,
          height - openingSize, // right inner
          openingSize,
          height - openingSize, // left inner
          openingSize,
          height, // bottom left inner
          0,
          height, // bottom left
        ]

      case 'left':
        return [
          0,
          0, // top left
          width,
          0, // top right
          width,
          height, // bottom right
          0,
          height, // bottom left
          0,
          height - openingSize, // bottom left inner
          openingSize,
          height - openingSize, // bottom inner
          openingSize,
          openingSize, // top inner
          0,
          openingSize, // top left inner
        ]
    }
  }

  const ElementToolbar = ({selectedElement, canvasElements, setCanvasElements}) => {
    if (!selectedElement) return null

    const element = canvasElements.find((el) => el.id === selectedElement)
    if (!element) return null

    const stage = stageRef.current
    if (!stage) return null

    // Try to find either the group or its first child
    const elementNode =
      stage.findOne(`#${selectedElement}`) ||
      stage.findOne(`#${selectedElement}-rect-0`) ||
      stage.findOne(`#${selectedElement}-line-0`) ||
      stage.findOne(`#${selectedElement}-circle-0`)

    if (!elementNode) return null

    // Get stage container position
    const stageBox = stage.container().getBoundingClientRect()

    // Get element's bounding box
    const elementBox = elementNode.getClientRect()

    // Calculate position considering stage scale and position
    const scale = stage.scaleX()
    const stagePos = stage.position()

    // Calculate absolute position
    const absoluteX = stageBox.left + (elementBox.x + elementBox.width / 2) * scale + stagePos.x
    // Use the top of the element instead of the center
    const absoluteY = stageBox.top + elementBox.y * scale + stagePos.y

    const toolbarStyle = {
      position: 'absolute',
      left: '50%',
      top: '16px', // Give some padding from the top
      transform: 'translateX(-50%)',
      display: 'flex',
      gap: '4px',
      padding: '4px',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      zIndex: 1000,
      pointerEvents: 'auto',
    }

    const buttonStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      padding: '4px',
      border: 'none',
      borderRadius: '4px',
      background: '#f0f0f0',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      pointerEvents: 'auto',
    }

    const handleDuplicate = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const elementToDuplicate = canvasElements.find((el) => el.id === selectedElement)
      if (!elementToDuplicate) return

      // Get the current node to access its transformation properties
      const node = selectedShapeRef.current
      if (!node) return

      const newId = `element-${Date.now()}`
      const newElement = {
        ...elementToDuplicate,
        id: newId,
        x: elementToDuplicate.x + 20,
        y: elementToDuplicate.y + 20,
        // Copy transformation properties from the current state
        rotation: node.rotation(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        children: elementToDuplicate.children.map((child) => {
          // Handle text elements for stalls
          if (child.type === 'text' && elementToDuplicate.isStall) {
            return {...child, text: `${child.text}`}
          }

          // If it's a line element with points (like for stalls or triangles)
          if (child.type === 'line' && child.points) {
            // We need to transform the points based on current scale
            const transformedPoints = [...child.points]
            return {...child, points: transformedPoints}
          }

          return child
        }),
      }

      setCanvasElements((prev) => [...prev, newElement])

      // Give React a chance to render the new element
      requestAnimationFrame(() => {
        // Find and simulate click on the new element
        const shape = stageRef.current?.findOne(`#${newId}`)
        if (shape) {
          // Trigger the click handler directly
          handleSelect(newId)
        }
      })
    }

    const handleFlipHorizontal = (e) => {
      e.preventDefault()
      e.stopPropagation()

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // For stalls, handle orientation change
            if (el.id.includes('stall')) {
              const orientation = el.orientation || 'top'
              let newOrientation = orientation
              switch (orientation) {
                case 'left':
                  newOrientation = 'right'
                  break
                case 'right':
                  newOrientation = 'left'
                  break
                // top and bottom remain the same on horizontal flip
              }

              // Generate new points for the new orientation
              const points = getStallPoints(el.width, el.height, newOrientation)

              return {
                ...el,
                orientation: newOrientation,
                children: el.children.map((child) => {
                  if (child.type === 'line') {
                    return {...child, points}
                  }
                  return child
                }),
              }
            }

            // For other elements, just flip the points if they exist
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  return {
                    ...child,
                    points: child.points.map((p, index) => (index % 2 === 0 ? el.width - p : p)),
                  }
                }
                return child
              }),
            }
          }
          return el
        })
      )
    }

    const handleFlipVertical = (e) => {
      e.preventDefault()
      e.stopPropagation()

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // For stalls, handle orientation change
            if (el.id.includes('stall')) {
              const orientation = el.orientation || 'top'
              let newOrientation = orientation
              switch (orientation) {
                case 'top':
                  newOrientation = 'bottom'
                  break
                case 'bottom':
                  newOrientation = 'top'
                  break
                // left and right remain the same on vertical flip
              }

              // Generate new points for the new orientation
              const points = getStallPoints(el.width, el.height, newOrientation)

              return {
                ...el,
                orientation: newOrientation,
                children: el.children.map((child) => {
                  if (child.type === 'line') {
                    return {...child, points}
                  }
                  return child
                }),
              }
            }

            // For other elements, just flip the points if they exist
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  return {
                    ...child,
                    points: child.points.map((p, index) => (index % 2 === 1 ? el.height - p : p)),
                  }
                }
                return child
              }),
            }
          }
          return el
        })
      )
    }

    const handleDelete = (e) => {
      e.preventDefault()
      e.stopPropagation()
      deleteElement(selectedElement)
    }

    const startLabelEdit = (e) => {
      e.preventDefault()
      e.stopPropagation()
      const currentLabel = element.children?.find((child) => child.type === 'text')?.text || ''
      setTempLabel(currentLabel)
      setIsEditingLabel(true)
    }

    const handleLabelChange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            return {
              ...el,
              children: el.children.map((child) =>
                child.type === 'text' ? {...child, text: tempLabel} : child
              ),
            }
          }
          return el
        })
      )
      setIsEditingLabel(false)
    }

    return (
      <div
        className='element-toolbar'
        style={toolbarStyle}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        {isEditingLabel ? (
          <form
            onSubmit={handleLabelChange}
            style={{display: 'flex', gap: '4px'}}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              type='text'
              value={tempLabel}
              onChange={(e) => setTempLabel(e.target.value)}
              style={{
                padding: '4px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                width: '120px',
                pointerEvents: 'auto',
              }}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <button
              type='submit'
              style={{...buttonStyle, background: '#4CAF50', color: 'white'}}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✓
            </button>
            <button
              type='button'
              style={buttonStyle}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsEditingLabel(false)
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✕
            </button>
          </form>
        ) : (
          <>
            <button
              style={buttonStyle}
              onClick={handleDuplicate}
              onMouseDown={(e) => e.stopPropagation()}
              title='Duplicate'
            >
              <Copy size={16} />
            </button>
            <button
              style={buttonStyle}
              onClick={startLabelEdit}
              onMouseDown={(e) => e.stopPropagation()}
              title='Edit Label'
            >
              <Type size={16} />
            </button>
            <button
              style={buttonStyle}
              onClick={handleFlipHorizontal}
              onMouseDown={(e) => e.stopPropagation()}
              title='Flip Horizontal'
            >
              <FlipHorizontal size={16} />
            </button>
            <button
              style={buttonStyle}
              onClick={handleFlipVertical}
              onMouseDown={(e) => e.stopPropagation()}
              title='Flip Vertical'
            >
              <FlipVertical size={16} />
            </button>
            <button
              style={{...buttonStyle, color: '#ff4444'}}
              onClick={handleDelete}
              onMouseDown={(e) => e.stopPropagation()}
              title='Delete'
            >
              <Trash2 size={16} />
            </button>
          </>
        )}
      </div>
    )
  }

  // Convert barn layout to canvas elements
  // Update the convertBarnToCanvasElements function
  const convertBarnToCanvasElements = useCallback(() => {
    if (!barnLayout.structure) return []

    let elements = []
    let horizontalAisleNumber = 1

    // Add barn structure (non-draggable)
    elements.push({
      id: 'barn-structure',
      type: 'group',
      x: 50,
      y: 50,
      width: barnLayout.structure.width,
      height: barnLayout.structure.height,
      draggable: false,
      name: 'Barn Structure',
      isBarnStructure: true,
      children: [
        {
          type: 'rectangle',
          x: 0,
          y: 0,
          width: barnLayout.structure.width,
          height: barnLayout.structure.height,
          fill: '#ffffff',
          stroke: 'black',
          strokeWidth: 1,
        },
      ],
    })

    // Add aisles
    let horizontalAisleCounter = 1
    barnLayout.aisles?.forEach((aisle) => {
      const customData = customizations.aisles[aisle.id] || {}
      if (!customData.deleted) {
        const isHorizontal = aisle.type === 'horizontal'
        const aisleName = isHorizontal ? `Aisle ${horizontalAisleCounter}` : ''
        if (isHorizontal) horizontalAisleCounter++

        elements.push({
          id: aisle.id,
          type: 'group',
          x: 50 + aisle.x,
          y: 50 + aisle.y,
          width: aisle.width,
          height: aisle.height,
          draggable: true,
          name: customData.name || aisle.name || aisleName,
          isAisle: true,
          children: [
            {
              type: 'rectangle',
              x: 0,
              y: 0,
              width: aisle.width,
              height: aisle.height,
              fill: '#f6f6f6',
              stroke: '#cccccc',
              strokeWidth: 1,
            },
            ...(isHorizontal
              ? [
                  {
                    type: 'text',
                    x: aisle.width / 2,
                    y: aisle.height / 2 - 24,
                    width: aisle.width,
                    text: customData.name || aisleName,
                    fontSize: 48,
                    align: 'center',
                    offsetX: aisle.width / 2,
                  },
                ]
              : []),
          ],
        })
      }
    })

    // Add stalls with correct orientation
    barnLayout.stalls?.forEach((stall) => {
      const customData = customizations.stalls[stall.id] || {}
      if (!customData.deleted) {
        // Determine stall orientation
        const nearestHorizontalAisle = barnLayout.aisles?.find(
          (aisle) =>
            aisle.type === 'horizontal' &&
            Math.abs(aisle.y + aisle.height / 2 - (stall.y + stall.height / 2)) < stall.height * 2
        )

        let orientation = 'top'
        if (nearestHorizontalAisle) {
          orientation = stall.y < nearestHorizontalAisle.y ? 'bottom' : 'top'
        }

        const points = getStallPoints(stall.width, stall.height, orientation)

        elements.push({
          id: stall.id,
          type: 'group',
          x: 50 + stall.x,
          y: 50 + stall.y,
          width: stall.width,
          height: stall.height,
          draggable: true,
          name: customData.name || stall.name || `Stall ${stall.id.split('-')[1]}`,
          isStall: true,
          orientation: orientation,
          children: [
            {
              type: 'line',
              points: points,
              fill: '#e9edef',
              stroke: '#333869',
              strokeWidth: 1,
              closed: true,
            },
            {
              type: 'text',
              x: stall.width / 2,
              y: stall.height / 2 - 12,
              width: stall.width,
              text: customData.name || stall.name || `Stall ${stall.id.split('-')[1]}`,
              fontSize: 40,
              align: 'center',
              offsetX: stall.width / 2,
            },
          ],
        })
      }
    })

    return elements
  }, [barnLayout, customizations, draggedElements])

  useEffect(() => {
    if (!isEditMode) {
      // Reset all edit mode changes
      setCanvasElements([])
      setSelectedElement(null)
      setDraggedElements({})
      setSelectedId(null)

      // Force recalculation of barn layout only when not in edit mode
      const {layout, errors} = calculateBarnLayout()
      setBarnLayout(layout)
      setLayoutErrors(errors)
    } else {
      // When switching to edit mode, if we have existing canvasElements, don't override them
      if (canvasElements.length === 0) {
        const barnElements = convertBarnToCanvasElements()
        setCanvasElements(barnElements)
      }
    }
  }, [isEditMode])

  // Effect to initialize canvas elements when switching to edit mode
  // useEffect(() => {
  //   if (isEditMode) {
  //     const barnElements = convertBarnToCanvasElements()
  //     setCanvasElements((prev) => {
  //       // Keep any custom added elements, just update barn elements
  //       const customElements = prev.filter(
  //         (el) => !el.isBarnStructure && !el.isAisle && !el.isStall
  //       )
  //       return [...barnElements, ...customElements]
  //     })
  //   }
  // }, [isEditMode, convertBarnToCanvasElements])

  // Add this effect to handle mode switching
  useEffect(() => {
    // Only calculate layout if not in edit mode or if no canvas elements exist
    if (!isEditMode || canvasElements.length === 0) {
      const {layout, errors} = calculateBarnLayout()

      // Only update layout if not in edit mode
      if (!isEditMode) {
        setBarnLayout(layout)
        setLayoutErrors(errors)

        const stallNumbers =
          layout.stalls?.map((stall) => parseInt(stall.id.split('-')[1]) || 0) || []
        setLastStallNumber(Math.max(0, ...stallNumbers))
      }
    }
  }, [formData, isEditMode, canvasElements.length])

  // Canvas element management functions
  const addCanvasElement = (elementType, template = null) => {
    if (!isEditMode) return

    const baseElement = {
      id: `element-${Date.now()}`,
      x: 150,
      y: 150,
      draggable: true,
    }

    let newElement

    if (elementType.startsWith('line-')) {
      const isVertical = elementType.endsWith('-v')
      const lineWidth = isVertical ? 20 : 400
      const lineHeight = isVertical ? 400 : 20
      const linePoints = isVertical ? [10, 0, 10, 400] : [0, 10, 400, 10]

      newElement = {
        ...baseElement,
        type: 'group',
        width: template?.width || lineWidth,
        height: template?.height || lineHeight,
        children: [
          // Add the actual line with a wider hit area
          {
            type: 'line',
            points: template?.points || linePoints,
            stroke: template?.stroke || '#333869',
            strokeWidth: template?.strokeWidth || 2,
            lineCap: template?.lineCap || 'round',
            dash: template?.dash,
            hitStrokeWidth: 20, // Wide hit area for better selection
          },
          // Add a visual helper when selected (optional)
          {
            type: 'rect',
            x: 0,
            y: 0,
            width: template?.width || lineWidth,
            height: template?.height || lineHeight,
            stroke: '#333869',
            strokeWidth: 0.5,
            opacity: 0,
            fillEnabled: false,
          },
        ],
      }
    } else if (elementType.startsWith('stall-')) {
      const width = template?.width || formData.stallLength * feetToPixelRatio
      const height = template?.height || formData.stallWidth * feetToPixelRatio
      const nextStallNumber = lastStallNumber + 1
      setLastStallNumber(nextStallNumber)

      const orientation = elementType.split('-')[1]
      const points = getStallPoints(width, height, orientation)

      newElement = {
        ...baseElement,
        id: `custom-stall-${nextStallNumber}`,
        type: 'group',
        width,
        height,
        name: `Stall ${nextStallNumber}`,
        orientation,
        isStall: true,
        children: [
          {
            type: 'line',
            points,
            fill: '#e9edef',
            stroke: '#333869',
            strokeWidth: 1,
            closed: true,
          },
          {
            type: 'text',
            x: width / 2,
            y: height / 2 - 12,
            width,
            text: `Stall ${nextStallNumber}`,
            fontSize: 40,
            align: 'center',
            offsetX: width / 2,
          },
        ],
      }
    } else {
      switch (elementType) {
        case 'rectangle':
          newElement = {
            ...baseElement,
            type: 'group',
            width: template?.width || 100,
            height: template?.height || 100,
            children: [
              {
                type: 'rectangle',
                x: 0,
                y: 0,
                width: template?.width || 100,
                height: template?.height || 100,
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333869',
                strokeWidth: 1,
              },
              {
                type: 'text',
                x: (template?.width || 100) / 2,
                y: (template?.height || 100) / 2,
                text: 'Rectangle',
                fontSize: 20,
                fill: '#000000',
                align: 'center',
                offsetX: (template?.width || 100) / 2,
                offsetY: 10,
              },
            ],
          }
          break

        case 'circle':
          const circleSize = template?.width || 100
          newElement = {
            ...baseElement,
            type: 'group',
            width: circleSize,
            height: circleSize,
            children: [
              {
                type: 'circle',
                x: circleSize / 2,
                y: circleSize / 2,
                radius: circleSize / 2,
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333869',
                strokeWidth: 1,
              },
              {
                type: 'text',
                x: circleSize / 2,
                y: circleSize / 2,
                text: 'Circle',
                fontSize: 20,
                fill: '#000000',
                align: 'center',
                offsetX: circleSize / 4,
                offsetY: 10,
              },
            ],
          }
          break

        case 'triangle':
          const triWidth = template?.width || 100
          const triHeight = template?.height || 100
          newElement = {
            ...baseElement,
            type: 'group',
            width: triWidth,
            height: triHeight,
            children: [
              {
                type: 'line',
                points: [triWidth / 2, 0, 0, triHeight, triWidth, triHeight],
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333869',
                strokeWidth: 1,
                closed: true,
              },
              {
                type: 'text',
                x: triWidth / 2,
                y: triHeight / 2 + 10,
                text: 'Triangle',
                fontSize: 20,
                fill: '#000000',
                align: 'center',
                offsetX: triWidth / 4,
              },
            ],
          }
          break

        case 'text':
          newElement = {
            ...baseElement,
            type: 'group',
            width: 200,
            height: 40,
            children: [
              {
                type: 'text',
                x: 100,
                y: 20,
                text: 'Sample Text',
                fontSize: 40,
                fill: '#000000',
                align: 'center',
                offsetX: 100,
                offsetY: 12,
              },
            ],
          }
          break

        default:
          return
      }
    }

    if (newElement) {
      // Add new element
      setCanvasElements((prev) => [...prev, newElement])

      // Give React a chance to render the new element
      requestAnimationFrame(() => {
        // Find and simulate click on the new element
        const shape = stageRef.current?.findOne(`#${newElement.id}`)
        if (shape) {
          // Trigger the click handler directly
          handleSelect(newElement.id)
        }
      })
    }
  }

  const applyCustomization = useCallback((original, customData) => {
    if (!customData) return original
    return {
      ...original,
      ...customData,
      x: customData.x ?? original.x,
      y: customData.y ?? original.y,
      width: customData.width ?? original.width,
      height: customData.height ?? original.height,
      name: customData.name ?? original.name,
    }
  }, [])

  // Selection handlers
  const handleSelect = useCallback(
    (id) => {
      if (!isEditMode) return

      // If clicking the same element, deselect it
      if (selectedElement === id) {
        setSelectedElement(null)
        if (transformerRef.current) {
          transformerRef.current.nodes([])
          transformerRef.current.getLayer().batchDraw()
        }
        return
      }

      setSelectedElement(id)

      // Important: Use requestAnimationFrame to ensure DOM is ready
      requestAnimationFrame(() => {
        // Find the shape - look for both group and individual elements
        const shape = stageRef.current?.findOne(`#${id}`) || stageRef.current?.findOne(`.${id}`)

        if (shape && transformerRef.current) {
          // Detach from any previous nodes
          transformerRef.current.nodes([])
          // Attach to new node
          transformerRef.current.nodes([shape])
          // Force update
          transformerRef.current.getLayer().batchDraw()
        }
      })
    },
    [isEditMode, selectedElement]
  )

  // Delete handler
  const handleDelete = useCallback(
    (id) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...prev[type][id], deleted: true},
        },
      }))
      setSelectedId(null)
    },
    [isEditMode]
  )

  // Name change handler
  const handleNameChange = useCallback(
    (id, newName) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...(prev[type][id] || {}), name: newName},
        },
      }))
    },
    [isEditMode]
  )

  // Dimension change handler
  const handleDimensionChange = useCallback(
    (id, dimensions) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...(prev[type][id] || {}), ...dimensions},
        },
      }))
    },
    [isEditMode]
  )

  const deleteElement = (id) => {
    if (!id) return

    const confirmed = window.confirm('Are you sure you want to delete this element?')

    if (confirmed) {
      // Remove the element from canvas elements
      setCanvasElements((prev) => prev.filter((el) => el.id !== id))

      // Clear selection
      setSelectedElement(null)
      if (transformerRef.current) {
        transformerRef.current.nodes([])
        transformerRef.current.getLayer().batchDraw()
      }
    }
  }

  // Render the editor sidebar
  const renderEditorSidebar = () => {
    if (!isEditMode) return null

    // Filter templates by type
    const shapeOnlyTemplates = Object.entries(shapeTemplates).filter(
      ([key]) => !key.startsWith('stall-') && !key.startsWith('line-')
    )

    const stallTemplates = Object.entries(shapeTemplates).filter(([key]) =>
      key.startsWith('stall-')
    )

    const horizontalLineTemplates = Object.entries(shapeTemplates).filter(
      ([key]) => key.startsWith('line-') && key.endsWith('-h')
    )
    const verticalLineTemplates = Object.entries(shapeTemplates).filter(
      ([key]) => key.startsWith('line-') && key.endsWith('-v')
    )

    return (
      <div className='editor-sidebar'>
        <div className='editor-section'>
          <h3>Shapes</h3>
          <div className='shape-grid'>
            {shapeOnlyTemplates.map(([key, template]) => {
              const IconComponent = template.icon
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                >
                  <IconComponent size={24} />
                  <span>{key}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Stalls</h3>
          <div className='shape-grid'>
            {stallTemplates.map(([key, template]) => {
              const IconComponent = template.icon
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                >
                  <IconComponent size={24} />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Horizontal Lines</h3>
          <div className='shape-grid'>
            {horizontalLineTemplates.map(([key, template]) => {
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                  style={{height: '48px'}}
                >
                  <div
                    style={{
                      width: '24px',
                      height: '2px',
                      backgroundColor: template.stroke,
                      borderTop: template.dash
                        ? key.includes('dotted')
                          ? '2px dotted #333869'
                          : '2px dashed #333869'
                        : '2px solid #333869',
                    }}
                  />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Vertical Lines</h3>
          <div className='shape-grid'>
            {verticalLineTemplates.map(([key, template]) => {
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                  style={{height: '48px'}}
                >
                  <div
                    style={{
                      width: '24px',
                      height: '2px',
                      backgroundColor: template.stroke,
                      borderTop: template.dash
                        ? key.includes('dotted')
                          ? '2px dotted #333869'
                          : '2px dashed #333869'
                        : '2px solid #333869',
                    }}
                  />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Text</h3>
          <button className='text-button' onClick={() => addCanvasElement('text')}>
            <Type size={24} />
            <span>Add Text</span>
          </button>
        </div>
      </div>
    )
  }

  // Effect to handle transformer
  useEffect(() => {
    if (selectedElement && transformerRef.current) {
      requestAnimationFrame(() => {
        const shape =
          stageRef.current?.findOne(`#${selectedElement}`) ||
          stageRef.current?.findOne(`.${selectedElement}`)

        if (shape) {
          transformerRef.current.nodes([shape])
          transformerRef.current.getLayer().batchDraw()
        }
      })
    }
  }, [selectedElement])

  const handleWheel = (e) => {
    e.evt.preventDefault()

    const scaleBy = 1.05
    const stage = stageRef.current
    const oldScale = stage.scaleX()
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy

    setStageScale(newScale)
    setStagePosition({
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    })
  }

  const calculateBarnLayout = useCallback(() => {
    let {
      structureWidth,
      structureLength,
      verticalAisleCount,
      verticalAisleWidth,
      horizontalAisleCount,
      horizontalAisleWidth,
      stallLength,
      stallWidth,
    } = formData

    // Convert all inputs to numbers
    structureWidth = parseFloat(structureWidth)
    structureLength = parseFloat(structureLength)
    verticalAisleCount = parseFloat(verticalAisleCount)
    verticalAisleWidth = parseFloat(verticalAisleWidth)
    horizontalAisleCount = parseFloat(horizontalAisleCount)
    horizontalAisleWidth = parseFloat(horizontalAisleWidth)
    stallLength = parseFloat(stallLength)
    stallWidth = parseFloat(stallWidth)

    // Convert dimensions from feet to pixels
    const structureWidthPx = structureWidth * feetToPixelRatio
    const structureLengthPx = structureLength * feetToPixelRatio
    const verticalAisleWidthPx = verticalAisleWidth * feetToPixelRatio
    const horizontalAisleWidthPx = horizontalAisleWidth * feetToPixelRatio
    const stallWidthPx = stallLength * feetToPixelRatio // Using stall length as width
    const stallHeightPx = stallWidth * feetToPixelRatio // Using stall width as height

    // Initialize layout
    const layout = {
      structure: {width: structureLengthPx, height: structureWidthPx},
      aisles: [],
      stalls: [],
      overflowInfo: null,
    }

    let errors = []

    if (stallLength < 5 || stallWidth < 5) {
      return {
        layout,
        errors,
      }
    }

    // Calculate total height needed for each aisle group (aisle + top and bottom stalls)
    const aisleGroupHeight = horizontalAisleWidthPx + stallHeightPx * 2
    const totalAisleGroupsHeight = aisleGroupHeight * horizontalAisleCount

    // Calculate vertical center of the barn
    const barnCenterY = structureWidthPx / 2

    // Calculate starting Y position for first aisle group (centered in barn)
    let startY = barnCenterY - totalAisleGroupsHeight / 2

    // Calculate overflow if any
    let overflowAmount = 0
    if (totalAisleGroupsHeight > structureWidthPx) {
      overflowAmount = totalAisleGroupsHeight - structureWidthPx
      layout.overflowInfo = {
        requiredWidth: Math.ceil((structureWidth + overflowAmount / feetToPixelRatio) * 100) / 100,
        currentWidth: structureWidth,
        additionalWidthNeeded: Math.ceil((overflowAmount / feetToPixelRatio) * 100) / 100,
      }
    }

    // Calculate vertical aisle positions (evenly spaced horizontally)
    const verticalAislePositions = []
    if (verticalAisleCount > 0) {
      const totalVerticalAisleSpace = verticalAisleCount * verticalAisleWidthPx
      const remainingHorizSpace = structureLengthPx - totalVerticalAisleSpace
      const spaceBetweenVerticalAisles = remainingHorizSpace / (verticalAisleCount + 1)

      let currentPos = spaceBetweenVerticalAisles
      for (let i = 0; i < verticalAisleCount; i++) {
        verticalAislePositions.push(currentPos)
        currentPos += verticalAisleWidthPx + spaceBetweenVerticalAisles
      }
    }

    // Helper function to create an aisle with customizations
    const createAisle = (baseAisle) => {
      const aisleId = `aisle-${layout.aisles.length + 1}`
      const customData = customizations.aisles[aisleId]

      if (customData?.deleted) return null

      return applyCustomization(
        {
          ...baseAisle,
          id: aisleId,
          name: `Aisle ${layout.aisles.length + 1}`,
        },
        customData
      )
    }

    // Plot vertical aisles
    verticalAislePositions.forEach((pos) => {
      const aisle = createAisle({
        x: pos,
        y: 0,
        width: verticalAisleWidthPx,
        height: structureWidthPx,
        type: 'vertical',
      })
      if (aisle) layout.aisles.push(aisle)
    })

    // Create sections between vertical aisles
    const sections = []
    let currentX = 0

    // Add first section (from left edge to first aisle)
    if (verticalAislePositions.length > 0) {
      sections.push({
        start: 0,
        end: verticalAislePositions[0],
      })
    }

    // Add middle sections
    for (let i = 0; i < verticalAislePositions.length - 1; i++) {
      sections.push({
        start: verticalAislePositions[i] + verticalAisleWidthPx,
        end: verticalAislePositions[i + 1],
      })
    }

    // Add last section
    if (verticalAislePositions.length > 0) {
      sections.push({
        start: verticalAislePositions[verticalAislePositions.length - 1] + verticalAisleWidthPx,
        end: structureLengthPx,
      })
    } else {
      sections.push({
        start: 0,
        end: structureLengthPx,
      })
    }

    // Calculate how many stalls can fit in the smallest section
    const sectionWidths = sections.map((section) => section.end - section.start)
    const minSectionWidth = Math.min(...sectionWidths)
    const stallsPerSection = Math.floor(minSectionWidth / stallWidthPx)

    // Helper function to create a stall with customizations
    const createStall = (baseStall) => {
      const stallId = `stall-${layout.stalls.length + 1}`
      const customData = customizations.stalls[stallId]

      if (customData?.deleted) return null

      return applyCustomization(
        {
          ...baseStall,
          id: stallId,
          name: `Stall ${layout.stalls.length + 1}`,
        },
        customData
      )
    }

    // Function to place stalls in a row
    const placeStallRow = (startY, section, orientation) => {
      const availableWidth = section.end - section.start
      const totalStallsWidth = stallsPerSection * stallWidthPx
      const leftOffset = (availableWidth - totalStallsWidth) / 2

      for (let i = 0; i < stallsPerSection; i++) {
        const stallX = section.start + leftOffset + i * stallWidthPx
        const stallY = startY

        // Check if stall is partially or fully outside barn boundaries
        const isOutOfBounds = stallY < 0 || stallY + stallHeightPx > structureWidthPx

        // Create points relative to the stall's own coordinate system (0,0)
        const points = getStallPoints(stallWidthPx, stallHeightPx, orientation)

        const stall = createStall({
          points: points, // Use the points directly, don't offset them
          x: stallX, // Position will be handled by the group
          y: stallY,
          width: stallWidthPx,
          height: stallHeightPx,
          orientation: orientation, // Add orientation to the stall data
          isOutOfBounds,
        })

        if (stall) layout.stalls.push(stall)
      }
    }

    // Plot horizontal aisles and their stalls
    for (let i = 0; i < horizontalAisleCount; i++) {
      const currentAisleY = startY + i * aisleGroupHeight
      const isAisleOutOfBounds =
        currentAisleY < 0 || currentAisleY + horizontalAisleWidthPx > structureWidthPx

      // Add horizontal aisle
      const aisle = createAisle({
        x: 0,
        y: currentAisleY + stallHeightPx, // Position after top stalls
        width: structureLengthPx,
        height: horizontalAisleWidthPx,
        type: 'horizontal',
        isAisleOutOfBounds,
      })
      if (aisle) layout.aisles.push(aisle)

      // Check for boundary issues and add to errors
      if (currentAisleY < 0 || currentAisleY + aisleGroupHeight > structureWidthPx) {
        if (errors.length == 0) {
          errors.push({
            type: 'boundary',
            severity: 'error',
            message: `Barn width is too small. Current: ${structureWidth}ft. Required: ${layout.overflowInfo.requiredWidth}ft. Please increase width by at least ${layout.overflowInfo.additionalWidthNeeded}ft.`,
          })
        }
      }

      // Place top row stalls
      sections.forEach((section) => {
        placeStallRow(currentAisleY, section, 'bottom')
      })

      // Place bottom row stalls
      sections.forEach((section) => {
        placeStallRow(currentAisleY + stallHeightPx + horizontalAisleWidthPx, section, 'top')
      })
    }

    return {
      layout,
      errors,
    }
  }, [formData, customizations, applyCustomization])

  // Update the useEffect that handles layout calculation
  useEffect(() => {
    if (!isEditMode || (isEditMode && canvasElements.length === 0)) {
      const {layout, errors} = calculateBarnLayout()
      setBarnLayout(layout)
      setLayoutErrors(errors)

      // Update last stall number only if we're not in edit mode
      if (!isEditMode) {
        const stallNumbers =
          layout.stalls?.map((stall) => parseInt(stall.id.split('-')[1]) || 0) || []
        setLastStallNumber(Math.max(0, ...stallNumbers))
      }
    }
  }, [formData, isEditMode])

  const renderEditForm = () => {
    if (!selectedId || !isEditMode) return null

    const item = selectedId.startsWith('stall-')
      ? barnLayout.stalls.find((s) => s.id === selectedId)
      : barnLayout.aisles.find((a) => a.id === selectedId)

    if (!item) return null

    const type = selectedId.startsWith('stall-') ? 'stalls' : 'aisles'
    const customization = customizations[type][selectedId] || {}

    return (
      <div className='edit-form-overlay'>
        <div className='edit-form'>
          <h3>
            {customization.name || item.name || (type === 'stalls' ? 'Stall' : 'Aisle')} Settings
          </h3>

          <div className='form-group'>
            <label>Name</label>
            <input
              type='text'
              value={customization.name || item.name || ''}
              onChange={(e) => handleNameChange(selectedId, e.target.value)}
            />
          </div>

          <div className='form-group'>
            <label>Width (ft)</label>
            <input
              type='number'
              value={(customization.width || item.width) / feetToPixelRatio}
              onChange={(e) =>
                handleDimensionChange(selectedId, {
                  width: parseFloat(e.target.value) * feetToPixelRatio,
                })
              }
            />
          </div>

          <div className='form-group'>
            <label>Length (ft)</label>
            <input
              type='number'
              value={(customization.height || item.height) / feetToPixelRatio}
              onChange={(e) =>
                handleDimensionChange(selectedId, {
                  height: parseFloat(e.target.value) * feetToPixelRatio,
                })
              }
            />
          </div>

          <button onClick={() => handleDelete(selectedId)} className='delete-btn'>
            <i className='fas fa-trash'></i> Delete
          </button>
        </div>
      </div>
    )
  }

  const validateField = (name, value) => {
    let error = ''
    switch (name) {
      case 'structureLength':
        if (!value) {
          error = 'Please enter a valid structure length.'
        }
        break
      case 'structureWidth':
        if (!value) {
          error = 'Please enter a valid structure width.'
        }
        break
      case 'horizontalAisleCount':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid number for Length Wise Aisles.'
        }
        break
      case 'verticalAisleCount':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid number for Width Wise Aisles.'
        }
        break
      case 'horizontalAisleWidth':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid aisle width for Length Wise Aisles.'
        }
        break
      case 'verticalAisleWidth':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid aisle width for Width Wise Aisles.'
        }
        break
      case 'stallLength':
        if (!value || isNaN(value) || value < 5) {
          error = 'Please enter a valid stall length. It should be equal or more than 5 feet.'
        }
        break
      case 'stallWidth':
        if (!value || isNaN(value) || value < 5) {
          error = 'Please enter a valid stall width. It should be equal or more than 5 feet.'
        }
        break
      default:
        break
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }))
  }

  const zoomIn = () => {
    setStageScale((prevScale) => prevScale * 1.5) // Increase scale
  }

  const zoomOut = () => {
    setStageScale((prevScale) => prevScale / 1.5) // Decrease scale
  }

  const fitView = () => {
    const stage = stageRef.current
    const padding = 15 // Add a padding around the barn layout

    const containerWidth = stage.width() // Full available width of the stage
    const containerHeight = stage.height() // Full available height of the stage

    // Get the width and height of the barn layout
    const layoutWidth = barnLayout.structure?.width || 1 // Default to 1 to avoid division by 0
    const layoutHeight = barnLayout.structure?.height || 1

    // Calculate scaling factors based on the container's available size minus the padding
    const scaleX = (containerWidth - padding * 2) / layoutWidth
    const scaleY = (containerHeight - padding * 2) / layoutHeight

    // Choose the smaller scale to ensure the entire layout fits inside the container
    const newScale = Math.min(scaleX, scaleY)

    // Center the stage by calculating the appropriate offsets with padding
    const offsetX = (containerWidth - layoutWidth * newScale) / 2
    const offsetY = (containerHeight - layoutHeight * newScale) / 2

    // Set the new scale and center position
    setStageScale(newScale)
    setStagePosition({x: offsetX, y: offsetY})
  }

  useEffect(() => {
    if (barnLayout.structure) {
      fitView()
    }
  }, [barnLayout.structure])

  useEffect(() => {
    const handleResize = () => {
      if (stageRef.current) {
        stageRef.current.width(window.innerWidth - 210 - 75 - 5)
        stageRef.current.height(window.innerHeight - 54 - 50 - 40)
        fitView()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target
    const updatedValue = type === 'checkbox' ? checked : value

    // Set the field data
    setFormData({
      ...formData,
      [name]: updatedValue,
    })

    // Validate the field being updated
    validateField(name, updatedValue)
  }

  // Add these helper functions at the top of your component:
  const updateElementOnTransform = (element) => {
    if (!selectedShapeRef.current) return element
    const node = selectedShapeRef.current

    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    const rotation = node.rotation()

    const newElement = {
      ...element,
      x: node.x(),
      y: node.y(),
      rotation: rotation,
      scaleX: scaleX,
      scaleY: scaleY,
      width: element.width * scaleX,
      height: element.height * scaleY,
    }

    return newElement
  }

  const handleModeSwitch = () => {
    if (isEditMode) {
      // When switching from edit to view mode
      const confirmed = window.confirm(
        'Switching to view mode will reset all changes. Are you sure you want to continue?'
      )
      if (confirmed) {
        setIsEditMode(false)
      }
    } else {
      // When switching to edit mode, no confirmation needed
      setIsEditMode(true)
    }
  }

  // Add this useEffect for keyboard controls
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Delete') {
        if (selectedElement) {
          deleteElement(selectedElement)
        }
      } else if (e.key === 'Escape') {
        setSelectedElement(null)
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [selectedElement])

  const SaveDialog = ({isOpen, onClose}) => {
    const inputRef = useRef(null) // Moved outside the conditional

    // Focus input when dialog opens
    useEffect(() => {
      if (isOpen && inputRef.current) {
        // Small delay to ensure DOM is ready
        setTimeout(() => {
          inputRef.current.focus()
        }, 50)
      }
    }, [isOpen])

    const handleSave = (e) => {
      e.preventDefault() // Prevent form submission from refreshing page

      if (!designName.trim()) {
        alert('Please enter a design name')
        return
      }

      const designData = {
        name: designName,
        timestamp: new Date().toISOString(),
        formData,
        customizations,
        barnLayout,
        canvasElements: canvasElements.map((element) => ({
          ...element,
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
          rotation: element.rotation || 0,
          scaleX: element.scaleX || 1,
          scaleY: element.scaleY || 1,
          isStall: element.isStall,
          isAisle: element.isAisle,
          orientation: element.orientation,
          children: element.children || [],
          customData: element.customData,
        })),
        lastStallNumber,
        isEditMode: true,
      }

      const existingDesigns = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
      localStorage.setItem('barnDesigns', JSON.stringify([...existingDesigns, designData]))

      setDesignName('')
      onClose()
    }

    if (!isOpen) return null

    // Use a form to handle submit properly
    return (
      <div
        className='modal-overlay'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000, // Higher than zoom controls
        }}
      >
        <div
          className='modal-content'
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <h2>Save Design</h2>
          <form onSubmit={handleSave}>
            <div className='modal-body'>
              <input
                ref={inputRef}
                type='text'
                placeholder='Enter design name'
                value={designName}
                onChange={(e) => setDesignName(e.target.value)}
                className='design-name-input'
                style={{
                  width: '100%',
                  padding: '8px',
                  marginBottom: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              />
            </div>
            <div
              className='modal-footer'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '8px',
              }}
            >
              <button
                type='button'
                onClick={onClose}
                style={{
                  padding: '8px 16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  background: 'white',
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='primary'
                style={{
                  padding: '8px 16px',
                  border: 'none',
                  borderRadius: '4px',
                  background: '#4CAF50',
                  color: 'white',
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  const handleLoad = (design) => {
    // Create a promise to ensure state updates happen in order
    const loadStates = async () => {
      // Clear previous design state first
      setCanvasElements([])
      setSelectedElement(null)
      setSelectedId(null)
      setDraggedElements({})

      // Wait a tick for clearing to complete
      await new Promise((resolve) => setTimeout(resolve, 0))

      // Set form data first
      setFormData(design.formData)

      // Set customizations before barnLayout
      setCustomizations(design.customizations || {})

      // Set exact saved barn layout
      if (design.barnLayout) {
        setBarnLayout(design.barnLayout)
      }

      // Important: Set edit mode after loading data but before setting canvas elements
      setIsEditMode(true)

      // Wait another tick to ensure edit mode is set
      await new Promise((resolve) => setTimeout(resolve, 0))

      // Now set the exact canvas elements
      if (design.canvasElements?.length > 0) {
        const validElements = design.canvasElements
          .filter((element) => {
            return (
              element &&
              element.id &&
              typeof element.x === 'number' &&
              typeof element.y === 'number' &&
              typeof element.width === 'number' &&
              typeof element.height === 'number'
            )
          })
          .map((element) => ({
            ...element,
            draggable: true,
            rotation: element.rotation || 0,
            scaleX: element.scaleX || 1,
            scaleY: element.scaleY || 1,
            children: element.children || [],
          }))

        setCanvasElements(validElements)
      }

      // Set the last stall number
      setLastStallNumber(design.lastStallNumber || 0)
    }

    // Execute the loading sequence
    loadStates().then(() => {
      setShowLoadDialog(false)
    })
  }

  const LoadDialog = ({isOpen, onClose}) => {
    const [designs, setDesigns] = useState([])

    useEffect(() => {
      if (isOpen) {
        // Load saved designs from localStorage
        try {
          const savedDesigns = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
          setDesigns(savedDesigns)
        } catch (error) {
          console.error('Error loading designs:', error)
          setDesigns([])
        }
      }
    }, [isOpen])

    // If dialog is not open, don't render anything
    if (!isOpen) return null

    return (
      <div className='modal-overlay'>
        <div className='modal-content'>
          <h2>Load Design</h2>
          <div className='modal-body'>
            {designs.length === 0 ? (
              <p>No saved designs found</p>
            ) : (
              <div className='designs-list'>
                {designs.map((design, index) => (
                  <div key={index} className='design-item'>
                    <div className='design-info'>
                      <h3>{design.name}</h3>
                      <p>{new Date(design.timestamp).toLocaleString()}</p>
                    </div>
                    <button
                      onClick={() => {
                        handleLoad(design)
                        onClose() // Ensure dialog closes after loading
                      }}
                    >
                      Load
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className='modal-footer'>
            <button onClick={onClose} className='close-button'>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }

  // Add the quick save function
  const handleQuickSave = () => {
    const designs = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
    const currentDesign = designs[designs.length - 1]

    if (!currentDesign) {
      setShowSaveDialog(true)
      return
    }

    const designData = {
      ...currentDesign,
      timestamp: new Date().toISOString(),
      formData,
      customizations,
      // Save the exact barn layout
      barnLayout,
      canvasElements: canvasElements.map((element) => ({
        ...element,
        // Preserve exact positions
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        // Preserve transforms
        rotation: element.rotation || 0,
        scaleX: element.scaleX || 1,
        scaleY: element.scaleY || 1,
        // Preserve custom properties
        isStall: element.isStall,
        isAisle: element.isAisle,
        orientation: element.orientation,
        children: element.children || [],
        // Preserve any custom data
        customData: element.customData,
      })),
      lastStallNumber,
      isEditMode: true,
    }

    designs[designs.length - 1] = designData
    localStorage.setItem('barnDesigns', JSON.stringify(designs))
  }

  return (
    <div id='barn-designer' style={{height: '100%', width: '100%'}}>
      <SaveDialog isOpen={showSaveDialog} onClose={() => setShowSaveDialog(false)} />
      <LoadDialog isOpen={showLoadDialog} onClose={() => setShowLoadDialog(false)} />
      {/* Toolbar */}
      <div className='barn-designer-toolbar'>
        <button className={`mode-toggle ${isEditMode ? 'active' : ''}`} onClick={handleModeSwitch}>
          <i className={`fas fa-${isEditMode ? 'eye' : 'edit'}`}></i>
          {isEditMode ? ' View Mode' : ' Edit Mode'}
        </button>

        <button onClick={() => setShowLoadDialog(true)}>
          <Upload size={16} />
          Load Design
        </button>

        {isEditMode && (
          <>
            <button onClick={() => setShowSaveDialog(true)}>
              <Save size={16} />
              Save Design
            </button>
            <button onClick={handleQuickSave}>
              <Redo size={16} />
              Quick Save
            </button>
          </>
        )}
      </div>

      <Split
        className='split-horizontal split-bottom'
        minSize={[210, 800]}
        maxSize={[210, Infinity]}
        gutterSize={5}
        snapOffset={0}
      >
        {/* Left Panel - Either Editor Sidebar or Parameters Form */}
        {isEditMode ? (
          renderEditorSidebar()
        ) : (
          <div className='parameters'>
            <h1 className='text-dark fw-bolder mb-3 fs-3 text-center'> Barn Designer </h1>
            <form>
              <div className='field-container'>
                <div className='field'>
                  <label>Structure Length (feet)</label>
                  <input
                    type='number'
                    name='structureLength'
                    value={formData.structureLength}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.structureLength && (
                    <span className='error-msg'>{errors.structureLength}</span>
                  )}
                </div>

                <div className='field'>
                  <label>Structure Width (feet)</label>
                  <input
                    type='number'
                    name='structureWidth'
                    value={formData.structureWidth}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.structureWidth && (
                    <span className='error-msg'>{errors.structureWidth}</span>
                  )}
                </div>
              </div>

              <div className='field-container'>
                <div className='field'>
                  <label>Horizontal Aisles Count</label>
                  <input
                    type='number'
                    name='horizontalAisleCount'
                    value={formData.horizontalAisleCount}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.horizontalAisleCount && (
                    <span className='error-msg'>{errors.horizontalAisleCount}</span>
                  )}
                </div>

                <div className='field'>
                  <label>Horizontal Aisle Width (feet)</label>
                  <input
                    type='number'
                    name='horizontalAisleWidth'
                    value={formData.horizontalAisleWidth}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.horizontalAisleWidth && (
                    <span className='error-msg'>{errors.horizontalAisleWidth}</span>
                  )}
                </div>
              </div>

              <div className='field-container'>
                <div className='field'>
                  <label>Vertical Aisles Count</label>
                  <input
                    type='number'
                    name='verticalAisleCount'
                    value={formData.verticalAisleCount}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.verticalAisleCount && (
                    <span className='error-msg'>{errors.verticalAisleCount}</span>
                  )}
                </div>

                <div className='field'>
                  <label>Vertical Aisle Width (feet)</label>
                  <input
                    type='number'
                    name='verticalAisleWidth'
                    value={formData.verticalAisleWidth}
                    onChange={handleChange}
                    min='0'
                  />
                  {errors.verticalAisleWidth && (
                    <span className='error-msg'>{errors.verticalAisleWidth}</span>
                  )}
                </div>
              </div>

              <div className='field-container'>
                <div className='field'>
                  <label>Stall Length</label>
                  <input
                    type='number'
                    name='stallLength'
                    value={formData.stallLength}
                    onChange={handleChange}
                    min='5'
                  />
                  {errors.stallLength && <span className='error-msg'>{errors.stallLength}</span>}
                </div>

                <div className='field'>
                  <label>Stall Width</label>
                  <input
                    type='number'
                    name='stallWidth'
                    value={formData.stallWidth}
                    onChange={handleChange}
                    min='5'
                  />
                  {errors.stallWidth && <span className='error-msg'>{errors.stallWidth}</span>}
                </div>
              </div>
            </form>
          </div>
        )}

        {/* Right Panel - Canvas */}
        <div id='canvas' style={{position: 'relative'}}>
          {/* Error messages */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 50,
              padding: '8px',
              maxHeight: '128px',
              overflowY: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              textAlign: 'center',
            }}
          >
            {layoutErrors.map((error, index) => (
              <div
                key={index}
                style={{
                  color: error.severity === 'error' ? 'red' : 'orange',
                  display: 'block',
                  marginBottom: '4px',
                }}
              >
                {error.message}
              </div>
            ))}
          </div>

          {/* Zoom controls */}
          <div className='zoom-controls'>
            <button onClick={zoomIn}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                <path d='M32 18.133H18.133V32h-4.266V18.133H0v-4.266h13.867V0h4.266v13.867H32z'></path>
              </svg>
            </button>
            <button onClick={zoomOut}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 5'>
                <path d='M0 0h32v4.2H0z'></path>
              </svg>
            </button>
            <button onClick={fitView}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 30'>
                <path d='M3.692 4.63c0-.53.4-.938.939-.938h5.215V0H4.708C2.13 0 0 2.054 0 4.63v5.216h3.692V4.631zM27.354 0h-5.2v3.692h5.17c.53 0 .984.4.984.939v5.215H32V4.631A4.624 4.624 0 0027.354 0zm.954 24.83c0 .532-.4.94-.939.94h-5.215v3.768h5.215c2.577 0 4.631-2.13 4.631-4.707v-5.139h-3.692v5.139zm-23.677.94c-.531 0-.939-.4-.939-.94v-5.138H0v5.139c0 2.577 2.13 4.707 4.708 4.707h5.138V25.77H4.631z'></path>
              </svg>
            </button>
          </div>

          {/* Stage */}
          <Stage
            width={window.innerWidth - 210 - 75 - 5}
            height={window.innerHeight - 54 - 50 - 40}
            ref={stageRef}
            draggable={!isDragging && !isTransforming}
            onWheel={handleWheel}
            scaleX={stageScale}
            scaleY={stageScale}
            x={stagePosition.x}
            y={stagePosition.y}
            onDragStart={(e) => {
              if (e.target === e.target.getStage()) {
                e.evt.preventDefault()
                e.evt.stopPropagation()
              }
            }}
            onDragMove={(e) => {
              if (!isDragging && !isTransforming && e.target === e.target.getStage()) {
                setStagePosition({
                  x: e.target.x(),
                  y: e.target.y(),
                })
              }
            }}
            onClick={(e) => {
              // Check if click is on toolbar
              const targetElement = e.evt.target
              if (targetElement.closest('.element-toolbar')) {
                return // Don't handle stage clicks if clicking toolbar
              }

              // Regular stage click handling
              if (e.target === e.target.getStage()) {
                setSelectedElement(null)
                if (transformerRef.current) {
                  transformerRef.current.nodes([])
                  transformerRef.current.getLayer().batchDraw()
                }
              }
            }}
          >
            <Layer>
              {/* Render either barn layout or canvas elements based on mode */}
              {isEditMode ? (
                // Edit mode - render canvas elements
                <>
                  {/* Render barn structure */}
                  <Rect
                    x={50}
                    y={50}
                    width={barnLayout.structure?.width}
                    height={barnLayout.structure?.height}
                    fill='#ffffff'
                    stroke='black'
                    strokeWidth={1}
                    draggable={false}
                  />

                  {/* Render all canvas elements */}
                  {canvasElements.map((element) => {
                    if (element.isBarnStructure) return null

                    const isSelected = selectedElement === element.id

                    return (
                      <Group
                        key={element.id}
                        id={element.id}
                        x={element.x}
                        y={element.y}
                        width={element.width}
                        height={element.height}
                        draggable={element.draggable && isEditMode}
                        rotation={element.rotation || 0}
                        scaleX={element.scaleX || 1}
                        scaleY={element.scaleY || 1}
                        onClick={() => handleSelect(element.id)}
                        onTap={() => handleSelect(element.id)}
                        ref={isSelected ? selectedShapeRef : null}
                        onDragStart={(e) => {
                          setIsDragging(true)
                          // Select element when starting to drag
                          if (selectedElement !== element.id) {
                            handleSelect(element.id)
                          }

                          // Force transformer update
                          requestAnimationFrame(() => {
                            const shape = e.target
                            if (shape && transformerRef.current) {
                              transformerRef.current.nodes([shape])
                              transformerRef.current.getLayer().batchDraw()
                            }
                          })
                        }}
                        onDragEnd={(e) => {
                          setIsDragging(false)
                          if (selectedShapeRef.current) {
                            const node = selectedShapeRef.current
                            setCanvasElements((prev) =>
                              prev.map((el) =>
                                el.id === element.id
                                  ? {
                                      ...el,
                                      x: node.x(),
                                      y: node.y(),
                                    }
                                  : el
                              )
                            )
                          }
                        }}
                      >
                        {element.children?.map((child, index) => {
                          switch (child.type) {
                            case 'rectangle':
                              return <Rect key={`${element.id}-rect-${index}`} {...child} />
                            case 'circle':
                              return <Circle key={`${element.id}-circle-${index}`} {...child} />
                            case 'line':
                              return <Line key={`${element.id}-line-${index}`} {...child} />
                            case 'text':
                              return <Text key={`${element.id}-text-${index}`} {...child} />
                            default:
                              return null
                          }
                        })}
                      </Group>
                    )
                  })}

                  {selectedElement && (
                    <Transformer
                      ref={transformerRef}
                      rotateEnabled={true}
                      enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                      boundBoxFunc={(oldBox, newBox) => {
                        const minSize = 20
                        if (Math.abs(newBox.width) < minSize || Math.abs(newBox.height) < minSize) {
                          return oldBox
                        }
                        return newBox
                      }}
                      onTransformStart={() => {
                        setIsDragging(false)
                        setIsTransforming(true)
                      }}
                      onTransformEnd={() => {
                        setIsTransforming(false)
                        if (selectedShapeRef.current) {
                          const node = selectedShapeRef.current
                          setCanvasElements((prev) =>
                            prev.map((el) =>
                              el.id === selectedElement ? updateElementOnTransform(el) : el
                            )
                          )
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                // View mode - render barn layout
                <>
                  {/* Barn structure */}
                  <Rect
                    x={50}
                    y={50}
                    width={barnLayout.structure?.width}
                    height={barnLayout.structure?.height}
                    fill='#ffffff'
                    stroke='black'
                    strokeWidth={1}
                  />

                  {/* Clipping group for stalls and aisles */}
                  <Group
                    clipFunc={(ctx) => {
                      ctx.beginPath()
                      ctx.rect(50, 50, barnLayout.structure?.width, barnLayout.structure?.height)
                      ctx.closePath()
                    }}
                  >
                    {/* Draw the aisles */}
                    {(() => {
                      let horizontalAisleNumber = 1
                      return barnLayout.aisles?.map((aisle) => (
                        <Group
                          key={`aisle-group-${aisle.id}`}
                          onClick={() => handleSelect(aisle.id)}
                          onTap={() => handleSelect(aisle.id)}
                        >
                          <Rect
                            x={50 + aisle.x}
                            y={50 + aisle.y}
                            width={aisle.width}
                            height={aisle.height}
                            fill={selectedId === aisle.id ? '#e0e0e0' : '#f6f6f6'}
                            stroke={selectedId === aisle.id ? '#333869' : '#cccccc'}
                            strokeWidth={selectedId === aisle.id ? 2 : 1}
                          />
                          {aisle.type === 'horizontal' && (
                            <Text
                              x={50 + aisle.x}
                              y={50 + aisle.y + aisle.height / 2 - 10}
                              width={aisle.width}
                              text={
                                customizations.aisles[aisle.id]?.name ||
                                `Aisle ${horizontalAisleNumber++}`
                              }
                              fontSize={48}
                              align='center'
                            />
                          )}
                        </Group>
                      ))
                    })()}

                    {/* Draw stalls */}
                    {barnLayout.stalls?.map((stall) => (
                      <Group
                        key={`stall-group-${stall.id}`}
                        onClick={() => handleSelect(stall.id)}
                        onTap={() => handleSelect(stall.id)}
                      >
                        <Line
                          points={stall.points} // Use points directly, they're already relative
                          x={50 + stall.x} // Add base offset to x
                          y={50 + stall.y} // Add base offset to y
                          closed={true}
                          fill={selectedId === stall.id ? '#d4d6dd' : '#e9edef'}
                          stroke={selectedId === stall.id ? '#1a237e' : '#333869'}
                          strokeWidth={selectedId === stall.id ? 2 : 1}
                        />
                        <Text
                          x={50 + stall.x + stall.width / 2} // Center text horizontally
                          y={50 + stall.y + stall.height / 2 - 10} // Center text vertically
                          width={stall.width}
                          text={
                            customizations.stalls[stall.id]?.name ||
                            stall.name ||
                            `${stall.id.split('-')[1]}`
                          }
                          fontSize={40}
                          align='center'
                          offsetX={stall.width / 2} // Ensure proper text centering
                        />
                      </Group>
                    ))}
                  </Group>
                </>
              )}
            </Layer>
          </Stage>
          <div className='toolbar-container'>
            {isEditMode && selectedElement && (
              <ElementToolbar
                selectedElement={selectedElement}
                canvasElements={canvasElements}
                setCanvasElements={setCanvasElements}
              />
            )}
          </div>
        </div>
      </Split>

      {/* Edit form overlay */}
      {renderEditForm()}
    </div>
  )
}

export default BarnDesigner
