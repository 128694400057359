import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks';
import { FeiEcNameMismatchAlert } from './FeiEcNameMismatchAlert';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';
import { getMembershipNumber } from './MembershipFunctions';
import moment from 'moment';

const FeiEcMembershipTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState()
    const [membershipName, setMembershipName] = useState("")
    const sglLite = useAppSelector(state => state.showCompany.sgl_lite)
    
    const columnDefs = [
        { field: 'key', flex:1, maxWidth: 245, minWidth:245, lockVisible: false, resizable: true, lockPosition: 'left', cellClass: 'text-start' , cellStyle: {'border-right': '1px solid #eee'}
            , cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value',flex: 1, lockVisible: false, lockPosition: 'left', resizable: true, cellClass: 'text-start', cellRenderer: HideJsonRenderer },
    ]

    // useEffect(() => {
    //     if(gridRef){
    //         if(methods.getValues('people.fei_lookup_response')){
    //             // nameMismatchAcceptedCheckbox()
    //             parseLookupData(JSON.parse(methods.getValues('people.fei_lookup_response')))
    //         }
    //         else{
    //             gridRef.setRowData([])
    //         }

    //     }
    // }, [gridRef, methods.getValues('people.fei_lookup_response')])

    useEffect(() => {
        if(gridRef){
            if (props.tabname == "EC" && methods.getValues('people.ec_lookup_response') && isValidJson(methods.getValues('people.ec_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('people.ec_lookup_response')))
            }
            else if (props.tabname == "FEI" && methods.getValues('people.fei_lookup_response') && isValidJson(methods.getValues('people.fei_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('people.fei_lookup_response')))
            }
            else{
                gridRef.setRowData([])
            }
        }
    }, [gridRef, methods.getValues('people.ec_lookup_response'), methods.getValues('people.fei_lookup_response')])

    const parseLookupData = (lookup) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let row_data = []
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     value.map((data) => {
                //         let lookup = Object.entries(data)
                //         lookup.map(([key, value]) => {
                //             row_data.push({'key': key, "value": value})
                //         })
                //     })
                // }
                // else{
                    row_data.push({'key':key, "value":value})
                // }
            })
            gridRef.setRowData(row_data)
        }
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '93vh' }), []);

    const lookup_data = () => {
        let memObj = {}
        let org = props.tabname
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code){
            memObj = node.data
          }
        });
        
        if (props.tabname.toLowerCase() == "ec"){
            callECLookupApi(false)
        }
        else if (props.tabname.toLowerCase() == "fei"){
            callFeiLookupApi(false)
        }
    }

    function callECLookupApi(accept_mismatch_checked= false){
        let memObj = {}, org = "EC";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        props.getLookupData(org, memObj, 'ecLookup', accept_mismatch_checked)
    }    

    function callFeiLookupApi(accept_mismatch_checked= false){
        let memObj = {}, org = "FEI";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        props.getLookupData(org, memObj, 'feiLookup', accept_mismatch_checked)
    }

    const acceptMismatch = (tabname) => {
        if (tabname == "EC"){
            // methods.setValue('people.first', props.personFirstName, {shouldDirty: true})
            // methods.setValue('people.last_name', props.personLastName, {shouldDirty: true})
            let organizationMembers = methods.getValues("organizationMembers"), organization = ""
            organization = organizationMembers.find(membershipData =>{
                if (membershipData.organization_code.toLowerCase() == 'ec'){
                    return membershipData
                }
            })
            if(!organization){
                organization.membership_id = 0
                organization.organization_code = "EC"
                organization.people_id = methods.getValues('people.people_id')
            }
            organization.name_mismatch_accepted = true
            // organization.membership_name = membershipName?.trim()
            let membership_year = methods.getValues("people.ec_last_year_active")?parseInt(methods.getValues("people.ec_last_year_active")):0;
            if((!organization.valid_start || organization.valid_start == '0000-00-00') && (!organization.valid_end || organization.valid_end == '0000-00-00') && membership_year > 0){
                organization.valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
                organization.valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
                // organization.membership_level = props.personFirstName + " " + props.personLastName
                organization.membership_level = methods.getValues("people.ec_sport_license_type")

                let current_date = moment().format('YYYY-MM-DD')
                if (organization.valid_end >= current_date) { //RTO has current compition year membership of EC
                    organization.is_membershipvalid = true
                }
            }

            props.updateMembership(organization)
        }
        else if (tabname == "FEI"){
            // methods.setValue('people.first', props.personFirstName, {shouldDirty: true})
            // methods.setValue('people.last_name', props.personLastName, {shouldDirty: true})
            let organizationMembers = methods.getValues("organizationMembers"), organization = ""
            organization = organizationMembers.find(membershipData =>{
                if (membershipData.organization_code.toLowerCase() == 'fei'){
                    return membershipData
                }
            })
            if(!organization){
                organization.membership_id = 0
                organization.organization_code = "FEI"
                organization.people_id = methods.getValues('people.people_id')
            }
            organization.name_mismatch_accepted = true
            // organization.membership_name = membershipName?.trim()

            let membership_year = methods.getValues("people.fei_membership_year")?parseInt(methods.getValues("people.fei_membership_year")):0;
            let fei_isactive = methods.getValues("people.fei_isactive")
            if(organization.valid_start == '0000-00-00' && organization.valid_start == '0000-00-00' && membership_year > 0 && fei_isactive){
                organization.valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
                organization.valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
                // organization.membership_level = methods.getValues("people.ec_sport_license_type")

                let current_date = moment().format('YYYY-MM-DD')
                if (organization.valid_end >= current_date) { //RTO has current compition year membership of FEI
                    organization.is_membershipvalid = true
                }
            }

            props.updateMembership(organization)
            

        }
    }
    
    const handleClearMembership = (tabname) => {
        if (tabname == "FEI"){
            methods.setValue('people.fei_lookup_response', '')
            props.clearMembership('FEI')
            // props.removeMembership('FEI', true)
        }
        else if (tabname == "EC"){
            methods.setValue('people.ec_lookup_response', '')

            props.clearMembership('EC')
            // props.removeMembership('EC', true)
        }
    }

    // getting boolean to enable/disable name mismatch checkbox
    const getMismatchAcceptDisable = () => {
        let isMisMatchDisable = true
        let memberships = methods.getValues('organizationMembers')
        let personName = methods.getValues('people.first') + ' ' + methods.getValues('people.last_name')
        if (props.tabname == "FEI" /*&& !sglLite*/){
            let FEImemberships = memberships?.find(data => {
            return data.organization_code == "FEI"
            })
            if(FEImemberships){
                if(FEImemberships.membership_name && FEImemberships.membership_name?.trim().toUpperCase() != personName?.trim().toUpperCase()){
                    isMisMatchDisable = false 
                }
            } 
        }
        else if (props.tabname == "EC"){
            let ECmemberships = memberships?.find(data => {
                return data.organization_code == "EC"
                })
                if(ECmemberships){
                    if(ECmemberships.membership_name && ECmemberships.membership_name?.trim().toUpperCase() != personName?.trim().toUpperCase()){
                        isMisMatchDisable = false 
                    }
                }
        }

        return isMisMatchDisable
    }

    const getMembershipName = () => {
        let mem_name = intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.FEIMEMBERSHIP.NAMEON' })+' '+props.tabname
        let memberships = methods.getValues('organizationMembers')

        if (props.tabname == "FEI"){
            let FEImemberships = memberships?.find(data => {
                return data.organization_code == "FEI"
            })
            if(FEImemberships.membership_name){
                mem_name = `${mem_name}&emsp;&emsp;${FEImemberships.membership_name}`
            }
        }
        else if (props.tabname == "EC"){
            let ECmemberships = memberships?.find(data => {
                return data.organization_code == "EC"
            })
            if(ECmemberships.membership_name){
                mem_name = `${mem_name}&emsp;&emsp;${ECmemberships.membership_name}`

            }
        }

        return mem_name;

    }
    // getting boolean to set default checked of name mismatch checkbox
    const getMismatchAccepted = () => {
        let memberships = methods.getValues('organizationMembers')
        if (props.tabname == "FEI"){
            let FEImemberships = memberships?.find(data => {
                return data.organization_code == "FEI"
             })
             if(FEImemberships){
                 if(FEImemberships.name_mismatch_accepted)
                 {
                     return true;
                 }
             }
             return false;
        }
        else if (props.tabname == "EC"){
            let ECmemberships = memberships?.find(data => {
                return data.organization_code == "EC"
             })
             if(ECmemberships){
                 if(ECmemberships.name_mismatch_accepted)
                 {
                     return true;
                 }
             }
             return false;
        }
    }
        
    //calling fei lookup api on accept name mismatch checkbox 
    function onNameMismatchChecked(checked){
        if(checked){
            if(props.tabname == 'FEI'){
                callFeiLookupApi(true);
            }
            else if(props.tabname == 'EC'){
                callECLookupApi(true)
            }
        } else {
            handleClearMembership(props.tabname)
        }
    }

    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-2 membership-data-grid'>
                    {(props.ECNameMismatch) &&
                        <FeiEcNameMismatchAlert show={props.ECNameMismatch} handleClose={() => props.setECNameMismatch(false)} personFirstName={props.personFirstName} personLastName={props.personLastName} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} tabname={'EC'} name={methods.getValues('people.isfarmrto')? methods.getValues('people.company_name'): methods.getValues('people.first') + ' ' + methods.getValues('people.last_name')} />
                    }
                    {(props.FEINameMismatch) &&
                        <FeiEcNameMismatchAlert show={props.FEINameMismatch} handleClose={() => props.setFEINameMismatch(false)} personFirstName={props.personFirstName} personLastName={props.personLastName} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} tabname={'FEI'} name={methods.getValues('people.isfarmrto')? methods.getValues('people.company_name'): methods.getValues('people.first') + ' ' + methods.getValues('people.last_name')} />
                    }
                    <OutputListingGridStatic area ={"People - FeiEc Membership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData?rowData:[]} containerStyle={containerStyle}  rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-1'>
                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                        
                        <label className='col-form-label mx-2 fs-5 py-1' dangerouslySetInnerHTML={{ __html: getMembershipName() }}></label>
                    </div>
                </div>
                {(methods.getValues('people.first') || methods.getValues('people.last_name') || methods.getValues('people.company_name')) &&
                <div className='row align-items-center mb-5'>
                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id={props.tabname+'name_mismatch_accepted'}
                            checked={getMismatchAccepted()}
                            disabled={getMismatchAcceptDisable()}
                            onChange={(e) => onNameMismatchChecked(e.target.checked)}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor={props.tabname+'name_mismatch_accepted'}> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.FEIMEMBERSHIP.ACCEPTNAMEMISMATCH' })}</label>
                    </div>
                </div>
                }
                {/* Hide the update button for FEI lookup when sglLite mode and Opened tab is FEI
                    FEI is provided hardcoded here because FEI and EC both are handle by the same tab
                    So only sglLite flag will hide the button for both EC and FEI */}
                {/* {sglLite && props.tabname == 'FEI'? null: */}
                    <div className='row align-items-center mb-2'>
                        <div className='col-lg-2 w-175px'>
                            <button type='button' onClick={() => lookup_data()} className="btn btn-sm btn-secondary fw-bold h-30px py-1  text-uppercase">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.UPDATE' })}
                            </button>
                            {/* <button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.UPDATE' })}
                            </button> */}
                        </div>
                    </div>
                {/* // } */}
            </div>
        </>
    );
}

export { FeiEcMembershipTab }