

const CheckboxHeader = ({ headerName, onChange, checked }) => {
    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className='w-lg-20px form-check-sm form-check-custom ps-lg-2 py-2 mx-1'>
                <input
                    className='form-check-input'
                    type="checkbox"
                    id="checkAll" 
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)} 
                />
            </div>
            { headerName.toUpperCase() }
        </div>
    );
}

export default CheckboxHeader