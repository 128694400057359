import { useMemo, useState, useEffect} from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import { MembershipTab } from './AdditonalTab/MembershipTab'
import { AdditionalManualOverrideForm } from './AdditonalTab/AdditionalManualOverrideForm'
import { USEFMembershipTab } from './AdditonalTab/USEFMembershipTab'
import { FEIMembershipTab } from './AdditonalTab/FEIMembershipTab'
import { useFormContext} from 'react-hook-form'
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

// Assets
import "./tab.css"
import "flatpickr/dist/themes/material_blue.css"
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';

// Constants
import { renderIcon, getExtraNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import { useAppSelector } from '../../../redux/hooks'
import moment from 'moment'
import { allowManualMembershipOverrideForHorse, deleteMembershipOverride } from './AdditonalTab/MembershipFunctions';
import { DateStringEditor } from '../../../modules/sgl-utils/DateStringEditor';

const AdditionalTab = (props) => {
    
    const [membershipGridState, setMembershipGridState] = useState(null)
    const [showManualOverrideForm, setManualOverrideForm] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [organization_members_added, setOrganizationMembersAdded] = useState([])
    const [tab_names, setTabNames] = useState([])

    const intl = useIntl();
    const methods = useFormContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const currentShowEndDate = useAppSelector(state => state.currentShow.end_date);
    const organizationMembersData = methods.watch('organizationMembers');
    const [showUsefMismatchForm, setShowUsefMismatchForm] = useState(false)
    const [FEINameMismatch, setFEINameMismatch] = useState(false)
    const [horseName, setHorseName] = useState("")
    const alertDialog = useAlert()
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const sglLite = useAppSelector(state => state.showCompany.sgl_lite)

    
    const organizationTabs = () => {
        const organizationTabNames = ["USEF", "EC", "FEI"]
        if(methods.getValues('ushja_tab') == true){
            organizationTabNames.push("USHJA")
        }
        if(methods.getValues('norcal_tab') == true){
            organizationTabNames.push("Norcal")
        }
        return organizationTabNames
    }

    useEffect(() => {
        priorityColor(methods.getValues('organizationMembers'))
        setTabNames(tabName())
    }, [organizationMembersData])

    function tabName() {
        const tabs = []
        if(methods.getValues('organizationMembers')){
            let organizationMembers = methods.getValues('organizationMembers')
            let membershipFilter = organizationMembers?.map(data => {return data.organization_code.toUpperCase()})
            let existingTabs = organizationTabs()
            existingTabs.map((data, i) => {
                if(membershipFilter.includes(data.toUpperCase())){
                    let index = organizationMembers.findIndex((datas) => {  return data.toUpperCase() == datas.organization_code.toUpperCase()})
                    if(index >= 0){
                        tabs.push({"name": organizationMembers[index].organization_code, "membership_number": organizationMembers[index].membership_number, "organization": organizationMembers[index].organization_code})
                    }
                }  
            })
            
        }
        return tabs
    }

    const columnDefs = [
        { field: 'organization_code', headerName:"", suppressMenu: true, sortable: false, cellRenderer: renderIcon, maxWidth: 30, lockVisible: false, lockPosition: 'left', cellClass: 'text-center pl-r-5 image-cell', headerClass: 'ag-header-cell-wrap-text pl-r-5' },
        { field: 'organization_code',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.CODE' }), sortable: false, suppressMenu: true, maxWidth: 150, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
        { field: 'membership_number', cellClass: 'editable-cell ag-left-aligned-cell', flex:1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.MEMBERSHIPID' }), suppressMenu: true, sortable: false, minWidth: 150, lockVisible: false, lockPosition: 'left', editable: true, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
        { field: 'valid_start', cellClass: (params) => checkDateEditable(params).cellClass, flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.STARTDATE' }), suppressMenu: true, sortable: false, lockVisible: false, cellEditor: DateStringEditor, editable: (params) => checkDateEditable(params).editable, lockPosition: 'left', width: 130, cellRenderer: DateRenderer, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
        { field: 'valid_end', cellClass: (params) => checkDateEditable(params).cellClass, flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.ENDDATE' }), suppressMenu: true, sortable: false, lockVisible: false, cellEditor: DateStringEditor, editable: (params) => checkDateEditable(params).editable, lockPosition: 'left', width: 130, cellRenderer: DateRenderer, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header' },
    ]
  
    const [ rowData, setRowData ] = useState(methods.getValues('organizationMembers'))

    const rowClassRules = useMemo(() => {
        return {
            'bg-override': 'data.override',
            'bg-expired': 'data.expired',
            'bg-current': 'data.current',
            'bg-valid': 'data.valid',
            'bg-special': 'data.special',
            'bg-none': 'data.none',
        };
    }, []);

    const onCellDoubleClick = (params) => {
        if (params.colDef.field == "icon") {
            setSelectedRow(params.data)
        }else if(params.colDef.field == "organization_code" && allowManualMembershipOverrideForHorse(params?.data?.organization_code)){//Check if the manual membership override is allowed or not for organization
            setSelectedRow(params.data)
            setManualOverrideForm(true)
        }
    }

    // function to check if membership date is editable
    function checkDateEditable(params) {
        const { data } = params.node;
        const isEditable = !(data.managed && data.system_org);
        const cellClass = isEditable ? 'editable-cell' : 'non-editable-cell';
        return {
          editable: isEditable,
          cellClass: cellClass,
        };
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '100vh' }), []);

    const renderMembershipTab = (tab) => {
        switch (tab.name) {
            case "USEF": return <USEFMembershipTab membership_number={tab.membership_number} membershipGridState = {membershipGridState} onMembershipUpdate={onMembershipUpdate} removeMembership={removeMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup} getLookupData={getLookupData} showUsefMismatchForm={showUsefMismatchForm} setShowUsefMismatchForm={setShowUsefMismatchForm} />
            case "FEI": return <FEIMembershipTab membership_number={tab.membership_number} membershipGridState = {membershipGridState} onMembershipUpdate={onMembershipUpdate} removeMembership={removeMembership} clearMembership={clearMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup}  getLookupData={getLookupData} horseName={horseName} FEINameMismatch={FEINameMismatch} setFEINameMismatch={setFEINameMismatch} />
            default: return <MembershipTab tabname={tab.name} membership_number={tab.membership_number} membershipGridState = {membershipGridState} onMembershipUpdate={onMembershipUpdate} removeMembership={removeMembership} updateMembership={updateMembership} updateMembershipsDataFromLookup={updateMembershipsDataFromLookup} getLookupData={getLookupData}/>
        }
    }   

    const priorityColor = (organizationMembers) => {
        let rowDataleftGrid = organizationMembers?.map((data) => {
            // initializing color schemes
            data.none = false;
            data.expired = false;
            data.special = false;
            data.override = false;
            data.valid = false;

            if (data.membership_number && data?.membership_number != "")
                {
                    let ab_horseMembershipValid = false;
                    let membership_color = 'white';
                    
                    let today = moment().format('YYYY-MM-DD'); // default
                    if(props.form_meta.timezone_info){
                        today = moment(props.form_meta.timezone_info.date)
                    }
                    
                    // if(data.is_membershipvalid == true || data.is_membershipvalid == 1){
                    if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !='')
                        if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(data.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                            data.is_membershipvalid = true
                            ab_horseMembershipValid = true
                        }
                        else{
                            data.is_membershipvalid = false
                            ab_horseMembershipValid = false
                        }
                    // }

                    let membershipOverride = data.membership_override

                    if(membershipOverride && membershipOverride.inactive_override_date >= currentShowEndDate){
                        // al_membershipcolor = '#FFFF00' //Yellow Color
                         membership_color = 'yellow'
                        if(membershipOverride.owner_nonmemberfeepaid || (membershipOverride.ec_override_reason == 20 && membershipOverride.organization_code == 'EC')){
                            // al_membershipcolor = '#FFA500' //Orange Color
                            membership_color = 'orange'
                        }
                    }else if (ab_horseMembershipValid){
                        // al_membershipcolor = '#00FF00' //Green Color
                        membership_color = 'green';

                        // Check USEF suspension and change row color to red if suspended, regardless of their current membership validity
                        if (methods.watch('horse') && methods.watch('horse.usef_suspended') == true && data?.organization_code === 'USEF') {
                            membership_color = 'red';
                        }

                        // Check EC suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (methods.watch('horse') && methods.watch('horse.ec_status') == "SUSPENDED" && data?.organization_code === 'EC') {
                            membership_color = 'red';
                        }

                        // Check FEI suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (methods.watch('horse') && methods.watch('horse.fei_inactive_reason') == "SUSPENDED" && methods.watch('horse.fei_id') !='' && data?.organization_code === 'FEI') {
                            membership_color = 'red';
                        }
                    }else {
                        if(!data.valid_end || data?.valid_end == '0000-00-00' || data?.valid_end == ''){ //change format
                            // ec statuses in array
                            let ec_inactive_statuses = ['DECEASED', 'RETIRED', 'SOLD'];
                            if(membershipOverride && membershipOverride.membership_number != "" && membershipOverride.organization_code == "EC" && 
                            ec_inactive_statuses.includes(methods.getValues("horse.ec_status") )){
                                // al_membershipcolor = "#FF0000" //Red
                                membership_color = 'red'
                            } else {
                                // al_membershipcolor = "#FFFFFF" //White
                                membership_color = 'white'
                            }
                        } else{
                             // al_membershipcolor = "#FF0000" //Red
                            membership_color = 'red'
                        }
                    }
            
                    switch(membership_color){
                        case 'white':
                            data.none = true;
                            break;
                        case 'red':
                            data.expired = true;
                            break;
                        case 'orange':
                            data.special = true;
                            break;
                        case 'yellow':
                            data.override = true;
                            break;
                        case 'green':
                            data.valid = true;
                            break;
                    }
            } else{
                data.none = true
            }
            return data
        })

        // membershipGridState?.setRowData(rowDataleftGrid)
        if(membershipGridState){
            updateAGGridWithoutFlickering(({ current: { api : membershipGridState}}), rowDataleftGrid) // update grid without rerendering 
        }
        setRowData(rowDataleftGrid)
    }

    useEffect(() => {
        methods.setValue('organization_members_added',organization_members_added,{shouldDirty:true})
    }, [organization_members_added])

    function onCellValueChanged(params) {
        // onMembershipUpdate(params.node.data)
        const updatedData = params.data;
        // Update organizationMembers with the modified row data
        const organizationMembers = methods.getValues('organizationMembers').map(member => 
            member.sgl_id === updatedData.sgl_id ? updatedData : member
        );
        methods.setValue('organizationMembers', organizationMembers);
        
        const moment = require('moment');
        const inValidValues = [undefined, '00/00/0000', '0000-00-00']
    
        if (params.node.data.valid_start && !inValidValues.includes(params.node.data.valid_start)) { //format valid_start date if not formattted already on input
            if(!params.node.data.valid_start.includes('/') && !params.node.data.valid_start.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_start = `${params.node.data.valid_start.slice(0, 2)}/${params.node.data.valid_start.slice(2, 4)}/${params.node.data.valid_start.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }

        if (params.node.data.valid_end && !inValidValues.includes(params.node.data.valid_end)) { //format valid_end date if not formattted already on input
            if(!params.node.data.valid_end.includes('/') && !params.node.data.valid_end.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_end = `${params.node.data.valid_end.slice(0, 2)}/${params.node.data.valid_end.slice(2, 4)}/${params.node.data.valid_end.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }




        let updated_memberships_list = onMembershipUpdate(params.node.data, organization_members_added)
        updated_memberships_list.valid_end ='0000-00-00'
        updated_memberships_list.valid_start = '0000-00-00'
        setOrganizationMembersAdded([...updated_memberships_list])
        let lookup_variable = ''
        let lookup = false
        switch(params.node.data.organization_code.toUpperCase()){
            case 'USEF':
                lookup_variable = 'usef_response_eti_lookup'
                lookup = true
                break;

            case 'EC':
                lookup_variable = 'ec_lookup_response'
                lookup = true
                break;

            case 'FEI':
                lookup_variable = 'fei_lookup_response'
                lookup = true
                break;

            case 'USHJA':
                lookup_variable = 'ushja_lookup_response'
                lookup = true
                break;

            case 'NORCAL':
                lookup_variable = 'norcal_lookup_response'
                lookup = true
                break;
        }

        if(lookup_variable){
            methods.setValue(`horse.${lookup_variable}`, '', { shouldDirty: true })
        }

        if(lookup){
            if(params.node.data?.membership_number && params.node.data?.membership_number != ''){
                methods.setValue('lookup_organization', params.node.data.organization_code)
            }
        }
        if(params.node.data.membership_number == ''){//Remove membership override
            let organization_members = methods.getValues().organizationMembers?.map((member) => {
                if(member.organization_code === params.node.data.organization_code){
                    if(member.membership_override){
                        let membershipOverrideAdded = methods.getValues().membership_override_added ?? []
                        let membershipOverrideRemoved = methods.getValues().membership_override_removed ?? []
                        let updatedData = deleteMembershipOverride(member, membershipOverrideAdded, membershipOverrideRemoved)
                        methods.setValue('membership_override_removed',updatedData.membership_override_removed, { shouldDirty: true })
                        methods.setValue('membership_override_added',updatedData.membership_override_added, { shouldDirty: true })
                    }
                }
                return member
            })
            methods.setValue('organizationMembers',organization_members, { shouldDirty: true })
        }
    }
    // function onMembershipUpdate(membership_data){
    function onMembershipUpdate(membership_data, added_memberships){
        if(membership_data.membership_number == ""){
            membership_data.valid_start = '0000-00-00'
            membership_data.valid_end = '0000-00-00'
            membership_data.membership_level = ""
        }

        if(membership_data.valid_end && moment(membership_data.valid_end).isValid()){
            let valid_end = moment(membership_data.valid_end).isValid()?moment(membership_data.valid_end):'';
            if(membership_data.membership_number !="" && valid_end.isSameOrAfter(moment())){
                membership_data.is_membershipvalid = true;
            }
        }

        let found_code = added_memberships.some(el => el.organization_code === membership_data.organization_code);
        if(found_code){
            let objIndex = added_memberships.findIndex((obj => obj.organization_code == membership_data.organization_code));
            added_memberships[objIndex].organization_code = membership_data.organization_code;
            added_memberships[objIndex].membership_number = membership_data.membership_number;
            added_memberships[objIndex].membership_level = membership_data.membership_level;
            added_memberships[objIndex].name_mismatch_accepted = membership_data.name_mismatch_accepted;
            added_memberships[objIndex].is_membershipvalid = membership_data.is_membershipvalid;
            
            added_memberships[objIndex].valid_start = (membership_data.valid_start && moment(membership_data.valid_start).isValid())? membership_data.valid_start:'';
            added_memberships[objIndex].valid_end = (membership_data.valid_end && moment(membership_data.valid_end).isValid())? membership_data.valid_end:'';
        }
        else{
            membership_data.valid_start = (membership_data.valid_start && moment(membership_data.valid_start).isValid())? membership_data.valid_start:'';
            membership_data.valid_end = (membership_data.valid_end && moment(membership_data.valid_end).isValid())? membership_data.valid_end:'';
            
            added_memberships.push(membership_data)
        }
        priorityColor(methods.watch('organizationMembers'))
        return added_memberships;
    }

    useEffect(() => {

        let org = methods.getValues('lookup_organization')
        
        let end_point = '';
        if(org && org != ''){
            // call lookup data apis
            let memObj = {};
            membershipGridState.forEachNode(node => {
                if(org == node.data.organization_code)
                memObj = node.data
              });

            if(org == 'USEF'){
                end_point = 'usefLookup'
            } 
            else if(org == 'FEI' /*&& !sglLite*/){
                end_point = 'feiLookup'
            } 
            else if(org == 'EC'){
                end_point = 'ecLookup'
            } 
            else if(org == 'USHJA'){
                end_point = 'ushjaLookup'
            } 
            else if(org.toUpperCase() == 'NORCAL'){
                end_point = 'norcalLookup'
            }

            if(end_point != ''){
                getLookupData(org, memObj, end_point)
            }
        }
    }, [methods.watch('lookup_organization')])

    function getLookupData(org, memObj, end_point, accept_mismatch_checked= false, associated_membership){
        axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/' + end_point, {
            params: {
                horse: methods.getValues("horse"),
                customer_id: customer_id,
                membership_obj: memObj,
                accept_mismatch_checked: accept_mismatch_checked,
                associated_membership: associated_membership
            }
        }).then((response) => {
            if(response.data.success) {
                methods.setValue('horse', response.data.horse, {shouldDirty: true})
                // sethorseUpdateData(result.data.horse) // adding for now to update 
                if(response.data.updated_memberships_list) {
                    updateMembershipsDataFromLookup(response.data.updated_memberships_list, response.data.low_priority_orgs)
                }
                if(response.data.mismatch_fields && response.data.mismatch_fields.length > 0) {
                    if(org == 'USEF'){
                        methods.setValue('usef_lookup_field', response.data.mismatch_fields)
                        setShowUsefMismatchForm(true)
                    } else if (org == 'FEI'){
                        let mismatch_field = response.data.mismatch_fields[0]
                        setHorseName(mismatch_field.lookupField)
                        setFEINameMismatch(true)
                    }

                }
            } else {
                let error_id = 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.'+response.data.error_message_id
                alertDialog({message: intl.formatMessage({ id: error_id })})
                clearMembership(org)
                // alertDialog({message: response.data.error_message, title: 'Error'})
            }
        
        })
        methods.setValue('lookup_organization', '');
        
    }

    function updateMembershipsDataFromLookup(updated_memberships_list, low_priority_orgs=[]){
        if(Object.keys(updated_memberships_list).length > 0){
            for (const queryKey of Object.keys(updated_memberships_list)) {
                if(updated_memberships_list[queryKey] && Object.keys(updated_memberships_list[queryKey]).length > 0){ 
                    if(low_priority_orgs.includes(queryKey)){
                        // dont update/create if user already created the membership
                        let organization_members = methods.getValues('organization_members_added') || []
                        let membership_exists = organization_members.find(data => data.organization_code == queryKey)
                        if(!membership_exists){
                            updateMembership(updated_memberships_list[queryKey])
                        }
                    } else {
                        updateMembership(updated_memberships_list[queryKey])
                    }
                } else {
                    if(!low_priority_orgs.includes(queryKey)){
                        removeMembership(queryKey)
                    }
                }
            }
        }
    }

    function updateMembership(updated_membership){
        let memberships = methods.getValues('organizationMembers')
        let membershipIndex = -1
        if(memberships && memberships.length > 0){
            membershipIndex = memberships.findIndex((obj => obj.organization_code.toLowerCase() == updated_membership.organization_code.toLowerCase()));
        }
        if(membershipIndex >= 0){
            for (const queryKey of Object.keys(updated_membership)) {
                if(queryKey !== 'organization_code'){
                    memberships[membershipIndex][queryKey] = updated_membership[queryKey]
                }
            }
        }
        methods.setValue('organizationMembers',memberships,{shouldDirty:true})
        let updated_memberships_list = onMembershipUpdate(updated_membership, organization_members_added)
        setOrganizationMembersAdded([...updated_memberships_list])
    }
    
    function removeMembership(organization_code, remove_override = false){
        // removing from the list of recently added organizations
        let organization_members = methods.getValues('organization_members_added')
        let index = -1
        if(organization_members && organization_members.length > 0){
            index = organization_members?.findIndex(data => data.organization_code == organization_code)
        }
        if(index >= 0){
            organization_members = organization_members.splice(index, 1)
            setOrganizationMembersAdded([...organization_members])
        }

        // clearing values from organization members list from grid
        let organizationMembers = methods.getValues('organizationMembers')
        let membershipIndex = -1
        if(organizationMembers && organizationMembers.length > 0) { membershipIndex = organizationMembers.findIndex((obj => obj.organization_code == organization_code)); }
        if(membershipIndex >= 0){
            organizationMembers[membershipIndex].membership_number = ""
            organizationMembers[membershipIndex].valid_start = "0000-00-00"
            organizationMembers[membershipIndex].valid_end = "0000-00-00"
            // organizationMembers[membershipIndex].membership_level = "" // needs conformation

            // adding in the list of memberships to remove
            let membershipsRemove = methods.getValues('memberships_removed')
            if(membershipsRemove){
                membershipsRemove.push(organizationMembers[membershipIndex])
            } else {
                membershipsRemove = [organizationMembers[membershipIndex]]
            }
            methods.setValue('memberships_removed', membershipsRemove, {shouldDirty: true})
            // end adding in the list of memberships to remove

            // remove membership override if exists
            if(remove_override){
                let memebershipOverrideToRemove = organizationMembers[membershipIndex]['membership_override'];
                if(memebershipOverrideToRemove){
                    let membershipsOverridesRemoved = methods.getValues('membership_override_removed')
                    if(membershipsOverridesRemoved){
                        membershipsOverridesRemoved.push(memebershipOverrideToRemove)
                    } else {
                        membershipsOverridesRemoved = [memebershipOverrideToRemove]
                    }
                    methods.setValue('membership_override_removed', membershipsOverridesRemoved, {shouldDirty: true})
                    organizationMembers[membershipIndex]['membership_override'] = null;
                }
            }
            // end remove membership override if exists
        }
        
        methods.setValue('organizationMembers',organizationMembers,{shouldDirty:true})
    }

    //Clear membership dates if membership not found from look up
    function clearMembership(org){
        let organization_members = methods.getValues().organizationMembers?.map((member) => {
            if(member.organization_code === org){
                if(member.membership_number){
                    member.valid_start = "0000-00-00"
                    member.valid_end = "0000-00-00"
                    member.valid = false
                    member.is_membershipvalid = false
                    member.name_mismatch_accepted = false
                    // member.membership_name = ''
                }

                let updated_memberships_list = onMembershipUpdate(member, methods.getValues('organization_members_added') ? methods.getValues('organization_members_added'): [])
                setOrganizationMembersAdded([...updated_memberships_list])
            }
            return member
        })
        methods.setValue('organizationMembers',organization_members, { shouldDirty: true })
    }

    return (
        <>
            <div className='form-group row mb-2'>
                {showManualOverrideForm && <AdditionalManualOverrideForm show={showManualOverrideForm} handleClose={() => setManualOverrideForm(false)} rowData={selectedRow} showId={currentShowID} horseId={methods?.getValues().horse?.horse_id} calledFrom={"horses"}/>}
                <div className='row mb-2'>
                    <div className='col-lg-6'> {/* Column 1 */}
                        <div className='row align-items-center mb-2 d-grid'>
                            <OutputListingGridStatic
                                columnDefs={columnDefs}
                                // rowData={rowData}
                                containerStyle={containerStyle}
                                rowClassRules={rowClassRules}
                                onCellDoubleClick={onCellDoubleClick}
                                rowHeight={getExtraNarrowRowHeight}
                                setGridRef={setMembershipGridState}
                                onCellValueChanged={onCellValueChanged}
                                enableRangeSelection={true}
                                stopEditingWhenCellsLoseFocus={true}
                            ></OutputListingGridStatic>
                        </div>
                    </div> {/* End Column 1 */}
                    <div className='col-lg-6 d-flex flex-column mb-2 border-start bg-white'> {/* Column 2 */}
                    {/* <h3 className='text-center'>Memberships</h3> */}
                        <ul className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap mb-0' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            {tab_names.length > 0 &&
                                tab_names.map((tab, index) => {
                                    return (
                                        <li key={index} className='nav-item'>
                                            <a
                                                className={"nav-link text-active-dark py-1 text-gray-700" + (governingOrganization == tab.name ? ' active' : '')}
                                                id={tab.name + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + tab.name}
                                                data-tooltip-id={`HORSE.DETAIL.TAB.ADDITIONAL.SECTION.${tab.name}`}
                                            >
                                                {tab.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className="tab-content mt-2 pb-2" id="myTabContent">
                            {tab_names.length > 0 &&
                                tab_names.map((tab, index) => {
                                    return (
                                        <div key={index} className={"tab-pane fade show" + (governingOrganization == tab.name ? " active" : "")} id={tab.name} role="tabpanel" >
                                            {renderMembershipTab(tab)}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div> {/* End Column 2 */}
                </div>
            </div>
        </>
    );
}

export { AdditionalTab }