import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import Select from 'react-select';



// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAuth } from '../../../modules/auth';
import { useAppSelector } from '../../../redux/hooks';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import { useEffect, useState } from 'react';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import { KTSVG } from '../../../../_metronic/helpers';
import { date_options, reactSelectStyles, setDate, maskDateInput } from '../../../modules/sgl-utils/fieldControls';

// Constants


const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const SetMembershipDates = ({ show, handleClose }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const [entryIds, setEntryIds] = useState([])
    const [horseOrganizations, setHorseOrganizations] = useState([])
    const [peopleOrganizations, setPeopleOrganizations] = useState([])

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()

    const methods = useForm({
        defaultValues: 
        {
            "set_membership_dates_for": 'Rider',
            "valid_start": '0000-00-00',
            "valid_end": '0000-00-00',
            "create_new_memberships": 0
        }
    });

    //Don't remove these as they update layout according to new values
    let set_membership_dates_for = methods.watch('set_membership_dates_for')

    const getMetaData = async () =>{//Set data in drop down on page load
        loadingOverlay({ show: true })
        axios.get(`${NEST_API_URL}/organizations/getMetaDataForSetMembershipDates?customer_id=${customerID}`)
        .then(response => {
            setHorseOrganizations(response?.data?.horse_organizations ?? [])
            setPeopleOrganizations(response?.data?.people_organizations ?? [])
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            loadingOverlay({ show: false })
        })
    }


    const setDatesForOptions = () => {// fill option for set date for drop down
        let overrideOptions = ['Rider', 'Owner', 'Trainer', 'Horse']
        return overrideOptions.map((reason) => ({value: reason, label: reason}))
    }

    const setOrganizations = () => {// fill option for organization for drop down
        if(methods.getValues('set_membership_dates_for') === 'Horse'){
            return horseOrganizations.map((org) => ({value: org, label: org}))
        }
        return peopleOrganizations.map((org) => ({value: org, label: org}))
    }

    const updateMembershipDates = async() => {
        let data = methods.getValues()
        let currentDate = moment().format("YYYY-MM-DD")
        let membershipsCount = 0
        let entityIds = []
        // Validation
        if(!data.set_membership_dates_for){
            alertDialog({message: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.MISSINGENTITYERROR' })})
            return
        }else if(!data.organization_code){
            alertDialog({message: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.MISSINGORGERROR' })})
            return
        }else if(!data.valid_start || data.valid_start === '0000-00-00' || !data.valid_end || data.valid_end === '0000-00-00' || moment(data.valid_end).isBefore(data.valid_start)){
            alertDialog({message: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.INVALIDDATEERROR' })})
            return
        }else if(moment(data.valid_end).isBefore(currentDate) || currentDate === data.valid_end){
            alertDialog({message: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.INVALIDSTARTDATEERROR' })})
            return
        }

        if(entryIds.length > 0){
            //Get Memberships Count 
            loadingOverlay({ show: true })
            await axios.post(`${NEST_API_URL}/entries/getExistingMembershipsCountForEntries`, {
                customer_id: customerID, 
                entry_ids: entryIds, 
                org_code: methods.getValues('organization_code'),
                entity_type: methods.getValues('set_membership_dates_for'),
                show_id: currentShowID
            })
            .then(response => {
                membershipsCount = response?.data?.membershipsCount
                entityIds = response?.data?.entityIds ?? []
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
            .finally(() => {
                loadingOverlay({ show: false })
            })
            
            if(entityIds.length <= 0){
                alertDialog({message: `No ${data.set_membership_dates_for} found to set the membership dates for ${data.organization_code}`})
            }else if(membershipsCount <= 0 && !methods.getValues('create_new_memberships')){
                alertDialog({message: `No memberships found to set the membership dates for ${data.organization_code}`})
            }else{
                //Confirmation Message
                let message = `${intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.CONFIRMATION.MESSAGE1' })} in ${data.organization_code}`
                if(membershipsCount > 0){
                    message = `${message} for ${membershipsCount} ${data.set_membership_dates_for}s`
                }
                message = `${message} from ${data.valid_start} to ${data.valid_end}`
                if(data.create_new_memberships){
                    message = message + intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.CONFIRMATION.MESSAGE2' })
                }
                //Get confirmation from user
                message = message + intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.CONFIRMATION.MESSAGE3' })                    
                const choice = await confirmDialog({ message })
                if (choice) {
                    handleClose()
                    let eventID = getEventID("set-membership-date")
                    // show progress bar
                    progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.PROGRESSBAR.MESSAGE" }), timeElapsed: true })
                    //Webservice to update membership dates
                    axios.post(`${NEST_API_URL}/entries/updateMembershipsDates`, {
                        customer_id: customerID,
                        show_id: currentShowID,
                        entity_ids: entityIds,
                        entity_type: data.set_membership_dates_for,
                        org_code: data.organization_code,
                        valid_start: data.valid_start,
                        valid_end: data.valid_end,
                        create_new_membership: data.create_new_memberships,
                        event_id: eventID
                    })
                    .then(response => {
                        if(response?.data?.success){
                            
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => {
                        // close progress bar
                        progressBar( {show: false, eventID} )
                    })
                }
            }
            
        }else{
            alertDialog({message: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.NOENTRYERROR' })})
            return
        }
        
    }

    useEffect(() => {
        if (show) {//Get Entry Ids in selection
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                setEntryIds(res)
            })
            .catch((error) => {
                if (error.response) {
                alertDialog({message: error.response.data.error});
                }
            })
            .finally(() => {
                loadingOverlay({ show: false })
            })
        }  
        getMetaData()// Get organization drop down values
    },[])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    updateMembershipDates()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.SETMEMBERSHIPDATES' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form set-membership-dates-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='set_membership_dates_for' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.SETDATESFOR">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.LABEL.SETMEMBERSHIPDATEFOR' })}</label>
                                <div className='col-lg-8'>
                                    <Controller
                                        name = 'set_membership_dates_for'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? setDatesForOptions().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={setDatesForOptions()}
                                            onChange={(e) => {
                                                methods.setValue('set_membership_dates_for', e.value, {shouldDirty: true})
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>

                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='organization_code' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.ORGANIZATION">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.LABEL.ORGANIZATION' })}</label>
                                <div className='col-lg-8'>
                                    <Controller
                                        name = 'organization_code'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? setOrganizations().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={setOrganizations()}
                                            onChange={(e) => {
                                                methods.setValue('organization_code', e.value, {shouldDirty: true})
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            tabIndex={2}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>

                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-140px' htmlFor='valid_start' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.STARTDATE">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.LABEL.STARTDATE' })}</label>
                                <div className='col-lg-6'>
                                    <div className="input-group date">
                                        <Controller
                                            {...methods.register('valid_start', { required: true })}
                                            name="valid_start"
                                            control={methods.control}
                                            render={({ field: { onChange, value, name } }) => {
                                                const formattedValue = (value && moment(value).isValid())
                                                    ? moment(value).format('MM/DD/YYYY')
                                                    : '';

                                                return (
                                                    <Flatpickr
                                                        autoComplete="off"
                                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                        id="valid_start"
                                                        placeholder="MM/DD/YYYY"
                                                        name={name}
                                                        value={formattedValue}
                                                        options={date_options}
                                                        onClose={(value, dateStr, instance) => {
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                            }
                                                            setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                        }}
                                                        onInput={(e) => { maskDateInput(e) }}
                                                        tabIndex={3}
                                                    />
                                                );
                                            }}
                                        />

                                        <div className="input-group-append">
                                            <label htmlFor='valid_start' className='date-label'>
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-140px' htmlFor='valid_end' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.ENDDATE">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.LABEL.ENDDATE' })}</label>
                                <div className='col-lg-6'>
                                    <div className="input-group date">
                                        <Controller
                                            {...methods.register('valid_end', { required: true })}
                                            name="valid_end"
                                            control={methods.control}
                                            render={({ field: { onChange, value, name } }) => {
                                                const formattedValue = (value && moment(value).isValid())
                                                    ? moment(value).format('MM/DD/YYYY')
                                                    : '';

                                                return (
                                                    <Flatpickr
                                                        autoComplete="off"
                                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                        id="valid_end"
                                                        placeholder="MM/DD/YYYY"
                                                        name={name}
                                                        value={formattedValue}
                                                        options={date_options}
                                                        onClose={(value, dateStr, instance) => {
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                            }
                                                            setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                        }}
                                                        onInput={(e) => { maskDateInput(e) }}
                                                        tabIndex={4}
                                                    />
                                                );
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor='valid_end' className='date-label'>
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3'></div>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("create_new_memberships", {required: false})}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='create_new_memberships'
                                        tabIndex={5}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='create_new_memberships' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.CEARTEMEMBERSHIPIFMISSING"> {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.LABEL.CHECKBOX' })}</label>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>

                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={6}>
                                {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.BUTTON.CANCEL' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={updateMembershipDates} tabIndex={7} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.SETMEMBERSHIPSDATE.LABEL.EXECUTE">
                                {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPDATES.MODAL.BUTTON.EXECUTE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default SetMembershipDates


