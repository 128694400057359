/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { date_options, time_options_12hours, setDate, setTime, maskDateInput } from '../../../modules/sgl-utils/fieldControls'
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider'
import { useSchedulerContext } from './SchedulerContext'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import axios from 'axios'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SignupGroup = ({show, handleClose}: Props) => {
  const intl = useIntl()
  const {currentClassGroup, classGroupsGridRef, setCurrentClassGroup, getClassGroups, systemPrefs} = useSchedulerContext();
  const { register, watch, reset, getValues, control, setValue } = useForm({});
  const alertDialog = useAlert();
  const actionClassGroupIDs = watch("class_group_ids", []);

  useEffect(() => {
    //initialize data in form
    reset({
      show_id: currentClassGroup.show_id,
      customer_id: currentClassGroup.customer_id,
      signup_publish: true,
      start_classsignup_date: currentClassGroup.start_classsignup_date != '0000-00-00' ? currentClassGroup.start_classsignup_date : systemPrefs?.start_classsignup_date,
      start_classsignup_time: currentClassGroup.start_classsignup_time != '00:00:00' ? currentClassGroup.start_classsignup_time : systemPrefs?.start_classsignup_time,
      stop_classsignup_date: currentClassGroup.stop_classsignup_date != '0000-00-00' ? currentClassGroup.stop_classsignup_date : systemPrefs?.stop_classsignup_date,
      stop_classsignup_time: currentClassGroup.stop_classsignup_time != '00:00:00' ? currentClassGroup.stop_classsignup_time : systemPrefs?.stop_classsignup_time,
      class_group_ids: [],
    })
  }, [currentClassGroup]);

  useEffect(() => {
    if(show){ //modal shown
      let selectedClassGroups:any = []
      let selectedRows = classGroupsGridRef.getSelectedNodes()

      //Filter groups required for signup
      for(let row of selectedRows){
        if(!row.data.tripgoatonce && !row.data.schedule_break){
          selectedClassGroups.push(row.data.class_group_id)
        }
      }

      if(selectedClassGroups.length == 0){ //No valid groups selected after applying filter?
        alertDialog({message: 'Select at least one non-flat group for Signup.'})
        handleClose()
      }
      else{ // Valid groups found then initialize variables for dialog
        setValue('class_group_ids', selectedClassGroups) //set filtered class groups in form object
      }
    }
  }, [show]);

  const handleSave = (event: React.MouseEvent<HTMLElement>) => {
    const data = getValues()

    loadingSpinnerBtnWait(event)
    axios.patch(`${NEST_API_URL}/scheduler/updateclassgroupssignupsettings`, data)
    .then(result => {   
      handleClose() //close dialog
      setCurrentClassGroup({}) //clear selected class group
      getClassGroups(true); //refresh groups list
    })
    .catch(async function (error) {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    })
    .finally(() =>{
        loadingSpinnerBtnRelease(event)
    });
  }

  return createPortal(
    <Modal
      id='kt_modal_signup_group'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal min-w-600px'
      show={show}
      onHide={handleClose}
      onKeyPress={(event: any) => {
        if (event.key == 'Enter') {
          handleSave(event)
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4" >{intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.TITLE.SIGNUPGROUPS'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'> {/* Card Body */}
            
          <div className="form-group row"> {/* Column Group */}
          <p className="fw-bold">Publish { actionClassGroupIDs.length > 1 ? actionClassGroupIDs.length + ' non-flat Groups' : '1 non-flat Group' } for Web Signup!</p>
          <div className='row mb-2'>
            <div className='col-lg-12 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                <input
                    className='form-check-input'
                    type='checkbox'
                    id='DESCRIPTION'
                    {...register("signup_publish")}
                    autoFocus
                />
                </div>
                <label className='col-form-label fs-5 py-1' htmlFor='DESCRIPTION' data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.LABEL.PUBLISHFORWEBSIGNUP"> {intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.LABEL.PUBLISH'})}</label>
            </div>
          </div>

          <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='STARTSIGNUPMODAL' data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.LABEL.STARTSIGNUPON">
                <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.STARTSIGNUP'})}</span>
              </label>

              <div className='col-lg-4 '>
                  <div className="input-group date">
                    <Controller
                        name="start_classsignup_date"
                        control={control}
                        render={({
                            field: { onChange, value, name },
                        }) => {
                            const formattedValue = (value && moment(value).isValid())
                                ? moment(value).format('MM/DD/YYYY')
                                : '';

                            return (
                                <Flatpickr
                                    className="form-control form-control-sm fs-6 min-h-20px py-1 pe-0"
                                    id="STARTSIGNUPMODAL"
                                    placeholder="MM/DD/YYYY"
                                    options={date_options}
                                    name={name}
                                    value={formattedValue}
                                    onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                        if (!isValidDate) {
                                            instance.input.value = ''; // Clear the input if the date is invalid
                                        }
                                        setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                    }}
                                    onInput={(e: any) => { maskDateInput(e) }}
                                    autoComplete="off"
                                />
                            );
                        }}
                    />
                      <div className="input-group-append">
                          <label htmlFor='STARTSIGNUPMODAL' className='date-label'>
                              <span className="input-group-text">
                                  <i className="la la-calendar"></i>
                              </span>
                          </label>
                      </div>
                  </div>
              </div>

              <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='ATMODAL' data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.LABEL.STARTSIGNUPONAT">
                  <span className=''>at</span>
              </label>

              <div className='col-lg-4 '>
                  <div className="input-group date">
                    <Controller
                      name="start_classsignup_time"
                      control = {control}
                      render={({
                          field: { onChange, value, name },
                      }) => (
                          <Flatpickr 
                              className="form-control form-control-sm fs-6 min-h-20px py-1"
                              id="ATMODAL"
                              placeholder="00 : 00"
                              name = {name}
                              value = {value}
                              options={time_options_12hours}
                              onClose={(value: any) => setTime(value, onChange)}
                              onChange={(value: any) => setTime(value, onChange)}
                              autoComplete={"off"}
                          />
                          )}
                      />
                      <div className="input-group-append">
                          <label htmlFor='ATMODAL' className='date-label'
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    const atModal = document.getElementById('ATMODAL') as HTMLElement & { _flatpickr?: { open: () => void } };
                                    if (atModal?._flatpickr) {
                                        atModal._flatpickr.open();
                                    }
                                }}
                          >
                              <span className="input-group-text">
                                  <i className="la la-clock"></i>
                              </span>
                          </label>
                      </div>
                  </div>
              </div>
          </div>

          <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='STOPSIGNUPMODAL' data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.LABEL.STOPSIGNUPON">
                <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.STOPSIGNUP'})}</span>
              </label>

              <div className='col-lg-4 '>
                  <div className="input-group date">
                      <Controller
                          name="stop_classsignup_date"
                          control={control}
                          render={({
                              field: { onChange, value, name },
                          }) => {
                              const formattedValue = (value && moment(value).isValid())
                                  ? moment(value).format('MM/DD/YYYY')
                                  : '';

                              return (
                                  <Flatpickr
                                      className="form-control form-control-sm fs-6 min-h-20px py-1"
                                      id="STOPSIGNUPMODAL"
                                      placeholder="MM/DD/YYYY"
                                      options={date_options}
                                      name={name}
                                      value={formattedValue}
                                      onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                          const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                          if (!isValidDate) {
                                              instance.input.value = ''; // Clear the input if the date is invalid
                                          }

                                          setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                      }}
                                      onInput={(e: any) => { maskDateInput(e) }}
                                      autoComplete="off"
                                  />
                              );
                          }}
                      />
                      <div className="input-group-append">
                          <label htmlFor='STOPSIGNUPMODAL' className='date-label'>
                              <span className="input-group-text">
                                  <i className="la la-calendar"></i>
                              </span>
                          </label>
                      </div>
                  </div>
              </div>

              <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='ATMODAL2' data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.LABEL.STOPSIGNUPONAT">
                  <span className=''>at</span>
              </label>

              <div className='col-lg-4 '>
                  <div className="input-group date">
                    <Controller
                      name="stop_classsignup_time"
                      control = {control}
                      render={({
                          field: { onChange, value, name },
                      }) => (
                          <Flatpickr 
                              className="form-control form-control-sm fs-6 min-h-20px py-1"
                              id="ATMODAL2"
                              placeholder="00 : 00"
                              name = {name}
                              value = {value}
                              options={time_options_12hours}
                              onClose={(value: any) => setTime(value, onChange)}
                              onChange={(value: any) => setTime(value, onChange)}
                              autoComplete={"off"}
                          />
                          )}
                      />
                      <div className="input-group-append" >
                          <label htmlFor='ATMODAL2' className='date-label'
                                 onClick={(e) => {
                                  e.stopPropagation();
                                  const atModal = document.getElementById('ATMODAL2') as HTMLElement & { _flatpickr?: { open: () => void } };
                                  if (atModal?._flatpickr) {
                                      atModal._flatpickr.open();
                                  }
                                 }}
                          >
                              <span className="input-group-text">
                                  <i className="la la-clock"></i>
                              </span>
                          </label>
                      </div>
                  </div>
              </div>
          </div>

          </div> {/* End Column Group */}


          </div>{/* End Card Body */}

          <div className='card-footer py-3'>
            <div className='row mb-2'>
              <div className='col-lg-12 pe-9 d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' onClick={handleClose}>
                  {intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.BUTTON.CANCEL'})}
                </button>

                <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={(e) => { handleSave(e) }} autoFocus data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.GROUPSIGNUPSETTINGS.BUTTON.APPLY">
                  <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                  {intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.BUTTON.APPLY'})}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {SignupGroup}
