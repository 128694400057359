import {useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {initialQueryState, KTSVG} from '../../../_metronic/helpers'
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider'
import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { future_date_options, maskDateInput, reactSelectStyles, setDate } from '../../modules/sgl-utils/fieldControls'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import Select from 'react-select'
import { renderShowPicker } from '../../redux/reducers/renderShowPickerReducer'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateShowLicense = ({show, handleClose}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { register, watch, reset, getValues, control, setValue } = useForm({});
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const [errorMessage, setErrorMessage] = useState('')
  const [productNumber, setProductNumber] = useState('1')
  const navigate = useNavigate()
  const loadingOverlay = useLoadingOverlay()
  const [endDateOptions, setEndDateOptions] = useState(future_date_options);

  const startDate = watch("start_date");

  useEffect(() => {
    if (startDate) {
      const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
      setEndDateOptions({
        dateFormat: "MM/DD/YYYY",
        allowInput: true, // manually type date
        parseDate: (datestr: moment.MomentInput, format: moment.MomentFormatSpecification | undefined) => {
          return moment(datestr, format, true).toDate();
        },
        formatDate: (date: moment.MomentInput, format: string | undefined, locale: any) => {
          return moment(date).format(format);
        },
        minDate: formattedStartDate,
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (productNumber != '3') {
      setValue('asset_code', '')
      setValue('asset_password', '')
    }
  }, [productNumber]);

  const handleSave = () => {
    setErrorMessage('')
    var data = getValues();
    data.customer_id = customerID;
    var error_message = ''
    if(data.show_name == ''){
      error_message = 'Please enter Show Name.';
    }
    else if(!data.start_date || !data.end_date){
      error_message = 'Please enter Show Dates.';
    }
    else if(data.start_date > data.end_date){
      error_message = 'Start date must not be greater than end date.';
    }
    else if(data.product_number == '3' || data.product_number == 3){
      if(!data.asset_password || data.asset_password == ''){
        error_message = 'Please enter Asset Password.';
      }
      if(!data.asset_code || data.asset_code == ''){
        error_message = 'Please enter Asset Code.';
      }
    }
    setErrorMessage(error_message);
    if(error_message == ''){
      loadingOverlay({show: true})
      data.create_show = true
      axios.get(`${NEST_API_URL}/license/webTransactLicense`, {
        params:data
      })
      .then(result => { 
        loadingOverlay({show: false})
        if(result.data.success) {
          dispatch(renderShowPicker())
          handleClose();
          navigate('shows/detail/'+result.data.show_id)
        } else {
          setErrorMessage(result.data.error_message);
        }      
      })
      .catch(async function (error) {
          loadingOverlay({show: false})
          if (error.response) {
            setErrorMessage(error.response.data.error);
          }
      });
    }
  }

  const lisenceOptions = [
    { label: "Template Show (No Entries Allowed)", value: '1' },
    { label: "Demo Show (25 Entries)", value: '2' },
    { label: "Regular License (Unlimited Entries)", value: '3' }
  ];

  return createPortal(
    <Modal
      id='kt_modal_create_license'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
      show={show}
      onHide={handleClose}
      onKeyDown={(event:any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) {
          handleSave()
        }
        if (event.key === 'Escape') {
          handleClose()
        }
      }}
      autoFocus={true}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.TITLE.CREATESHOW'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SELECTLICENSE'>
                <span className=''>{intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.SELECTLICENSE'})}</span>
              </label>

              <div className='col-lg-9'>
                <Controller
                    name='product_number'
                    control={control}
                    defaultValue={lisenceOptions[0]?.value}
                    render={({ field: { onChange, value, name } }) => (
                        <Select
                            inputId='SELECTLICENSE'
                            name={name}
                            options={lisenceOptions}
                            onChange={(option: any) => {onChange(option.value); setProductNumber(option.value)}}                    
                            value={lisenceOptions?.find((option) => option.value == value)}
                            theme={(theme) => ({ ...theme, borderRadius: 0 })}
                            placeholder='Select'
                            styles={reactSelectStyles}
                            defaultValue={lisenceOptions[0]}
                            autoFocus
                            tabIndex={1}
                        />
                    )}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='SHOWNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.SHOWNAME'})}</label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='SHOWNAME'
                  type='text'
                  {...register("show_name",{
                    required: true
                    })}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.SHOWNAME'})}
                  tabIndex={2}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='DATESFROM'>{intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.SHOWDATES'})}</label>
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                  <div className="input-group date">
                        <Controller
                              name="start_date"
                              control={control}
                              render={({ field: { onChange, value, name } }) => {
                                  const formattedValue = (value && moment(value).isValid())
                                      ? moment(value).format('MM/DD/YYYY')
                                      : '';

                                  return (
                                      <Flatpickr
                                          className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                          id="start_date"
                                          name={name}
                                          autoComplete="off"
                                          value={formattedValue}
                                          placeholder={intl.formatMessage({ id: 'FORM.LICENSE.SHOWS.LABEL.STARTDATE' })}
                                          options={future_date_options}
                                          tabIndex={3}
                                          onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                              if (!isValidDate) {
                                                  instance.input.value = ''; // Clear the input if the date is invalid
                                              }

                                              // Use setDate with dateStr and onChange
                                              setDate(dateStr, onChange, null);
                                          }}
                                          onInput={(e: any) => { maskDateInput(e) }}
                                      />
                                  );
                              }}
                          />
                          <div className="input-group-append">
                            <label htmlFor='start_date' className='date-label'>
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                          </div>
                      </div>
                  </div>
                  
                  <div className='col-lg-6 fv-row'>
                  <div className="input-group date">
                        <Controller
                              name="end_date"
                              control={control}
                              render={({ field: { onChange, value, name } }) => {
                                  const formattedValue = (value && moment(value).isValid())
                                      ? moment(value).format('MM/DD/YYYY')
                                      : '';

                                  return (
                                      <Flatpickr
                                          key={JSON.stringify(endDateOptions)}
                                          className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                          id="end_date"
                                          name={name}
                                          autoComplete="off"
                                          value={formattedValue}
                                          placeholder={intl.formatMessage({ id: 'FORM.LICENSE.SHOWS.LABEL.ENDDATE' })}
                                          options={endDateOptions}
                                          tabIndex={4}
                                          onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                              if (!isValidDate) {
                                                  instance.input.value = ''; // Clear the input if the date is invalid
                                              }

                                              // Use setDate with dateStr and onChange
                                              setDate(dateStr, onChange, null);
                                          }}
                                          onInput={(e: any) => { maskDateInput(e) }}
                                      />
                                  );
                              }}
                          />
                          <div className="input-group-append">
                            <label htmlFor='end_date' className='date-label'>
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='ASSETCODE'>
                <span className=''>{intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.ASSETCODE'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='ASSETCODE'
                  type='tel'
                  {...register("asset_code")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.ASSETCODE'})}
                  disabled={productNumber != '3'}
                  tabIndex={5}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='ASSETPASSWORD'>
                <span className=''>{intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.ASSETPASSWORD'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='ASSETPASSWORD'
                  type='tel'
                  {...register("asset_password")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  disabled={productNumber != '3'}
                  placeholder={intl.formatMessage({id: 'FORM.LICENSE.SHOWS.LABEL.ASSETPASSWORD'})}
                  tabIndex={6}
                />
              </div>
            </div>
          </div>
          {(errorMessage != '') &&
            <div><span className='error_message'>{errorMessage}</span></div>
          }
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            
            <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={handleClose} tabIndex={7}>
              {intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.BUTTON.CANCEL'})}
            </button>
            <button className='btn btn-sm btn-dark fw-bold'  type='button' onClick={handleSave} tabIndex={8}>
              {intl.formatMessage({id: 'FORM.LICENSE.SHOWS.BUTTON.CREATE'})}
            </button>
          </div>

        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateShowLicense}
