import { useEffect, useState, useImperativeHandle, forwardRef } from "react"
import { useIntl } from 'react-intl'
import { useFormContext } from "react-hook-form"
import { useAlert, useLoadingOverlay, useConfirm } from "../../../../modules/sgl-utils/DialogsProvider"
import { useAppSelector } from "../../../../redux/hooks"
import { ScratchDropPopup } from "./ScratchDropPopup"
import { CombinedClassPopup } from "./CombinedClassPopup"
import axios from "axios"
import { ClassAddWarningDialog } from "./ClassAddWarningDialog"
import { CheckRider } from "./CheckRider"

const ClassAddDrop = forwardRef((props, ref) => {

    const intl = useIntl()
    const methods = useFormContext()
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()

    const customer_id = useAppSelector((state) => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)

    const [conflictedRiders, setConflictedRiders] = useState([]);
    const [classNumberList, setClassNumberList] = useState([]);
    const [checkRider, setCheckRider] = useState([])
    const [dialogCount, setDialogCount] = useState();
    const [riderClassVerified, setRiderClassVerified] = useState(false);
    const [addWarnings, setAddWarnings] = useState([]);
    const [classAddWarningDialog, setClassAddWarningDialog] = useState([]);

    const [showCombinedClassPopup, setShowCombinedClassPopup] = useState(false)
    // Mode in which combined class popup is opened
    const [isAddMode, setIsAddMode] = useState(true)
    const [classList, setClassList] = useState({})

    const [showScratchDropPopup, setShowScratchDropPopup] = useState(false)
    const [scratchDropPopupData, setScratchDropPopupData] = useState({})

    const [rapidAPIData, setRapidAPIData] = useState({})
    const [tripsToChange, setTripsToChange] = useState([])

    useEffect(() => {
        if(dialogCount == 0){
            if (props.calledFrom === "Entries") {
                callAddClassesApi(classNumberList.toString())
            } else if (props.calledFrom === "Rapid") {
                callRapidAddDropClassByNumbers(
                    rapidAPIData.entryNumber, 
                    rapidAPIData.riderId, 
                    classNumberList.toString(), 
                    rapidAPIData.classesToDrop
                )
            }
           
            setRiderClassVerified(false)
        }
    }, [dialogCount]);

    function callRapidAddDropClassByNumbers(entryNumber, riderId, classesToAdd, classesToDrop, showId = 0, confirmDivisionDropChoice = "", classesNotDroppable=[], divisionClassesToScratch=[], divisionClassesToDrop=[], addResponse={}, invalidClasses=[]) {
        // Set data for subsequent calls
        setRapidAPIData({ entryNumber, riderId, classesToAdd, classesToDrop })

        loadingOverlay({ show: true })

        axios.post( process.env.REACT_APP_NEST_API_URL + '/class-add-drop/rapidAddDropClassByNumbers', {
            params: {
                entry_number: entryNumber,
                rider_id: riderId,
                classes_to_add: classesToAdd,
                classes_to_drop: classesToDrop,
                customer_id: customer_id,
                show_id: showId > 0 ? showId : currentShowID,
                rider_class_verified: riderClassVerified,
                // params for confirmDivisionDrop service
                confirm_division_drop_choice: confirmDivisionDropChoice,
                classes_not_droppable: classesNotDroppable,
                division_classes_to_scratch: divisionClassesToScratch,
                division_classes_to_drop: divisionClassesToDrop,
                add_response: addResponse,
                invalid_classes: invalidClasses,
                trips_to_change: tripsToChange
            }
        }) 
        .then(async result => { 

            // Clear Trips To Change State
            setTripsToChange([])

            // Hide Loading Overlay
            loadingOverlay({ show: false })

            if (result.data?.error_message) {
                alertDialog({ message: result.data.error_message})
            }

            let warnings = []
            if(result.data.checkCombinedClasses){
                setClassList(result.data.classList)
                setIsAddMode(result.data.mode)
                setShowCombinedClassPopup(true)
            } else if(result.data.checkChangeRider){
                setConflictedRiders(result.data.conflicted_classes)
                setClassNumberList(result.data.class_numbers_list)
                setDialogCount(result.data.conflicted_classes.length)
                setCheckRider(
                    Array(result.data.conflicted_classes.length).fill(true)
                )
            } else if (result.data.status === "requiresDropConfirmation") {
                let userChoice = await confirmDialog({message: result.data.message, icon: "comment"})
                if(userChoice){
                    callRapidAddDropClassByNumbers(entryNumber, riderId, "", classesToDrop, showId, "Drop", result.data.classesNotDroppable, result.data.divisionClassesToScratch, result.data.divisionClassesToDrop, result.data.addResponse, result.data.invalidClasses)
                } else {
                    callRapidAddDropClassByNumbers(entryNumber, riderId, "", classesToDrop, showId, "Cancel", result.data.classesNotDroppable, result.data.divisionClassesToScratch, result.data.divisionClassesToDrop, result.data.addResponse, result.data.invalidClasses)
                }
            } else if (result.data.status === "requiresScratchConfirmation") {
                    setScratchDropPopupData({
                        entryNumber,
                        riderId,
                        classesToDrop,
                        showId,
                        addResponse: result.data.addResponse, 
                        invalidClasses: result.data.invalidClasses,
                        message: result.data.message,
                        classesToDrop,
                        divisionClassesToDrop: result.data.divisionClassesToDrop,
                        divisionClassesToScratch: result.data.divisionClassesToScratch,
                        classesNotDroppable: result.data.classesNotDroppable
                    })
                    setShowScratchDropPopup(true)
            } else if (result.data.status === "requiresCombinedClassConfirmation") {
                    setClassList(result.data.classList)
                    setIsAddMode(false)
                    setShowCombinedClassPopup(true)
            } else if (result.data.status === "invalidInput") {
                alertDialog({ message: "Please enter valid class numbers."})
            } else {
                let classesNotAddedOrRemovedSuccessfully = false
                if(result.data.hold_message){
                    // Warning if the entry is on credit hold or the rider is not medical hold 
                    warnings.push({message: result.data.hold_message, icon: ''})
                }

                if(result.data.fee_error?.length > 0){
                    // Warning if the validation of entry fees failed
                    warnings.push({message: result.data.fee_error.join('\n'), icon: ''})
                }

                if(result.data.requirements_response){
                    if(!result.data.requirements_response.requirements_satisfied){
                        // Warning for classes where class requirement are disallow and can not be added
                        if(result.data.requirements_response.cancel_classes_message.length > 0){
                            warnings.push({message: result.data.requirements_response.cancel_classes_message.join('\n'), icon: 'stop'})
                            classesNotAddedOrRemovedSuccessfully = true
                        }

                        else if(result.data.requirements_response.warn_classes_message.length > 0){
                            // Warning for classes where class requirements are warn and are added
                            warnings.push({message: result.data.requirements_response.warn_classes_message.join('\n'), icon: ''})
                        }
                    }
                }

                if(result.data.rule_failed_messages.length > 0){
                    // Display the failed rule messages
                    classesNotAddedOrRemovedSuccessfully = true
                    props.setErrors(prevError => [...prevError, ...result.data.rule_failed_messages])
                }

                setAddWarnings(warnings)
                // To manage multiple dialogs show and close state, initialize it the number of dialogs
                setClassAddWarningDialog(
                    Array(warnings.length).fill(true)
                )

                // Set Message of Added Class Numbers
                if (result.data.added_class_numbers.length > 0) {
                    let message = "Added "
                    message += result.data.added_class_numbers.length > 1 ? "classes " : "class "
                    message += result.data.added_class_numbers.join(", ")
                    message += " for entry " + entryNumber + "."
                    props.setMessages(prevMessages => [...prevMessages, message])
                }

                // Set Message of Deleted Class Numbers
                if (result.data.deleted_class_numbers.length > 0) {
                    let message = "Dropped "
                    message += result.data.deleted_class_numbers.length > 1 ? "classes " : "class "
                    message += result.data.deleted_class_numbers.join(", ")
                    message += " from entry " + entryNumber + "."
                    props.setMessages(prevMessages => [...prevMessages, message])
                }

                // Set Error of Invalid Class Numbers
                // Invalid Classes: classes that are not part of the selected show
                if (result.data?.invalidClasses?.length > 0) {
                    const num = result.data.invalidClasses.length
                    let error = num > 1 ? "Classes " : "Class "
                    error += result.data.invalidClasses.slice(0, num - 1).join(", ")
                    if (num > 1) {
                        error += " and "
                    }
                    error += result.data.invalidClasses[num-1]
                    error += num > 1 ? " are not classes " : " is not a class "
                    error += "of this show."
                    props.setErrors(prevError => [...prevError, error])
                    classesNotAddedOrRemovedSuccessfully = true
                }

                // Set Error of UnAdded Class Numbers
                // UnAdded Classes: classes that are not added to entry but were sent to drop
                if (result.data?.unAddedClasses?.length > 0) {
                    const num = result.data.unAddedClasses.length
                    let error = "Entry " + entryNumber + " does not have "
                    error += num > 1 ? " classes " : " class "
                    error += result.data.unAddedClasses.slice(0, num - 1).join(", ")
                    if (num > 1) {
                        error += " and "
                    }
                    error += result.data.unAddedClasses[num-1]
                    error += " added."
                    props.setErrors(prevError => [...prevError, error])
                    classesNotAddedOrRemovedSuccessfully = true
                }

                // Set Error of Already Added Class Numbers
                // UnAdded Classes: classes that are not added to entry but were sent to drop
                if (result.data?.alreadyAddedClasses?.length > 0) {
                    const num = result.data.alreadyAddedClasses.length
                    let error = "Entry " + entryNumber + " already has "
                    error += num > 1 ? " classes " : " class "
                    error += result.data.alreadyAddedClasses.slice(0, num - 1).join(", ")
                    if (num > 1) {
                        error += " and "
                    }
                    error += result.data.alreadyAddedClasses[num-1]
                    error += " added."
                    props.setErrors(prevError => [...prevError, error])
                    classesNotAddedOrRemovedSuccessfully = true
                }

                if (props.calledFrom === "Rapid" && !classesNotAddedOrRemovedSuccessfully){
                    props.clearStates()

                    // Move focus to entry number
                    if (props.entryNumberInputRef) {
                        setTimeout(() => {
                            props.entryNumberInputRef.current.focus();
                            props.entryNumberInputRef.current.select();
                        }, 300);
                    }
                }
            }
        })
        .catch(() =>  {
            // Clear Trips To Change State
            setTripsToChange([])
            
            loadingOverlay({ show: false })
        })
        .finally(() => { loadingOverlay({ show: false }) })
    }

    function callAddClassesApi(class_numbers) {
        if(class_numbers){
            loadingOverlay({ show: true })
            axios.post( process.env.REACT_APP_NEST_API_URL + '/class-add-drop/addClasses', {
                params: {
                    class_numbers: class_numbers,
                    customer_id: customer_id,
                    horse:methods.getValues('horse.horse_data'),
                    entry: methods.getValues('entry'),
                    trips: methods.getValues('trips'),
                    entry_fees: methods.getValues("entryFeesData.entryFees"),
                    entry_prices: methods.getValues("entryPrices"),
                    rider_id: props.person_id,
                    show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                    riderClassVerified,
                    entry_rider_records: methods.getValues("entry.EntryRiders").map(er => methods.getValues(`linked_people[${er.rider_id}].people_data`))
                }
            }) 
            .then(async result => { 
                // Hide Loading Overlay
                loadingOverlay({ show: false })
    
                if(result.data.success){
                    let warnings = []
                    if(result.data.checkCombinedClasses){
                        setClassList(result.data.classList)
                        setIsAddMode(true)
                        setShowCombinedClassPopup(true)
                    } else if(result.data.checkChangeRider){
                        setConflictedRiders(result.data.conflicted_classes)
                        setClassNumberList(result.data.class_numbers_list)
                        setDialogCount(result.data.conflicted_classes.length)
                        setCheckRider(
                            Array(result.data.conflicted_classes.length).fill(true)
                        )
                    } else {
                        if(result.data.hold_message){
                            // Warning if the entry is on credit hold or the rider is not medical hold 
                            warnings.push({message: result.data.hold_message, icon: ''})
                        }
                        if(result.data.fee_error?.length > 0){
                            // Warning if the validation of entry fees failed
                            warnings.push({message: result.data.fee_error.join('\n'), icon: ''})
                        }

                        if(result.data.requirements_response){
                            if(!result.data.requirements_response.requirements_satisfied){
                                // Warning for classes where class requirement are disallow and can not be added
                                if(result.data.requirements_response.cancel_classes_message.length > 0){
                                    warnings.push({message: result.data.requirements_response.cancel_classes_message.join('\n'), icon: 'stop'})
                                }
    
                                if(result.data.requirements_response.warn_classes_message.length > 0){
                                    // Warning for classes where class requirements are warn and are added
                                    warnings.push({message: result.data.requirements_response.warn_classes_message.join('\n'), icon: ''})
                                }
                            }
                        }

                        if(result.data.rule_failed_messages.length > 0){
                            // Display the failed rule messages
                            props.setErrors(result.data.rule_failed_messages[0])
                        } else {
                            props.setErrors("")
                        }

                        if (props.calledFrom === "Entries") {
                            methods.setValue('trips', result.data.trips, {shouldDirty: true})
                            methods.setValue('entryFeesData.entryFees', result.data.entry_fees, {shouldDirty: true})
                            methods.setValue('entryPrices', result.data.entry_prices, {shouldDirty: true})
                            const existingDeletedTrips = methods.getValues("deleted_trips") ? methods.getValues("deleted_trips") : []
                            methods.setValue('deleted_trips', [...new Set([...existingDeletedTrips, ...result.data.deleted_trips])], {shouldDirty: true})
                            const existingAddedClasses = methods.getValues("added_class_numbers") ? methods.getValues("added_class_numbers") : []
                            methods.setValue('added_class_numbers', [...new Set([...existingAddedClasses, ...result.data.added_class_numbers])], {shouldDirty: true})
                            const existingDeletedClasses = methods.getValues("deleted_class_numbers") ? methods.getValues("deleted_class_numbers") : []
                            methods.setValue('deleted_class_numbers', [...new Set([...existingDeletedClasses, ...result.data.deleted_class_numbers])], {shouldDirty: true})
                            const existingDeletedEntryPrices = methods.getValues('deletedEntryPrices') ? methods.getValues('deletedEntryPrices') : [] // concat the array instead or replacing it completely
                            methods.setValue('deletedEntryPrices', [...new Set([...existingDeletedEntryPrices, ...result.data.deleted_entry_prices])], {shouldDirty: true})
                        } 

                        setAddWarnings(warnings)
                        // To manage multiple dialogs show and close state, initialize it the number of dialogs
                        setClassAddWarningDialog(
                            Array(warnings.length).fill(true)
                        )
                    }
                    
                } else {
                    await alertDialog({message: result.data.error_message})
                }

                // Move focus to drop Input
                if (props.dropInputRef) {
                    setTimeout(() => {
                        props.dropInputRef.current.focus();
                    }, 600);
                }
            }).catch( error => {
                loadingOverlay({ show: false })
            })
        }
    }
    // The component instance will be extended with whatever you return from the 
    // callback passed as the second argument
    useImperativeHandle(ref, () => ({
        callAddClassesApi,
        callConfirmDivisionDrop,
        callRapidAddDropClassByNumbers,
    })); 

    const handleCheckRiderClose = (idx) => {
        setDialogCount(dialogCount - 1)
        setRiderClassVerified(true)
        setCheckRider(prevCombineClass => {
            const newArray = [...prevCombineClass]
            newArray[idx] = false
            return newArray
        });
    }

    // Handle multiple dialog closing
    const handleWarningClose = (idx) => {
        setClassAddWarningDialog(prevWarning => {
            const newArray = [...prevWarning]
            newArray[idx] = false
            return newArray
        })
    }

    const callConfirmDivisionDrop = async (class_numbers, userChoice, divisionClassesToDrop=[], divisionClassesToScratch=[], classesNotDroppable=[]) => {
        loadingOverlay({ show: true })
        
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/class-add-drop/confirmDivisionDrop', {
            params: {
                class_numbers: class_numbers,
                customer_id: customer_id,
                entry: methods.getValues('entry'),
                trips: methods.getValues('trips'),
                rider_id: props.person_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                userChoice,
                divisionClassesToDrop,
                divisionClassesToScratch,
                classesNotDroppable
            }
        })
        .then(async res => {
            if (res.data.status === "requiresDropConfirmation") {
                let userChoice = await confirmDialog({message: res.data.message, icon: "comment"})
                if(userChoice){
                    callConfirmDivisionDrop(class_numbers, "Drop", res.data.divisionClassesToDrop, res.data.divisionClassesToScratch, res.data.classesNotDroppable)
                } else {
                    callConfirmDivisionDrop(class_numbers, "Cancel", res.data.divisionClassesToDrop, res.data.divisionClassesToScratch, res.data.classesNotDroppable)
                }
            } else if (res.data.status === "requiresScratchConfirmation") {
                setScratchDropPopupData({
                    message: res.data.message,
                    class_numbers,
                    divisionClassesToDrop: res.data.divisionClassesToDrop,
                    divisionClassesToScratch: res.data.divisionClassesToScratch,
                    classesNotDroppable: res.data.classesNotDroppable
                })
                setShowScratchDropPopup(true)
            } else if (res.data.status === "requiresCombinedClassConfirmation") {
                setClassList(res.data.classList)
                setIsAddMode(false)
                setShowCombinedClassPopup(true)
            } else if (res.data.status === "completed") {
                methods.setValue('trips', res.data.trips, {shouldDirty: true})
                if(res.data?.deleteNumbersList.length > 0){
                    callDropClassesApi(res.data.deleteNumbersList, res.data.trips)
                } else {
                    loadingOverlay({ show: false })
                }
            } else if (res.data.status === "invalidInput") {
                alertDialog({ message: "Please enter valid class numbers."})
            } else {
                loadingOverlay({ show: false })
            }
        })
        .catch(() => loadingOverlay({ show: false }))
        .finally(() => { loadingOverlay({ show: false }) })
    }

    function callDropClassesApi(class_numbers, trips){
        loadingOverlay({ show: true })
        axios.post( process.env.REACT_APP_NEST_API_URL + '/class-add-drop/dropClasses', {
            params: {
                class_numbers: class_numbers,
                customer_id: customer_id,
                entry: methods.getValues('entry'),
                trips: trips?trips:methods.getValues('trips'),
                rider_id: props.person_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                entry_rider_records: methods.getValues("entry.EntryRiders").map(er => methods.getValues(`linked_people[${er.rider_id}].people_data`)),
                horse:methods.getValues('horse.horse_data'),
                entry_fees: methods.getValues("entryFeesData.entryFees"),
                entry_prices: methods.getValues("entryPrices"),
            }
        }) 
        .then(async result => { 
            loadingOverlay({ show: false })
            if(result.data.success){
                if(result.data.checkCombinedClasses){
                    setClassList(result.data.classList)
                    setIsAddMode(false)
                    setShowCombinedClassPopup(true)
                } else {

                    if(result.data.rule_failed_messages.length > 0){
                        // Display the failed rule messages
                        props.setErrors(result.data.rule_failed_messages[result.data.rule_failed_messages.length - 1])
                    } else{
                        props.setErrors('')
                    }
                    let older_fees = methods.getValues("entryFeesData.entryFees")

                    methods.setValue('trips', result.data.trips, {shouldDirty: true})
                    methods.setValue('entryFeesData.entryFees', result.data.entry_fees, {shouldDirty: true})
                    methods.setValue('entryPrices', result.data.entry_prices, {shouldDirty: true})
                    const existingDeletedTrips = methods.getValues("deleted_trips") ? methods.getValues("deleted_trips") : []
                    methods.setValue('deleted_trips', [...new Set([...existingDeletedTrips, ...result.data.deleted_trips])], {shouldDirty: true})
                    const existingAddedClasses = methods.getValues("added_class_numbers") ? methods.getValues("added_class_numbers") : []
                    methods.setValue('added_class_numbers', [...new Set([...existingAddedClasses, ...result.data.added_class_numbers])], {shouldDirty: true})
                    const existingDeletedClasses = methods.getValues("deleted_class_numbers") ? methods.getValues("deleted_class_numbers") : []
                    methods.setValue('deleted_class_numbers', [...new Set([...existingDeletedClasses, ...result.data.deleted_class_numbers])], {shouldDirty: true})
                    const existingDeletedEntryPrices = methods.getValues('deletedEntryPrices') ? methods.getValues('deletedEntryPrices') : [] // concat the array instead or replacing it completely
                    methods.setValue('deletedEntryPrices', [...new Set([...existingDeletedEntryPrices, ...result.data.deleted_entry_prices])], {shouldDirty: true})

                    if(result.data.dropFeeRequiredFlag){
                        let choice = await confirmDialog({message: intl.formatMessage({id: "ENTRIES.CLASSADDDROP.DROPFEES.MESSAGE"}), icon:'warning'})
                        if(choice){
                            validateReason(result.data.ClassRuleFeeIDToDelete)
                        } else if(result.data.dropNominationFee && result.data.droppedNominationFeeIds?.length > 0){
                        
                            let selectedEntryFee = older_fees.filter(entryFee => result.data.droppedNominationFeeIds.includes(entryFee.showfees_id))
                            if(selectedEntryFee.length > 0){
                                const deletedEntryFees = methods.getValues("deletedEntryFees") ? [...methods.getValues("deletedEntryFees"), ...selectedEntryFee] : selectedEntryFee
                                methods.setValue("deletedEntryFees", deletedEntryFees, { shouldDirty: true })
                            }
                        }
                    } else if(result.data.dropNominationFee && result.data.droppedNominationFeeIds?.length > 0){
                        
                        let selectedEntryFee = older_fees.filter(entryFee => result.data.droppedNominationFeeIds.includes(entryFee.showfees_id))
                        if(selectedEntryFee.length > 0){
                            const deletedEntryFees = methods.getValues("deletedEntryFees") ? [...methods.getValues("deletedEntryFees"), ...selectedEntryFee] : selectedEntryFee
                            methods.setValue("deletedEntryFees", deletedEntryFees, { shouldDirty: true })
                        }
                    }

                }
            } else {
                alertDialog({message: result.data.error_message})
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    const validateReason = (ClassRuleFeeIDToDelete) => {
        // Append Deleted Entry Fee to deletedEntryFees array only if the record was from database 
        if (ClassRuleFeeIDToDelete.length > 0) {
            // Append deleted row in deletedEntryFees Array
            let selectedEntryFee = methods.getValues('entryFeesData.entryFees').filter(entryFee => ClassRuleFeeIDToDelete.includes(entryFee.showfees_id))
            const deletedEntryFees = methods.getValues("deletedEntryFees") ? [...methods.getValues("deletedEntryFees"), ...selectedEntryFee] : selectedEntryFee
            methods.setValue("deletedEntryFees", deletedEntryFees, { shouldDirty: true })
        }

        // Remove deleted row from Ag-grid
        const filteredEntryFees = methods.getValues("entryFeesData.entryFees").filter((entryFee, index) => !ClassRuleFeeIDToDelete.includes(entryFee.showfees_id))
        methods.setValue("entryFeesData.entryFees", filteredEntryFees)

        // Reload Entry Fee Ag-grid
        // entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
    }

    return (
        <>
            <ScratchDropPopup
                show={showScratchDropPopup}
                scratchDropPopupData={scratchDropPopupData}
                callConfirmDivisionDrop={callConfirmDivisionDrop}
                handleClose={() => setShowScratchDropPopup(false)}
                callRapidAddDropClassByNumbers={callRapidAddDropClassByNumbers}
                calledFrom={props.calledFrom}
            />
            <CombinedClassPopup 
                show={showCombinedClassPopup}
                classList={classList} 
                handleClose={() => setShowCombinedClassPopup(false)} 
                callRapidAddDropClassByNumbers={callRapidAddDropClassByNumbers}
                rapidAPIData={rapidAPIData}
                callAddClassesApi={callAddClassesApi}
                callConfirmDivisionDrop={callConfirmDivisionDrop}
                isAddMode={isAddMode}
                area={props.calledFrom}
            />
            {
                conflictedRiders.map((conflictedRider, idx) =>
                <CheckRider 
                    show={checkRider[idx]}
                    handleClose={() => handleCheckRiderClose(idx)}
                    conflictedClass = {conflictedRider}
                    classNumberList = {classNumberList}
                    setClassNumberList = {setClassNumberList}
                    calledFrom={props.calledFrom}
                    setTripsToChange={setTripsToChange}
                />)
            }
            {
                addWarnings.map((warning, idx) => 
                <ClassAddWarningDialog 
                    show={classAddWarningDialog[idx]}
                    handleClose={() => handleWarningClose(idx)}
                    message = {warning.message}
                    icon = {warning.icon}
                    dropInputRef={props.dropInputRef}
                />)
            }
        </>
    )
})


export default ClassAddDrop