import {configureStore} from '@reduxjs/toolkit'
import currentShowReducer from './reducers/currentShowReducer';
import showCompanyReducer from './reducers/showCompanyReducer';
import newShowIdReducer from './reducers/newShowIdReducer';
import currentDetailPageInfoReducer from './reducers/currentDetailPageInfoReducer';
import showCompanyMetadataReducer from './reducers/showCompanyMetadata';
import renderShowPickerReducer from './reducers/renderShowPickerReducer';

const store = configureStore({
  reducer: {
    currentShow: currentShowReducer,
    showCompany: showCompanyReducer,
    newShowId: newShowIdReducer,
    currentDetailPageInfo : currentDetailPageInfoReducer,
    showCompanyMetadata: showCompanyMetadataReducer,
    renderShowPicker: renderShowPickerReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store