/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent'
import { searchOptions } from '../../modules/sgl-utils/reactSelectOptions'

type Props = {
  show: boolean
  onSubmitForm: ({}) => void,
  onClearFilter: () => void,
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchForm = ({show, onSubmitForm, onClearFilter, handleClose}: Props) => {
  const intl = useIntl()
  const { register, reset, getValues, control, formState } = useForm({});
  const context=useOutputContext()

  const handleReset = () => {
    reset();
  }
  const handleSave = async () => {
      var data = getValues();
      onSubmitForm(data);
  }

  useEffect(() => {
    reset(context.outputAdvanceFilter,{keepDefaultValues:true})
  },[]);

  return createPortal(
      
    <Modal
      id='kt_modal_create_app'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
      show={show}
      onHide={handleClose}
      onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) { //call function only if no button is in focus
          handleSave();
        }
        if (event.key === "Escape") {
          handleClose();
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.TITLE.SEARCHSHOWS'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form id='shows-filter-form' noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='SHOWNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.SHOWNAME'})}</label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='SHOWNAME'
                  type='text'
                  {...register("show_name")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.SHOWNAME'})}
                  autoFocus
                  tabIndex={1}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='HORSE'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.HORSE'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='HORSE'
                  type='tel'
                  {...register("horse")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.HORSE'})}
                  tabIndex={2}
                />
              </div>
            </div>

            
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='RIDERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.RIDER'})}</label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      id='RIDERFIRSTNAME'
                      type='text'
                      {...register("rider_first_name")}
                      className='form-control form-control-sm mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.FIRSTNAME'})}
                      tabIndex={3}
                    />
                  </div>
                  
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...register("rider_last_name")}
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.LASTNAME'})}
                      tabIndex={4}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='TRAINERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.TRAINER'})}</label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      id='TRAINERFIRSTNAME'
                      {...register("trainer_first_name")}
                      type='text'
                      className='form-control form-control-sm mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.FIRSTNAME'})}
                      tabIndex={5}
                    />
                  </div>
                  
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...register("trainer_last_name")}
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.LASTNAME'})}
                      tabIndex={6}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='OWNERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.OWNER'})}</label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      id='OWNERFIRSTNAME'
                      {...register("owner_first_name")}
                      type='text'
                      className='form-control form-control-sm mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.FIRSTNAME'})}
                      tabIndex={7}
                    />
                  </div>
                  
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      {...register("owner_last_name")}
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.LASTNAME'})}
                      tabIndex={8}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='DATESFROM'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.DATES'})}</label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <div className="input-group date">
                      <Controller
                          name="start_date"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                              const formattedValue = (value && moment(value).isValid())
                                  ? moment(value).format('MM/DD/YYYY')
                                  : '';

                              return (
                                  <Flatpickr
                                      className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                      id="DATESFROM"
                                      name={name}
                                      autoComplete="off"
                                      value={formattedValue}
                                      placeholder="MM/DD/YYYY"
                                      options={date_options}
                                      tabIndex={9}
                                      onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                          const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                          if (!isValidDate) {
                                              instance.input.value = ''; // Clear the input if the date is invalid
                                          }

                                          // Use setDate with dateStr and onChange
                                          setDate(dateStr, onChange, null);
                                      }}
                                      onInput={(e: any) => { maskDateInput(e) }}
                                  />
                              );
                          }}
                      />
                      <div className="input-group-append">
                        <label htmlFor='DATESFROM' className='date-label'>
                          <span className="input-group-text">
                              <i className="la la-calendar"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className='col-lg-6 fv-row'>
                    <div className="input-group date">
                      <Controller
                          name="end_date"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                              const formattedValue = (value && moment(value).isValid())
                                  ? moment(value).format('MM/DD/YYYY')
                                  : '';

                              return (
                                  <Flatpickr
                                      className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                      id="DATESTO"
                                      name={name}
                                      autoComplete="off"
                                      value={formattedValue}
                                      placeholder="MM/DD/YYYY"
                                      options={date_options}
                                      tabIndex={10}
                                      onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                          const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                          if (!isValidDate) {
                                              instance.input.value = ''; // Clear the input if the date is invalid
                                          }

                                          // Use setDate with dateStr and onChange
                                          setDate(dateStr, onChange, null);
                                      }}
                                      onInput={(e: any) => { maskDateInput(e) }}
                                  />
                              );
                          }}
                      />
                      <div className="input-group-append">
                        <label htmlFor='DATESTO' className='date-label'>
                          <span className="input-group-text">
                              <i className="la la-calendar"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='row mb-2'>
              <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                  <input
                    className='form-check-input'
                    {...register("exact_matches")}
                    type='checkbox'
                    id='EXACTMATCHES'
                    tabIndex={11}
                  />
                </div>

                <label className='col-form-label fs-5 fw-bold py-1' htmlFor='EXACTMATCHES'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.LABEL.EXACTMATCHES'})}</label>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCH'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.SHOWS.LABEL.SEARCH'})}</span>
              </label>

              <div className='col-lg-9'>
                <ReactSelectComponent 
                  name='search'
                  id='SEARCH'
                  options={searchOptions} 
                  control={control} 
                  methods={null} 
                  tabIndex={12}
                />
              </div>
            </div>
            
          </div>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button  type="button" className='btn btn-sm btn-secondary fw-bold' onClick={handleReset} tabIndex={13}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES'})}
            </button>

            <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{marginLeft:"auto"}} onClick={handleClose} tabIndex={14}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL'})}
            </button>

            <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={15}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH'})}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {SearchForm}
