import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { KTSVG } from '../../../_metronic/helpers';
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import { Controller, useForm } from 'react-hook-form';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import moment from 'moment';
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import { datOptions, ratingOptions, searchOptions } from '../../modules/sgl-utils/reactSelectOptions';
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';


// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
    show: boolean
    onSubmitForm: ({}) => void,
    onClearFilter: () => void,
    handleClose: () => void
  }

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchDivisionsForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const current_show_id =  useAppSelector(state => state.currentShow.show_id);
    
    const [rings, setRings] = useState<any[]>([]);
    const { register, reset, getValues, control, formState } = useForm({});
    const context=useOutputContext()

    const handleReset = () => {
        reset();
    }

    const handleSave = async () => {
        var data = getValues();
        onSubmitForm(data);
    }

  // get scheduled in rings options
  const getScheduledRingOptions = () => {
    const ringsOptions = rings?.map((ring) => ({value: ring.ring_id, label: ring?.ring_name ? ring.ring_name : ring?.Ring?.name}));
    ringsOptions?.unshift({label: 'Select', value: ''})
    return ringsOptions;
  }

  const getRings = async () =>{
    const response: any = await axios.get(`${NEST_API_URL}/entries/AdvanceSearchMetaData?customer_id=${customerID}&show_id=${current_show_id}`);
      setRings(response?.data?.rings ?? [])
  }

    useEffect(() => {
        if(rings.length == 0){
            getRings()
        }
    }, [show]);

    useEffect(() => {
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
    },[]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) { //call function only if no button is in focus
                  handleSave();
                }
                if (event.key === "Escape") {
                  handleClose();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.TITLE.SEARCHDIVISIONS' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='DIVISIONNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.DIVISIONNAME' })}</label>
                            <div className='col-lg-3'>
                                <input
                                    id='DIVISIONNAME'
                                    type='text'
                                    {...register("division_name")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.DIVISIONNAME' })}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='CODE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.CODE' })}</label>
                            <div className='col-lg-3'>
                                <input
                                    id='CODE'
                                    type='text'
                                    {...register("code")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.CODE' })}
                                    tabIndex={2}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='CLASSNUM'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.CLASSNUM' })}</label>
                            <div className='col-lg-3'>
                                <input
                                    id='CLASSNUM'
                                    type='text'
                                    {...register("class_number")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.CLASSNUM' })}
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='RATING'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.RATING' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    name='rating'
                                    id='RATING'
                                    options={ratingOptions} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='DAY'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.DAY' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    name='day'
                                    id='DAY'
                                    options={datOptions} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={5}
                                />
                            </div>
                            <div className='col-lg-3'>
                                <div className="input-group date">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                        }) => {
                                            const formattedValue = (value && moment(value).isValid())
                                                ? moment(value).format('MM/DD/YYYY')
                                                : '';

                                            return (
                                                <Flatpickr
                                                    className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                    id="DATESTO"
                                                    name={name}
                                                    value={formattedValue}
                                                    placeholder="MM/DD/YYYY"
                                                    onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
              
                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                        }
                                                        setDate(dateStr, onChange, null);
                                                    }}
                                                    options={date_options}
                                                    autoComplete="off"
                                                    tabIndex={6}
                                                    onInput={(e: any) => { maskDateInput(e) }}
                                                />
                                            );
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor='DATESTO' className='date-label'>
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='RING'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.RING' })}</label>
                            <div className='col-lg-9'>
                                <ReactSelectComponent 
                                    name='ring'
                                    id='RING'
                                    options={getScheduledRingOptions()} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={7}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("exact_matches")}
                                        type='checkbox'
                                        id='EXACTMATCHESONLY'
                                        tabIndex={8}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='EXACTMATCHESONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.EXACTMATCHESONLY' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCH'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.SEARCH' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    name='search'
                                    id='SEARCH'
                                    options={searchOptions} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={9}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={handleReset} tabIndex={10}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={11}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={12}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SearchDivisionsForm }