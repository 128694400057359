import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import { FEINameMismatchAlert } from './FEINameMismatchAlert';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';

// Assets
import '../tab.css'
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';
import moment from 'moment';


const FEIMembershipTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const {horse_id} = useParams();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [loading, setLoading] = useState(true)
    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState()
    const confirmDialog = useConfirm()
    const [flag, setFlag] = useState(false)
    const [membershipName, setMembershipName] = useState("")
    const alertDialog = useAlert()
    const sglLite = useAppSelector(state => state.showCompany.sgl_lite)

    const columnDefs = [
        { field: 'key', maxWidth: 245, minWidth:245, lockVisible: false, lockPosition: 'left', cellClass: 'text-start' , cellStyle: {'border-right': '1px solid #eee'}
            ,cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value', flex:1, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellRenderer: HideJsonRenderer },
    ]

    useEffect(() => {
        if(gridRef){
            if(methods.getValues('horse.fei_lookup_response') && isValidJson(methods.getValues('horse.fei_lookup_response'))){
                // nameMismatchAcceptedCheckbox()
                parseLookupData(JSON.parse(methods.getValues('horse.fei_lookup_response')))
            }
            else{
                gridRef.setRowData([])
            }

        }
    }, [gridRef, methods.getValues('horse.fei_lookup_response')])

    const parseLookupData = (lookup) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let FEIData = []
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     value.map((data) => {
                //         let lookup = Object.entries(data)
                //         lookup.map(([key, value]) => {
                //             FEIData.push({'key': key, "value": value})
                //         })
                //     })
                // }
                // else{
                    FEIData.push({'key':key, "value":value})
                // }
            })
            gridRef.setRowData(FEIData)
        }
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);

    const lookup_data = () => { 
        callFeiLookupApi(false)
    }

    function callFeiLookupApi(accept_mismatch_checked= false){
        let memObj = {}, org = "FEI";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        props.getLookupData(org, memObj, 'feiLookup', accept_mismatch_checked)
    }

    const acceptMismatch = () => {
        // methods.setValue('horse.name', props.horseName, {shouldDirty: true}) // DO NOT update horse name from FEI
        let organizationMembers = methods.getValues("organizationMembers"), organization = ""
        organization = organizationMembers.find(membershipData =>{
            if (membershipData.organization_code.toLowerCase() == 'fei'){
                return membershipData
            }
        })
        if(!organization){
            organization.membership_id = 0
            organization.organization_code = "FEI"
            organization.horse_id = methods.getValues('horse.horse_id')
        }
        organization.name_mismatch_accepted = true
        // organization.membership_name = membershipName?.trim()

        let membership_year = methods.getValues("horse.fei_membership_year")?parseInt(methods.getValues("horse.fei_membership_year")):0;
        let fei_isactive = methods.getValues("horse.fei_isactive")
        if(organization.valid_start == '0000-00-00' && organization.valid_start == '0000-00-00' && membership_year > 0 && fei_isactive){
            organization.valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
            organization.valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
            // organization.membership_level = props.personFirstName + " " + props.personLastName

                let current_date = moment().format('YYYY-MM-DD')
                if (organization.valid_end >= current_date) { //RTO has current compition year membership of FEI
                    organization.is_membershipvalid = true
                }
        }

        props.updateMembership(organization)

    }
    
    const handleClearMembership = () => {
        methods.setValue('horse.fei_lookup_response', '')
        props.clearMembership('FEI')
        // props.removeMembership('FEI', true)
    }

    // getting boolean to enable/disable name mismatch checkbox
    const getFeiMismatchAcceptDisable = () => {
        let memberships = methods.getValues('organizationMembers')
        let FEImemberships = memberships.find(data => {
           return data.organization_code == "FEI"
        })
        if(FEImemberships){
            if(FEImemberships.membership_name && FEImemberships.membership_name?.trim().toUpperCase() != methods.getValues('horse.name')?.trim().toUpperCase()){
                return false 
            }
            else{
                return true
            }
        } else {
            return true;
        }
    }

    // getting boolean to set default checked of name mismatch checkbox
    const getFeiMismatchAccepted = () => {
        let memberships = methods.getValues('organizationMembers')
        let FEImemberships = memberships.find(data => {
           return data.organization_code == "FEI"
        })
        if(FEImemberships){
            if(FEImemberships.name_mismatch_accepted)
            {
                return true;
            }
        }
        return false;
    }

    //calling fei lookup api on accept name mismatch checkbox 
    function onFeiNameMismatchChecked(checked){
        if(checked){
            callFeiLookupApi(true);
        } else {
            handleClearMembership()
        }
    }

    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-2 membership-data-grid'>
                    {(props.FEINameMismatch) &&
                        <FEINameMismatchAlert show={props.FEINameMismatch} handleClose={() => props.setFEINameMismatch(false)} horseName={props.horseName} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} name={methods.getValues('horse.name')} />
                    }
                    <OutputListingGridStatic  area ={"Horses - FEIMembership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData?rowData:[]} containerStyle={containerStyle}  rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true}masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-5'>
                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='name_mismatch_accepted'
                            checked={getFeiMismatchAccepted()}
                            disabled={getFeiMismatchAcceptDisable() /*&& !sglLite*/}
                            onChange={(e) => onFeiNameMismatchChecked(e.target.checked)}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='name_mismatch_accepted'> {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.FEIMEMBERSHIP.ACCEPTNAMEMISMATCH' })}</label>
                    </div>
                </div>
                {/* {sglLite? null:  */}
                    <div className='row align-items-center mb-2'>
                        <div className='col-lg-2 w-175px'>
                            <button type='button' onClick={() => lookup_data()} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase">
                                {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.UPDATE' })}
                            </button>
                        </div>
                    </div>
                {/* } */}
            </div>
        </>
    );
}

export { FEIMembershipTab }