import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import {PageTitle} from '../../../_metronic/layout/core'
// Custom Components
import {useEffect, useState} from 'react'
import { useForm, FormProvider, Controller} from "react-hook-form";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import { useCallback } from 'react'
import { ShowsTab } from './Detail/ShowsTab';
import { HunterIncrementTab } from './Detail/HunterIncrementTab';
import { CircuitPointsTab } from './Detail/CircuitPointsTab';
import { CircuitDivisionsTab } from './Detail/CircuitDivisionsTab';
import { StatsTab } from './Detail/StatsTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { useNavigate, useParams } from 'react-router-dom';
import { useLoadingOverlay, useAlert } from '../../modules/sgl-utils/DialogsProvider';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';

import moment from 'moment';
import history from '../../modules/sgl-utils/unstableHistory';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';

// Constants
import { date_options } from '../../modules/sgl-utils/fieldControls';
import { HistoryTab } from '../../modules/components/HistoryTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'
import { setDate, maskDateInput } from '../../modules/sgl-utils/fieldControls';

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const CircuitDetail = (props) => {
    const intl = useIntl();
    const {circuit_id} = useParams();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const navigate = useNavigate();
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert();
    const [rowClicked, setRowClicked] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [loading, setLoading] = useState(true)

    const [statsResultLoading, setStatsResultLoading] = useState(true)
    const [open_forms, setOpenForms] = useState([])
    const [sub_area, setSubArea] = useState("")
    const [record_id, setRecordId] = useState("")

	let current_circuit_id = props.id == "" ? 0 : props.id || props.id == 0 ? props.id : circuit_id

    const [circuitDivisionIds, setCircuitDivisionIds] = useState([])
	const [update_data, setUpdateData] = useState(0)
    // prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

    const [gridRowData, setGridRowData] = useState('')
    const getDetailPageInfo = (id, area, component, circuitDivisionIds, data) => {
        setRecordId(id)
        setSubArea(area)
        setChildComponent(component)
        setCircuitDivisionIds(circuitDivisionIds)
        sessionStorage.setItem("dialog_is_open_in_show", true)
        setRowClicked(rowClicked => !rowClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        setOpenForms(forms_open)
        setGridRowData(data)
      };

  
    // To open same detail page multiple times 
    useEffect(() => {
        if(sessionStorage.getItem("dialog_is_open_in_show") == "true"){
        sessionStorage.removeItem("dialog_is_open_in_show")
        setRowClicked(true)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        if(open_forms.includes(sub_area+"_"+record_id) == false){
            forms_open.push(sub_area+"_"+record_id);
            sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
        }
        }
    }, [rowClicked])

    const methods = useForm({
        defaultValues: 
        {
            "circuit":{
                "circuit_id": 0,
                "name": "",
                "description": "",
                "start_date":"",
                "end_date" : "",
                "h_i_champ_additional_points": 0,
                "h_i_reserve_additional_points": 0,
                "organization_code" : "", 
                "circuit_points" : "",
                "included_in_year_end" : 0,
                "c_p_champ_additional_points": 0,
                "c_p_reserve_additional_points": 0,
                "suppress_on_web": false,

            },
            "circuitxShow_data": [],
            "circuit_divisions": [],
            "statistics": {},
            'show_deleted': [],
            'deleted_circuit_divisions': []
        },
    }); 
    const { register, handleSubmit, reset, formState , control, setFocus} = methods
    const { dirtyFields } = formState; 
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    
    const [circuitPointsRowData, setCircuitPointsRowData] = useState([]) 
    const getCircuitPointsArrayFromString = (circuitPoints) => {
        const rowData = []
        if (circuitPoints === '') {
            for (let i = 1; i <= 12; i++) {
                rowData.push({ points: 0, place: i })
            }
        } else { // bf_UnpackTextToArrays(Circuit_Points, circuitPointsPlacings, circuitPointsPoints, "¡", "†");
            const regex = /(\d+)¡(\d+)†/g;
            let circuitPoint
            while ((circuitPoint = regex.exec(circuitPoints))) {
                rowData.push({ place: parseInt(circuitPoint[1]),  points: parseInt(circuitPoint[2]) })
            }
        }
        return rowData
    }

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    const saveCircuit = async () => {
        const data = dirtyValues(dirtyFields, methods.getValues())

        if (Object.keys(data).length === 0) // Save only if something is updated.
            return 

        loadingOverlay({ show: true, message: 'Saving Circuit..'})
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/circuits/detail', {
            params: {
                circuit_id: circuit_id,
                customer_id: customer_id,
                data 
            }
        })
        updateData() // Update/Refresh/Reload Page Data after saving Circuit
        loadingOverlay({ show: false, message: 'Saving Circuit..'})
    }

    const onSubmit = async (form_data) => {  
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }

        let submitData = dirtyValues(dirtyFields, form_data) 
        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/circuits/detail', {
            params: {
                circuit_id: circuit_id,
                customer_id: customer_id,
                data: submitData
            }
        }).then((response) => {
            loadingOverlay({show: false})
            if(response.data.success) {
                // Use Case (Param Id changed): Next/Prev buttons
                if(props?.id == undefined && Number(methods.getValues('circuit.circuit_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                    if(row_selected && row_selected != "" && row_ids[row_selected]){
                        setLoading(true)
                        history.push(`${PUBLIC_URL}/circuits/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                    }
                }
                else if(props?.id == undefined && current_circuit_id > 0){
                    history.push(`${PUBLIC_URL}/circuits`, { allowNavigation: true })
                }
                else if(props?.id == undefined && current_circuit_id == 0){
					history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
				}
                else{
                    if(props.updateCallbackFromParent){
                    props.updateCallbackFromParent()
                    props.closeModal()
                    }
                }
                if( row_selected == row_ids?.length -1 && props?.id === undefined){
                    if (next_button && last_button) {
                        next_button.disabled = true;
                        last_button.disabled = true;
                        prev_button.disabled = false;
                        first_button.disabled = false;
                    }
                  }
                  if(row_selected == 0 && props?.id === undefined){
                    if (prev_button && first_button) {
                        prev_button.disabled = true;
                        first_button.disabled = true;
                        next_button.disabled = false;
                        last_button.disabled = false;
                    }
                }
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
        })
        
    }

    useEffect(() => {
    
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/circuits/detail', {
            params: {
              circuit_id: circuit_id,
              customer_id: customer_id,
            }
          })
        ]  
        ).then (
          axios.spread( ({data : circuitData}) => {

            //Check if record id is invalid then redirect to list page
            if ((circuitData || circuitData === '') && isInvalidRecord(circuitData?.circuit, circuit_id, 'circuit_id')){
              navigate('/circuits')
              loadingOverlay({show: false})
            }
            
            reset(circuitData)
            setCircuitPointsRowData(getCircuitPointsArrayFromString(circuitData.circuit?.circuit_points))

            if(sessionStorage.getItem('forms_open') == undefined || circuit_id == current_circuit_id){
				sessionStorage.setItem('forms_open', JSON.stringify(["CircuitDivisions_"+Number(current_circuit_id)]));
			}
            // Hide loading overlay
            loadingOverlay({show: false})
            setLoading(false)

            // call api for get statistics data
            setStatsResultLoading(true);
            axios.post( process.env.REACT_APP_NEST_API_URL + '/circuits/getStats', {
                params: {
                  circuit_id: circuit_id,
                  customer_id: customer_id,
                }
              }).then((response) => {
                if(response.data.success){
                    setStatsResultLoading(false);
                    methods.setValue ('statistics', response.data.statistics);
                } else {
                    setStatsResultLoading(false);
                }
            })
          })
        )
      },[circuit_id, update_data])

    const tabs = ["SHOWS", "HUNTERINCREMENT", "CIRCUITPOINTS", "CIRCUITDIVISIONS", "STATS", "HISTORY"]
    const componentTags = [
        <ShowsTab />, 
        <HunterIncrementTab />, 
        <CircuitPointsTab rowData={circuitPointsRowData} />, 
        <CircuitDivisionsTab callbackFromParent={getDetailPageInfo} saveCircuit={saveCircuit} />, 
        <StatsTab setStatsResultLoading={setStatsResultLoading} statsResultLoading= {statsResultLoading}/>,
        <HistoryTab area="Circuits" rowData={methods.getValues("change_history_logs") ?? []} />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    useAutoFocus('NAME', loading)

    return (
        <>
        {!loading && (
			<>
           {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('circuit.name'), methods.getValues('circuit.circuit_id')]}>{intl.formatMessage({id: 'MENU.CIRCUITS'})}</PageTitle> : ""}
            <div id={"circuits_detail_page"} className='modal-body py-3 px-4 horse-input-form input-form'>
                <FormProvider {...methods}>
                    <form noValidate className='form max-width'  onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                    <HelpLink tooltip="CIRCUITS.DETAIL.LINK.HELP" classname="top" />
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group row"> {/* Column Group */}
                                <div className='row mb-2'>
                                    <div className='col-lg-12'>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px' htmlFor='NAME' data-tooltip-id="CIRCUITS.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.NAME' })}</label>

                                            <div className='col-lg-4'>
                                                <input
                                                    {...methods.register( 'circuit.name', 
                                                            {
                                                            required: "Circuit name is required."
                                                            }
                                                        )
                                                    }
                                                    id='NAME'
                                                    type='text'
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.NAME' })}
                                                />
                                                { methods.formState.errors?.circuit?.name && <div><span className='error_message'>{methods.formState.errors.circuit.name?.message}</span></div>}
                                            </div>

                                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                                <input
                                                    {...register( 'circuit.suppress_on_web', 
                                                        {
                                                        required: false
                                                        })
                                                    }
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='SUPPRESSCIRCUITONWEB'
                                                />
                                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='SUPPRESSCIRCUITONWEB' data-tooltip-id="CIRCUITS.DETAIL.LABEL.SUPPRESSCIRCUITONWEB"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.SUPPRESSCIRCUITONWEB' })}</label>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px' htmlFor='START' data-tooltip-id="CIRCUITS.DETAIL.LABEL.START">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.START' })}</label>
                                            <div className='me-7' style={{ width: '164px'}}>
                                                <div className="input-group date">
                                                    <Controller
                                                        name="circuit.start_date"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, name },
                                                        }) => {
                                                            const formattedValue = (value && moment(value).isValid())
                                                                ? moment(value).format('MM/DD/YYYY')
                                                                : '';

                                                            return (
                                                                <Flatpickr
                                                                    className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                                                    id="START"
                                                                    name={name}
                                                                    placeholder="MM/DD/YYYY"
                                                                    value={formattedValue}
                                                                    onClose={(value, dateStr, instance) => {
                                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                        if (!isValidDate) {
                                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                                        }
                                                                        setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                                                    }}
                                                                    onInput={(e) => { maskDateInput(e) }}
                                                                    options={date_options}
                                                                    autoComplete="off"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <label htmlFor='START' className='date-label'>
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar"></i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-75px' htmlFor='END' data-tooltip-id="CIRCUITS.DETAIL.LABEL.END">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.END' })}</label>

                                            <div style={{ width: '165px'}}>
                                                <div className="input-group date">
                                                    <Controller
                                                        name="circuit.end_date"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, name },
                                                        }) => {
                                                            const formattedValue = (value && moment(value).isValid())
                                                                ? moment(value).format('MM/DD/YYYY')
                                                                : '';

                                                            return (
                                                                <Flatpickr
                                                                    className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                                                    id="END"
                                                                    name={name}
                                                                    placeholder="MM/DD/YYYY"
                                                                    value={formattedValue}
                                                                    onClose={(value, dateStr, instance) => {
                                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                        if (!isValidDate) {
                                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                                        }
                                                                        setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                                                    }}
                                                                    onInput={(e) => { maskDateInput(e) }}
                                                                    options={date_options}
                                                                    autoComplete="off"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <label htmlFor='END' className='date-label'>
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar"></i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px' htmlFor='DESCRIPTION' data-tooltip-id="CIRCUITS.DETAIL.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.LABEL.DESCRIPTION' })}</label>

                                            <div className='col-lg-4'>
                                                <textarea
                                                    id="DESCRIPTION"
                                                    {...register( 'circuit.description', 
                                                        {
                                                        required: false
                                                        })
                                                    }
                                                    rows="3"
                                                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/* End Column Group */}

                                <div className='d-flex flex-column border p-2 bg-white'>
                                    <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                        <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + ([1, 2, 3].indexOf(index) >= 0 ? ' min-w-200px text-center' : '')}
                                                            tabIndex="-1"
                                                            id={tab + "-tab"}
                                                            data-bs-toggle='tab'
                                                            href={"#" + tab}
                                                            data-tooltip-id={`CIRCUITS.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.' + tab })}
                                                        </a>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>

                                    <div className="tab-content mt-5 pb-2" id="myTabContent">
                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab} role="tabpanel" >
                                                        <HelpLink tooltip={"CIRCUITS.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                        {componentTags[index]}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div> {/* End Card Body */}
                        </div> {/* End Card Body */}

                        <InputFormFooter goBackPath={'/circuits'}  data={'circuit'} propId={props?.id}/>
                    </form>
                </FormProvider>
                {rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} setRecordId={setRecordId} parent_id={current_circuit_id} updateCallbackFromParent={updateData} open_forms={open_forms} circuitDivisionIds={circuitDivisionIds} shows = {methods.getValues('circuitxShow_data')} gridRowData={gridRowData} />}

            </div>
            </>
    		)}
        </>
    );
}

export { CircuitDetail }