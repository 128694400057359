import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../redux/hooks';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../modules/sgl-utils/SglFunctions';
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import EntriesGrid from './FeeSplit/EntriesGrid';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { truncateToNumPlaces } from '../../../modules/sgl-utils/SglFunctions';
import { NumericFormat } from 'react-number-format';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
// import icon from "../../../../_metronic/assets/CustomIcons/fee_split.jpg"

const modalsRoot = document.getElementById('root-modals') || document.body

const FeeSplit = ({ show, handleClose }) => {
    const intl = useIntl() 
    const currencyFormatter = useCurrencyFormatter()
    const { getOutputSelectionAreaIDs } = useOutputContext()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)

    const [feesData, setFeesData] = useState([]) // fee related data (description, unit_price, etc.)
    const feesOptions = feesData.map(fee => ({ label: fee.description, value: fee.sgl_id }))
    const [selectedFee, setSelectedFee] = useState({})

    const [isFeedBeddingFee, setIsFeedBeddingFee] = useState(false)
    const [alreadyDelivered, setAlreadyDelivered] = useState(false)
    const [checkAll, setCheckAll] = useState(true)

    const [quantity, setQuantity] = useState(0)
    const quantityInputRef = useRef()
    const [totalPrice, setTotalPrice] = useState(0)

    const [rowData, setRowData] = useState([])
    const [showMessage, setShowMessage] = useState(false)

    // To Force useEffect to reapply split when include is checked/unchecked
    const [forceReapplySplit, setForceReapplySplit] = useState(false)
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [totalFee, setTotalFee] = useState(0)
    const [disableApplyButton, setDisableApplyButton] = useState(true)

    const onFeeSelect = (e) => {
        let obj = feesData.find(fee => fee.sgl_id == e.value)

        if (obj) {
            setQuantity(1)
            setIsFeedBeddingFee(obj.MasterFee ? obj.MasterFee.feed_bedding_fee : obj.feed_bedding_fee)
            setTotalPrice(1 * obj.unit_price)
            setSelectedFee(obj)
            quantityInputRef.current.select()
        }

        if (showMessage) { setShowMessage(false) }
    }

    // Get Entry Fee Select
    useEffect(() => {
        if (show) { // Fetch list only once popup is displayed 
            if(showFinancialsLocked){
                alertDialog({ title: "warning", message: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.SHOWLOCKED"}) })
                handleClose()
                return
            }


            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    alertDialog({ message: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.SELECTENTRIESMESSAGE"}) })
                    handleClose()
                    return
                }
                
                loadingOverlay({ show: true })
                axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/getFeeSplitMetadata', {
                    entry_ids: res,
                    show_id: currentShowID,
                    customer_id: customerId
                })
                .then(response => {
                    setFeesData(response.data.showFeesData)

                    const entriesData = response.data.entriesData
                    for (let entryData of entriesData) {
                        entryData.quantity = 0
                        entryData.split_fee = 0
                        entryData.unit_fee = 0
                        entryData.include = true
                    }

                    setRowData(entriesData)

                    loadingOverlay({ show: false })
                })
                .catch(() => loadingOverlay({ show: false }))
            })
            .catch(() => loadingOverlay({ show: false }))
        }
    }, [show])

    const calculateFeeSplit = () => {
        // Calculate number of included entries
        let numIncludedEntries = 0
        for (let row of rowData) {
            if (row.include) {
                numIncludedEntries++
            }
        }

        if (selectedFee && quantity) {
            let distributedQuantity
            let distributedFee
            let totalQuantityAfterSplit
            let totalFeeAfterSplit 
            if (numIncludedEntries !== 0) { // Safety check to avoid division by zero error
                // Quantity to distribute among each entry
                distributedQuantity = quantity/numIncludedEntries
                // Truncate result upto two decimal places
                distributedQuantity = truncateToNumPlaces(distributedQuantity, 2)

                // Fee to distribute among each entry (aka split fee)
                distributedFee = (quantity * selectedFee.unit_price)/numIncludedEntries
                distributedFee = truncateToNumPlaces(distributedFee, 2)

                // Total quantity after applying split
                totalQuantityAfterSplit = parseFloat((distributedQuantity * numIncludedEntries).toFixed(2))
                // Total fee after applying split
                // totalFeeAfterSplit = distributedFee * numIncludedEntries
                totalFeeAfterSplit = parseFloat((distributedFee * numIncludedEntries).toFixed(2))
                /* 
                    Note: Total quantity and fee after split can be less than original quantity and fee before split respectively as we can lose some data (0.0x) because of division operation. We will adjust this while assigning values to each included entry.
                */
            }

            setRowData(prevRowData => {
                const newRowData = [...prevRowData];

                for (let i = 0; i < newRowData.length; i++) {
                    if (newRowData[i].include) {
                        if(!newRowData[i]?.quantityUpdated){
                        let tempQuantity = distributedQuantity
                        // If total quantity after split is less than original quantity i.e., we have quantity loss after division, add 0.01 until quantity after split equals original quantity 
                        if (totalQuantityAfterSplit < quantity) {
                            tempQuantity = distributedQuantity + 0.01
                            totalQuantityAfterSplit += 0.01
                            totalQuantityAfterSplit = parseFloat((totalQuantityAfterSplit).toFixed(2)) // without this if we calculate total quantity it become more the original quantity
                        }

                        let tempFee = distributedFee
                        if (totalFeeAfterSplit < totalPrice) {
                            tempFee = distributedFee + 0.01
                            totalFeeAfterSplit += 0.01
                            totalFeeAfterSplit = parseFloat((totalFeeAfterSplit).toFixed(2)) // without this if we calculate total fee it become more the original fee
                        }

                        // Create new object as we cannot update state directly (by reference)
                        newRowData[i] = {
                            ...newRowData[i],
                            unit_fee: selectedFee.unit_price,
                            split_fee: tempFee,
                            quantity: tempQuantity
                        }
                        }else{
                            newRowData[i] = {
                                ...newRowData[i],
                                unit_fee: selectedFee.unit_price,
                                split_fee: newRowData[i]?.quantity * selectedFee.unit_price
                            }
                        }
                        
                    } else {
                        newRowData[i] = {
                            ...newRowData[i],
                            unit_fee: selectedFee.unit_price,
                            split_fee: 0,
                            quantity: 0
                        }
                    }
                }
                return newRowData
            })
        }else{
            // Clear out the split_fee and quantity
            setRowData(prevRowData => {
                const newRowData = [...prevRowData];

                for (let i = 0; i < newRowData.length; i++) {
                    newRowData[i] = {
                        ...newRowData[i],
                        unit_fee: selectedFee.unit_price,
                        split_fee: 0,
                        quantity: 0,
                        quantityUpdated: false
                    }
                }
                return newRowData
            })
        }
        setCheckAll(numIncludedEntries === rowData.length)
    }
    
    // Calculate the total quantity and total fee from the grid data. This will be displayed at the bottom
    useEffect(() => {
        let quantity = 0
        let fee = 0
        for (let i = 0; i < rowData?.length; i++) {
            quantity = parseFloat((quantity + rowData[i]?.quantity).toFixed(2))
            fee = parseFloat((fee + rowData[i]?.split_fee).toFixed(2))
        }
        setTotalQuantity((prevState) => quantity)
        setTotalFee((prevState) => fee)
    }, [rowData])

    // When fee or quantity is changed update the quatityUpdated parameter for row data to false
    useEffect(() => {
        clearQuantityUpdated()
    }, [quantity, selectedFee])

    // check the Apply button enable condition when any of the following is updated (selectedFee, quantity, totalQuantity, totalPrice, totalFee)
    useEffect(() => {
        let parsedQuantity = parseAndRound(quantity);
        let parsedTotalQuantity = parseAndRound(totalQuantity);
        let parsedTotalPrice = parseAndRound(totalPrice);
        let parsedTotalFee = parseAndRound(totalFee);
        setDisableApplyButton(!selectedFee.showfees_id || quantity == 0 || parsedQuantity !== parsedTotalQuantity || parsedTotalPrice !== parsedTotalFee)
    }, [selectedFee, quantity, totalQuantity, totalPrice, totalFee])

    useEffect(() => {
        calculateFeeSplit()
    }, [quantity, selectedFee, forceReapplySplit])

    const handleQuantityChange = (e) => {
        const { value } = e.target
        if (value > 0) {
            setQuantity(value)
            setTotalPrice(value * selectedFee.unit_price)
        }  
    } 

    const saveSplit = async (event) => {
       
        loadingSpinnerBtnWaitByEnter(event)
    
        const entryFees = []
        for (let row of rowData) {
            // Ignore not included entries
            if (!row.include) {
                continue
            }
            let newEntryFee = {
                entry_id: row.entry_id,
                show_id: row.show_id,
                quantity: row.quantity,
                description: selectedFee.description,
                unitprice: selectedFee.unit_price,
                category: selectedFee.category,
                showfees_id: selectedFee.showfees_id,
                totalprice: Number(row.split_fee).toFixed(2),
            } 

            if (isFeedBeddingFee && alreadyDelivered) {
                newEntryFee.delivered = true
            }

            entryFees.push(newEntryFee)
        }

        axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/feeSplit", {
            entry_fees: entryFees,
            customer_id: customerId,
        })
        .then(response => {
            if (response.data.success) {
               setShowMessage(true)
            }

            clearStates()
            loadingSpinnerBtnReleaseByEnter(event)
        })
        .catch(reason =>  {
            if (reason.response.data?.error) {
                alertDialog({ message: reason.response.data.error })

                if (reason.response.data.error.includes("quantity")) {
                    quantityInputRef.current.focus()
                }
            }
            loadingSpinnerBtnReleaseByEnter(event)
        }).finally(() => {
            loadingSpinnerBtnReleaseByEnter(event)
        })
    }

    const clearStates = () => {
        setSelectedFee({}); 
        setQuantity(0)
        setTotalPrice(0)
        setAlreadyDelivered(false)
        setIsFeedBeddingFee(false)
    }

    const onClose = () => {
        clearStates()
        setRowData([])
        setShowMessage(false)
        handleClose();  
    }

    const toggleCheckAllRows = (checked) => {
        setRowData(prevRowData => {
            const newRowData = [...prevRowData];
            for (let i = 0; i < newRowData.length; i++) {
                newRowData[i] = {...newRowData[i], include: checked, quantityUpdated: false }
            }
            return newRowData
        })
        setForceReapplySplit(prevState => !prevState)
    }

    const clearQuantityUpdated = (updatedElement=null) => {
        setRowData(prevRowData => {
            const newRowData = [...rowData];
            for (let i = 0; i < newRowData.length; i++) {
                if(updatedElement && updatedElement?.entry_id === newRowData[i]?.entry_id){
                    newRowData[i] = {...updatedElement, quantityUpdated: false } // when single include checkbox is changed
                }else{
                    newRowData[i] = {...newRowData[i], quantityUpdated: false }
                }
            }
            return newRowData
        })
    }

    function parseAndRound(value) {
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue) ? parseFloat(parsedValue.toFixed(2)) : 0;
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered fee-splits-modal'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key === "Enter" && !isButtonFocused && !disableApplyButton) { //check if enter press should activate function
                    let applyButton = document.getElementById('FeeSplitApplyButton')
                    saveSplit(applyButton)
                }
                if (event.key === 'Escape') {
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.FEESPLIT' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            {/* <div className='col-lg-2'>
                                <img 
                                    src={icon} 
                       
                                />
                            </div> */}
                            <div className='col'>
                                <div className='row mb-2 mt-2'>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold' htmlFor='FEEDESC'>{intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.ADDMISCFEE" })}</label>
                                    <div className='col-lg-8'>
                                        <Select 
                                            options={feesOptions}
                                            id="FEEDESC"
                                            value={
                                                selectedFee.sgl_id ? feesOptions.find((ss) => ss.value === selectedFee.sgl_id): null }
                                            onChange={onFeeSelect}
                                            styles={reactSelectStyles}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold' htmlFor='QUANTITY'>{intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.QUANTITY" })}</label>
                                    <div className='col-lg-2'>
                                        <NumericFormat
                                            ref={quantityInputRef}
                                            name="quantity"
                                            onChange={handleQuantityChange}
                                            value={quantity}
                                            id="QUANTITY"
                                            format="####" // Specify the desired format for the numeric input
                                            className="form-control form-control-sm fs-6 min-h-20px py-1 number-input"
                                            placeholder={intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.QUANTITY" })}
                                            disabled={!selectedFee.showfees_id}
                                            tabIndex={2}
                                        />
                                    </div>

                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                        { intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.UNITFEE"  }) }
                                    </label>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px'>
                                        { currencyFormatter(selectedFee['unit_price']) }
                                    </label>

                                    <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                        { intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.TOTALFEE" }) }
                                    </label>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px'>
                                        { currencyFormatter(totalPrice) }
                                    </label>
                                </div>
                            </div> 
                        </div>
                     
                        {
                            isFeedBeddingFee ?
                            <div className='row mb-2'>
                                <label className='col-lg-2'></label>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        name="priority_order"
                                        onChange={e => setAlreadyDelivered(e.target.checked)}
                                        checked={alreadyDelivered}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='ALREADYDELIVERED'
                                        tabIndex={3}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='ALREADYDELIVERED'> {intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.LABEL.ALREADYDELIVERED" })}</label>
                                </div>
                            </div>
                            : null
                        }
                    </div>

                    <EntriesGrid 
                        rowData={rowData}
                        quantity={totalQuantity}
                        totalPrice={totalFee}
                        setForceReapplySplit={setForceReapplySplit}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        toggleCheckAllRows={toggleCheckAllRows}
                        selectedFee={(selectedFee && selectedFee?.sgl_id) ? selectedFee?.sgl_id :0}
                        setRowData={setRowData}
                        clearQuantityUpdated={clearQuantityUpdated}
                        tabIndex={4}
                    />
        
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <label className="col col-form-label fs-5 py-1" style={{ color: '#28a745' }}>
                            { showMessage ? "Fees split among included entries." : "" }
                        </label>

                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={5}>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>

                        <button 
                            id='FeeSplitApplyButton'
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase' 
                            onClick={saveSplit}
                            autoFocus
                            disabled={disableApplyButton}
                            tabIndex={6}
                        >
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.APPLY" })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default FeeSplit