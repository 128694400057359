import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
// Assets
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment"
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { horseConvertHeight, horseAge, hasVerifiedMeasurementCard, horseType, controlLookupFields } from './AdditonalTab/MembershipFunctions';
import { useState , useEffect} from 'react';
import USEFLogo from '../../../../_metronic/assets/CustomIcons/USEF_Icon.jpeg'
import ECLogo from '../../../../_metronic/assets/CustomIcons/EC_icon.png'
import FEILogo from '../../../../_metronic/assets/CustomIcons/fei_icon.jpeg'

// Constants
import { date_options, setDate, maskDateInput } from '../../../modules/sgl-utils/fieldControls';

const GeneralTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const [age, setAge] = useState()
    const [height, setHeight] = useState()

    useEffect(() => {
        if(methods.getValues('horse')){
            membershipStatus(methods.getValues('horse'))
            setAge(horseAge(methods.getValues("horse.dob")))
            setHeight(methods.getValues('horse.height_centimeters'))
            let disableStates = controlLookupFields(methods.getValues('horse'), methods.getValues('organizationMembers'))
            methods.setValue('disableHeight', disableStates?.disHeight)
            methods.setValue('disableDOB', disableStates.disDob)
            methods.setValue('disableType', disableStates.disType)
            methods.setValue('disableName', disableStates.disName)
        }
    }, [methods.watch('horse')])

    const updateHeightRelatedData = () => {
        if(methods.getValues('horse.type')?.trim() != ""){
            if(!hasVerifiedMeasurementCard(methods.getValues('horse.usef_response_eti_lookup', methods.getValues('horse.measurement_override_date')))){
                if(methods.getValues('horse.height')!="" || methods.getValues('horse.height') <= 0){
                    methods.setValue('horse.type',"", {shouldDirty:true})
                }
            }
        }
        methods.setValue('horse.height_centimeters', horseConvertHeight(methods.getValues('horse.height', 'cm')), {shouldDirty:true})
        let horse_type = horseType(methods.getValues('horse.height_centimeters'))
        let type = horse_type !=""?horse_type:methods.getValues('horse.type')
        methods.setValue('horse.type', horseType(methods.getValues("horse.height_centimeters")), {shouldDirty:true})
        setHeight(methods.getValues('horse.height_centimeters'))
    }

    const membershipStatus = (horses) => {          //This function needs review
        // horses["usef_suspension_status"]  = "Not Available"
        methods.setValue('horse.usef_suspension_status', "Not Available") //horse USEF No is empty, set as Not Available
        methods.setValue('horse.fei_suspension_status', "Not Available")
        methods.setValue('horse.ec_suspension_status', "Not Available")
        
        //USEF Suspension Check
        if(horses.usef_no && horses.usef_no?.toString().trim() != ""){ //horse USEF No is Not Empty, check usef_suspended value if true set suspended
            methods.setValue('horse.usef_suspension_status', horses.usef_suspended ? "Suspended" : "Not Suspended")
        }

        //EC Suspension Status Check
        if(horses.ec_ppn && horses.ec_ppn?.toString()?.trim() != ""){
            methods.setValue('horse.ec_suspension_status', horses.ec_status?.toLowerCase() ==  "suspended" ? "Suspended": "Not Suspended")
        }

        //FEI Suspension Status Check
        if(horses.fei_id && horses.fei_id?.toString()?.trim() != ""){
            // Note: FEI suspension verification is different in SGL Cloud then in 4D. Discussed with MA. Please see method ->  entry_VerifyHorseSuspension 
            methods.setValue('horse.fei_suspension_status', horses.fei_inactive_reason?.toLowerCase() == "suspended"? "Suspended": "Not Suspended")
        }
    }

    const lookup_usef_data = () => { 
        methods.setValue('lookup_organization', 'USEF');
    }
    const lookup_fei_data = () => { 
        methods.setValue('lookup_organization', 'FEI');
    }
    const lookup_ec_data = () => { 
        methods.setValue('lookup_organization', 'EC');
    }

    return (
        <>
            <div className='form-group row mb-2'>
                <div className='row mb-2'>
                    <div className='col-lg-6'> {/* Column 1 */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='GREEN' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.GREEN">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.GREEN' })}</label>
                            <div className='col-lg-7'>
                                {props.form_meta.green && (
                                        <Controller
                                            name='horse.green'
                                            render={({field: {onChange, value, name}}) => (
                                                <Select
                                                options={props.form_meta.green}
                                                id='horse.green'
                                                value={props.form_meta.green.find(
                                                    (ss) => ss.value === value
                                                )}
                                                name={name}
                                                isSearchable={true}
                                                onChange={(green) => {
                                                    onChange(green.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                placeholder='Select'
                                                />
                                            )}
                                            />
                                    )}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='SEX'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.SEX">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SEX' })}</label>
                            <div className='col-lg-7'>
                                {props.form_meta.sex && (
                                        <Controller
                                        name='horse.sex'
                                        render={({field: {onChange, value, name}}) => (
                                            <Select
                                            options={props.form_meta.sex}
                                            id='horse.sex'
                                            value={props.form_meta.sex.find(
                                                (ss) => ss.value === value
                                            )}
                                            name={name}
                                            isSearchable={true}
                                            onChange={(sex) => {
                                                onChange(sex.value)
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder='Select'
                                            />
                                        )}
                                        />
                                    )}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='color'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.COLOR">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.COLOR' })}</label>
                            <div className='col-lg-7'>
                                <input
                                    {...methods.register('horse.color', 
                                        {
                                            required: false
                                        })
                                    }
                                    id='color'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.COLOR' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='height'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.HEIGHT">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.HEIGHT' })}</label>
                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <input
                                        {...methods.register("horse.height", 
                                        {
                                            required: false
                                        })
                                        }
                                            id='height'
                                            type='text'
                                            disabled={methods.watch('disableHeight')}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.HEIGHT' })}
                                            onKeyUp={(e) => {
                                                if (e.key != "Tab" && e.key != "Shift") {
                                                    updateHeightRelatedData()
                                                }
                                            }}
                                        />
                                    </div>
                                        {height > 0 && <label className='col-lg-6 col-form-label fs-5 py-1'>{height} cm</label>}
                                </div>

                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='measurement_override_date'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.OVERRIDEDATE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.OVERRIDEDATE' })}</label>
                            <div className='col-lg-7'>
                                <div className="input-group date">
                                    <Controller
                                        name="horse.measurement_override_date"
                                        render={({ field: { onChange, value, name } }) => {
                                            const formattedValue = (value && moment(value).isValid())
                                                ? moment(value).format('MM/DD/YYYY')
                                                : '';

                                            return (
                                                <Flatpickr
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                    id="measurement_override_date"
                                                    name={name}
                                                    value={formattedValue}
                                                    placeholder="MM/DD/YYYY"
                                                    options={date_options}
                                                    autoComplete="off"
                                                    onClose={(value, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                        }

                                                        setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                    }}
                                                    onInput={(e) => { maskDateInput(e) }} // Ensure input masking is applied
                                                />
                                            );
                                        }}
                                    />

                                    <div className="input-group-append">
                                        <label htmlFor='measurement_override_date' className='date-label'>
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='TYPE'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.TYPE' })}</label>
                            <div className='col-lg-7'>
                                {props.form_meta.horse_type && (
                                        <Controller
                                        name='horse.type'
                                        render={({field: {onChange, value, name}}) => (
                                            <Select
                                            options={props.form_meta.horse_type}
                                            id='type'
                                            value={props.form_meta.horse_type.find(
                                                (ss) => ss.value === value
                                            )}
                                            name={name}
                                            isSearchable={true}
                                            isDisabled={methods.watch('disableType')}
                                            onChange={(horse_type) => {
                                                onChange(horse_type.value)
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder='Select'
                                            />
                                        )}
                                        />
                                    )}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='meas_card_no'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.MEAS.CARD">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.MEASCARD' })}</label>
                            <div className='col-lg-7'>
                                <input
                                        {...methods.register("horse.meas_card_no", 
                                        {
                                            required: false
                                        })
                                        }
                                    id='meas_card_no'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.MEASCARD' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='heel'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.HEEL">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.HEEL' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.heel", 
                                {
                                    required: false
                                })
                                }
                                    id='heel'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.HEEL' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='breedregistrynumber'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.BREEDINGREG#">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREEDINGREGNUM' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.breedregistrynumber", 
                                {
                                    required: false
                                })
                                }
                                    id='breedregistrynumber'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREEDINGREGNUM' })}
                                />
                            </div>
                        </div>
                        {/* <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='usef_status'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.USEFSTATUS">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.USEFSTATUS' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.usef_status", 
                                {
                                    required: false
                                })
                                }
                                    id='usef_status'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.USEFSTATUS' })}
                                />
                            </div>
                        </div> */}
                        {/* <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='status'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.STATUS">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.STATUS' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.status", 
                                {
                                    required: false
                                })
                                }
                                    id='status'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.STATUS' })}
                                />
                            </div>
                        </div> */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='coggins_date'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.COGGINS">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.COGGINGS' })}</label>
                            <div className='col-lg-7'>
                                <div className="input-group date">
                                    <Controller
                                        name="horse.coggins_date"
                                        render={({ field: { onChange, value, name } }) => {
                                            const formattedValue = (value && moment(value).isValid())
                                                ? moment(value).format('MM/DD/YYYY')
                                                : '';

                                            return (
                                                <Flatpickr
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                    id="coggins_date"
                                                    name={name}
                                                    value={formattedValue}
                                                    placeholder="MM/DD/YYYY"
                                                    options={date_options}
                                                    autoComplete="off"
                                                    onClose={(value, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                        }

                                                        setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                    }}
                                                    onInput={(e) => { maskDateInput(e) }} // Ensure input masking is applied
                                                />
                                            );
                                        }}
                                    />

                                    <div className="input-group-append">
                                        <label htmlFor='coggins_date' className='date-label'>
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='declaration'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.DECLARATION">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DECLARATION' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.declaration", 
                                {
                                    required: false
                                })
                                }
                                    id='declaration'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DECLARATION' })}
                                />
                            </div>
                        </div> */}
                        {/* <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='vet_statement'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.VETSTATEMENT">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.VETSTATEMENT' })}</label>
                            <div className='col-lg-7'>
                                <input
                                {...methods.register("horse.vet_statement", 
                                {
                                    required: false
                                })
                                }
                                    id='vet_statement'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.VETSTATEMENT' })}
                                />
                            </div>
                        </div> */}
                        <div className='row mb-5'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='FOREIGN'></label>
                            <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                {...methods.register("horse.foreigner", 
                                {
                                    required: false
                                })
                                }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='foreigner'
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='foreigner'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.FOREIGN"> {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.FOREIGN' })}</label>
                            </div>
                        </div>
                        {/* New Membership status */}
                        <fieldset className='mb-2'>
                            <legend className="fs-5 fw-bold d-flex col-lg-11">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.GROUPLABEL.MEMBERSHIPSTATUS' })} <b></b></legend>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={USEFLogo} />
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.USEF">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.USEF' }) + " " + methods.watch('horse.usef_suspension_status')}</label>
                                {methods.getValues('horse.usef_suspendcheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{(methods.getValues('horse.usef_suspendcheckdate') && moment(methods.getValues('horse.usef_suspendcheckdate')).isValid())?moment(methods.getValues('horse.usef_suspendcheckdate')).format('MM/DD/YYYY'):''}</span>
                                    <span>{moment(methods.getValues('horse.usef_suspendchecktime'),'HH:mm:ss').isValid()?moment(methods.getValues('horse.usef_suspendchecktime'),'HH:mm:ss').format("h:mm A"):''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_usef_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={ECLogo} />
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.EC">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.EC' }) + " " + methods.watch('horse.ec_suspension_status')}</label>
                                {methods.getValues('horse.ec_statuscheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{(methods.getValues('horse.ec_statuscheckdate') && moment(methods.getValues('horse.ec_statuscheckdate')).isValid())?moment(methods.getValues('horse.ec_statuscheckdate')).format('MM/DD/YYYY'):''}</span>
                                    <span>{moment(methods.getValues('horse.ec_statuschecktime'),'HH:mm:ss').isValid()?moment(methods.getValues('horse.ec_statuschecktime'),'HH:mm:ss').format("h:mm A"):''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_ec_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={FEILogo} style={{scale: '1.3'}}/>
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.FEI">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.FEI' }) + " " + methods.watch('horse.fei_suspension_status')}</label>
                                {methods.getValues('horse.fei_statuscheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{(methods.getValues('horse.fei_statuscheckdate') && moment(methods.getValues('horse.fei_statuscheckdate')).isValid())?moment(methods.getValues('horse.fei_statuscheckdate')).format('MM/DD/YYYY'):''}</span>
                                    <span>{moment(methods.getValues('horse.fei_statuschecktime'),'HH:mm:ss').isValid()?moment(methods.getValues('horse.fei_statuschecktime'),'HH:mm:ss').format("h:mm A"):''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_fei_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                        </fieldset>
                        
                    </div> {/* End Column 1 */}
                    <div className='col-lg-6'> {/* Column 2 */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='rider'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.RIDER">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.RIDER' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("rider.lf_name", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='rider'
                                    disabled={true}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='owner'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.OWNER">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.OWNER' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("owner.lf_name", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='owner'
                                    disabled={true}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='trainer'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.TRAINER">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.TRAINER' })}</label>
                                <div className='col-lg-7'>
                                <input
                                        {...methods.register("trainer.lf_name", 
                                        {
                                            required: false
                                        })
                                        }
                                    id='trainer'
                                    disabled={true}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='billTo'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.BILLTO">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BILLTO' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("billTo.lf_name", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='billTo'
                                    disabled={true}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='prizemoneyrecipient'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.PRIZE$RECIPIENT">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.PRIZERECIPIENT' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("prizemoneyrecipient.lf_name", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='prizemoneyrecipient'
                                    type='text'
                                    disabled={true}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <div className='row mb-5'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='stable'  data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.LABEL.STABLE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.STABLE' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("stable.name", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='stable'
                                    disabled={true}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                </div>
                        </div>
                        <fieldset className='mb-2'>
                            <legend className="fs-5 fw-bold col-lg-11">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.GROUPLABEL.BREEDINGINFO' })} <b></b></legend>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='breed' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.BREED">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREED' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.breed", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='breed'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREED' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='breeder' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.BREEDER">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREEDER' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.breeder", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='breeder'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.BREEDER' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='dob' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.DATEOFBIRTH">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DOB' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <div className='row'>
                                        <div className='col-lg-7'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="horse.dob"
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY')
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                                id="dob"
                                                                name={name}
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                autoComplete="off"
                                                                disabled={methods.watch('disableDOB')}
                                                                onClose={(value, dateStr, instance) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    }
                                                                    setAge(horseAge(moment(value[0]).format('YYYY-MM-DD'))); // Update horse age
                                                                    setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                }}
                                                                onInput={(e) => { maskDateInput(e) }} // Ensure input masking is applied
                                                            />
                                                        );
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <label htmlFor='dob' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-40px'>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.AGE' })}:</label> */}
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.AGE">{age}</label>
                                        {methods.getValues('horse.usef_age_verified')?
                                        <label className='col col-form-label fw-bolder fs-5 py-1 text-success text-end w-lg-40px'>AV</label>
                                        :''}
                                    </div>

                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='COUNTRYFOALED' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.COUNTRYFOALED">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.COUNTRYFOALED' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                        {...methods.register("horse.country", // needs to confirm field
                                        {
                                            required: false
                                        })
                                        }
                                        id='COUNTRYFOALED'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.COUNTRYFOALED' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='sire' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.SIRE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SIRE' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.sire", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='sire'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SIRE' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='dam' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.DAM">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DAM' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.dam", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='dam'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DAM' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='DAMSIRE' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.DAMSIRE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DAMSIRE' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.dam_s_sire", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='DAMSIRE'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.DAMSIRE' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='microchip_number' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.MICROCHIP#">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.MICROCHIPNUM' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.microchip_number", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='microchip_number'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.MICROCHIPNUM' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='ueln' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.UELN">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.UELN' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.ueln", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='ueln'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.UELN' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='sale_price' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.SALEPRICE">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SALEPRICE' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    {/* <input
                                    {...methods.register("horse.sale_price", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='sale_price'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SALEPRICE' })}
                                    /> */}
                                    <Controller
                                                control={methods.control}
                                                name="horse.sale_price"
                                                render={({ field: { onChange, name, value } }) => (
                                                    <NumericFormat
                                                        prefix={'$'}
                                                        id='sale_price'
                                                        value={value !== '' ? Number(value): ""}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowNegative={false}
                                                        onValueChange={(e) => {
                                                            // Only update the value if it's changed by the user
                                                            if (Number(e.value) !== Number(value)) {
                                                                onChange(e.value);
                                                            }
                                                        }}
                                                        onFocus={(e) => {
                                                            e.target.select(); // Highlight the value
                                                        }}
                                                        className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SALEPRICE' })}
                                                    />
                                                )}
                                            />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='sale_contact_name' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.SALECONTACT">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SALECONTACT' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    <input
                                    {...methods.register("horse.sale_contact_name", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='sale_contact_name'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.SALECONTACT' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='sale_contact_number' data-tooltip-id="HORSE.DETAIL.TAB.GENERAL.SECTION.BREEDINGINFORMATION.LABEL.CONTACTNUMBER">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.CONTACTNUM' })}</label>
                                <div className='col-lg-7 ps-lg-0'>
                                    {/* <input
                                    {...methods.register("horse.sale_contact_number", 
                                    {
                                        required: false
                                    })
                                    }
                                        id='sale_contact_number'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.CONTACTNUM' })}
                                    /> */}
                                    <Controller
                                    control={methods.control}
                                    name="horse.sale_contact_number"
                                    render={({ field: { onChange, value} }) => (
                                    <PatternFormat
                                        format="(###) ###-####"
                                        value={value !== '' ? Number(value): ""}
                                        onValueChange={(e) => {
                                            if(e.value.length < 10){
                                                methods.setError('horse.sale_contact_number',{
                                                    type: "custom",
                                                    message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                                })
                                                onChange('')
                                            }
                                            if(e.value.length === 10 || e.value.length === 0){
                                                methods.setError('horse.sale_contact_number',{
                                                    type: "custom",
                                                    message: ""
                                                })
                                                onChange(e.value)
                                            }
                                            
                                        }}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        id={'sale_contact_number'}
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.GENERAL.LABEL.CONTACTNUM' })}
                                    />
                                    )}
                                />
                                { methods.formState.errors?.horse?.sale_contact_number && <div><span className='error_message'>{methods.formState.errors.horse?.sale_contact_number?.message}</span></div>}
                                </div>
                            </div>
                        </fieldset>
                    </div> {/* End Column 2 */}
                </div>
            </div>
        </>
    );
}

export { GeneralTab }