/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { KTSVG } from '../../../_metronic/helpers';
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';
import { getCustomOptionsFromArray, getShowOptions, memberCode, searchOptions } from '../../modules/sgl-utils/reactSelectOptions';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';

// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
    show: boolean
    onSubmitForm: ({}) => void,
    onClearFilter: () => void,
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchHorsesForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    let verify_entry = 0
    const { register, reset, getValues, control, formState, watch } = useForm({});
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [peopleOrganizations, setPeopleOrganizations] = useState<any[]>([]);
    const [shows, setShows] = useState<any[]>([]);
    const context=useOutputContext()

    const ownerSelectedOrg = watch('owner_organization_code')
    const trainerSelectedOrg = watch('trainer_organization_code')
    const peopleTaxStatusLevels: string[] = ["Requested", "Review", "Manual", "Completed"]

    const handleReset = () => {
        reset();
      }

    const handleSave = async () => {
        var data = getValues();
        await onSubmitForm(data);
        handleHide();
    }

    const handleHide = () => {
        handleReset();
        handleClose();
    }
    useEffect(() => {
        if(show && shows.length === 0){
            getSearchMetadata()
        }
    },[show]);

    useEffect(() => {
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
    },[]);

    const getSearchMetadata = async () => {
        const [horsesResponse, peopleResponse] = await Promise.all([
            axios.get(`${NEST_API_URL}/horses/AdvanceSearchMetaData?customer_id=${customerID}`),
            axios.get(`${NEST_API_URL}/people/AdvanceSearchMetaData?customer_id=${customerID}`)
          ]);
        setOrganizations(horsesResponse?.data?.organizations ?? []);
        setPeopleOrganizations(peopleResponse?.data?.organizations ?? []);
        setShows(horsesResponse?.data?.shows ?? []);
    }

    // Get people membership options for react-select component
    const getPeopleMembershipOptions = () => {
        const options = peopleOrganizations?.map((obj) => ({value: obj.code, label: obj.code}))
        options?.unshift({label: 'Any', value: ''});
        return options;
    }

    const getPeopleTaxStatusLevels = () => {
        const options = peopleTaxStatusLevels?.map((obj) => ({value: obj, label: obj}))
        options?.unshift({label: 'Any', value: ''});
        return options;
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={show}
            onHide={handleHide}
            onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                  handleSave();
                }
                if (event.key === "Escape") {
                    handleHide();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.TITLE.HORSESEARCH' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='NAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NAME' })}</label>
                            <div className='col-lg-6'>
                                <input
                                    id='NAME'
                                    type='text'
                                    {...register("name")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NAME' })}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    {...register("on_vet_hold")}
                                    type='checkbox'
                                    id='ONVETHOLD'
                                    tabIndex={2}
                                />
                                <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='ONVETHOLD'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.ONVETHOLD' })}</label>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='MEMBERCODE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.MEMBERCODE' })}</label>
                            <div className='col-lg-3'>                              
                                <ReactSelectComponent 
                                    name='organization_code'
                                    id='MEMBERCODE' 
                                    options={getCustomOptionsFromArray(organizations, 'code', 'code', {value: '', label: 'Any'})} 
                                    control={control} 
                                    methods={null}
                                    tabIndex={3}
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='MEMBERCODE-2'
                                    {...register("membership_number")}
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.MEMBERSHIPNUMBER' })}
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                        <fieldset className='mb-5'>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.GROUPLABEL.PARTICIPATION' })} <b></b></legend>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='PARTICIPATEDAT'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.PARTICIPATEDAT' })}</label>
                                <div className='col-lg-9'>
                                    <ReactSelectComponent 
                                        id='PARTICIPATEDAT'
                                        name='participated_at'
                                        options={getShowOptions(shows, {label: 'Any', value: ''})} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={5}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label d-flex flex-column-reverse fw-bold fs-5 py-1'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.INCLASS' })}</label>
                                <div className='col-lg-3'>
                                    <label className='col-form-label fw-bold fs-5 py-0' htmlFor='NUMBER'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NUMBER' })}</label>
                                    <input
                                        id='NUMBER'
                                        {...register("class_number")}
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NUMBER' })}
                                        tabIndex={6}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-5 py-0' htmlFor='NAMECONTAINS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NAMECONTAINS' })}</label>
                                    <input
                                        id='NAMECONTAINS'
                                        type='text'
                                        {...register("class_name")}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.NAMECONTAINS' })}
                                        tabIndex={7}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                   <ReactSelectComponent 
                                        id='MEMBERCODE'
                                        name='member_code'
                                        options={memberCode} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={8}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <div className="input-group date">
                                                    <Controller
                                                        name="start_date"
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            const formattedValue = (value && moment(value).isValid())
                                                                ? moment(value).format('MM/DD/YYYY')
                                                                : '';

                                                            return (
                                                                <Flatpickr
                                                                    className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                                                    id="FROMDATE"
                                                                    name={name}
                                                                    value={formattedValue}
                                                                    placeholder="MM/DD/YYYY"
                                                                    options={date_options}
                                                                    autoComplete="off"
                                                                    onClose={(value: moment.MomentInput[], dateStr: moment.MomentInput, instance: { input: { value: string; }; }) => {
                                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                        if (!isValidDate) {
                                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                                        } 
                                                                        setDate(dateStr, onChange, null);
                                                                    }}
                                                                    onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                    tabIndex={9}
                                                                />
                                                            );
                                                        }}
                                                    />

                                                <div className="input-group-append">
                                                    <label htmlFor='FROMDATE' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-20px'>&</label>
                                        <div className='col ps-0'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="end_date"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY')
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                                                id="TODATE"
                                                                name={name}
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                autoComplete="off"
                                                                onClose={(value: moment.MomentInput[], dateStr: moment.MomentInput, instance: { input: { value: string; }; }) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    } 
                                                                    setDate(dateStr, onChange, null);
                                                                }}
                                                                onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                tabIndex={10}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <label htmlFor='TODATE' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-5'>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.GROUPLABEL.RTO' })} <b></b></legend>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='OWNERMEMBERCODE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.OWNERMEMBERCODE' })}</label>
                                <div className={ownerSelectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                    <ReactSelectComponent 
                                        name='owner_organization_code'
                                        id='OWNERMEMBERCODE' 
                                        options={getPeopleMembershipOptions()} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={11}
                                    />
                                </div>
                                <div className={ownerSelectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                    <input
                                        id='OWNERCODE'
                                        type='text'
                                        {...register("owner_membership_number")}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.PLACEHOLDER.CODE' })}
                                        tabIndex={12}
                                    />
                                </div>
                                { ownerSelectedOrg == 'Tax Status' && (<div className='col-lg-2'>
                                    <ReactSelectComponent 
                                        name='owner_tax_status_level'
                                        id='OwnerTaxStausLevel' 
                                        options={getPeopleTaxStatusLevels()} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={13}
                                    />
                                </div>)}
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='TRAINERMEMBERCODE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.TRAINERMEMBERCODE' })}</label>
                                <div className={trainerSelectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                    <ReactSelectComponent 
                                        name='trainer_organization_code'
                                        id='TRAINERMEMBERCODE' 
                                        options={getPeopleMembershipOptions()} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={14}
                                    />
                                </div>
                                <div className={trainerSelectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                    <input
                                        id='TRAINERCODE'
                                        type='text'
                                        {...register("trainer_membership_number")}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.PLACEHOLDER.CODE' })}
                                        tabIndex={15}
                                    />
                                </div>
                                { trainerSelectedOrg == 'Tax Status' && (<div className='col-lg-2'>
                                    <ReactSelectComponent 
                                        name='trainer_tax_status_level'
                                        id='TrainerTaxStausLevel' 
                                        options={getPeopleTaxStatusLevels()} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={16}
                                    />
                                </div>)}
                            </div>
                        </fieldset>
                                         
                        <div className='row mb-2'>
                            <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("exact_matches")}
                                        type='checkbox'
                                        id='EXACTMATCHONLY'
                                        tabIndex={17}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='EXACTMATCHONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.EXACTMATCHONLY' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCHBEHAVIOUR'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.HORSES.LABEL.SEARCHBEHAVIOUR' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    id="SEARCHBEHAVIOUR" 
                                    name='search'
                                    options={searchOptions} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={18}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold'  onClick={handleReset} tabIndex={19}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleHide} tabIndex={20}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={21}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SearchHorsesForm }