import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';
import { Controller, useForm } from 'react-hook-form';
import { KTSVG } from '../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import { date_options, maskDateInput, reactSelectStyles, setDate } from '../../modules/sgl-utils/fieldControls';
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';
import { getShowOptions, getOptionsFromArray, memberCode, searchOptions } from '../../modules/sgl-utils/reactSelectOptions';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { NumericFormat, PatternFormat } from 'react-number-format';
import Select from 'react-select';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
    show: boolean
    onSubmitForm: ({}) => void,
    onClearFilter: () => void,
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchPeopleForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id);

    const participationRoles: string[] = ["Rider", "Trainer", "Owner", "Responsible Party", "Prize Money Recipient"]
    const taxStatusLevels: string[] = ["Requested", "Review", "Manual", "Completed"]
    
    const { register, reset, control, setValue, getValues, watch } = useForm({});
    const context=useOutputContext()

    const accountBalanceOptions = [
        { label: "Any", value: 1 },
        { label: "Greater than or Equal to", value: 2 },
        { label: "Less than or Equal to", value: 3 },
        { label: "Between...", value: 4 }
    ]
    const [shows, setShows] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const selectedOrg = watch('organization_code')
    const [disableParticipationSection, setDisableParticipationSection] = useState(false)
    const participatedAt = watch('participated_at')

    const handleReset = () => {
        reset();
        setValue("start_date","")
        setValue("end_date","")
        setDisableParticipationSection(false)
    }
    const handleSave = async () => {
        var data = getValues();
        onSubmitForm(data);
    }

    useEffect(() => {
        if(shows.length == 0){
            getShows()
            getSearchMetadata()
        }
    }, [shows]);

    useEffect(() => {
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
        if(watch('get_riders_trained')){
            setDisableParticipationSection(true)
        }
        setValue('account_balance_option', 1) // Set default to Any
        setValue('balance_from', 0)
        setValue('balance_to', 0)
    },[]);

    const getShows = async () => {
        const response: any = await axios.get(`${NEST_API_URL}/shows?customer_id=${customerID}`);
        setShows(response?.data?.shows ?? []);
    }

    const getSearchMetadata = async () => {
        const response: any = await axios.get(`${NEST_API_URL}/people/AdvanceSearchMetaData?customer_id=${customerID}`);
        setOrganizations(response?.data?.organizations ?? []);
    }

    // Get membership options for react-select component
    const getMembershipOptions = () => {
        const options = organizations?.map((obj) => ({value: obj.code, label: obj.code}))
        options?.unshift({label: 'Any', value: ''});
        return options;
    }

    const getTaxStatusLevels = () => {
        const options = taxStatusLevels?.map((obj) => ({value: obj, label: obj}))
        options?.unshift({label: 'Any', value: ''});
        return options;
    }

    const resetParticipationData = (value: any) => {
        // Reset participation section fields when get_riders_trained option is checked
        if(value){
            reset({
                participated_as: 'Any',
                participated_at: '',
                number: '',
                name_contains: '',
                member_code: 'Class Between',
                start_date: '',
                end_date: '',
                ref_on_start: '',
                ref_on_end: '',
                ref_on_entries_spending_start: '',
                ref_on_entries_spending_end: '',
                ref_on_entries_balance_start: '',
                ref_on_entries_balance_end: '',
                ref_on_entries_prizes_start: '',
                ref_on_entries_prizes_end: '',
            })
            setDisableParticipationSection(true)
        }else{
            setDisableParticipationSection(false)
        }
      }

      useEffect(() => {
        if(participatedAt || participatedAt === ''){
            if(participatedAt === ''){
                setValue('missing_tax_status_and_payments', false)
            }else{
                setValue('missing_tax_status_and_payments', getValues('missing_tax_status_and_payments'))
            }
        }
    }, [participatedAt])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event:any) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                  handleSave();
                }
                if (event.key === "Escape") {
                    handleClose();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.TITLE.RTOSEARCH' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='FIRSTNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.NAME' })}</label>
                            <div className='col-lg-3'>
                                <input
                                    id='FIRSTNAME'
                                    type='text'
                                    {...register("first_name")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.FIRSTNAME' })}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='LASTNAME'
                                    type='text'
                                    {...register("last_name")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.LASTNAME' })}
                                    tabIndex={2}
                                />
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    {...register("get_riders_trained", {
                                        onChange: (e) => resetParticipationData(e.target.checked)
                                    })}
                                    id='GETRIDERTRAINED'
                                    tabIndex={3}
                                />
                                <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='GETRIDERTRAINED'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.GETRIDERTRAINED' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='ADDRESS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.ADDRESS' })}</label>
                            <div className='col-lg-6'>
                                <input
                                    id='ADDRESS'
                                    type='text'
                                    {...register("address")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.ADDRESS' })}
                                    tabIndex={4}
                                />
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    {...register("on_credit_hold")}
                                    id='ONCREDITHOLD'
                                    tabIndex={5}
                                />
                                <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='ONCREDITHOLD'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.ONCREDITHOLD' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='CITYSTATEZIP'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.CITYSTATEZIP' })}</label>
                            <div className='col-lg-2'>
                                <input
                                    id='CITYSTATEZIP'
                                    type='text'
                                    {...register("city")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.CITY' })}
                                    tabIndex={6}
                                />
                            </div>
                            <div className='col-lg-2'>
                                <input
                                    id='STATE'
                                    type='text'
                                    {...register("state")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.STATE' })}
                                    tabIndex={7}
                                />
                            </div>
                            <div className='col-lg-2'>
                                <input
                                    id='ZIP'
                                    type='text'
                                    {...register("zip")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.ZIP' })}
                                    tabIndex={8}
                                />
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    {...register("on_medical_hold")}
                                    id='ONMEDICALHOLD'
                                    tabIndex={9}
                                />
                                <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='ONMEDICALHOLD'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.ONMEDICALHOLD' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='EMAIL'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.EMAIL' })}</label>
                            <div className='col-lg-6'>
                                <input
                                    id='EMAIL'
                                    type='text'
                                    {...register("email")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.EMAIL' })}
                                    tabIndex={10}
                                />
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    {...register("tin_verified")}
                                    id='TINVERIFIED'
                                    tabIndex={11}
                                />
                                <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='TINVERIFIED'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.TINVERIFIED' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='MEMBERCODE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.MEMBERCODE' })}</label>
                            <div className={selectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                <ReactSelectComponent 
                                    name='organization_code'
                                    id='MEMBERCODE' 
                                    options={getMembershipOptions()} 
                                    control={control} 
                                    methods={null}
                                    tabIndex={12}
                                />
                            </div>
                            <div className={selectedOrg == 'Tax Status' ? 'col-lg-2' : 'col-lg-3'}>
                                <input
                                    id='CODE'
                                    type='text'
                                    {...register("membership_number")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.PLACEHOLDER.CODE' })}
                                    tabIndex={13}
                                />
                            </div>
                            { selectedOrg == 'Tax Status' && (<div className='col-lg-2'>
                                <ReactSelectComponent 
                                    name='tax_status_level'
                                    id='TaxStausLevel' 
                                    options={getTaxStatusLevels()} 
                                    control={control} 
                                    methods={null}
                                    tabIndex={14}
                                />
                            </div>)}
                        </div>
                        <div className='row mb-5'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='PHONENUMBER'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.PHONENUMBER' })}</label>
                            <div className='col-lg-6'>
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    defaultValue=""
                                    render={({field: {onChange, value}}) => (
                                    <PatternFormat
                                        value={getValues('phone_number') == '' || getValues('phone_number') == null ? '' : Number(value)}
                                        id='PHONENUMBER'
                                        format="(###) ###-####"
                                        onValueChange={(e) => {
                                            onChange(e.value)
                                        }}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        tabIndex={15}
                                    />
                                    )}
                                />
                            </div>
                        </div>
                        <fieldset className='mb-5' disabled={disableParticipationSection}>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.GROUPLABEL.PARTICIPATION' })} <b></b></legend>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='PARTICIPATEDAT'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.PARTICIPATEDAS' })}</label>
                                <div className='col-lg-3'>
                                    <ReactSelectComponent 
                                        id='PARTICIPATEDAT'
                                        name='participated_as'
                                        options={getOptionsFromArray(participationRoles, {label: 'Select', value: ''}, {label: 'Any', value: 'Any'} )} 
                                        control={control} 
                                        methods={null}
                                        disabled={disableParticipationSection}
                                        tabIndex={16}
                                    />
                                </div>
                                <div className='col-lg-6 form-check-sm form-check-custom'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        {...register("missing_tax_status_and_payments")}
                                        id='VERIFYMISSINGTAXSTATUSANDPAIDPAYMENTS'
                                        disabled={!(participatedAt && participatedAt !== 'Any')}
                                        tabIndex={17}
                                    />
                                    <label className='col-form-label mx-2 fw-bold fs-5 py-1' htmlFor='VERIFYMISSINGTAXSTATUSANDPAIDPAYMENTS'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.VERIFYMISSINGTAXSTATUSANDPAIDPAYMENTS' })}</label>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='AT'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.AT' })}</label>
                                <div className='col-lg-9'>
                                    <ReactSelectComponent 
                                        id='AT'
                                        name='participated_at'
                                        options={getShowOptions(shows, {label: 'Select', value: ''}, {label: 'Any', value: 'Any'} )} 
                                        control={control} 
                                        methods={null}
                                        disabled={disableParticipationSection}
                                        tabIndex={18}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label d-flex flex-column-reverse fw-bold fs-5 py-1'  htmlFor='CLASS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.INCLASS' })}</label>
                                <div className='col-lg-3'>
                                    <label className='col-form-label fw-bold fs-5 py-0' htmlFor='NUMBER'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.NUMBER' })}</label>
                                    <Controller
                                        name="number"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                        <NumericFormat
                                            {...field}
                                            id="NUMBER"
                                            allowNegative={false}
                                            decimalScale={0}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                            tabIndex={19}
                                        />
                                        )}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-5 py-0' htmlFor='NAMECONTAINS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.NAMECONTAINS' })}</label>
                                    <input
                                        id='NAMECONTAINS'
                                        type='text'
                                        {...register("name_contains")}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.NAMECONTAINS' })}
                                        tabIndex={20}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <ReactSelectComponent 
                                        id='CLASS'
                                        name='member_code'
                                        options={memberCode} 
                                        control={control} 
                                        methods={null}
                                        disabled={disableParticipationSection}
                                        tabIndex={21}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="start_date"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY') // Display format with four-digit year
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                                id="DATESFROM"
                                                                autoComplete="off"
                                                                name={name}
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onClose={(value: any, dateStr: string, instance: { input: { value: string; }; }) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    } 
                                                                    setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                }}
                                                                onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                tabIndex={22}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <label htmlFor='DATESFROM' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-20px'>&</label>
                                        <div className='col ps-0'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="end_date"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY') // Display format with four-digit year
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                                                id="DATESTO"
                                                                autoComplete="off"
                                                                name={name}
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onClose={(value: any, dateStr: string, instance: { input: { value: string; }; }) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    }

                                                                    setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                }}
                                                                onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                                                tabIndex={23}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <label htmlFor='DATESTO' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='REFON'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.REFON' })}</label>
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <Controller
                                                name="ref_on_start"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONSTART"
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={24}
                                                />
                                                )}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-30px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.TO' })}</label>
                                        <div className='col ps-0'>
                                            <Controller
                                                name="ref_on_end"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONEND"
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={25}
                                                />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.TOTALENTRIES' })}</label>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-6 col-form-label fw-bold fs-5 py-1' htmlFor='REFONENTRIESSPENDING'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.REFONENTRIESSPENDING' })}</label>
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <Controller
                                                name="ref_on_entries_spending_start"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESSPENDINGSTART"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={26}
                                                />
                                                )}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-35px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.AND' })}</label>
                                        <div className='col ps-0'>
                                            <Controller
                                                name="ref_on_entries_spending_end"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESSPENDINGEND"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={27}
                                                />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-6 col-form-label fw-bold fs-5 py-1' htmlFor='REFONENTRIESBALANCE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.REFONENTRIESBALANCE' })}</label>
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <Controller
                                                name="ref_on_entries_balance_start"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESBALANCESTART"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={28}
                                                />
                                                )}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-35px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.AND' })}</label>
                                        <div className='col ps-0'>
                                            <Controller
                                                name="ref_on_entries_balance_end"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESBALANCEEND"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={29}
                                                />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-6 col-form-label fw-bold fs-5 py-1' htmlFor='REFONENTRIESPRIZE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.REFONENTRIESPRIZE' })}</label>
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <Controller
                                                name="ref_on_entries_prizes_start"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESPRIZESTART"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={30}
                                                />
                                                )}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-35px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.AND' })}</label>
                                        <div className='col ps-0'>
                                            <Controller
                                                name="ref_on_entries_prizes_end"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                <NumericFormat
                                                    {...field}
                                                    id="REFONENTRIESPRIZEEND"
                                                    allowNegative={false}
                                                    decimalScale={3}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                    tabIndex={31}
                                                />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='mb-5'>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.GROUPLABEL.ACCOUNTBALANCE' })} <b></b></legend>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <Controller
                                        control={control}
                                        name='account_balance_option'
                                        render={({field: { onChange, value }}) => (
                                            <Select
                                                value={accountBalanceOptions.find(opt => opt.value === value) ?? accountBalanceOptions[0]}
                                                options={accountBalanceOptions}
                                                onChange={(e:any) => { onChange(e.value) }}
                                                styles={reactSelectStyles}
                                                tabIndex={32}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col pe-0'>
                                            <input
                                                id='ACCOUNTBALANCESTART'
                                                {...register("balance_from")}
                                                type='text'
                                                disabled={watch('account_balance_option') < 2} 
                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                tabIndex={33}
                                            />
                                        </div>
                                        <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-35px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.AND' })}</label>
                                        <div className='col ps-0'>
                                            <input
                                                id='ACCOUNTBALANCEEND'
                                                {...register("balance_to")}
                                                type='text'
                                                disabled={watch('account_balance_option') < 4}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                tabIndex={34}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='WITHCREDITS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.WITHCREDITS' })}</label>
                                <div className='col-lg-9'>
                                    <ReactSelectComponent 
                                        id='WITHCREDITS'
                                        name='with_credits'
                                        options={getShowOptions(shows, {label: 'Pick Show', value: ''})} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={35}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className='row mb-2'>
                            <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        {...register("exact_match")}
                                        id='EXACTMATCHONLY'
                                        tabIndex={36}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='EXACTMATCHONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.EXACTMATCHONLY' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCHBEHAVIOUR'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.LABEL.SEARCHBEHAVIOUR' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    id="SEARCHBEHAVIOUR"
                                    name='search' 
                                    options={searchOptions} 
                                    control={control} 
                                    methods={null}
                                    tabIndex={37}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={handleReset} tabIndex={38}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={39}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={40}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SearchPeopleForm }