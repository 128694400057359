import { useEffect, useState } from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import {useIntl} from 'react-intl'
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useSchedulerContext } from './SchedulerContext';
import ClassesGrid from './ClassesGrid';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { date_options, time_options_12hours, setDate, setTime, maskDateInput } from '../../../modules/sgl-utils/fieldControls';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AddEditGroup = (props) => {
  const intl = useIntl();

  const [isDisabledForInfoClass, setIsDisabledForInfoClass] = useState(false);
  const [isDisabledForScheduledClass, setIsDisabledForScheduledClass] = useState(false);
  const [isFlatUnderSaddleClass, setIsFlatUnderSaddleClass] = useState(false);
  const [isDisabledForSignupSettings, setIsDisabledForSignupSettings] = useState(false);
  const {customerID, currentShowID, currentClassGroup, selectedDate, selectedRing, showDates, showRings, classGroupScheduleGridRef, classesGridRef, setCurrentClassGroup, getClassGroups, systemPrefs} = useSchedulerContext();
  const alertDialog = useAlert()
  const confirmDialog = useConfirm()

  const { register, handleSubmit, watch, reset, getValues, control, setValue } = useForm({
    defaultValues: currentClassGroup
  });
  const start_time_default = watch("start_time_default", '00:00:00');

  const onSubmit = data => console.log(data);

  const defaultAddData = {
    "schedule_type": "Schedule Class",
    "break_duration": 0,
    "schedule_break": false,
    "day": selectedDate,
    "ring_id": selectedRing,
    "class_list": "",
    "group_name": "",
    "class_time_per_trip": 0,
    "tripgoatonce": false,
    "class_setup_prep_time": 0,
    "signup_publish": systemPrefs && 'signup_publish' in systemPrefs ? systemPrefs.signup_publish : false,
    "ClassGroupxclasses": [],
    "actual_end_time": '',
    "sync_id": "",
    "class_group_id": 0,
    "day_number": 0,
    "estimated_start_time": "",
    "estimated_end_time": "",
    "actual_start_time": "",
    "group_sequence": 0,
    "last_schedule_date": "",
    "last_schedule_time": "",
    "orderofgotype": 0,
    "rotateingroupingsof": 0,
    "total_trips": 0,
    "completed_trips": 0,
    "start_time_default": "",
    "show_id": currentShowID,
    "remaining_trips": 0,
    "customer_id": customerID,
    "sgl_id": 0,
    "history": "",
    "cancelled": false,
    "start_classsignup_date": systemPrefs && 'start_classsignup_date' in systemPrefs ? systemPrefs.start_classsignup_date : "0000-00-00",
    "start_classsignup_time": systemPrefs && 'start_classsignup_time' in systemPrefs ? systemPrefs.start_classsignup_time : "00:00:00",
    "stop_classsignup_date": systemPrefs && 'stop_classsignup_date' in systemPrefs ? systemPrefs.stop_classsignup_date : "0000-00-00",
    "stop_classsignup_time": systemPrefs && 'stop_classsignup_time' in systemPrefs ? systemPrefs.stop_classsignup_time : "00:00:00",
    "set_order_by": "",
    "trips_in_between": 0,
    "order_tool": "",
    "web_order_user_name": "",
    "web_order_user_email": "",
    "jumper_table": "",
    "timeallowed_tripone": 0,
    "timeallowed_jo": 0,
    "reset_order": 0,
    "hunter_type": "",
    "check_in": 0,
    "jogordertrips": 0,
    "hunter_scoreby": "",
    "class_group_uuid": "",
    "set_order": 0,
    "derbytiebreakerone": 0,
    "derbytiebreakertwo": 0,
    "icon": "",
    "duration": 0,
    "started": false,
    "completed": false,
    "edit": "",
    "trips_to_go": "",
    "classesScheduledElseWhere": [],
}

  useEffect(() => {
    reset({ ...defaultAddData, ...currentClassGroup })
  }, [systemPrefs])

  useEffect(() => {
    setIsDisabledForSignupSettings((isDisabledForInfoClass || isFlatUnderSaddleClass))
  }, [isDisabledForInfoClass, isFlatUnderSaddleClass]);

  function scheduleClassChange(e){
    // Disable inputs
    setIsDisabledForInfoClass(false)
    setIsDisabledForScheduledClass(true)

    // Reset Values
    setValue("break_duration", 0)
    setValue("schedule_break", false)
  }

  function infoClassChange(e){
    // Disable inputs
    setIsDisabledForInfoClass(true)
    setIsDisabledForScheduledClass(false)

    // Reset Values
    classesGridRef.setRowData([])
    classGroupScheduleGridRef.setRowData([])
    setValue("class_list", '')
    setValue("signup_publish", false)
    setValue("start_classsignup_date", '0000-00-00')
    setValue("start_classsignup_time", '00:00:00')
    setValue("stop_classsignup_date", '0000-00-00')
    setValue("stop_classsignup_time", '00:00:00')
    setValue("class_time_per_trip", 0)
    setValue("tripgoatonce", false)
    setValue("schedule_break", true)
    setIsFlatUnderSaddleClass(false) // clear under saddle
  }

  function flatUnderSaddleClassChange(e){
    setIsFlatUnderSaddleClass(e.target.checked)
    setValue("tripgoatonce", e.target.checked);
  }

  useEffect(() => {
    reset({...defaultAddData, ...currentClassGroup});
    if(currentClassGroup.schedule_type == "Schedule Break"){
        infoClassChange(null)
    }
    else {
        scheduleClassChange(null)
    }
    setIsFlatUnderSaddleClass(currentClassGroup.tripgoatonce)
    setValue("tripgoatonce", currentClassGroup.tripgoatonce)

    //Set current class group
    props.setCurrentClassGroup({...defaultAddData, ...currentClassGroup})
  }, [currentClassGroup]);

    function changeClassList(e){
        var classListValue = e.currentTarget.value
        if(classListValue && classListValue.length){
            let data = getValues()
            axios.get(`${NEST_API_URL}/scheduler/getclassgroupclasses?customer_id=${customerID}&show_id=${currentShowID}&day=${selectedDate}&ring_id=${selectedRing}&class_group_id=${data['class_group_id']}&class_list=` + classListValue)
                .then(result => {
                    let gridRowsData = [];
                    let firstNonWarmupClassIndex = -1;
                    if(result.data.classes.length > 0){
                        for(let key in result.data.classes){ //Get related classes from the entered class_list string
                            let row = {};
                            row.class_id = result.data.classes[key].class_id;
                            row.class_group_id = data['class_group_id'];
                            row.Class = result.data.classes[key];
                            gridRowsData.push(row)

                            if(!result.data.classes[key].warmup_class && firstNonWarmupClassIndex < 0){
                                firstNonWarmupClassIndex = key
                            }
                        }

                        //Set group data
                        setValue('classesScheduledElseWhere', result.data.classesScheduledElseWhere);
                        setValue('group_name', result.data.classes[(Math.max(0, firstNonWarmupClassIndex))].name);
                        setValue('tripgoatonce', result.data.tripgoatonce);
                        setValue('class_time_per_trip', result.data.class_time_per_trip);
                        
                    }

                    //update classes list for the entered classes list
                    classesGridRef.setRowData(gridRowsData)
                })
                .catch(async function (error) {
                    if (error.response) {
                        setValue('classesScheduledElseWhere', []);
                        alertDialog({message: error.response.data.error, title: 'warning'})
                    }
                });
        }
    }

    const handleClassGroupSave = async (event) => {
        let data = getValues()
        if(data){
            if(data.classesScheduledElseWhere.length > 0){
                let confirmMsg = ''
                if(data.classesScheduledElseWhere.length == 1){
                    confirmMsg = `Class ${data.classesScheduledElseWhere[0].number} is`
                }
                else {
                    let classNos = []
                    for(let classObj of data.classesScheduledElseWhere){
                        classNos.push(classObj.number)
                    }
                    confirmMsg = `Classes ${(classNos.join(', '))} are`
                }
                confirmMsg += " scheduled in another group. Only warmup classes can be scheduled in multiple groups."

                const choice = await confirmDialog({message: confirmMsg, title: 'warning', icon: 'warning', okButtonTitle: intl.formatMessage({id: 'SCHEDULER.MOVEGROUP.BUTTON.MOVE'}), cancelButtonTitle: intl.formatMessage({id: 'SCHEDULER.MOVEGROUP.BUTTON.LEAVE'}) })
                if(choice){
                    saveClassGroup(event);   
                }
            }
            else {
                saveClassGroup(event);
            }
        }
    }

    const saveClassGroup = (event) => { 
        let data = getValues()
        data.start_time_default = data["start_time_default"] ? data["start_time_default"] : '00:00:00'
        data.start_classsignup_date = data["start_classsignup_date"] ? data["start_classsignup_date"] : '0000-00-00'
        data.start_classsignup_time = data["start_classsignup_time"] ? data["start_classsignup_time"] : '00:00:00'
        data.stop_classsignup_date = data["stop_classsignup_date"] ? data["stop_classsignup_date"] : '0000-00-00'
        data.stop_classsignup_time = data["stop_classsignup_time"] ? data["stop_classsignup_time"] : '00:00:00'
        data = {...defaultAddData, ...data}
        
        loadingSpinnerBtnWait(event)
        axios.post(`${NEST_API_URL}/scheduler/addeditclassgroup`,{
            customer_id: customerID,
            show_id: currentShowID,
            day: selectedDate,
            ring_id: selectedRing,
            class_group_id: data['class_group_id'],
            class_group: data
        })
        .then(result => {   
            reset({...defaultAddData}); //reset group for edit
            setCurrentClassGroup({}) //clear selected class group
            getClassGroups(true);
            props.setScheduleStatus('Pending');
        })
        .catch(async function (error) {
            if (error.response) {
                alertDialog({message: error.response.data.error, title: 'warning'});
            }
        })
        .finally(() =>{
            loadingSpinnerBtnRelease(event)
        }); 
    }

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
        <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.ADDEDITGROUP'})} <b></b></legend>
          <div className='row mb-2'>
              <div className="form-group row pe-0"> {/* Column Group */}
              <div className='col-lg-6'>{/* Column 1 */}

                  <div className='row mb-2' style={{ display: 'none'}}>
                      <div className='col-lg-4 '></div>
                      <div className='col-lg-4 d-flex align-items-center'>
                          <div className='form-check-sm form-check-custom  me-2'>
                          <input
                              className='form-check-input'
                              type='radio'
                              id='SCHEDULECLASS' 
                              value = "Schedule Class"                    
                              {...register("schedule_type")}
                              onChange={(e) => scheduleClassChange(e)}
                          />
                          </div>
                          <label className='form-check-label fs-6 py-1' htmlFor="SCHEDULECLASS">{intl.formatMessage({id: 'SCHEDULER.LABEL.SCHEDULECLASS'})}</label>
                      </div>

                      <div className='col-lg-4 d-flex align-items-center'>
                          <div className='form-check-sm form-check-custom  me-2'>
                          <input
                              className='form-check-input'
                              type='radio'
                              id='INFOCLASS'
                              value = "Schedule Break"
                              {...register("schedule_type")}
                              onChange={(e) => infoClassChange(e)}
                          />
                          </div>
                          <label className='form-check-label fs-6 py-1' htmlFor="INFOCLASS">{intl.formatMessage({id: 'SCHEDULER.LABEL.INFOCLASS'})}</label>
                      </div>
                  </div>

                  <div className='row mb-2' style={{ display: 'none'}}>
                      <label className='col-lg-4 col-form-label  fs-5 py-1' htmlFor="DAY">{intl.formatMessage({id: 'SCHEDULER.LABEL.DAY'})}</label>
                      <div className='col-lg-8'>
                          <select
                          id="DAY"
                          className='form-select  form-select-sm fs-6 min-h-20px py-1'
                          {...register("day")}
                          >
                            {
                                showDates.map(date => {
                                    let selectedFormatedDate = moment(selectedDate).format('dddd, MMMM D, YYYY')
                                    let currFormattedDate = moment(date).format('dddd, MMMM D, YYYY')
                                    return <option key={date} value={date} defaultValue={currFormattedDate == selectedFormatedDate}>{currFormattedDate}</option>
                                })
                            }
                      </select>
                      </div>
                  </div>

                  <div className='row mb-2' style={{ display: 'none'}}>
                      <label className='col-lg-4 col-form-label  fs-5 py-1' htmlFor="SCRING">{intl.formatMessage({id: 'SCHEDULER.LABEL.SCRING'})}</label>
                      <div className='col-lg-8'>
                          <select
                          id="SCRING"
                          className='form-select  form-select-sm fs-6 min-h-20px py-1'
                          {...register("ring_id")}
                          >
                          {
                              showRings.map(ring =>
                              <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                          }
                      </select>
                      </div>
                  </div>

                  <div className='row mb-2' style={{ display: 'none'}}>
                      <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='CLASSES'>
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.CLASSES'})}</span>
                      </label>

                      <div className='col-lg-8 '>
                          <input
                          id='CLASSES'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder={intl.formatMessage({id: 'SCHEDULER.LABEL.CLASSES'})}
                          disabled={isDisabledForInfoClass}
                          {...register("class_list")}
                          onBlur={(e) => changeClassList(e)}
                          />
                      </div>
                  </div>

                  <div className='row mb-2' style={{ display: 'none'}}>
                      <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='NAME'>
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.NAME'})}</span>
                      </label>

                      <div className='col-lg-8 '>
                          <input
                          id='NAME'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder={intl.formatMessage({id: 'SCHEDULER.LABEL.NAME'})}
                          {...register("group_name")}
                          />
                      </div>
                  </div>

                  <div className='row mb-2'>
                      <label className='w-200px col-form-label fs-5 py-1' htmlFor='MINPERTRIP' data-tooltip-id="SETPLANNEDTIME.DETAIL.SECTION.ADDEDITGROUP.LABEL.MINUTESPERTRIP">
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.MINPERTRIP'})}</span>
                      </label>

                      <div className='w-150px'>
                          <input
                          id='MINPERTRIP'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder="0"
                          disabled={isDisabledForInfoClass}
                          {...register("class_time_per_trip")}
                          />
                      </div>

                      <div className='col-lg-5 d-flex align-items-center'  style={{ display: 'none'}}>
                          <div className='form-check-sm form-check-custom  me-2' style={{ display: 'none'}}>
                          <input
                              className='form-check-input'
                              type='checkbox'
                              id='FLATUNDERSADDLE'
                              value= '1'
                              disabled={isDisabledForInfoClass}
                              {...register("tripgoatonce")}
                              onChange={flatUnderSaddleClassChange}
                          />
                          </div>
                          <label className='col-form-label fs-5 py-1' htmlFor='FLATUNDERSADDLE' style={{ display: 'none'}}> {intl.formatMessage({id: 'SCHEDULER.LABEL.FLATUNDERSADDLE'})}</label>
                      </div>
                  </div>

                  <div className='row mb-2'>
                      <label className='w-200px col-form-label fs-5 py-1' htmlFor='CLASSSETUPTIME' data-tooltip-id="SETPLANNEDTIME.DETAIL.SECTION.ADDEDITGROUP.LABEL.CLASSSETUPTIME">
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.CLASSSETUPTIME'})}</span>
                      </label>

                      <div className='w-150px'>
                          <input
                          id='CLASSSETUPTIME'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder="0"
                          {...register("class_setup_prep_time")}
                          />
                      </div>
                  </div>

                  <div className='row mb-2'>
                      <label className='w-200px col-form-label  fs-5 py-1' htmlFor='PLANNEDSTART' data-tooltip-id="SETPLANNEDTIME.DETAIL.SECTION.ADDEDITGROUP.LABEL.PLANNEDSTARTTIME">
                      <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.PLANNEDSTART'})}</span>
                      </label>

                      <div className='w-150px'>
                          <div className="input-group date">
                          <Controller
                            name="start_time_default"
                            control = {control}
                            render={({
                                field: { onChange, value, name },
                            }) => (
                                    <Flatpickr 
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="PLANNEDSTART"
                                        placeholder="00 : 00"
                                        name = {name}
                                        value = {value}
                                        options={{ ...time_options_12hours, minuteIncrement: 5 }}
                                        onClose={(value) => setTime(value, onChange)}
                                        onChange={(value) => setTime(value, onChange)}
                                    />
                                )}
                            />
                              <div className="input-group-append">
                                  <label htmlFor='PLANNEDSTART' className='date-label'
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        document.getElementById('PLANNEDSTART')._flatpickr.open(); 
                                    }}
                                  >
                                      <span className="input-group-text">
                                          <i className="la la-clock"></i>
                                      </span>
                                  </label>
                              </div>
                          </div>
                      </div>

                      <label className='col-lg-4 col-form-label  fs-5 py-1'>
                          <span className=''>{ start_time_default && start_time_default !='00:00:00' ? moment(start_time_default, "HH:mm:ss").format('h:mm A') : ''}</span>
                      </label>
                    </div>

                    <div className='row mb-2'>
                        <label className='w-200px col-form-label  fs-5 py-1' data-tooltip-id="SETPLANNEDTIME.DETAIL.SECTION.ADDEDITGROUP.LABEL.ESTIMATEDSTARTTIME">
                            <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.ESTIMAETDSTARTTIME'})}</span>
                        </label>

                        <label className='col-lg-2 col-form-label fs-5 py-1'>
                            <span className=''>{ (watch('estimated_start_time') && moment(getValues('estimated_start_time'), 'HH:mm:ss').format('hh:mm A') )}</span>
                        </label>
                    </div>

                  <div className='row mb-2'  style={{ display: 'none'}}>
                      <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='BREAKDURATION'>
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.BREAKDURATION'})}</span>
                      </label>

                      <div className='col-lg-3 '>
                          <input
                          id='BREAKDURATION'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder="0"
                          disabled={isDisabledForScheduledClass}
                          {...register("break_duration")}
                          />
                      </div>
                  </div>

                  <div className='row mb-2 d-flex mt-3'>
                      <div className='col-lg-2 w-100px me-3'>
                          <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 w-100px ' type='button' onClick={(e) => { handleClassGroupSave(e) }} data-tooltip-id="SETPLANNEDTIME.DETAIL.SECTION.ADDEDITGROUP.BUTTON.SAVE">
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({id: 'SCHEDULER.BUTTON.SAVE' })}
                          </button>
                      </div>
                  </div>

                  <div className='row'>
                      <p className='d-flex justify-content-start mb-0'>{intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.FLATUNDERSADDLE'})}</p>              
                  </div>

                  </div>{/* End Column 1 */}


                  <div className='col-lg-6 pe-0' style={{ display: 'none'}}>{/* Column 2 */}
                      <fieldset className="mt-0">
                      <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.CLASSES'})} <b></b></legend>
                          <div className='grid-classes'>
                              <ClassesGrid ></ClassesGrid>
                          </div>
                      </fieldset>

                      <fieldset className="mt-2" disabled={isDisabledForSignupSettings}>
                      <legend className="fs-6 d-flex fw-bold mb-1">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.SIGNUPSETTINGS'})} <b></b></legend>

                      <div className='row mb-2'>
                          <div className='col-lg-12 d-flex align-items-center'>
                              <div className='form-check-sm form-check-custom me-2'>
                                  <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='PUBLISH'
                                  {...register("signup_publish")}
                                  />
                              </div>
                              <label className='col-form-label  fs-5 py-1' htmlFor='PUBLISH'> {intl.formatMessage({id: 'SCHEDULER.LABEL.PUBLISH'})}</label>
                          </div>
                      </div>

                      <div className='row mb-2'>
                          <label className='col-lg-3 col-form-label  fs-5 py-1 pe-0' htmlFor='STARTSIGNUP'>
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.STARTSIGNUP'})}</span>
                          </label>

                          <div className='col-lg-4 '>
                              <div className="input-group date">
                                <Controller
                                    name="start_classsignup_date"
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                    }) => {
                                        const formattedValue = (value && moment(value).isValid())
                                            ? moment(value).format('MM/DD/YYYY')
                                            : '';

                                        return (
                                            <Flatpickr
                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                id="STARTSIGNUP"
                                                placeholder="MM/DD/YYYY"
                                                options={date_options}
                                                name={name}
                                                value={formattedValue}
                                                onClose={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }

                                                    setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                                }}
                                                onInput={(e) => { maskDateInput(e) }}
                                                autoComplete="off"
                                            />
                                        );
                                    }}
                                />
                                  <div className="input-group-append">
                                      <label htmlFor='STARTSIGNUP' className='date-label'>
                                          <span className="input-group-text">
                                              <i className="la la-calendar"></i>
                                          </span>
                                      </label>
                                  </div>
                              </div>
                          </div>

                          <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='AT'>
                              <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.AT'})}</span>
                          </label>

                          <div className='col-lg-4 '>
                              <div className="input-group date">
                              <Controller
                                name="start_classsignup_time"
                                control = {control}
                                render={({
                                    field: { onChange, value, name },
                                }) => (
                                    <Flatpickr 
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="AT"
                                        placeholder="00 : 00"
                                        name = {name}
                                        value = {value}
                                        options={time_options_12hours}
                                        onClose={(value) => setTime(value, onChange)}
                                        onChange={(value) => setTime(value, onChange)}
                                    />
                                    )}
                                />
                                  <div className="input-group-append">
                                      <label htmlFor='AT' className='date-label'
                                         onClick={(e) => {
                                            e.stopPropagation(); 
                                            document.getElementById('AT')._flatpickr.open(); 
                                        }}
                                      >
                                          <span className="input-group-text">
                                              <i className="la la-clock"></i>
                                          </span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className='row mb-2'>
                          <label className='col-lg-3 col-form-label  fs-5 py-1 pe-0' htmlFor='STOPSIGNUP'>
                          <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.STOPSIGNUP'})}</span>
                          </label>

                          <div className='col-lg-4 '>
                              <div className="input-group date">
                                <Controller
                                    name="stop_classsignup_date"
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                    }) => {
                                        const formattedValue = (value && moment(value).isValid())
                                            ? moment(value).format('MM/DD/YYYY')
                                            : '';

                                        return (
                                            <Flatpickr
                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                id="STOPSIGNUP"
                                                placeholder="MM/DD/YYYY"
                                                options={date_options}
                                                name={name}
                                                value={formattedValue}
                                                onClose={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }
                                                    setDate(dateStr, onChange); // Set and format the date as YYYY-MM-DD
                                                }}
                                                onInput={(e) => { maskDateInput(e) }}
                                                autoComplete="off"
                                            />
                                        );
                                    }}
                                />

                                  <div className="input-group-append">
                                      <label htmlFor='STOPSIGNUP' className='date-label'>
                                          <span className="input-group-text">
                                              <i className="la la-calendar"></i>
                                          </span>
                                      </label>
                                  </div>
                              </div>
                          </div>

                          <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='AT2'>
                              <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.AT'})}</span>
                          </label>

                          <div className='col-lg-4 '>
                              <div className="input-group date">
                              <Controller
                                name="stop_classsignup_time"
                                control = {control}
                                render={({
                                    field: { onChange, value, name },
                                }) => (
                                    <Flatpickr 
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="AT2"
                                        placeholder="00 : 00"
                                        name = {name}
                                        value = {value}
                                        options={time_options_12hours}
                                        onClose={(value) => setTime(value, onChange)}
                                        onChange={(value) => setTime(value, onChange)}
                                    />
                                    )}
                                />
                                  <div className="input-group-append">
                                      <label htmlFor='AT2' className='date-label'
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            document.getElementById('AT2')._flatpickr.open(); 
                                        }}
                                      >
                                          <span className="input-group-text">
                                              <i className="la la-clock"></i>
                                          </span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                      </div>

                      </fieldset>
                  </div>
              </div>
          </div>
        </fieldset>
    </form>
    </>
  );
};

export default AddEditGroup;
  