import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { date_options, maskDateInput, setDate } from '../../../../modules/sgl-utils/fieldControls';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const HeightVerifiedForm = ({ show, handleClose }: Props) => {
    const intl = useIntl()
    const methods = useFormContext()
    const [isValidDate, setIsValidDate] = useState(false);
    const [measurement_override_date, setMeasurement_override_date] = useState(methods.getValues('horse.horse_data.measurement_override_date'));

    const validateDate = () => {
        // let measurementOverrideDate = methods.getValues('horse.horse_data.measurement_override_date')

        if(moment().isAfter(moment(measurement_override_date), 'day')){
            setIsValidDate(true)
        }
        else{
            methods.setValue('horse.horse_data.measurement_override_date', measurement_override_date, {shouldDirty: true})
            setIsValidDate(false)
            handleClose()
        }
    }

    const clearDate = () => {
        methods.setValue('horse.horse_data.measurement_override_date','0000-00-00', {shouldDirty: true})
        handleClose()
    }
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event :any) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    validateDate()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.HEIGHTVERIFIED.TITLE.VERIFIED' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='row mb-2'>
                        <label className='col-lg-5 col-form-label fw-bold fs-5 py-1' htmlFor='measurement_override_date' data-tooltip-id="ENTRIES.DETAIL.MODAL.VERIFYHEIGHT.LABEL.HEIGHTVERIFIEDTHROUGH">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.HEIGHTVERIFIEDTHROUGH' })}</label>

                        <div className='col'>
                            <div className="input-group date">
                                <Flatpickr
                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                    id="measurement_override_date"
                                    value={measurement_override_date && measurement_override_date !== '0000-00-00'
                                        ? moment(measurement_override_date).format('MM/DD/YYYY')
                                        : null}
                                    options={date_options}
                                    autoComplete="off"
                                    onClose={(value: string | any[], dateStr: moment.MomentInput, instance: { input: { value: string; }; }) => {
                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                        if (!isValidDate) {
                                            instance.input.value = ''; // Clear the input if the date is invalid
                                        }
                                        // Set the date with formatting logic
                                        setDate(dateStr, (formattedDate: any) => {
                                            setMeasurement_override_date(formattedDate);
                                        });
                                    }}
                                    onInput={(e: any) => { maskDateInput(e); }}
                                    autoFocus
                                    tabIndex={1}
                                />
                                <div className="input-group-append">
                                    <label htmlFor='measurement_override_date' className='date-label'>
                                        <span className="input-group-text">
                                            <i className="la la-calendar"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isValidDate?
                    <label className='error_message'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.HEIGHTVERIFIED.LABEL.ERROR' })}</label>
                    :null
                    }
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark me-4 fw-bold text-uppercase' onClick={() => clearDate()} tabIndex={3} data-tooltip-id="ENTRIES.DETAIL.MODAL.VERIFYHEIGHT.BUTTON.CLEAR">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.HEIGHTVERIFIED.BUTTON.CLEAR' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => validateDate()} tabIndex={4} data-tooltip-id="ENTRIES.DETAIL.MODAL.VERIFYHEIGHT.BUTTON.EXECUTE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.HEIGHTVERIFIED.BUTTON.EXECUTE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { HeightVerifiedForm }