import { useMemo, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext } from "react-hook-form"
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { SearchInput } from '../SearchInput'
import { useAppSelector } from '../../../../redux/hooks'

import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions'
import { NumericFormat } from 'react-number-format'

type Props = {
    show: boolean
    handleClose: () => void
    fieldName: string
    customer_id: number
    dialogData: any
    setDialogData: any
    person_search_list: any
    person_search_status: string
    default_org: string
    type: any
    show_id: number,
    handleSearchTerm: (search_term :any) => void
    getSelectedPeople:  any
    generateNewRTOId: (people_data: any) => number
    updatePeople: any
    isTransactionalMode: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PersonLookupForm = ({ show, handleClose,customer_id, fieldName, dialogData, setDialogData, person_search_list, person_search_status, default_org, type, show_id, handleSearchTerm, getSelectedPeople, generateNewRTOId, updatePeople, isTransactionalMode = true }: Props) => {
    const intl = useIntl()
    const [organization, setOrganization] = useState(default_org)
    const [resultGridRef, setResultGridRef] = useState<any>(null)
    const [rowData, setRowData] = useState<any>([])
    const [search_status, setSearchStatus] = useState("")
    const [search_updated, setSearchUpdated] = useState(0)
    const [searchedPersonData, setSearchedPersonData] = useState(null)// Keep track of previous search data
    const [searchedOrgName, setSearchedOrgName] = useState('')// Keep track of previous org
    const [enableLookup, setEnableLookup] = useState(false)// Keep track of previous org
    const sglLite = useAppSelector(state => state.showCompany.sgl_lite)
    

    const alertDialog = useAlert()
    const methods = useFormContext();

    const columnDefs = [
        { field: getDisplayField(default_org), headerName: organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.NUM' }) },
        { field: 'lf_name',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.NAME' }) },
        { field: 'state', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.STATE' }) },
        { field: 'member_type', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.TYPE' }) },
        { field: 'competes_in', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.COMPETES' }) },
    ]

    const rowClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.suspended',
            'bg-none': 'data.none',
        };
    }, []);

    useEffect(() => {
        setSearchedPersonData(dialogData)
        setRowData(person_search_list)
        setSearchStatus(person_search_status)
        methods?.setValue(fieldName+'_in_modal', dialogData?.search_term)
        
    }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);

    const onOrganizationChange = (e: any) => {
        let dialog_data = dialogData
        if(!dialog_data['usef_no']){
            dialog_data['usef_no'] = ''
            setDialogData(dialog_data)
        }

        setOrganization(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.' + e.target.value }))
        let columnDefs = resultGridRef.getColumnDefs()
        columnDefs[0].headerName = e.target.value + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.NUM' })
        columnDefs[0].field = getDisplayField(e.target.value) 
        resultGridRef.setColumnDefs(columnDefs)
        setSearchUpdated(search_updated+1)
        // callLookupPersonApi(e.target.value, false)
        methods?.setValue(fieldName+'_in_modal', dialog_data?.search_term)

        let lastNameRef = document.getElementById('LAST');
        let searchRef = document.getElementById(fieldName+'_in_modal');
        let farmNameRef = document.getElementById('FARM_NAME');

        if (farmNameRef) {
            farmNameRef.focus();
        }else if (lastNameRef && e.target.value == 'USEF') {
            setTimeout(() => {
                lastNameRef?.focus()
            }, 100)
        }else if(searchRef){
            searchRef?.focus()
        }
    }

    useEffect(()=>{
        let lastNameRef = document.getElementById('LAST');
        let searchRef = document.getElementById(fieldName+'_in_modal');
        if (lastNameRef && organization == 'USEF') {
            setTimeout(() => {
                lastNameRef?.focus()
            }, 500)
        }else if(searchRef){
            setTimeout(() => {
                searchRef?.focus()
            }, 500)
        }
    },[])
    
    function getDisplayField(org:string){
        if(org === 'USEF') {
            return 'usef_number_ahsa_number'
        } else if(org === 'EC') {
            return 'ec_sln'
        } else if(org === 'FEI') {
            return 'fei_id'
        }
    }

    const callLookup = (event:any) =>{
        if((dialogData.first_name == '' || dialogData.last_name == '') && dialogData.company_name == ''){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.USEFLOOKUP.MISSINGNAME.ERROR' }), title: 'warning'})
        } else {
            callLookupPersonApi( organization, true, event)
        }
    }
    
    const handleKeyDown = (e:any, field:string, display_text:string) => {
        if (e.key === 'Enter') {
            let dialog_data = dialogData
            let error_msg = ''
            if(field == 'usef_no'){
                if(isNaN(e.target.value)){
                    error_msg = 'Invalid '+display_text+' provided.'
                }
            } else {
                dialog_data['usef_no'] = ''
                if(e.target.value != '' && !isNaN(e.target.value)){
                    error_msg = 'Invalid '+display_text+' provided.'
                }
            }
            if(error_msg == ''){
                setSearchUpdated(search_updated+1)
            } else {
                dialog_data[field] = ''
                setDialogData(dialog_data)
                alertDialog({message: error_msg, title: 'warning'})

            }
        }else{
            const isNavigationKey = ['Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key);
            if (isNavigationKey) {
                return;
            }
            const time_out = setTimeout(function(){
                setSearchUpdated(search_updated+1)
            }
            , 1000);
            return () => clearTimeout(time_out);
        }
    }

    const handleKeyPress = (event:any) => {
        let dialog_data = dialogData
        dialog_data['company_name'] = ''
        setDialogData(dialog_data)
        if (event.key === 'Enter') {
            handleSearchTerm(methods.getValues(fieldName+'_in_modal')); 
            setSearchUpdated(search_updated+1)
        }else{
            const isNavigationKey = ['Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key);
            if (isNavigationKey) {
                return;
            }
            const time_out = setTimeout(function(){
                handleSearchTerm(methods.getValues(fieldName+'_in_modal')) 
                setSearchUpdated(search_updated+1)
            }
            , 1000);
            return () => clearTimeout(time_out);
        }
    };

    useEffect(() => {
        if(search_updated > 0){
            callLookupPersonApi(organization, false, null)
        }
    }, [search_updated])

    const submitCancel = () => {
        if(handleClose){
            handleClose()
            setDialogData({
                first_name: '',
                last_name: '',
                city: '',
                state:'',
                usef_no: '',
                search_term: '',
                company_name: ''
            })
        }
    }

    function callLookupPersonApi(org_name:string, lookup_person: boolean, event:any){
        let isDialogDataEmpty = true
        for (const key in dialogData) {
            if (dialogData.hasOwnProperty(key) && dialogData[key] !== null && dialogData[key] !== undefined && dialogData[key] !== '') {
                isDialogDataEmpty = false
            }
        }
        if( (JSON.stringify(searchedPersonData) !== dialogData || searchedOrgName !== org_name || enableLookup !== lookup_person) && !isDialogDataEmpty){
            setSearchedPersonData(dialogData)
            setSearchedOrgName(org_name)
            setEnableLookup(lookup_person);
            (event?.target?.type == "button") && loadingSpinnerBtnWait(event)
            resultGridRef?.showLoadingOverlay()
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/searchPeopleByName', {
                params: {
                customer_id: customer_id,
                org_name: org_name,
                person_role: type,
                show_id: show_id,
                lookup_person: lookup_person,
                dialogData: dialogData
                }
            }) 
            .then(result => { 
                (event?.target?.type == "button") && loadingSpinnerBtnRelease(event)
                setSearchStatus(result.data.people_count_status)
                setRowData(result.data.people_list)
            }).finally(() => {
                resultGridRef?.hideOverlay()
            })
        }
    }

    const selectPeople = () =>{
        var selectedRow = resultGridRef.getSelectedRows()
        if(selectedRow.length > 0){
            if(selectedRow[0].people_id > 0){
                if (isTransactionalMode) {
                    getSelectedPeople(selectedRow[0], organization, type)
                } else {
                    getSelectedPeople(selectedRow[0])
                }
            } else{
                if(selectedRow[0].people_id == 0){
                    selectedRow[0].people_id = generateNewRTOId(methods.getValues('linked_people'))
                   
                }
                console.log("Row = ",  selectedRow[0])
                console.log("PEOPLE all = ",  methods.getValues('linked_people'))
                let people = methods.getValues('linked_people['+ selectedRow[0].people_id +']')
                console.log("PEOPLE = ", people)
                if(people){
                    updatePeople(people, organization, type)     
                } else {
                    getSelectedPeople(selectedRow[0], organization, type)
                }               
            }
            handleClose()
            setDialogData({
                first_name: '',
                last_name: '',
                city: '',
                state:'',
                usef_no: '',
                search_term: '',
                company_name: ''
            })
        }
    }

    const addNewPerson = () =>{
        let newPerson = {
            'people_data': {
                'fl_name': '',
                'lf_name': '',
                'first': '',
                'last_name': '',
                'city': '',
                'state': '',
                'people_id': 0,
                'customer_id': 0,
                'address': '',
                'postal_code': '',
                'social_security_number': '',
                'dob': '0000-00-00',
                'mobile_phone': '',
                'mobile_phone_country_code': '+1',
                'isUpdated': true,
                'safe_sport_training_expirydate': '0000-00-00',
                'isfarmrto': false,
                'company_name': ''
            },
            'memberships_data': []
        }
        if (organization == 'USEF'){
            newPerson.people_data.city = dialogData.city? dialogData.city: ''
            newPerson.people_data.state = dialogData.state? dialogData.state: ''
        }
        if (dialogData?.company_name && dialogData?.company_name?.trim() != '') {
            newPerson.people_data.first = ''
            newPerson.people_data.last_name = ''
            newPerson.people_data.isfarmrto = true;
            newPerson.people_data.company_name = dialogData.company_name.trim();
        } else {
            newPerson.people_data.first = dialogData.first_name ? dialogData.first_name.trim() : '';
            newPerson.people_data.last_name = dialogData.last_name ? dialogData.last_name.trim() : '';
            newPerson.people_data.isfarmrto = false;
            newPerson.people_data.company_name = '';
        }

        newPerson.people_data.fl_name = newPerson.people_data.isfarmrto ? dialogData.company_name.trim() : newPerson.people_data.first +' '+newPerson.people_data.last_name
        newPerson.people_data.lf_name = newPerson.people_data.isfarmrto ? dialogData.company_name.trim() : newPerson.people_data.last_name +', '+newPerson.people_data.first

        if(!newPerson.people_data.fl_name || newPerson?.people_data?.fl_name.trim() == ''){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDRTO.VALIDATION' })})
            return;
        }
        
        newPerson.people_data.people_id = generateNewRTOId (methods.getValues('linked_people'))
        newPerson.people_data.customer_id = customer_id
        newPerson.people_data.isUpdated = true

        newPerson.memberships_data = methods.getValues('people_organization')

        if (isTransactionalMode) {
            // call update people 
            updatePeople(newPerson, organization, type)
        } else {
            // Rapid Class Add Drop
            updatePeople(newPerson.people_data)
        }
        
        handleClose()
        setDialogData({
            first_name: '',
            last_name: '',
            city: '',
            state:'',
            usef_no: '',
            search_term: '',
            company_name: ''
        })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            enforceFocus={false}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={submitCancel}
            onKeyDown={(event :any) => {
                const activeElement = document.activeElement;
                if (event.key === "Enter" && activeElement && activeElement.tagName !== 'BUTTON') {
                    selectPeople()
                }

                if (event.key === 'Escape') {
                    event.stopPropagation()
                    submitCancel()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">
                    {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.TITLE.PERSON' }) + ' ' + organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.TITLE.LOOKUP' })}
                </h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={submitCancel}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body pt-0 pb-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' htmlFor='GOVERNINGORG' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.GOVERNINGORG">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.GOVERNINGORG' })}</label>
                                    <div className='col-lg-1 d-flex align-items-center w-lg-100px ps-6'>
                                        <input className="form-check-sm me-1" type="radio" value="USEF" id="USEF" name='GOVERNINGORG' defaultChecked={organization == 'USEF'} onChange={onOrganizationChange} tabIndex={1}/>
                                        <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='USEF' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.USEF"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.USEF' })}</label>
                                    </div>
                                    <div className='col-lg-1 d-flex align-items-center w-lg-75px ps-6 me-3'>
                                        <input className="form-check-sm me-1" type="radio" value="EC" id="EC" name='GOVERNINGORG' defaultChecked={organization == 'EC'} onChange={onOrganizationChange} tabIndex={1}/>
                                        <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='EC' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.EC"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.EC' })}</label>
                                    </div>
                                    {/* {
                                        sglLite? null: */}
                                        <div className='col-lg-1 d-flex align-items-center w-lg-100px ps-6'>
                                            <input className="form-check-sm me-1" type="radio" value="FEI" id="FEI" name='GOVERNINGORG' defaultChecked={organization == 'FEI'} onChange={onOrganizationChange} tabIndex={1}/>
                                            <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='FEI' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.FEI"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.FEI' })}</label>
                                        </div>
                                    {/* } */}
                                </div>
                            </div>
                            {/* Farm Name Input */}
                            {(type?.toLowerCase() == "owner" || type?.toLowerCase() == "recipient" || type?.toLowerCase() == "person") && (
                                <>
                                <div>
                                    <div className='row'>
                                        <label
                                            className='col-auto col-form-label fs-5 py-1 text-nowrap'
                                            htmlFor='FARM_NAME'
                                            data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.FARM_NAME"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.FARM_NAME' })}
                                        </label>
                                        <div className='col'>
                                            <input
                                                value={dialogData?.company_name}
                                                id='FARM_NAME'
                                                type='text'
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.FARM_NAME' })}
                                                onChange={(e) => {methods?.setValue(fieldName+'_in_modal', ''); setDialogData((prevDialogData: any) => ({ ...prevDialogData, company_name: e.target.value, first_name: '', last_name: '', usef_no: '', search_term: ''}))}}
                                                onKeyDown={(e) => handleKeyDown(e, 'company_name', 'company name')}
                                                tabIndex={7}
                                                // autoFocus
                                                // disabled={dialogData?.first_name || dialogData?.last_name || dialogData?.usef_no}
                                            />
                                        </div>
                                    </div>
                                </div>
                      
                                <hr className='my-2' />
                                </>
                            )}
                            <div className={'col' + (organization != 'USEF' ? ' d-none' : '')}> {/* USEF Section */}
                                <div className='row mb-2'>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-50px' htmlFor='FIRST' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.FIRST">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.FIRST' })}</label>
                                    <div className='col'>
                                        <input
                                            value={dialogData?.first_name}
                                            id='FIRST'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.FIRST' })}
                                            onChange={(e) => setDialogData((prevDialogData: any) => ({...prevDialogData, first_name: e.target.value, company_name: '', search_term: e.target.value != ''? e.target.value + (prevDialogData.last_name != '' ?' ':'')+ prevDialogData.last_name : prevDialogData.last_name == '' ? '' : prevDialogData.last_name}))}
                                            onKeyDown={(e) => handleKeyDown(e, 'first_name', 'first name')}
                                            tabIndex={2}
                                            // disabled={dialogData?.company_name}
                                        />
                                    </div>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-50px' htmlFor='LAST' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.LAST">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.LAST' })}</label>
                                    <div className='col'>
                                        <input
                                            value={dialogData?.last_name}
                                            id='LAST'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.LAST' })}
                                            onChange={(e) => setDialogData((prevDialogData: any) => ({...prevDialogData, last_name: e.target.value, company_name: '', search_term: prevDialogData.first_name != ''? prevDialogData.first_name + (e.target.value != '' ?' ':'')+ e.target.value : e.target.value == '' ? '' : e.target.value}))}
                                            onKeyDown={(e) => handleKeyDown(e, 'last_name', 'last name')}
                                            tabIndex={3}
                                            // disabled={dialogData?.company_name}
                                        />
                                    </div>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-50px' htmlFor='CITY' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.CITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.CITY' })}</label>
                                    <div className='col'>
                                        <input
                                            value={dialogData?.city}
                                            id='CITY'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.CITY' })}
                                            onChange={(e) => setDialogData((prevDialogData: any) => ({...prevDialogData, city: e.target.value}))}
                                            onKeyDown={(e) => handleKeyDown(e, 'city', 'city')}
                                            tabIndex={4}
                                        />
                                    </div>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-50px' htmlFor='STATE' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.STATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.STATE' })}</label>
                                    <div className='col'>
                                        <input
                                        value={dialogData?.state}
                                            id='STATE'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.STATE' })}
                                            onChange={(e) => setDialogData((prevDialogData: any) => ({...prevDialogData, state: e.target.value}))}
                                            onKeyDown={(e) => handleKeyDown(e, 'state', 'state')}
                                            tabIndex={5}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-auto col-form-label fs-5 py-1 w-lg-50px' htmlFor='USEF' data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.LABEL.USEFNUM">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.USEF' })}</label>
                                    <div className='col'>
                                        <NumericFormat
                                            value={dialogData?.usef_no}
                                            id='USEF'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.LABEL.USEFNUMBER' })}
                                            onChange={(e) => setDialogData((prevDialogData: any) => ({...prevDialogData, usef_no: e.target.value, company_name: ''}))}
                                            onKeyDown={(e) => handleKeyDown(e, 'usef_no', 'usef number')}
                                            tabIndex={6}
                                            // disabled={dialogData?.company_name}
                                            allowNegative={false}
                                            decimalScale={0}
                                        />
                                    </div>
                                    <div className='col-3 align-items-center'>
                                        <button type='button' onClick={(event)=>{callLookup(event)}} className="btn btn-sm btn-secondary fw-bold px-2 py-0 col-12 text-uppercase h-30px" tabIndex={7} data-tooltip-id={`ENTRIES.DETAIL.MODAL.PERSONLOOKUP.BUTTON.${organization}NAMELOOKUP`}>
                                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-0 mb-0" role="status" aria-hidden="true" style={{position: "relative",bottom: "1px"}}></span>
                                            {organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.BUTTON.NAMELOOKUP' })}
                                        </button>
                                    </div>
                                </div>
                            </div> {/* End USEF Section */}
                            {organization != 'USEF'? 
                            <div className={'col' + (organization == 'USEF' ? ' d-none' : '')}> {/* EC and FEI Section */}
                            <div className='row'>
                                <div className='col-lg-1 w-lg-325px'>
                                    <div className='row mb-2'>
                                        <SearchInput
                                         id={fieldName+'_in_modal'} 
                                         fieldName={fieldName+'_in_modal'} 
                                         containerClass={'pe-0'} 
                                         tabIndex={8} 
                                         className={"form-control form-control-solid px-11 py-1  bg-white"} 
                                         onSearch={handleKeyPress}
                                        //  disabled={dialogData?.company_name}
                                         handleSearchTerm={handleSearchTerm}
                                         enableFocus={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>: ''} {/* End EC and FEI Section */}
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic
                                 area ={"Entries - PersonLookupForm"}
                                columnDefs={columnDefs}
                                rowClassRules={rowClassRules}
                                rowData={rowData}
                                containerStyle={containerStyle}
                                setGridRef={setResultGridRef}
                                entriesSearchDialog={true}
                                tabIndex={9}
                            ></OutputListingGridStatic>
                        </div>
                        <div className='row'>
                            <label className='col-lg-12 col-form-label fs-5 py-1'>{search_status}</label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' onClick={()=>{addNewPerson();}} className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={10} id={'add_new_person_lookup_form'} data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.BUTTON.ADDNEW">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.BUTTON.ADDNEW' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={submitCancel} tabIndex={11}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' onClick={()=>{selectPeople();}} className='btn btn-sm btn-dark fw-bold text-uppercase' tabIndex={12} data-tooltip-id="ENTRIES.DETAIL.MODAL.PERSONLOOKUP.BUTTON.SELECT">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.PERSONLOOKUP.BUTTON.SELECT' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { PersonLookupForm }