import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import {useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { useForm, FormProvider, Controller } from "react-hook-form";
import moment from 'moment';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { useNavigate } from 'react-router-dom';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory';

// Custom Components
import { maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options } from '../../modules/sgl-utils/fieldControls';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import { ShowsTab } from './Detail/ShowsTab';
import { HistoryTab } from '../../modules/components/HistoryTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };
  

const ShowSeriesDetail = (props) => {
    const intl = useIntl()
    const {show_series_id} = useParams();
    let current_show_series_id = props.id || props.id == 0 ? props.id : show_series_id
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const[loading,setLoading] = useState(true)
    const alertDialog = useAlert()
    const navigate = useNavigate();
    const loadingOverlay = useLoadingOverlay()
    const [errorMessage, setErrorMessage] = useState('')
    const [allShows, setAllShows] = useState([])
    const [showsInSeriesRange, setShowsInSeriesRange] = useState([])
    const [prevData, setPrevData] = useState({});

    const methods = useForm({
        defaultValues: 
        {
            "show_series": {
                "series_name": "",
                "series_start": "",
                "series_end": "",
                "keep_entry_number_during_series": 0,
                "web_allow_add_entry_to_series": 0,
                "require_all_shows": 0,
                "web_replicate_classes": 0,
                "web_replicate_fee": 0,
                "web_add_entry_text": ""
              },
            "show": [],
            "added_shows": []
        }
    }); 
    const [checkedAllowAddEntry, setCheckedAllowAddEntry] = useState(true)
    const [rowClicked, setRowClicked] = useState("")
    const [record_id, setRecordId] = useState("")
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [open_forms, setOpenForms] = useState([])
    const [gridRowData, setGridRowData] = useState('')

    const getDetailPageInfo = (id, prevData, area, component, data) => {
        setRecordId(id)
        setSubArea(area)
        setPrevData(prevData)
        setChildComponent(component)
        sessionStorage.setItem("dialog_is_open_in_show_series", true)
        setRowClicked(rowClicked => !rowClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        setOpenForms(forms_open)
        setGridRowData(data)
      };

      const tabs = ["SHOWS", "HISTORY"]
      const componentTags = [
        <ShowsTab getDetailPageInfo={getDetailPageInfo} showsInSeriesRange={showsInSeriesRange}/>,
        <HistoryTab area="Class Rules" rowData={methods.getValues("change_history_logs") ?? []} />
      ]
      const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	  });
    
    // To open same detail page multiple times 
    useEffect(() => {
    if(sessionStorage.getItem("dialog_is_open_in_show_series") == "true"){
        sessionStorage.removeItem("dialog_is_open_in_show_series")
        setRowClicked(true)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        if(open_forms.includes(sub_area+"_"+record_id) == false){
            forms_open.push(sub_area+"_"+record_id);
            sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
        }
    }
    }, [rowClicked])
 
    const [update_data, setUpdateData] = useState(0)
    const updateData = () => {
      setUpdateData(update_data + 1)
    };

    const { handleSubmit, reset, formState, getValues, setFocus} = methods
    const { dirtyFields } = formState;
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    
	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    const onSubmit = async (form_data) => {
        var data = methods.getValues();
        var error_message = ''
        if(data?.show_series?.series_start > data?.show_series?.series_end){
            error_message = 'Start date must not be greater than end date.';
        }
        setErrorMessage(error_message);

        if(error_message == ''){
            const next_button = document.getElementById('next-btn');
            const last_button = document.getElementById('last-btn');
            const prev_button = document.getElementById('prev-btn');
            const first_button = document.getElementById('first-btn');
    
            // means detail page is not open in modal
            if(props?.id === undefined){
                next_button.disabled = false;
                prev_button.disabled = false;
                last_button.disabled = false;
                first_button.disabled = false;
            }

            let submit_data = dirtyValues(dirtyFields, form_data)
            loadingOverlay({show: true})
            return axios.post( process.env.REACT_APP_NEST_API_URL + '/show-series/detail', {
            params: {
                show_series_id: show_series_id,
                customer_id: customer_id,
                data: submit_data
            }
            }).then((response) => {
                loadingOverlay({show: false})
                if(response.data.success) {
                    // Use Case (Param Id changed): Next/Prev buttons
                    if(props?.id == undefined && Number(methods.getValues('show_series.show_series_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                        if(row_selected && row_selected != "" && row_ids[row_selected]){
                            setRowClicked(false)
                            setLoading(true)
                            history.push(`${PUBLIC_URL}/showseries/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                        }
                    }
                    else if(props?.id == undefined && current_show_series_id > 0){
                        history.push(`${PUBLIC_URL}/showseries`, { allowNavigation: true })
                    }
                    else if(props?.id == undefined && current_show_series_id == 0){
                        history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
                    }
                    if( row_selected == row_ids?.length -1 && props?.id === undefined){
                        if (next_button && last_button) {
                            next_button.disabled = true;
                            last_button.disabled = true;
                            prev_button.disabled = false;
                            first_button.disabled = false;
                        }
                    }
                    if(row_selected == 0 && props?.id === undefined){
                        if (prev_button && first_button) {
                            prev_button.disabled = true;
                            first_button.disabled = true;
                            next_button.disabled = false;
                            last_button.disabled = false;
                        }
                    }
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
            })
        }
    }

    useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/show-series/detail', {
            params: {
              show_series_id: show_series_id,
              customer_id: customer_id,
            }
          }),
          axios.get( process.env.REACT_APP_NEST_API_URL + '/show-series/metaData', {
            params: {
              show_series_id: show_series_id,
              customer_id: customer_id,
            }
          }),
        ]  
        ).then (
          axios.spread( ({data : showSeriesData}, {data : showSeriesMetaData}) => {
            //Check if record id is invalid then redirect to list page
            if ((showSeriesData || showSeriesData === '') && isInvalidRecord(showSeriesData.show_series, show_series_id, 'show_series_id')){
                navigate('/showseries')
                loadingOverlay({show: false})
            }

            const moment = require('moment');
            for(let show of showSeriesMetaData.showsNotInSeries){
                if(show.start_date && show.start_date != '0000-00-00' && show.end_date && show.end_date != '0000-00-00'){
                    show.label = `${show.label} (${moment?.utc(show.start_date).format('MM/DD/YYYY')} - ${moment?.utc(show.end_date).format('MM/DD/YYYY')})`
                }
            }
            setAllShows(showSeriesMetaData.showsNotInSeries)

            if(props?.id === undefined && Number(methods.getValues('show_series.show_series_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                reset(showSeriesData)
            }else{
                reset(showSeriesData, {keepDirtyValues: true})
            }
            setCheckedAllowAddEntry(methods.getValues('show_series.web_allow_add_entry_to_series') !== 0 ? true : false)
       
            sessionStorage.setItem('forms_open', JSON.stringify(["ShowSeries_"+Number(show_series_id)]));
            // Hide loading overlay
			loadingOverlay({show: false})
            setLoading(false)
          })
        )
    },[show_series_id, update_data])

    useAutoFocus('SERIES', loading, update_data)

    useEffect(() => {
        setShowsInSeriesRange(allShows.filter(s => s.start_date >= methods.watch('show_series.series_start') && s.end_date <= methods.watch('show_series.series_end')))
    }, [methods.watch('show_series.series_start'), methods.watch('show_series.series_end'), allShows])

    return (
        <>
        {!loading && (
		<>
        {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('show_series.series_name'), methods.getValues('show_series.show_series_id')]}>{intl.formatMessage({id: 'MENU.SHOWSERIES'})}</PageTitle> : ""}
         <FormProvider {...methods}>
            <div id={"showseries_detail_page"} className='modal-body py-3 px-4 showfee-input-form input-form'>
                <form id="ShowSeriesDetail" noValidate className='form max-width'  onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                <HelpLink tooltip="SHOWSERIES.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row">
                            <div className='row mb-2'> {/* Column Group */}
                                <div className='col-lg-6'> {/* Column 1 */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-100px' htmlFor='SERIES' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.SERIES">{intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.SERIES' })}</label>
                                        <div className='col-lg-9'>
                                            <input
                                                 {...methods.register( 'show_series.series_name', 
                                                    {
                                                    required: "Show series name is required."
                                                    }
                                                  )
                                                 }
                                                id='SERIES'
                                                type='text'
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                // placeholder={intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.SERIES' })}
                                            />
                                            { methods.formState.errors?.show_series?.series_name && <div><span className='error_message'>{methods.formState.errors.show_series.series_name?.message}</span></div>}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-100px' htmlFor='series_start' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.SERIESSTART">{intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.SERIESSTART' })}</label>
                                        <div className='col-lg-4'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="show_series.series_start"
                                                    render={({
                                                        field: { onChange, value, name },
                                                    }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY')
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                                id="series_start"
                                                                name={name}
                                                                autoComplete="off"
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onClose={(value, dateStr, instance) => {
                                                                    setErrorMessage('');
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    }
                                                                    setDate(dateStr, onChange);
                                                                }}
                                                                onInput={(e) => { maskDateInput(e) }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <label htmlFor='series_start' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {(errorMessage != '') &&
                                            <div><span className='error_message' style={{paddingLeft: "100px"}}>{errorMessage}</span></div>
                                        }
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-100px' htmlFor='series_end' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.SERIESEND">{intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.SERIESEND' })}</label>
                                        <div className='col-lg-4'>
                                            <div className="input-group date">
                                                <Controller
                                                    name="show_series.series_end"
                                                    render={({
                                                        field: { onChange, value, name },
                                                    }) => {
                                                        const formattedValue = (value && moment(value).isValid())
                                                            ? moment(value).format('MM/DD/YYYY')
                                                            : '';

                                                        return (
                                                            <Flatpickr
                                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                                id="series_end"
                                                                name={name}
                                                                autoComplete="off"
                                                                value={formattedValue}
                                                                placeholder="MM/DD/YYYY"
                                                                options={date_options}
                                                                onClose={(value, dateStr, instance) => {
                                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                    if (!isValidDate) {
                                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                                    }
                                                                    setErrorMessage('');
                                                                    setDate(dateStr, onChange);
                                                                }}
                                                                onInput={(e) => { maskDateInput(e) }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <label htmlFor='series_end' className='date-label'>
                                                        <span className="input-group-text">
                                                            <i className="la la-calendar"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px'></label>
                                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                                            <input
                                                {...methods.register( 'show_series.keep_entry_number_during_series', 
                                                    {
                                                    required: false
                                                    }
                                                  )
                                                }
                                                className='form-check-input'
                                                type='checkbox'
                                                id='KEPPENTRYNUMBER'
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='KEPPENTRYNUMBER' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.KEEPENTRYDATADURINGSERIES"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.KEPPENTRYNUMBER' })}</label>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px'></label>
                                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                                            <input
                                                {...methods.register( 'show_series.web_allow_add_entry_to_series', 
                                                       {
                                                       required: false
                                                       }
                                                    )
                                                }
                                                className='form-check-input'
                                                type='checkbox'
                                                id='ALLOWADDENTRY'
                                                onChange={(e) => {
                                                    setCheckedAllowAddEntry(e.target.checked)
                                                    methods.setValue("show_series.web_allow_add_entry_to_series", e.target.checked, {shouldDirty: true})
                                                    methods.setValue("show_series.web_replicate_classes", false, {shouldDirty: true})
                                                    methods.setValue("show_series.web_replicate_fee", false, {shouldDirty: true})
                                                }}
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='ALLOWADDENTRY' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.ALLOWADDENTRYTOSERIESFROMWEB"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.ALLOWADDENTRY' })}</label>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px'></label>
                                        <div className='col form-check-sm form-check-custom ps-lg-10'>
                                            <input
                                                {...methods.register( 'show_series.web_replicate_classes', 
                                                        {
                                                        required: false
                                                        }
                                                    )
                                                }
                                                className='form-check-input'
                                                type='checkbox'
                                                id='ADDCLASSESFROMWEB'
                                                disabled={!checkedAllowAddEntry}
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='ADDCLASSESFROMWEB' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.ADDCLASSESFROMWEB"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.ADDCLASSESFROMWEB' })}</label>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px'></label>
                                        <div className='col form-check-sm form-check-custom ps-lg-10'>
                                            <input
                                                {...methods.register( 'show_series.web_replicate_fee', 
                                                        {
                                                        required: false
                                                        }
                                                    )
                                                }
                                                className='form-check-input'
                                                type='checkbox'
                                                id='ADDFEEFROMWEB'
                                                disabled={!checkedAllowAddEntry}
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='ADDFEEFROMWEB' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.ADDFEEFROMWEB"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.ADDFEEFROMWEB' })}</label>
                                        </div>
                                    </div>
                                </div> {/* End Column 1 */}
                                <div className='col-lg-6'> {/* Column 2 */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1' style={{ width: '155px' }}></label>
                                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                                            <input
                                                 {...methods.register( 'show_series.require_all_shows', 
                                                    {
                                                    required: false
                                                    }
                                                  )
                                                }
                                                className='form-check-input'
                                                type='checkbox'
                                                id='REQUIREALLSHOWS'
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='REQUIREALLSHOWS' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.REQUIREALLSHOWS"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.REQUIREALLSHOWS' })}</label>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1' style={{ width: '155px' }} htmlFor='WEBADDENTRYTEXT' data-tooltip-id="SHOWSERIES.DETAIL.LABEL.WEBADDENTRYTEXT">{intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.LABEL.WEBADDENTRYTEXT' })}</label>
                                        <div className='col'>
                                            <textarea
                                                {...methods.register( 'show_series.web_add_entry_text', 
                                                    {
                                                    required: false
                                                    }
                                                  )
                                                }
                                               
                                                id="WEBADDENTRYTEXT"
                                                rows={5}
                                                className='form-control h-100 form-control-sm fs-6'
                                            ></textarea>
                                        </div>
                                    </div>
                                </div> {/* End Column 2 */}
                            </div> {/* End Column Group */}
                            {/* <div className='row mb-2'>
                                <OutputListingGridStatic onRowDoubleClicked={(row_data) => {getDetailPageInfo(row_data.show_id, "Shows", "ShowDetail", row_data)}} area ={"Show Series - Detail"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                            </div> */}
                        </div> {/* End Card Body */}
                        <div className='d-flex flex-column my-2 border p-2 bg-white'>
                            <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                <a className={"nav-link text-active-dark px-6 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 9 ? ' min-w-140px text-center' : '') }
                                                    tabIndex="-1"
                                                    id={tab + "-tab"}
                                                    data-bs-toggle='tab'
                                                    href={"#" + tab + "-Show-Series-" +current_show_series_id}
                                                    data-tooltip-id={`SHOWSERIES.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.SHOW.SERIES.TAB.' + tab })}
                                                </a>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        
                            <div className="tab-content mt-5 pb-2" id="myTabContent">
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Show-Series-" +current_show_series_id} role="tabpanel" >
                                                <HelpLink tooltip={"SHOWSERIES.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                {componentTags[index]}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/showseries'} data={'show_series'}/>
                </form>
            </div>
        </FormProvider>
        {rowClicked && <DetailPageModal prev_data={prevData} area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData}/>}
        </>
    	)}
        </>
    );
}

export { ShowSeriesDetail }